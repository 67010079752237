import React, { useState, useRef } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../Auth/helper";

function WeekPlan({ menus, theDate, setTheDate, start, end }) {
  const navigate = useNavigate();
  const [mappedMenus, setMappedMenus] = useState([]);

  const weekdays = [
    "#",
    "#",
    "Weekday",
    "Lun",
    "Mar",
    "Mié",
    "Jue",
    "Vie",
    "Sáb",
    "Dom",
    "#",
    "#",
  ];

  // console.log("start ", start, " end ", end);

  useEffect(() => {
    // console.log(menus);
    const mappedMenus = [];
    mappedMenus.push("#");
    mappedMenus.push("#");
    mappedMenus.push("#");
    if (menus && menus.length > 0) {
      // console.log(start);
      let nextDay = start;
      for (let d = 0; d < 7; d++) {
        // console.log(d, nextDay);
        // console.log(`menus[${d}]`, menus[d]);
        // const menuDateString = new Date(menus[d].date).toDateString();
        const weekdayDateString = new Date(nextDay).toDateString();
        // console.log(weekdayDateString);
        const matchedDateMenu = menus.filter((menu) => {
          const menuDateString = new Date(menu.date).toDateString();
          // console.log(menuDateString, weekdayDateString);
          return menuDateString === weekdayDateString;
        });
        // console.log("matchedDateMenu ", matchedDateMenu);
        if (matchedDateMenu?.length > 0) {
          mappedMenus.push(matchedDateMenu[0]);
        } else mappedMenus.push({});
        // next day
        nextDay = new Date(new Date(nextDay).setDate(nextDay.getDate() + 1));
      }
      // mappedMenus.map((w, indx) => {console.log(indx);});
    } else {
      for (let d = 0; d < 7; d++) {
        mappedMenus.push({});
      }
    }
    mappedMenus.push("#");
    mappedMenus.push("#");
    // console.log("mappedMenus ", mappedMenus);
    setMappedMenus(mappedMenus);
  }, [menus, theDate]);

  // function handleOnClick(id) {
  function handleOnClick(menu) {
    const date = new Date(menu.date);
    // console.log(formatDate(date));
    setTheDate(date);
    // navigate(`/recipes/${id}`);
  }
  const options = { year: "numeric", month: "long" };
  let verticalPlan = new Array(4);
  for (let i = 0; i < 4; i++) {
    verticalPlan[i] = new Array(13);
  }
  function printVerticalTable() {
    let reactElement;
    for (let j = 0; j < 13; j++) {
      for (let i = 0; i < 4; i++) {
        console.log(verticalPlan[i][j]);
        reactElement += verticalPlan[i][j];
      }
    }
    return reactElement;
  }

  return (
    <div
      className="container text-center"
      style={{
        backgroundColor: "#efcb6a",
        marginBottom: "20px",
        // padding: "20px",
        borderRadius: "10px",
      }}
    >
      <h1 style={{ color: "black" }}>WeekPlan</h1>
      {/* {indx}
      {start.toDateString()}
      IMPORTANT: -3 cause array of 12 elements and only 2 starting */}
      <h4 style={{ color: "black" }}>
        {start.toLocaleDateString("de-DE", options)}
      </h4>
      <div
      // style={{
      //   border: "0.5px solid black",
      // }}
      >
        <div className="large-screen">
          <div className="row">
            {weekdays.map((w, indx) => {
              const reactElement =
                indx === 0 ? (
                  <div key={w + indx}></div>
                ) : (
                  <div
                    key={w + indx}
                    className={indx === 2 ? "col-2" : "col"}
                    style={{
                      minWidth: w === "#" ? "0" : "50px",
                      border:
                        w === "#" ? "0px solid white" : "0.5px solid black",
                    }}
                  >
                    {w === "#" ? null : (
                      <>
                        {indx === 2
                          ? w
                          : new Date(
                              new Date(start).setDate(
                                start.getDate() + indx - 3
                              )
                            ).getDate()}
                      </>
                    )}
                  </div>
                );
              verticalPlan[0][indx] = reactElement;
              return reactElement;
            })}
          </div>
          {/* Weekday */}
          <div className="row">
            {weekdays.map((w, indx) => {
              const reactElement =
                indx === 0 ? (
                  <div key={w + indx}></div>
                ) : (
                  <div
                    key={w + indx}
                    className={indx === 2 ? "col-2" : "col"}
                    style={{
                      minWidth: w === "#" ? "0" : "50px",
                      border: w === "#" ? "none" : "solid 0.5px black",
                    }}
                  >
                    {w === "#" ? null : w}
                  </div>
                );
              verticalPlan[1][indx] = reactElement;
              return reactElement;
            })}
          </div>
          {/* Lunch */}
          <div className="row">
            {mappedMenus?.map((w, indx) => {
              // console.log(Object.keys({}).length);
              const showIt = !(w === "#");
              const isEmpty = !(Object.keys(w).length > 1);
              const reactElement =
                indx === 0 ? (
                  <div key={`div-${indx}`}></div>
                ) : (
                  <div
                    key={`div-${indx}`}
                    className={indx === 2 ? "col-2" : "col"}
                    style={{
                      border:
                        w === "#" && indx !== 2 ? "none" : "solid 0.5px black",
                      // border: indx === "0" ? "none" : "solid 0.5px black",
                      minWidth: !showIt ? "0" : "50px",
                    }}
                  >
                    {isEmpty ? (
                      <>
                        {showIt ? (
                          <FiberManualRecordIcon
                            style={{ color: "red" }}
                            onClick={() => {
                              const date = new Date(
                                new Date(start).setDate(
                                  start.getDate() + indx - 3
                                )
                              );
                              w.date = date;
                              // console.log(w);
                              handleOnClick(w);
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              // border:
                              //   w === "#" && indx !== 2
                              //     ? "none"
                              //     : "solid 0.5px black",
                              minWidth: !showIt ? "0" : "50px",
                            }}
                          >
                            {indx === 2 ? "Lunch" : null}
                          </div>
                        )}
                      </>
                    ) : (
                      <OverlayTrigger
                        // key={indx}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`tooltip-${indx}`}>
                            Lunch{" "}
                            <strong>
                              {w.lunch.courses.map((c, i) => {
                                // console.log(c.name);
                                const course = (i === 0 ? "" : " + ") + c.name;
                                // console.log(course);
                                return course;
                                // + (i === 0) ? "" : " + ";
                              })}
                            </strong>{" "}
                            on {w.weekday} the {new Date(w.date).toDateString()}
                          </Tooltip>
                        }
                      >
                        <FiberManualRecordIcon
                          style={{ color: "green" }}
                          // onClick={() => handleOnClick(w.lunch[0].id)}
                          onClick={() => handleOnClick(w)}
                        />
                      </OverlayTrigger>
                    )}
                  </div>
                );
              verticalPlan[2][indx] = reactElement;
              return reactElement;
            })}
          </div>
          {/* Dinner */}
          <div className="row">
            {mappedMenus?.map((w, indx) => {
              // console.log(Object.keys({}).length);
              const showIt = !(w === "#");
              const isEmpty = !(Object.keys(w).length > 1);
              const reactElement =
                indx === 0 ? (
                  <div key={w + indx}></div>
                ) : (
                  <div
                    key={`div-${indx}`}
                    className={indx === 2 ? "col-2" : "col"}
                    style={{
                      // border: w === "#" ? "none" : "solid 0.5px black",
                      border:
                        w === "#" && indx !== 2 ? "none" : "solid 0.5px black",
                      minWidth: !showIt ? "0" : "50px",
                    }}
                  >
                    {isEmpty ? (
                      <>
                        {showIt ? (
                          <FiberManualRecordIcon
                            style={{ color: "red" }}
                            onClick={() => {
                              const date = new Date(
                                new Date(start).setDate(
                                  start.getDate() + indx - 3
                                )
                              );
                              w.date = date;
                              // console.log(w);
                              handleOnClick(w);
                            }}
                          />
                        ) : (
                          <>{indx === 2 ? "Dinner" : null}</>
                        )}
                      </>
                    ) : (
                      <OverlayTrigger
                        // key={indx}

                        placement={"top"}
                        overlay={
                          <Tooltip id={`tooltip-${indx}`}>
                            Dinner{" "}
                            <strong>
                              {w.dinner.courses.map((c, i) => {
                                // console.log(c.name);
                                const course = (i === 0 ? "" : " + ") + c.name;
                                // console.log(course);
                                return course;
                                // + (i === 0) ? "" : " + ";
                              })}
                            </strong>{" "}
                            on {w.weekday} the {new Date(w.date).toDateString()}
                          </Tooltip>
                        }
                      >
                        <FiberManualRecordIcon
                          style={{ color: "green" }}
                          // onClick={() => handleOnClick(w.dinner[0].id)}
                          onClick={() => handleOnClick(w)}
                        />
                      </OverlayTrigger>
                    )}
                  </div>
                );
              verticalPlan[3][indx] = reactElement;
              return reactElement;
            })}
          </div>
          {/* <div className="row" style={{ backgroundColor: "#efcb6a" }}>
        {weekdays.map((w,indx) => (
          <div key={w+indx} className="col" style={{ minWidth: w === "#" ? "0" : "50px" }}>
            {w === "#" ? null : (
              <FiberManualRecordIcon style={{ color: "red" }} />
            )}
          </div>
        ))}
      </div> */}
          {/* {console.log(menus)} */}
          {/* <div>
        {mappedMenus?.map((menu, indx) => (
          <div key={indx}>
            {menu.lunch}
            {menu.dinner}
            {menu.date}
            {menu.weekday}
          </div>
        ))}
      </div> */}
        </div>
      </div>
      {/* <div>{printVerticalTable()}</div> */}
      <div className="mt-3 mobile-first">
        {mappedMenus?.map((w, indx) => {
          // console.log("(w, indx)", w, indx);
          // console.log("(indx) weekdays[indx] w", indx, weekdays[indx], w);
          // console.log(Object.keys({}).length);
          const showIt = !(w === "#");
          const isEmpty = !(Object.keys(w).length > 1);
          const reactElement =
            indx === 0 || indx === 1 || indx === 10 || indx === 11 ? null : (
              <div className="row" key={w + indx + `div-${indx}-${w}`}>
                <div
                  key={w + indx}
                  className={indx === 2 ? "col-6" : "col"}
                  style={{
                    minWidth: weekdays[indx] === "#" ? "0" : "50px",
                    border:
                      weekdays[indx] === "#" ? "none" : "solid 0.5px black",
                  }}
                >
                  {weekdays[indx] === "#" ? null : weekdays[indx]}
                </div>
                {indx === 2 ? null : (
                  <div
                    key={w + indx + indx}
                    className={indx === 2 ? "col-2" : "col"}
                    style={{
                      minWidth: w === "#" ? "0" : "50px",
                      border:
                        w === "#" ? "0px solid white" : "0.5px solid black",
                    }}
                  >
                    {w === "#" ? null : (
                      <>
                        {indx === 2
                          ? w // + "jhfhjfd"
                          : new Date(
                              new Date(start).setDate(
                                start.getDate() + indx - 3
                              )
                            ).getDate()}
                      </>
                    )}
                  </div>
                )}
                <div
                  key={`div-${indx}-${w}`}
                  className={indx === 2 ? "col-3" : "col"}
                  style={{
                    border:
                      w === "#" && indx !== 2 ? "none" : "solid 0.5px black",
                    // border: indx === "0" ? "none" : "solid 0.5px black",
                    minWidth: !showIt ? "0" : "50px",
                  }}
                >
                  {isEmpty ? (
                    <>
                      {showIt ? (
                        <FiberManualRecordIcon
                          style={{ color: "red" }}
                          onClick={() => {
                            const date = new Date(
                              new Date(start).setDate(
                                start.getDate() + indx - 3
                              )
                            );
                            w.date = date;
                            // console.log(w);
                            handleOnClick(w);
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            // border:
                            //   w === "#" && indx !== 2
                            //     ? "none"
                            //     : "solid 0.5px black",
                            minWidth: !showIt ? "0" : "50px",
                          }}
                        >
                          {indx === 2 ? "Lunch" : null}
                        </div>
                      )}
                    </>
                  ) : (
                    <OverlayTrigger
                      // key={indx}
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-${indx}`}>
                          Lunch{" "}
                          <strong>
                            {w.lunch.courses.map((c, i) => {
                              // console.log(c.name);
                              const course = (i === 0 ? "" : " + ") + c.name;
                              // console.log(course);
                              return course;
                              // + (i === 0) ? "" : " + ";
                            })}
                          </strong>{" "}
                          on {w.weekday} the {new Date(w.date).toDateString()}
                        </Tooltip>
                      }
                    >
                      <FiberManualRecordIcon
                        style={{ color: "green" }}
                        // onClick={() => handleOnClick(w.lunch[0].id)}
                        onClick={() => handleOnClick(w)}
                      />
                    </OverlayTrigger>
                  )}
                </div>
                <div
                  key={`div-${indx}`}
                  className={indx === 2 ? "col-3" : "col"}
                  style={{
                    border:
                      w === "#" && indx !== 2 ? "none" : "solid 0.5px black",
                    minWidth: !showIt ? "0" : "50px",
                  }}
                >
                  {isEmpty ? (
                    <>
                      {showIt ? (
                        <FiberManualRecordIcon
                          style={{ color: "red" }}
                          onClick={() => {
                            const date = new Date(
                              new Date(start).setDate(
                                start.getDate() + indx - 3
                              )
                            );
                            w.date = date;
                            // console.log(w);
                            handleOnClick(w);
                          }}
                        />
                      ) : (
                        <>{indx === 2 ? "Dinner" : null}</>
                      )}
                    </>
                  ) : (
                    <OverlayTrigger
                      // key={indx}
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-${indx}`}>
                          Dinner{" "}
                          <strong>
                            {w.dinner.courses.map((c, i) => {
                              // console.log(c.name);
                              const course = (i === 0 ? "" : " + ") + c.name;
                              // console.log(course);
                              return course;
                              // + (i === 0) ? "" : " + ";
                            })}
                          </strong>{" "}
                          on {w.weekday} the {new Date(w.date).toDateString()}
                        </Tooltip>
                      }
                    >
                      <FiberManualRecordIcon
                        style={{ color: "green" }}
                        // onClick={() => handleOnClick(w.dinner[0].id)}
                        onClick={() => handleOnClick(w)}
                      />
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            );
          return reactElement;
        })}
      </div>
    </div>
  );
}

export default WeekPlan;
