// import React, { useState, useRef, useEffect } from "react";
import React, { useState, useRef, useEffect } from "react";
import { setMessage } from "../../actions/action-creators/message";

import GrowingExtendedListItem from "./GrowingExtendedListItem";

export default function ListItems(props) {
  const [items, setItems] = useState(props.items);
  const [editMode, setEditMode] = useState(
    props.items.map((v, i) => {
      return false;
    })
  );

  let collection = props.title;

  const setTheEditMode = (idx, x) => {
    // console.log("hello", idx);
    setEditMode((prevValue) => {
      // console.log("hello", idx, prevValue[idx],x);
      prevValue[idx] = x;
      return prevValue;
    });
  };

  const onChange = (idx, x) => {
    // setIsDisabled(false);
    // setItem(value);
    setItems((prevValue) => {
      console.log("ListItems()::onChange", idx, prevValue[idx], x);
      prevValue[idx] = x;
      return prevValue;
    });
    console.log("idx, x ", idx, x);
  };

  useEffect(() => {}, [editMode]);
  // const numbers = [1, 2, 3, 4, 5];
  // const items = props.items;
  // const innerRef = useRef();
  // console.log("innerRef: ", innerRef)
  const listItems = () => {
    return (
      <div
        className="two-colum-list"
        // style={{ display: "grid", gridTemplateColumns: "50% 50%" }}
      >
        {items.map((item, indx) => {
          return (
            <GrowingExtendedListItem
              // ref={function (element) {
              //   let itemsContainer = [];
              //   console.log("element", element);
              //   if (element) {
              //     itemsContainer = element;
              //     let lastIndex = itemsContainer.length - 1;
              //     console.log("lastIndex", lastIndex);
              //     itemsContainer[lastIndex].scrollIntoView();
              //   }
              // }}
              collection={collection}
              getState={getStateCallback}
              passClearState={assignClearChildState}
              onChkbChange={props.onChkbChange}
              onChange={(x)=>onChange(indx, x)}
              isChecked={props.isChecked}
              hasChkBox={props.hasChkBox}
              indx={indx}
              // editMode={false}
              editMode={editMode[indx]}
              setEditMode={(x) => setTheEditMode(indx, x)}
              key={indx}
              onDelete={props.onDelete}
              onEdit={props.onEdit}
              onSave={props.onSave}
              myKey={item.index.toString()}
              value={item}
              options={props.options}
            />
          );
        })}
      </div>
    );
  };

  // console.log("listItems: ", listItems);
  // console.log("props: ", props);
  const cssClassToApply = `listItemsBox ${props.cssClass}`;
  // console.log("cssClassToApply: ", cssClassToApply);

  useEffect(() => {
    listItems();
  }, [items]);
  
  //this is the reference to the child clearState function
  // let clearGrandChildState = null;
  const clearGrandChildState = useRef(null);

  // this method assigns the child clearState function
  // to the parent reference
  function assignClearChildState(grandChildClearStateFunc) {
    // console.log("assignClearChildState ", grandChildClearStateFunc);
    clearGrandChildState.current = grandChildClearStateFunc;
  }
  // this method gets the child state for completeness of
  // the childComp tag
  function getStateCallback(item) {
    //do something to use the child state
    console.log("item ", item);
  }

  function resetForm() {
    console.log("clearChildState() ", clearGrandChildState.current);
    clearGrandChildState.current("");
  }

  return (
    <React.Fragment>
      <div
        // this is a wrapper "div" for the form
        // and therein is the "collection" you
        // want the reference to
        className={cssClassToApply}
        // style={{
        //   textAlign: "left",
        //   backgroundColor: "whitesmoke",
        //   height: "400px",
        //   overflowY: "scroll",
        // }}
      >
        {/* <form> */}
        <ul
        // ref={innerRef}
        // style={{ listStyleType: "none", paddingLeft: "0rem" }}
        >
          <div
          // ref={(innerRef) => {
          //   // console.log("innerRef: ", innerRef);
          //   let itemsContainer = [];
          //   if (innerRef) {
          //     itemsContainer = innerRef.children;
          //     let lastIndex = itemsContainer.length - 5;
          //     // console.log("lastIndex", lastIndex);
          //     itemsContainer[lastIndex].scrollIntoView();
          //   }
          //   return innerRef;
          // }}
          >
            {items.length > 0 ? listItems() : ""}
          </div>
        </ul>
        {/* </form> */}
      </div>
      {/* <div
        // this is a wrapper "div" for the form
        // and therein is the "collection" you
        // want the reference to
        className={cssClassToApply}
        // style={{
        //   textAlign: "left",
        //   backgroundColor: "whitesmoke",
        //   height: "400px",
        //   overflowY: "scroll",
        // }}
      >
        <ul
        // ref={innerRef}
        // style={{ listStyleType: "none", paddingLeft: "0rem" }}
        >
          <div
          // ref={(innerRef) => {
          //   // console.log("innerRef: ", innerRef);
          //   let itemsContainer = [];
          //   if (innerRef) {
          //     itemsContainer = innerRef.children;
          //     let lastIndex = itemsContainer.length - 5;
          //     // console.log("lastIndex", lastIndex);
          //     itemsContainer[lastIndex].scrollIntoView();
          //   }
          //   return innerRef;
          // }}
          >
            {items.map((item, idx) => (
              <GrowingExtendedListItem
                // ref={function (element) {
                //   let itemsContainer = [];
                //   console.log("element", element);
                //   if (element) {
                //     itemsContainer = element;
                //     let lastIndex = itemsContainer.length - 1;
                //     console.log("lastIndex", lastIndex);
                //     itemsContainer[lastIndex].scrollIntoView();
                //   }
                // }}
                getState={getStateCallback}
                passClearState={assignClearChildState}
                editMode={false}
                key={idx}
                onDelete={props.onDelete}
                onEdit={props.onEdit}
                onSave={props.onSave}
                myKey={item.index.toString()}
                value={item}
              />
            ))}
          </div>
        </ul>
      </div> */}
    </React.Fragment>
  );
}
