import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import GroupsIcon from "@mui/icons-material/Groups";
import ScheduleIcon from "@mui/icons-material/Schedule";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import EuroIcon from "@mui/icons-material/Euro";
import PersonIcon from "@mui/icons-material/Person";
import WalletIcon from "@mui/icons-material/Wallet";

import Repeater from "./Repeater";

function CardRecipeDetails({ recipe, createdAt, setRecipeServes }) {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [isModerator, setIsModerator] = useState(false);
  const [isChangingServes, setIsChangingServes] = useState(false);
  const [serves, setServes] = useState(recipe.servings);

  const myStyle = {};

  function handleChangeServes() {
    // toggle the change serves activity
    setIsChangingServes(!isChangingServes);
  }

  function onChange(event) {
    const { value, name } = event.target;
    console.log("value, name ", value, name);
    // recipe.servings = value;
    console.log("recipe.servings ", recipe.servings);
    setRecipeServes(value);
    setServes(value);
  }

  useEffect(() => {
    setIsModerator(currentUser?.roles.includes("ROLE_MODERATOR"));
    // console.log(currentUser);
  }, []);

  return (
    <div
      style={{
        margin: "0 2px",
        borderTop: "solid #cecece 0.75px",
        padding: "5px 5px 5px 5px",
        backgroundColor: "#f4f4f4",
        borderRadius: "10px",
        textAlign: "left",
      }}
    >
      {isModerator ? (
        <div>
          <label>
            <strong>Created at:</strong>{" "}
          </label>
          {createdAt}
        </div>
      ) : null}
      {isModerator ? (
        <div>
          <label>
            <strong>Status:</strong>&nbsp;
          </label>
          {recipe.published ? "Published" : "Pending"}
        </div>
      ) : null}
      <div className="row" style={{ fontSize: "0.5rem", fontWeight: "100" }}>
        <div
          className="col"
          style={{
            margin: "0 2px",
            borderTop: "solid #aeaeae 0.75px",
            border: "solid #0d6efd 5px",
            padding: "5px 5px 5px 5px",
            backgroundColor: "#e4e4e4",
            borderRadius: "10px",
            textAlign: "center",
          }}
          onClick={handleChangeServes}
        >
          <label htmlFor="serves" style={{}}>
            <strong>Serves: </strong>&nbsp;
          </label>
          {isChangingServes ? (
            <>
              <div id="serves">
                <GroupsIcon /> {serves ? serves : "4"}
              </div>
              <div>
                <FormControl style={myStyle}>
                  {/* {console.log("name, label, value ", name, label, value)} */}
                  {/* {console.log("options ", options)} */}
                  <InputLabel id={"serves"}>{serves}</InputLabel>
                  <Select
                    // disabled={disabled}
                    id={"serves"}
                    name={"serves"}
                    value={serves}
                    onChange={onChange}
                  >
                    {[1, 2, 3, 4, 5, 6, 8, 10, 12].map((serves, idx) => {
                      // console.log("serves", serves);
                      return (
                        <MenuItem key={idx} value={serves}>
                          {serves}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* </label> */}
                </FormControl>
              </div>
            </>
          ) : (
            <>
              <div id="serves">
                <GroupsIcon /> {serves ? serves : "4"}
              </div>
              <div>
                <Repeater icon={PersonIcon} count={serves ? serves : 4} />
              </div>
            </>
          )}
        </div>
        <div
          className="col"
          style={{
            margin: "0 2px",
            borderTop: "solid #aeaeae 0.5px",
            padding: "5px 5px 5px 5px",
            backgroundColor: "#e4e4e4",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <div>
            <label htmlFor="cost">
              <strong>
                Budget: <WalletIcon />
              </strong>
            </label>
          </div>
          <div>
            {" "}
            <Repeater icon={EuroIcon} count={recipe.cost ? recipe.cost : "4"} />
          </div>
        </div>
        <div
          className="col"
          style={{
            margin: "0 2px",
            borderTop: "solid #aeaeae 0.5px",
            padding: "5px 5px 5px 5px",
            backgroundColor: "#e4e4e4",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <div>
            <label htmlFor="difficulty">
              <strong>Cooking Spoon: </strong>{" "}
            </label>
          </div>
          <div>
            <div id="difficulty">
              <Repeater
                icon={RestaurantIcon}
                count={recipe.difficulty ? recipe.difficulty : "3"}
              />
            </div>
          </div>
          {/* <RestaurantIcon /> {recipe.difficulty ? recipe.difficulty : "4"} */}
        </div>
        <div
          className="col"
          style={{
            margin: "0 2px",
            borderTop: "solid #aeaeae 0.5px",
            padding: "5px 5px 5px 5px",
            backgroundColor: "#e4e4e4",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <label htmlFor="time">
            <strong>Time: </strong>&nbsp;
            <div id="time">
              <ScheduleIcon />{" "}
            </div>
            <div>{recipe.time ? recipe.time : "1:00h"}</div>
          </label>
        </div>
      </div>
      {recipe.comments?.length ? (
        <div>
          <div>Rating: {recipe.comments?.rating}</div>
          <div>Ratings: {recipe.comments?.ratings}</div>
          <div>
            Comments:
            <ul>
              {recipe.comments?.comments?.map((comment, indx) => {
                return <li key={indx}>{comment}</li>;
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CardRecipeDetails;
