// import { useState } from "react";
import {
  Outlet,
  // Link,
  Form,
  useLoaderData,
  redirect,
  NavLink,
  useNavigation,
} from "react-router-dom";
import { getContacts, createContact } from "./contacts.helper";

import KeyIcon from "@mui/icons-material/Key";

import Cards from "./Cards";
// https://reactrouter.com/en/main/start/tutorial
// assets
// styling
import "../../assets/css/Contacts.css";

export async function action() {
  const contact = await createContact();
  console.log("default action: create contact", contact);
  return redirect(`/contacts/${contact.id}/edit`);
  // return { contact };
}

// export function Contacts() {
function Contacts() {
  return (
    <div className="wrapper">
      <KeyIcon />
      <div className="Contacts">
        <Cards />
      </div>
    </div>
  );
}

// export default Contacts;

export async function loader() {
  const contacts = await getContacts();
  console.log("loader() contacts ", contacts);
  return { contacts };
}

export default function Root() {
  // export function Root() {
  const { contacts } = useLoaderData();
  // const [contacts, setContacts] = useState(loader());

  const navigation = useNavigation();

  return (
    <div className="Contacts">
      <div id="ContactRoot">
        {/* <div> */}
        <div id="sidebar">
          <h1>React Router Contacts</h1>
          <div>
            <form id="search-form" role="search">
              <input
                id="q"
                aria-label="Search contacts"
                placeholder="Search"
                type="search"
                name="q"
              />
              <div id="search-spinner" aria-hidden hidden={true} />
              <div className="sr-only" aria-live="polite"></div>
            </form>
            <Form method="post">
              <button type="submit">New</button>
            </Form>
          </div>
          <nav>
            {/* {console.log(contacts.<value>.contacts)} */}
            {/* {console.log("Root() contacts", contacts)} */}
            {/* {contacts.length
                ? contacts.then((contacts) => {
                    console.log("Root() ----> contacts", contacts); */}
            {
              contacts.length ? (
                <ul>
                  {/* {console.log("Root() contacts", contacts)} */}
                  {contacts.map((contact) => (
                    <li key={contact.id}>
                      <NavLink
                        to={`/contacts/${contact.id}`}
                        className={({ isActive, isPending }) =>
                          isActive ? "active" : isPending ? "pending" : ""
                        }
                      >
                        {/* {console.log(contact)} */}
                        {/* <Link to={`/contacts/${contact.id}`}> */}
                        {contact.first || contact.last ? (
                          <>
                            {contact.first} {contact.last}
                          </>
                        ) : (
                          <i>No Name</i>
                        )}{" "}
                        {contact.favorite && <span>★</span>}
                        {/* </Link> */}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>
                  <i>No contacts</i>
                </p>
              )
              // ;
            }
            {/* })
                : ""} */}
          </nav>
          {/* <nav>
              <ul>
                <li>
                  <a href={`/contacts/1`}>Your Name</a>
                  <Link to={`/contacts/1`}>Your Name</Link>
                </li>
                <li>
                  <a href={`/contacts/2`}>Your Friend</a>
                  <Link to={`/contacts/2`}>Your Friend</Link>
                </li>
              </ul>
            </nav> */}
        </div>
        <div
          id="detail"
          className={navigation.state === "loading" ? "loading" : ""}
        >
          <Outlet />
        </div>
        {/* </div> */}
        {/* <div style={{clear: "both"}}></div>
      <div>
        <Contacts />
      </div> */}
      </div>
      <Contacts />
    </div>
  );
}
