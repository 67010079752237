import React, { useState, useEffect } from "react";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const Dropdown = ({
  disabled,
  label,
  value,
  options,
  onChange,
  myStyle,
  name,
}) => {
  const [age, setAge] = React.useState("");

  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value as string);
  // };

  return (
    // fullWidth>
    <FormControl style={myStyle}>
      {/* <label id={name}>
        {label} */}
      {/* {console.log("name, label, value ", name, label, value)} */}
      {/* {console.log("options ", options)} */}
      <InputLabel id={"label" + name}>{label}</InputLabel>
      <Select
        disabled={disabled}
        id={name}
        name={name}
        value={value ? value : ""}
        onChange={onChange}
      >
        {options?.map((option, idx) => {
          // console.log(option, option.value);
          const value = option.value ? option.value : option;
          const label = option.label ? option.label : option;
          // console.log(label, value);
          return label === "" ? null : (
            // <option key={idx} value={option.value}>
            //   {option.label}
            // </option>
            <MenuItem key={idx} value={value}>
              {label}
              {/* {console.log(option)} */}
            </MenuItem>
          );
        })}
      </Select>
      {/* </label> */}
    </FormControl>
  );
};
export default Dropdown;
