import React from "react";

function Avatar(props) {
  return (<div className="centered-img">
    <img
      className="circle-img"
      src={props.imgURL}
      alt="avatar_img"
      // height="200"
      // width="200"
    />
    </div>
  );
}

export default Avatar;
