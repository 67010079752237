import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SellIcon from "@mui/icons-material/Sell";

import CardRecipeDetails from "./CardRecipeDetails";
// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";
import server from "../../config/server.config";
const imageServerURL = server.cdn;

// const imageServerURL = process.env.REACT_APP_IMAGE_SERVER_URL
//   ? process.env.REACT_APP_IMAGE_SERVER_URL
//   : "http://192.168.1.10:4000/";

function addDefaultSrc(e) {
  e.target.src = imageNotAvailable;
}

function RecipeCard(props) {
  const { id, createdAt, indx, recipe } = props;
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isModerator, setIsModerator] = useState(false);

  useEffect(() => {
    setIsModerator(currentUser?.roles.includes("ROLE_MODERATOR"));
    // console.log(currentUser);
  }, []);

  // console.log("RecipeCard ", indx);
  // return (
  //   <p>{indx}</p>
  // );
  return (
    <div
      id={"recipe-" + indx}
      key={indx}
      className="col-xl-3 col-lg-4 col-md-6"
    >
      {/* <p>{indx}</p> */}
      <Link
        to={"/recipes/" + id}
        // className="badge bg-warning"
      >
        {/* Edit */}
        <Card className="card" style={{ width: "18rem", margin: "10px auto" }}>
          {recipe.media && recipe.media.images && recipe.media.images.head ? (
            <div className="shrinkToFitImage">
              <Card.Img
                variant="top"
                src={imageServerURL + recipe.media.images.head}
                onError={addDefaultSrc}
              />
              {/* <label>
                                <strong>Picture (server side):</strong>
                                </label>
                                {recipe.media.images.head} */}
              {/* <img
                                width={"100%"}
                                src={recipe.media.images.head}
                                alt="Image Head Recipe {recipe.name}"
                              /> */}
            </div>
          ) : (
            <div>
              <Card.Img
                variant="top"
                style={{ maxHeight: "250px" }}
                src={imageNotAvailable}
                alt="Image Head Recipe not  available yet..."
              />
              {/* <img src={imageNotAvailable} alt="Image Head Recipe" /> */}
              {/* <br />
                            <p>No picture available yet...</p> */}
            </div>
          )}
          <Card.Body>
            <Card.Title>{recipe.name}</Card.Title>
            {/* <div>
                            <label>
                              <strong>Name:</strong>
                            </label>
                            {recipe.name}
                          </div> */}
            <div>
              <label>
                <strong>Description:</strong>
              </label>
              {/* <br />
                            {recipe.description} */}
            </div>
            <Card.Text>
              {recipe.description}
              {/* Some quick example text to build
                            on the card title and make up the bulk of the card's
                            content. */}
            </Card.Text>
            <div>
              <label>
                <strong>Category: </strong>
              </label>
              {/* {recipe.category} */}
              {recipe.category.length
                ? recipe.category.map((category, indx) => {
                    if (indx === 0) return " " + category;
                    return ", " + category;
                  })
                : " None"}
            </div>
            {/* {  recipe.subcategory ? ( <div>
                        <label>
                          <strong>Subcategory:</strong>
                        </label>
                        {recipe.subcategory}
                          </div>) : ("")
                          } */}
            <div>
              <label>
                <SellIcon />
                <strong>Tag: </strong>
              </label>
              {recipe.tags.length
                ? recipe.tags.map((tag, indx) => {
                    if (indx === 0) return " " + tag;
                    return ", " + tag;
                  })
                : " None"}
            </div>
            <div className="mb-1 mt-1">
              <label>
                <strong>Cook Ways: </strong>
              </label>
              {recipe.cookWays?.length
                ? recipe.cookWays.map((cookWay, indx) => {
                    return (
                      <button
                        key={indx}
                        className="badge bg-warning mr-2"
                        style={{ color: "#0d6efd", marginLeft: "10px" }}
                      >
                        {cookWay.label}
                      </button>
                    );
                  })
                : " None"}
            </div>

            <CardRecipeDetails recipe={recipe} createdAt={createdAt} />

            {/* <Button variant="primary" onClick={handleEditClick}>
                              Edit
                            </Button>
                            <Link
                              to={"/recipes/" + id}
                              className="badge bg-warning"
                            >
                              Edit
                            </Link> */}
          </Card.Body>
        </Card>
      </Link>
      {/* <div> {createdAt} </div>
                          <div> {recipe.name} </div>
                          <div> {recipe.description} </div>
                          <div> {recipe.category} </div>
                          <div> {recipe.subcategory} </div> */}
    </div>
  );
}
export default RecipeCard;
