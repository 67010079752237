import React, { useState , useContext } from "react";

import { ThemeProvider } from "styled-components";

import { useDispatch, useSelector } from "react-redux";

import { Outlet } from "react-router-dom";

import { WSContext } from "../../context/WSContext";

import { darkTheme, ligthtTheme } from "../../utils/theming";

import Navigation from "../Navigation/Navigation";

function Layout() {
  const { windowSize } = useSelector((state) => state);
  // make the window size available globally through reducer store
  // const [windowSize, setWindowSize] = useState(getWindowSize());
  // const [currentUser, setCurrentUser] = useState(undefined);
  const { user: currentUser } = useSelector((state) => state.auth);

  // console.log(process.env.REACT_APP_LOCALHOST_KEY, currentUser);
  const [theme, setTheme] = useState(
    currentUser && currentUser.theme === "dark" ? darkTheme : ligthtTheme
  );

  let ws = useContext(WSContext);

  //   // console.log("App() ", ws);
  //   // ws = ws.setUpWebSockets({ ...ws, msg: "Hello" });
  //   // console.log("App() ", ws);

  return (
    // <>
    <React.Fragment>
      <div style={{ fontSize: "1rem" }}>
        Width: {windowSize && windowSize.innerWidth}
        Height: {windowSize && windowSize.innerHeight}
      </div>
      {/* {console.log(windowSize)} */}
      <Navigation currentUser={currentUser} />
      <WSContext.Provider value={ws}>
        <ThemeProvider theme={theme}>
          {/* <Router history={history}> */}
          {/* <Navigation logOut={logOut} currentUser={currentUser} /> */}
          <div>
            {/* <header>
              <h1>My Super Cool App</h1>
              <NavMenu />
            </header> */}
            <main>
              {/* 2️⃣ Render the app routes via the Layout Outlet */}
              <Outlet />
            </main>
            {/* <footer>©️ me 2023</footer> */}
            {/* </> */}
          </div>
          {/* </Router> */}
        </ThemeProvider>
      </WSContext.Provider>
    </React.Fragment>
  );
}

export default Layout;
