/**
 * This helped a lot
 * especially styling
 * https://www.bezkoder.com/react-node-express-mongodb-mern-stack/
 */

import React, { useState, useEffect } from "react";

// import { Link } from "react-router-dom";

// import UserService from "../../services/user.service";
import CategoryDataService from "../../services/category.service";

import "./Categories.css";

import CategoriesListHeader from "./CategoriesListHeader";
import CategoriesListing from "./CategoriesListing";
import EditCategory from "./EditCategory";
import ShowCategory from "./ShowCategory";
import AddCategory from "./AddCategory";

/**
 * component definition
 */

const CategoriesList = ({ ensemble, setEnsemble }) => {
  const [categoryGroup, setCategoryGroup] = useState({});
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchName, setSearchName] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);

  useEffect(() => {
    retrieveCategoryGroup(ensemble);
    setCurrentCategory(null);
    setCurrentIndex(-1);
    setAddMode(false);
    setEditMode(false);
  }, [ensemble]);

  const retrieveCategoryGroup = (ensemble) => {
    CategoryDataService.getAll({ name: searchName, ensemble: ensemble })
      .then((response) => {
        setCategoryGroup(response.data.categoryGroup);
        // console.log("retrieveCategoryGroup() response.data ", response.data);
        // console.log(
        //   "retrieveCategoryGroup() response.data.categoryGroup ",
        //   response.data.categoryGroup
        // );
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  const refreshList = () => {
    retrieveCategoryGroup(ensemble);
    setCurrentCategory(null);
    setCurrentIndex(-1);
  };

  const setActiveCategory = (category, index) => {
    setEditMode(false);
    setAddMode(false);
    // console.log("category ", category);
    // console.log("index ", index);
    setCurrentCategory(category);
    setCurrentIndex(index);
  };

  const removeAllCategoryGroup = (ensemble) => {
    CategoryDataService.removeAll(ensemble)
      .then((response) => {
        console.log(response.data.categoryGroup);
        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function createCategory() {
    var data = {
      ensemble: ensemble,
      data: [],
    };
    CategoryDataService.create(data)
      .then((response) => {
        console.log("createCategory() response data", response.data);
        setCurrentCategory(null);
      })
      .catch((e) => {
        console.log("createCategory() ", e);
      });
  }

  const findByName = (ensemble) => {
    CategoryDataService.findByName(ensemble, searchName)
      .then((response) => {
        setCategoryGroup(response.data.categoryGroup);
        // console.log(response.data.categoryGroup);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function handleOnClickAdd() {
    setCurrentCategory(null);
    setCurrentIndex(-1);
    setEditMode(false);
    setAddMode(true);
  }

  function handleAddCategory(submitted) {
    setAddMode(false);
    if (submitted) refreshList();
  }

  return (
    <div
      className="container"
      // style={{ border: "solid black 1px" }}
    >
      {/* <p>{ensemble}</p> */}
      <div className="list row">
        <CategoriesListHeader
          searchName={searchName}
          setSearchName={setSearchName}
          findByName={findByName}
          ensemble={ensemble}
          setEnsemble={setEnsemble}
        />
        {/*
         * Left Pane
         */}
        <div
          className="col-md-6"
          style={{
            // border: "solid black 1px",
            opacity: addMode ? "0.25" : "1.0",
            backgroundColor: "whitesmoke",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <h4>CategoryGroup for: {ensemble}</h4>
          <CategoriesListing
            categoryGroup={categoryGroup}
            currentIndex={currentIndex}
            setActiveCategory={setActiveCategory}
          />
          {categoryGroup && categoryGroup.categories && categoryGroup.categories.length > 0 ? (
            <button
              className="m-3 btn btn-sm btn-danger"
              onClick={() => removeAllCategoryGroup(ensemble)}
            >
              Remove All
            </button>
          ) : null}

          {!categoryGroup ? (
            <button
              className="m-3 btn btn-sm btn-danger"
              onClick={createCategory}
            >
              Create List
            </button>
          ) : null}
          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={handleOnClickAdd}
          >
            Add
          </button>

          {/* <button className="m-3 btn btn-sm btn-danger">
            <Link to={"/addcategory"} className="nav-link">
              Add
            </Link>
          </button> */}
        </div>
        {/*
         * Right Pane
         */}
        <div className="col-md-6">
          {/* {console.log("currentCategory ", currentCategory)} */}
          {!editMode && addMode ? (
            <AddCategory ensemble={ensemble} handleAddCategory={handleAddCategory} />
          ) : null}

          {currentCategory && !addMode ? (
            <div>
              {editMode && !addMode ? (
                <EditCategory
                  ensemble={ensemble}
                  currentCategory={currentCategory}
                  setCurrentCategory={setCurrentCategory}
                  categoryGroup={categoryGroup}
                  currentIndex={currentIndex}
                  setEditMode={setEditMode}
                />
              ) : null}
              {!editMode && !addMode ? (
                <ShowCategory
                  setEditMode={setEditMode}
                  currentCategory={currentCategory}
                />
              ) : null}
            </div>
          ) : (
            <>
              {!currentCategory &&
              !addMode &&
              categoryGroup &&
              categoryGroup.categories &&
              categoryGroup.categories.length > 0 ? (
                // <div style={{ border: "solid black 1px" }}>
                <div>
                  <br />
                  <p>Please click on a Category...</p>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default CategoriesList;
