var __DEBUG__; // = "DEBUG"

if (process.env.NODE_ENV === "development") {
  __DEBUG__ = "DEBUG";
}

// __DEBUG__ = true && __DEBUG__;
__DEBUG__ = false && __DEBUG__;

// console.log("__DEBUG__ ", __DEBUG__);

export default __DEBUG__;
