import React, { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import __DEBUG__ from "../../config/debug.config";
import authHeader from "../../services/auth-header";
import chatService from "../../services/chat.service";

import "../../assets/css/Members.css";

import server from "../../config/server.config";

const API_URL = server.host + (server.port === "" ? "" : ":" + server.port);

function Members(props) {
  const { user, theme } = props;

  const [members, setMembers] = useState(user.members);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: theme.name,
  };

  async function getUser(id) {
    // console.log("getUser ", id);
    try {
      const response = await retrieveUser(id);
      // console.log(
      //   "getUser response.data.user.fullname ",
      //   response.data.user.fullname
      // );
      return response.data.user;
    } catch (err) {
      if (__DEBUG__) {
        // console.log(err.message);
        // console.log(err.response.status);
        // console.log(err.response.statusText);
        // console.log(err.response.data.message);
        console.log(err);
        // return err.response.data.message;
      }
      // throw new Error(err.response.data.message);
      console.log(err);
      throw new Error(err);
    }
  }

  // this is a 'service' function
  function retrieveUser(id) {
    // console.log("retrieveUser ", id);
    return axios.get(API_URL + `/api/users/${id}`, { headers: authHeader() });
    // .then((response) => {
    //   // console.log("getUserInfo response.data ", response.data.user);
    //   console.log(
    //     "getUserInfo response.data.user.fullname ",
    //     response.data.user.fullname
    //   );
    //   return {user: response.data.user};
    // })
    // .catch((err) => {
    //   if (__DEBUG__) {
    //     // console.log(err.message);
    //     // console.log(err.response.status);
    //     // console.log(err.response.statusText);
    //     // console.log(err.response.data.message);
    //     console.log(err);
    //     // return err.response.data.message;
    //   }
    //   // throw new Error(err.response.data.message);
    //   console.log(err);
    //   throw new Error(err);
    // });
  }

  useEffect(getMembers, []);

  function getMembers() {
    user.members.map((member, index) => {
      console.log("member index ", member, index);
      // member.id = member;
      getUser(member)
        .then((user) => {
          // console.log("user ", user);
          // console.log("user.fullname ", user.fullname);
          var theMembers = [...members];
          // theMembers.push(user);
          theMembers[index] = user;
          setMembers(theMembers);
          //     return user;
        })
        .catch((e) => {
          console.log(e);
          //     return null;
        });
      return 0;
      // console.log("user ", user);
      // console.log("fullname ", user.fullname);
    });
  }

  async function onDelete(id) {
    console.log("id ", id);
    let theMember;
    var theMembers = [];
    // use filter, better?
    for (let index = 0; index < members.length; index++) {
      const member = members[index];
      console.log("member ", member);
      console.log(
        "member.id, id, member.id===id ",
        member.id,
        id,
        member.id === id
      );
      if (member.id === id) {
        member.memberOf = id;
        theMember = member;
      } else {
        theMembers.push(member);
      }
    }
    console.log("theMembers ", theMembers);
    console.log("members ", members);
    setMembers(theMembers);
    // save the new members list to the user
    user.members = theMembers;

    if (theMember) {
      // now save the 'user'
      try {
        const userData = {
          username: user.username,
          members: theMembers,
          memberOf: user.id,
        };

        const { data } = await chatService.savePreferences(user.id, userData);
        console.log("data ", data);
        const errorMessage = data.msg;
        toast.error(errorMessage, toastOptions);
      } catch (error) {
        console.log(error);
        const errorMessage = error;
        toast.error(errorMessage, toastOptions);
      }

      // and the member which isn't member anymore
      try {
        const userData = {
          username: theMember.username,
          // member of itself
          memberOf: id,
          members: [],
        };
        const { data } = await chatService.savePreferences(id, userData);
        console.log("data ", data);
        const errorMessage = data.msg;
        toast.error(errorMessage, toastOptions);
      } catch (error) {
        console.log(error);
        const errorMessage = error;
        toast.error(errorMessage, toastOptions);
      }
    }
  }

  return (
    <div style={{ border: "dotted red 3pt", marginTop: "20px" }}>
      {/* {toast.error("Hello", toastOptions)} */}
      {/* {theme.name} */}
      {/* dark */}
      <h5>Family/Club members</h5>
      <div className="Members">
        <form className="membersList">
          {members.length === 0 ? (
            <div>Please add the members of your family/club.</div>
          ) : (
            <ul>
              {/* {console.log(user)} */}
              {console.log("user.members ", user.members)}
              {console.log("members ", members)}
              {members &&
                members.map((member, index) => {
                  if (member){
                  return (
                    <li key={index}>
                      {member.fullname}
                      <button
                        className="deleteButton"
                        onClick={(event) => {
                          event.preventDefault();
                          onDelete(member.id);
                        }}
                      >
                        <div style={{ display: "inline" }}>
                          <span style={{ verticalAlign: "top" }}>Delete</span>
                          <DeleteForeverIcon />
                        </div>
                      </button>
                    </li>
                  );}
                  return null
                })}
            </ul>
          )}
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Members;
