function CategoriesListing({ categoryGroup, setActiveCategory, currentIndex }) {
  return (
    <div
      // style={{ border: "solid black 1px" }}
    >
      {categoryGroup && categoryGroup.categories && categoryGroup.categories.length > 0 ? (
        <ul className="list-group">
          {/* {console.log("categoryGroup ", categoryGroup)} */}
          {categoryGroup &&
            categoryGroup.categories &&
            categoryGroup.categories.map((category, index) => (
              <li
                className={
                  "list-group-item " + (index === currentIndex ? "active" : "")
                }
                onClick={() => setActiveCategory(category, index)}
                key={index}
              >
                {category ? category.name : null}
                {/* {currentCategory ? currentCategory.name : null} */}
              </li>
            ))}
        </ul>
      ) : (
        <h6>No entries for that category found. Please add some!</h6>
      )}
    </div>
  );
}

export default CategoriesListing;
