import server from "../config/server.config";

export const host = server.host + ":" + server.port;

export const loginRoute = `${host}/api/auth/login`;
export const registerRoute = `${host}/api/auth/register`;
export const logoutRoute = `${host}/api/auth/logout`;

export const userRoute = `${host}/api/user/users`;
export const allUsersRoute = `${host}/api/user/allusers`;
export const setAvatarRoute = `${host}/api/user/setavatar`;

export const sendMessageRoute = `${host}/api/messages/addmsg`;
export const recieveMessageRoute = `${host}/api/messages/getmsg`;
