// import { v4 as uuidv4 } from "uuid";

import CategoryDataService from "../../services/category.service";

function EditCategory({
  ensemble,
  currentCategory: category,
  setCurrentCategory,
  categoryGroup,
  currentIndex,
  setEditMode,
}) {
  const updateCategory = (ensemble) => {
    setEditMode(false);
    categoryGroup.categories[currentIndex] = category;
    // var data = {
    //   name: category.name,
    //   description: category.description,
    //   presentation: category.presentation,
    //   published: false,
    //   media: {
    //     images: {
    //       head: category.media.images.head,
    //     },
    //   },
    // };
    if (!category.id) {
      // console.log("uuidv4 ", category);
      category.id = category._id;// uuidv4();
    }
    // console.log("updateCategory() ensemble", ensemble);
    // console.log("updateCategory() category", category);
    // console.log("updateCategory() ensemble", ensemble);
    CategoryDataService.update(ensemble, category.id, category)
      .then((response) => {
        // console.log(
        //   "updateCategory() response data category name",
        //   response.data.category.name
        // );
        // console.log(
        //   "updateCategory() response data category description",
        //   response.data.category.description
        // );
        // console.log("updateCategory() response data id", response.data.id);
        console.log("updateCategory() response data", response.data);
        // setCurrentCategory({
        //   id: response.data.id,
        //   name: response.data.category.name,
        //   description: response.data.category.description,
        //   presentation: response.data.category.presentation,
        //   published: response.data.category.published,
        //   media: {
        //     images: {
        //       head: response.data.category.media.images.head,
        //     },
        //   },
        // });
        // setCurrentCategory(response.data);
        // console.log("updateCategory() ", response.data);
      })
      .catch((e) => {
        console.log("updateCategory() ", e);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log("category ", category);
    let theCategory = { ...category };
    // theCategory.category = {};
    // console.log("theCategory ", theCategory);
    if (name === "media-images-head") {
      const media = {};
      media.images = {};
      media.images.head = value;
      theCategory = { ...category, media: media };
    } else {
      theCategory = { ...category, [name]: value };
    }
    // console.log("theCategory category ", theCategory, category);
    setCurrentCategory((previous) => theCategory);
  };

  const updatePublished = (status) => {
    var category = {
      ...category,
      published: status,
    };
    var data = { ...category, category: category };
    setCurrentCategory(data);
    updateCategory(ensemble);
    // CategoryDataService.update(category.id, data)
    //   .then((response) => {
    //     // setCurrentCategory({ ...category, category: category });
    //     console.log(response.data);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  };

  return (
    <div
      style={{
        // border:s "solid black 1px",
        backgroundColor: "whitesmoke",
        padding: "20px",
        borderRadius: "20px",
      }}
    >
      <h4>Category</h4>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          name="name"
          type="text"
          className="form-control"
          id="name"
          required
          value={category.name}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <input
          name="description"
          type="text"
          className="form-control"
          id="description"
          required
          value={category.description}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="presentation">Presentation</label>
        <input
          name="presentation"
          type="text"
          className="form-control"
          id="presentation"
          required
          value={category.presentation}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="media-images-head">Picture</label>
        <input
          type="text"
          className="form-control"
          id="media-images-head"
          required
          value={
            category.media &&
            category.media.images &&
            category.media.images.head
              ? category.media.images.head
              : ""
          }
          onChange={handleInputChange}
          name="media-images-head"
        />
      </div>
      <div>
        <label>
          <strong>Status:</strong>&nbsp;
        </label>
        {category.published ? "Published" : "Pending"}
      </div>
      {/* {console.log("category ", category)} */}
      <button
        className="badge bg-warning"
        onClick={() => updateCategory(ensemble)}
        // className="btn btn-success"
      >
        Save
      </button>
      {category.published ? (
        <button
          className="badge bg-primary mr-2"
          onClick={() => updatePublished(false)}
        >
          UnPublish
        </button>
      ) : (
        <button
          className="badge bg-primary mr-2"
          onClick={() => updatePublished(true)}
        >
          Publish
        </button>
      )}
    </div>
  );
}

export default EditCategory;
