import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const theme = createTheme({
  components: {
    // Name of the component ⚛️
    ToggleServes: { defaultProps: { color: "blue" } },
    ToggleButtonGroup: { defaultProps: { color: "blue" } },
    MuiButtonBase: {
      defaultProps: {
        // The default props to change
        color: "blue",
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
});

export default function ToggleServes(props) {
  // const [alignment, setAlignment] = React.useState("web");
  const [chosen, setChosen] = React.useState([]);
  const [active, setActive] = React.useState([]);
  const comensales = ["R", "K", "J", "S", "G"];
  // let active = [];

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    // newAlignment: string
    newChosen: string
  ) => {
    // console.log("newAlignment ", newAlignment);
    // console.log("newChosen ", newChosen);
    // console.log("chosen ", chosen);
    // setAlignment(newAlignment);
    setChosen(newChosen);
    props.setServes(newChosen.length);
    // console.log("chosen ", newChosen,  newChosen.includes(comensales[0]));
    setActive(() => comensales.map((comensal,indx) => newChosen.includes(comensal)));
    // active[indx] = !active[indx];
    // console.log("active ", active);
  };

  // array of objects
  function handleToogle(indx) {
    console.log("chosen ", chosen,  chosen.includes(comensales[indx]));
    active[indx] = !active[indx];
    // chosen.includes(comensales[indx]);
  }

  return (
    <div className="muiToggleButtonClasses">
      {/* <ThemeProvider theme={theme}> */}
      <ToggleButtonGroup
        // style={{ position: "relative", isolation: "isolate" }}
        color="primary"
        // color="secondary"
        value={chosen}
        // exclusive
        size="large"
        onChange={handleChange}
        aria-label="Comensales"
      >
        {comensales.map((comensal, indx) => {
          // active[indx] = false;
          return (
            <ToggleButton
              style={{
                color: active[indx] ? "red" : "blue",
                backgroundColor: active[indx] ? "green" : "yellow",
              }}
              key={indx}
              value={comensal}
              // onChange={() => handleToogle(indx)}
            >
              {comensal}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>{" "}
      {/* </ThemeProvider> */}
    </div>
  );
}
