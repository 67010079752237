/**
 * This helped a lot
 * especially styling
 * https://www.bezkoder.com/react-node-express-mongodb-mern-stack/
 */

import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import KeyIcon from "@mui/icons-material/Key";

// import UserService from "../../services/user.service";
import CookwareDataService from "../../services/cookware.service";
import CategoryDataService from "../../services/category.service";

import "../../components/Cookware/Cookware.css";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";
const ensemble = "Kitchenware";
/**
 * component definition
 */

const CookwareList = () => {
  const [cookware, setCookware] = useState([]);
  const [currentCookware, setCurrentCookware] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchName, setSearchName] = useState("");
  const [categoryGroup, setCategoryGroup] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isModerator, setIsModerator] = useState(false);

  useEffect(() => {
    setIsModerator(currentUser?.roles.includes("ROLE_MODERATOR"));
    // console.log(currentUser);
  }, []);

  useEffect(() => {
    retrieveCookware();
  }, []);

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const retrieveCookware = () => {
    CookwareDataService.getAll({ name: searchName })
      .then((response) => {
        setCookware(response.data.cookware);
        // console.log(response.data.cookware);
        // console.log(response.data);
      })
      .catch((e) => {
        console.log("retrieveCookware: ", e);
      });
  };

  const refreshList = () => {
    retrieveCookware();
    setCurrentCookware(null);
    setCurrentIndex(-1);
  };

  const setActiveCookware = (cookware, index) => {
    // console.log("cookware ", cookware);
    // console.log("index ", index);
    setCurrentCookware(cookware);
    setCurrentIndex(index);
  };

  const removeAllCookware = () => {
    CookwareDataService.removeAll()
      .then((response) => {
        console.log(response.data.cookware);
        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const findByName = () => {
    CookwareDataService.findByName(searchName)
      .then((response) => {
        setCookware(response.data.cookware);
        console.log(response.data.cookware);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveCategoryGroup(ensemble);
  }, []);

  const retrieveCategoryGroup = (ensemble) => {
    CategoryDataService.getAll({ name: searchName, ensemble: ensemble })
      .then((response) => {
        setCategoryGroup(response.data.categoryGroup);
        // console.log("response.data.categoryGroup ", response.data.categoryGroup);
        // console.log(response);
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  return (
    <div className="container">
      <KeyIcon style={{ position: "absolute", left: "0" }} />
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name"
              value={searchName}
              onChange={onChangeSearchName}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={findByName}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4>Cookware List</h4>
          <ul className="list-group">
            {categoryGroup &&
              categoryGroup.categories &&
              categoryGroup.categories.map((category, cindex) => {
                // console.log(category);

                const cookwareInCategory =
                  cookware &&
                  cookware.map((cookware, index) => {
                    // console.log(category, cookware.cookware);
                    // console.log(
                    //   category.name,
                    //   cookware.cookware.category
                    // );
                    if (category.name === cookware.cookware.category) {
                      cookware.cookware.matched = true;
                      return (
                        <li
                          className={
                            "list-group-item " +
                            (index === currentIndex ? "active" : "")
                          }
                          onClick={() => setActiveCookware(cookware, index)}
                          key={cindex + " " + index}
                        >
                          {cookware.cookware.name}
                        </li>
                      );
                    } else {
                      return null;
                    }
                  });
                const heading = (
                  <li
                    className={"list-group-item "}
                    key={cindex}
                    style={{
                      fontWeight: "bold",
                      paddingLeft: "5px",
                      fontSize: "large",
                    }}
                    onClick={() => setActiveCookware(null, -1)}
                  >
                    {category.name}
                  </li>
                );
                // console.log("cookware, cookwareInCategory ", cookware, cookwareInCategory);
                if (!cookwareInCategory || cookwareInCategory === undefined)
                  cookwareInCategory = {};
                return [heading, ...cookwareInCategory];
              })}
            <li
              className={"list-group-item "}
              key={"undefined"}
              style={{
                fontWeight: "bold",
                paddingLeft: "5px",
                fontSize: "large",
              }}
              onClick={() => setActiveCookware(null, -1)}
            >
              {"Unknown"}
            </li>
            {cookware &&
              cookware.map((cookware, index) => {
                // console.log(category, cookware.cookware);
                // console.log(
                //   category.name,
                //   cookware.cookware.category
                // );
                if (!cookware.cookware.matched) {
                  return (
                    <li
                      className={
                        "list-group-item " +
                        (index === currentIndex ? "active" : "")
                      }
                      onClick={() => setActiveCookware(cookware, index)}
                      key={index}
                    >
                      {cookware.cookware.name}
                    </li>
                  );
                } else {
                  return null;
                }
              })}
          </ul>
          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={removeAllCookware}
          >
            Remove All
          </button>

          <button className="m-3 btn btn-sm btn-danger">
            <Link to={"/addcookware"} className="nav-link">
              Add
            </Link>
          </button>
        </div>
        <div className="col-md-6">
          {currentCookware ? (
            <div>
              <h4>Cookware</h4>
              <div>
                <label>
                  <strong>Name:</strong>&nbsp;
                </label>
                {currentCookware.cookware.name}
              </div>
              <div>
                <label>
                  <strong>Description:</strong>&nbsp;
                </label>
                {currentCookware.cookware.description}
              </div>
              <div>
                <label>
                  <strong>Presentation:</strong>&nbsp;
                </label>
                {currentCookware.cookware.presentation}
              </div>
              <div>
                <label>
                  <strong>Category:</strong>&nbsp;
                </label>
                {currentCookware.cookware.category}
              </div>
 {currentCookware.cookware.externalLinks.length > 0 ?
               <div>
                <label>
                  <strong>External Links:</strong>&nbsp;
                </label>
                <ul>
                  {currentCookware.cookware.externalLinks.map((link, index) => {
                    return (
                      <li key={index}>
                        <a href={link.link}>{link.provider}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>:null}
              <div>
                <label>
                  <strong>Picture:</strong>&nbsp;
                </label>
                {currentCookware.cookware.media &&
                currentCookware.cookware.media.images &&
                currentCookware.cookware.media.images.head ? (
                  <div>
                    <>
                      {currentCookware.cookware.externalLinks.length > 0 ? (
                        <a
                          href={currentCookware.cookware.externalLinks[0].link}
                        >
                          <img
                            width={"25%"}
                            src={currentCookware.cookware.media.images.head}
                            alt={`Image Head category ${currentCookware.cookware.name}`}
                          />
                        </a>
                      ) : (
                        <img
                          width={"25%"}
                          src={currentCookware.cookware.media.images.head}
                          alt={`Image Head category ${currentCookware.cookware.name}`}
                        />
                      )}
                    </>
                    {currentCookware.cookware.externalLinks.length > 0 ? (
                      <a
                        href={currentCookware.cookware.externalLinks[0].link}
                        className="badge bg-warning"
                      >
                        Buy now!
                      </a>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <img
                      width={"25%"}
                      src={imageNotAvailable}
                      alt="Image Head cookware"
                    />
                  </div>
                )}
              </div>
              <div>
                <label>
                  <strong>Units:</strong>&nbsp;
                </label>
                {currentCookware.cookware.qtyUnit}
              </div>
              <div>
                <label>
                  <strong>Quantity:</strong>&nbsp;
                </label>
                {currentCookware.cookware.quantity}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>&nbsp;
                </label>
                {currentCookware.cookware.published ? "Published" : "Pending"}
              </div>
              {/* {console.log("currentCookware ", currentCookware)} */}
              <Link
                to={"/cookware/" + currentCookware.id}
                className="badge bg-info"
              >
                Show
              </Link>
              {isModerator ? (
                <Link
                  to={"/cookware/" + currentCookware.id}
                  className="badge bg-warning"
                >
                  Edit
                </Link>
              ) : null}
            </div>
          ) : (
            <div>
              <br />
              <p>Please click on a Cookware...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CookwareList;
