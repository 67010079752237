import React from "react";

// styling
// assets
import "../../assets/css/StyledDiv.css";

function StyledDiv(props) {
  return (
    <div className="colors"
      // style={{backgroundColor: props.color}}
    >
      {props.children}
    </div>
  );
}

export default StyledDiv;