import React from "react";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";

const SpinButton = ({ label, value, name, myStyle, onChange }) => {
  return (
    <FormControl style={myStyle}>
      {/* <InputLabel id={name}>{"lkj"+label}</InputLabel> */}
      {/* <label>
        {label} */}
      <TextField
        style={{
          paddingTop: "2pt",
          paddingBottom: "2pt",
          marginTop: "0",
          marginBottom: "0",
        }}
        id="quantity"
        label={label}
        type="number"
        value={value}
        onChange={onChange}
        placeholder="1"
        margin="normal"
        name={name}
      />
      {/* </label> */}
    </FormControl>
  );
};
export default SpinButton;
