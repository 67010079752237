import React from "react";

import KeyIcon from "@mui/icons-material/Key";

function Messages() {
  return (
    <div className="container">
      <KeyIcon style={{ position: "absolute", left: "0" }} />
      <h2>Messages</h2>
      <p>Here you can scroll through all of your messages.</p>
    </div>
  );
}

export default Messages;
