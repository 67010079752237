import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
// import es from "date-fns/locale/es";
import de from "date-fns/locale/de";
import parseISO from "date-fns/parseISO";

// registerLocale("es", es);
// setDefaultLocale("es", es);
registerLocale("de", de);
setDefaultLocale("de", de);

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
const MenuDatePicker = ({ menu, onChange }) => {
  const [startDate, setStartDate] = useState(menu.date ? menu.date : new Date());
  useEffect(() => {
    setStartDate(menu.date ? menu.date : new Date());
  }, [menu]);
  return (
    <>
      {console.log("startDate ", startDate)}
      <DatePicker
        locale="de"
        selected={parseISO(startDate)}
        onChange={(date) => {
          setStartDate(date);
          onChange(date);
          // console.log(props.menu);
          // console.log(date);
        }}
      />
    </>
  );
};

export default MenuDatePicker;
