import InventoryService from "../../services/inventory.service";

// auxilary functions
// function handleSave(errorElement, currentUser, items) {
function handleSave( currentUser, items) {
  const errorElement = document.getElementById("error");
  // const errorElement = document.getElementById("error");
  // console.log("handleSave: ");
  // console.log("notes: ", notes);
  // console.log("postInventory()/handleSave() ", currentUser);

  // just replace the list of todos, i.e. the tasks
  // console.log("todo.current: ", todo.current);

  InventoryService.postInventory(currentUser, items).then(
    (response) => {
      // console.log(response.data);
      // console.log(response.status);
      // console.log("postInventory() ", response.statusText);
      // console.log("postInventory() ", response.data.message);
      // console.log("postInventory() ", errorElement);
      // console.log(response.headers);
      // console.log(response.config);
      if (errorElement) {
        errorElement.hidden = false;
        errorElement.style.color = "black";
        errorElement.style.display = "block"; // "none";
        errorElement.innerText = response.data.message;
      }
      return response.data.message;
    },
    (error) => {
      const _content =
        error.message ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.toString();
      if (errorElement) {
        errorElement.hidden = false;
        errorElement.innerText = _content;
      }
      // console.log("error ", error);
      // console.log("error.message ", error.message);
      // console.log("error.toString() ", error.toString());
      // console.log("error.response ", error.response);
      // console.log("error.response.data ", error.response.data);
      // console.log("error.response.data.message ", error.response.data.message);
      // console.log("_content ", _content);
      // console.log("errorElement ", errorElement);
      return _content;
    }
  );
}

export default handleSave;
