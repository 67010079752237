import React, { useEffect, useState, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

import SearchBox from "../Playground/SearchBox";
import Dropdown from "../ShoppingList/Dropdown";
import ToggleServes from "../Playground/ToggleServes";

import RecipeDataService from "../../services/recipe.service";

import { coursesTypes, mealTypes } from "./menu.config";

/**
 *
 * @param {*} param0
 * meal - lunch OR dinner
 * findByName - search function
 * menu - the menu of the selected day (compromising lunch AND dinner)
 * setMenu - updates the menu on the parent level
 * reset - a tentative resetting capability
 * @returns
 */
function MenuBuilder({ meal, findByName, menu, setMenu, reset }) {
  // passClearStateFunc
  const [course, setCourse] = useState(0);
  const [courses, setCourses] = useState(null);
  const [mealType, setMealType] = useState(menu[meal].mealType);
  const [nextStep, setNextStep] = useState(false);
  const [dish, setDish] = useState("");
  const [serves, setServes] = useState(0);

  // const [showIt, setShowIt] = useState(true);

  // let courses;
  // console.log("menu: ", menu);

  // useEffect(() => {
  //   // const clearState = () => {
  //   //   // alert("Booboo");
  //   //   setShowIt(false);
  //   // };
  //   // pass the clearState function back to parent
  //   // passClearStateFunc(clearState);
  //   passClearStateFunc(()=>setShowIt);
  // }, []);

  useEffect(() => {
    // const clearState = () => {
    //   // alert("Booboo");
    //   setShowIt(false);
    // };
    // pass the clearState function back to parent
    // passClearStateFunc(clearState);
    // console.log("menu ", menu);
    // console.log("menu[meal].mealType ", menu[meal].mealType);
    // console.log("menu[meal].courses ", menu[meal].courses);
    setMealType(menu[meal].mealType);
    const indx = mealTypes.indexOf(menu[meal].mealType);
    setCourses(coursesTypes[indx]);
  }, [menu]);

  //this is the reference to the child clearState function
  // let clearChildState = null;
  const clearChildState = useRef(null);

  // this method assigns the child clearState function
  // to the parent reference
  function assignClearChildState(childClearStateFunc) {
    // console.log("assignClearChildState ", childClearStateFunc);
    clearChildState.current = childClearStateFunc;
  }
  // this method gets the child state for completeness of
  // the childComp tag
  function getStateCallback(item) {
    //do something to use the child state
    console.log("item ", item);
  }

  function resetForm() {
    // console.log("clearChildState() ", clearChildState.current);
    clearChildState.current("");
  }

  function handleLoopCourse() {
    if (courses.length) {
      // setCourse((prevValue) => (prevValue + 1) % courses.length);
      setCourse((prevValue) => prevValue + 1);
    }
    // console.log("handleLoopCourse course", course);
    // console.log("handleLoopCourse courses.length", courses.length);
  }

  /**
   *
   * @param {*} name which meal: lunch or dinner (or breakfast)
   * @param {*} value which dish for the corresponding course
   *                  has been selected
   * @param {*} id the recipe id
   * @returns
   */
  function onSelect(name, value, id) {
    // console.log("onSelect() name: ", name);
    console.log("value: ", value);
    RecipeDataService.get(value.id)
      .then((response) => {
        const recipe = response.data.recipe;
        // setCurrentRecipe(() => {});
        setMenu((prevValue) => {
          const menu = prevValue[name];
          // const menu = {};
          console.log("MenuBuilder::onSelect() recipe ", recipe);
          // console.log("menu (before) ", menu);
          // console.log("menu (before) menu.courses", menu.courses);
          // console.log("courses[course] ", courses[course]);
          // console.log(prevValue);
          console.log("MenuBuilder::serves", serves);
          // const course = name === "lunch" ? course : courseForDinner;
          menu.mealType = mealType;
          menu.serves = serves;
          if (!menu.courses) menu.courses = [];
          menu.courses[course] = {
            description: courses[course],
            name: value.name,
            difficulty: recipe.difficulty,
            time: recipe.time,
            rating: recipe.comments.rating,
            ratings: recipe.comments.ratings,
            image: recipe.media.images.head,
            id: value.id,
            cookWay: "LetSCook",
            servings: recipe.servings,
            cost: recipe.cost,
            serves: serves,
          };
          // setServes(recipe.servings);
          prevValue[name] = menu;
          console.log("menu (after) ", menu);
          // console.log("course ", course);
          // console.log("prevValue ", prevValue);
          // reset the Dropbox to "empty"
          // console.log("value: ", value);
          // setDish(value.name);
          setDish("");
          // console.log("dish: resetting to ", dish);
          // console.log(menu);
          handleLoopCourse();
          setNextStep(true);
          resetForm("");
          return prevValue;
        });
      })
      .catch((error) => {
        console.error("Error RecipeDataService.get(id):", error);
      });

    // console.log("id", id);
    // alert("Selected value: " + value + " " + id);
  }

  function deleteMenuItem(indx) {
    menu[meal].courses[indx] = {};
    // if (meal === "lunch") menu[meal].courses[indx] = {};
    // else menu.dinner[indx] = {};
    setCourse(indx);
    // console.log("deleteMenuItem menu ", menu);
    // console.log("deleteMenuItem indx", indx);
  }

  function updateServes(serves){
    // console.log("menu[meal] ", menu[meal]);
    // console.log("course ", course);
    for (let course = 0; course < menu[meal].courses.length; course++) {
      menu[meal].courses[course].serves = serves;
    }
    menu[meal].serves = serves;
    setServes(serves);
    console.log("menu[meal] ", menu[meal]);
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    const indx = mealTypes.indexOf(value);
    setCourses(coursesTypes[indx]);
    // console.log("handleOnChange() indx, courses ", indx, courses);
    // console.log("handleOnChange() name, value ", name, value);
    setMealType(value);
  }

  return (
    <div>
      {/* <h4>{meal}</h4> */}
      {/* <input
              name="lunch"
              placeholder="Lunch"
              value={menu[meal]}
              onChange={changeMenu}
              style={{
                display: formShown ? "inline" : "none",
                // padding: "5pt",
                margin: "2pt",
              }}
            /> */}
      {/* {console.log("menu ", menu)}
      {console.log("meal ", meal)}
      {console.log("mealType ", mealType)}
      {console.log("courses ", courses)} */}
      <div style={{ backgroundColor: "yellow" }}>
        <div className="row">
          <div className="col-2" style={{ margin: "auto" }}>
            {meal}
          </div>
          <div className="col">Serves: {serves}
            <ToggleServes setServes={updateServes} />
          </div>
          <div className="col">
            <Dropdown
              disabled={mealType ? true : false}
              myStyle={{
                margin: "15pt 0pt 0pt 0pt",
                // float: "left",
                // maxWidth: "60pt",
                width: "100%",
              }}
              label="MealType"
              options={mealTypes}
              // options={categoryGroups.Cookware.categories}
              value={mealType}
              className="form-control"
              id="mealType"
              required
              onChange={handleOnChange}
              name="mealType"
            />
          </div>
        </div>

        <div
          style={{
            display: mealType === null ? "none" : "block",
            textAlign: "right",
          }}
        >
          {courses && nextStep && course < courses.length - 1 ? (
            <div style={{ display: "inline" }} onClick={handleLoopCourse}>
              <span style={{ verticalAlign: "top" }}>Add: </span>{" "}
              {courses ? courses[course] : null}
              <AddIcon />
              <span style={{ verticalAlign: "top" }}>/Next: </span>{" "}
              {courses ? courses[course + 1] : null}
            </div>
          ) : null}

          {/* {dish} */}
          {/* {console.log("course ", course)} */}
          {/* {console.log("dish ", dish)} */}
          {courses && course < courses.length ? (
            <SearchBox
              // getState={getState}
              getState={getStateCallback}
              // passClearState={passClearState}
              passClearState={assignClearChildState}
              destructureVector="matchingRecipes"
              name={meal}
              // value={dish ? dish : ""}
              value={dish}
              onSelect={onSelect}
              placeholder={meal + " " + courses[course]}
              // the recipes are now objects {name, id}
              // value={{ name: " ", id: "0" }}
              find={findByName}
            />
          ) : null}

          <div
            style={{
              backgroundColor: "#ffffff",
              border: "solid black 1px",
              maxWidth: "400px",
              // height: "20px",
              overflow: "hidden",
              padding: "1px",
            }}
          >
            {/* {console.log("courses ", courses)} */}
            {/* {console.log("meal, menu ", meal, menu)} */}
            {/* {console.log("reset ", reset)} */}
            {courses?.map((course, indx) => {
              // console.log("indx ", indx);
              // console.log(
              //   "menu, indx ",
              //   menu &&
              //     menu[meal] &&
              //     menu[meal][indx] &&
              //     menu[meal][indx].name,
              //   indx
              // );
              if (
                menu[meal].courses &&
                menu[meal].courses[indx] &&
                menu[meal].courses[indx].name &&
                !reset
              )
                return (
                  <div
                    key={indx}
                    style={{
                      float: "left",
                      padding: "5px 10px",
                      margin: "5px",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "20px",
                    }}
                  >
                    {courses[indx]}{" "}
                    {menu &&
                      menu[meal] &&
                      menu[meal].courses[indx] &&
                      menu[meal].courses[indx].name}
                    <div
                      onClick={() => deleteMenuItem(indx)}
                      style={{
                        position: "relative",
                        display: "inline-block",
                        backgroundColor: "#c0c0c0",
                        padding: "2px",
                        borderRadius: "15px",
                        color: "#1976d2",
                        marginLeft: "5px",
                      }}
                    >
                      <ClearIcon style={{ marginTop: "-2px" }} />
                    </div>
                  </div>
                );
              else return null;
            })}
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
      </div>
    </div>
  );
}
export default MenuBuilder;
