import http from "../config/http-common";

import authHeader from "./auth-header";

class RecipeDataService {
  getAllPaginated(params) {
    const response = http.get("/api/recipes", {
      headers: authHeader(),
      params: params,
    });
    // console.log("response ", response);
    return response;
  }

  getAll(params) {
    const response = http.get("/api/recipes/all", {
      headers: authHeader(),
      params: params,
    });
    // console.log("response ", response);
    return response;
  }

  get(id) {
    return http.get(`/api/recipes/${id}`, { headers: authHeader() });
  }

  create(data) {
    // console.log("data ", data);
    return http.post("/api/recipes/add", data, { headers: authHeader() });
  }

  update(id, data) {
    // const dataInJSON = JSON.stringify(data);
    // console.log("id ", id, "data ", data, "dataInJSON ", dataInJSON);
    // console.log("id ", id, "data ", data, );
    return http.put(`/api/recipes/${id}`, data, {headers: authHeader(),
    });
  }

  delete(id) {
    return http.delete(`/api/recipes/${id}`, { headers: authHeader() });
  }

  deleteAll() {
    return http.delete(`/api/recipes`, { headers: authHeader() });
  }

  findByName(name) {
    return http.get(`/api/recipes?name=${name}`, {
      headers: authHeader(),
    });
  }
}
export default new RecipeDataService();
