const ensembles = [
  "Ingredient",
  "Cooking",
  "Tags",
  "Measure",
  "Packaging",
  "Kitchenware",
  "CookWays",
  "MainSections",
  "Sections",
  "SubSections",
];

export default ensembles;