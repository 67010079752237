import http from "../config/http-common";
import axios from "axios";
import authHeader from "./auth-header";

import server from "../config/server.config";

const API_URL =
  server.host + (server.port === "" ? "" : ":" + server.port) + "/api/user/";

class ListDataService {

  getAll(params) {
    const response = http.get("/api/user/lists", {
      headers: authHeader(),
      params: params,
    });
    // console.log("response ", response);
    return response;
  }

  get(id) {
    return http.get(`/api/user/lists/${id}`, { headers: authHeader() });
  }

  create(data) {
    // console.log("data ", data);
    return http.post("/api/user/lists/add", data, { headers: authHeader() });
  }

  update(id, data) {
    console.log("data ", data)
    return http.put(`/api/user/lists/${id}`, data, { headers: authHeader() });
  }

  delete(id) {
    return http.delete(`/api/user/lists/${id}`, { headers: authHeader() });
  }

  deleteAll() {
    return http.delete(`/api/user/lists`, { headers: authHeader() });
  }

  findByName(name) {
    return http.get(`/api/user/lists?name=${name}`, {
      headers: authHeader(),
    });
  }

  getLists(user) {
    return axios.get(`${API_URL}lists/${user.id}`, {
      timeout: 1000,
      headers: authHeader(),
    });
  }

  postLists(user, lists) {
    const data = {
      userid: user.id,
      // List: lists,
      lists: lists,
    };
    // console.log(data);
    return axios.post(API_URL + "lists", data, {
      // `method` is the request method to be used when making the request
      method: "post", // default "get"
      headers: authHeader(),
      timeout: 1000, // default is `0` (no timeout)
    });
  }


}
export default new ListDataService();



