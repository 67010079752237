import React from "react";

function Detail(props) {
  return (
    <div className={props.class}>
      <p className="info"><a href={`tel:${props.phone}`}>{props.phone}</a></p>
      <p className="info"><a href={`mailto:${props.email}`}>{props.email}</a></p>
    </div>
  );
}

export default Detail;
