import { useState } from "react";

function Quantity(props) {
  // const { quantity } = props;
  const [quantity, setQuantity] = useState(1);
  const { id, superHandleDecrement, superHandleIncrement } = props;

  function handleDecrement(id, quantity) {
    if (quantity > 1) {
      superHandleDecrement(id, quantity-1);
      setQuantity(quantity - 1);
    }
  }

  function handleIncrement(id, quantity) {
    superHandleIncrement(id, quantity+1);
    setQuantity(quantity + 1);
    // console.log("quantity ", quantity+1);
  }

  return (
    <div>
      <label>
        <strong>Quantity: </strong>
      </label>
      <button
        key={id}
        className="badge bg-warning mr-2"
        onClick={() => handleDecrement(id, quantity)}
        style={{ color: "#0d6efd", marginLeft: "10px" }}
      >
        <span style={{ fontSize: "1.5rem" }}>{"-"}</span>
      </button>
      &nbsp; {quantity}
      <button
        key={id + 1}
        className="badge bg-warning mr-2"
        onClick={() => handleIncrement(id, quantity)}
        style={{ color: "#0d6efd", marginLeft: "10px" }}
      >
        <span style={{ fontSize: "1.5rem" }}>{"+"}</span>
      </button>
    </div>
  );
}

export default Quantity;
