import React, { useEffect, useState } from "react";
import DaySpinner from "./DaySpinner";
import CreateMenuEntry from "./CreateMenuEntry";
import Menu from "./Menu";

function MenuForSelectedDay({
  user,
  theDate,
  setTheDate,
  menus,
  findByName,
  onAdd,
  onDelete,
  onReplace,
  convertDate,
}) {
  // console.log(theDate);
  // console.log(menus);
  // const [isLoading, setIsLoading] = useState(true);
  const [menu, setMenu] = useState(null);

  const Lunch = ({ children }) => (
    <div style={{ fontSize: "1rem", fontWeight: "300" }}>
      Lunch:
      <span style={{ fontSize: "2rem", fontWeight: "300" }}> {children}</span>
    </div>
  );

  const Dinner = ({ children }) => (
    <div style={{ fontSize: "1rem", fontWeight: "300" }}>
      Dinner:
      <span style={{ fontSize: "2rem", fontWeight: "300" }}> {children}</span>
    </div>
  );

  function getMenuOfTheDate(theDate) {
    const menuOfTheDate = menus.filter((menu) => {
      const menuDate = new Date(menu.date).toDateString();
      // console.log(
      //   "getMenuOfTheDate(theDate) ",
      //   menu,
      //   menuDate,
      //   theDate.toDateString()
      // );
      return menuDate === theDate.toDateString();
    });
    // console.log("getMenuOfTheDate(theDate) ", menus, menuOfTheDate);
    if (menuOfTheDate && menuOfTheDate.length > 0) {
      setMenu(menuOfTheDate[0]);
      // setIsLoading(false);
      // console.log("getMenuOfTheDate(theDate) ", menuOfTheDate[0]);
      return menuOfTheDate[0];
    } else {
      setMenu(null);
      // setIsLoading(false);
      return null;
    }
  }

  // useEffect(() => {
  //   getMenuOfTheDate(theDate);
  // }, []);

  useEffect(() => {
    getMenuOfTheDate(theDate);
  }, [theDate, menus]);

  // console.log(menus);



  // if (!isLoading) {
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        padding: "20px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DaySpinner setTheDate={setTheDate} theDate={theDate} />
      {/* {console.log(theDate)}
        {console.log(menu)} */}
      {menu ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flex: "0 1 0%",
          }}
        >
          <Menu
            user={user}
            findByName={findByName}
            key={menu.id}
            id={menu.id}
            menu={menu}
            onDelete={onDelete}
            onReplace={onReplace}
            convertDate={convertDate}
          />
          {/* <Lunch>{menu.lunch}</Lunch>
            <Dinner>{menu.dinner}</Dinner> */}
        </div>
      ) : (
        <div>
          <CreateMenuEntry
            findByName={findByName}
            // availableRecipes={availableRecipes}
            onAdd={onAdd}
            theDate={theDate}
          />
        </div>
      )}
    </div>
  );
}
// }

export default MenuForSelectedDay;
