import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";

import Item from "./Item";

import Dropdown from "./Dropdown";
import SpinButton from "./SpinButton";
import TextInput from "./TextInput";
import SearchBox from "../Playground/SearchBox";
import IngredientDataService from "../../services/ingredient.service";
import CookwareDataService from "../../services/cookware.service";

// import options from "./qtyUnitOptions";

// const emptyItem = {
//   index: 1,
//   name: "",
//   quantity: 0,
//   qtyUnit: "uds",
//   description: "",
// };


export default function EditItemRow(props) {
  const [item, setItem] = useState(props.value);
  const [collection, setCollection] = useState(props.collection);
  const [destructureVector, setDestructureVector] = useState(
    props.collection.toLowerCase()
  );

  // let collection = props.collection;
  const indx = props.indx;
  // let destructureVector;

  // // this method clear the state in child component
  // const clearState = (emptyItem) => {
  //   // console.log(
  //   //   "GrowingExtendedListItem::clearState() indx, emptyItem",
  //   //   indx,
  //   //   emptyItem
  //   // );
  //   setItem(emptyItem);
  //   props.onChange(emptyItem);
  // };

  // // pass the clearState function back to parent
  // props.passClearState(clearState);

  // // props.getState(item);

  // useEffect(() => {
  //   collection = props.collection;
  //   destructureVector = collection.toLowerCase();
  //   // if (destructureVector.slice(-1) === "s") {
  //   //   destructureVector.slice(0, -1);
  //   // }

  //   console.log("destructureVector: ", destructureVector);
  // }, [props.collection]);
  // console.log("props.value: ", props.value);

  /**
   *
   * @param {*} name
   * calls:
   * - setItem()
   * - props.onEdit()
   */
  const onSelect = (name, value, id) => {
    // console.log("onSelect(): name, value, id ", name, value, id);
    // console.log("onSelect(): item ", item);
    // const ingredient = { ...item.ingredient, name: name };
    let property;
    if (collection === "Ingredients") {
      property = "ingredient";
    } else {
      property = "cookware";
    }
    // console.log("onSelect(): property ", property);
    const newItem = { ...item, name: name, [property]: name, id: id };
    // alert("Selected ingredient: " + ingredient);
    // console.log("onSelect(): newItem ", newItem);
    // console.log("onSelect(): ingredient ", ingredient);
    // setItem({ ...item, ingredient: ingredient });
    // setItem({ ...item, name: name });
    setItem(newItem);
    props.onChange(newItem);
    // on "select" the form will be disabled for input
    // props.onEdit(false);
  };

  /**
   *
   * @param {*} event
   * calls:
   * - setItem()
   * - props.onEdit()
   * NOT
   * - props.onChange()
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log("name, value: ", name, value);
    const newItem = { ...item, [name]: value };
    setItem(newItem);
    props.onChange(newItem);
    props.onEdit(false);
    // console.log("newItem: ", newItem);
  };

  useEffect(() => {
    setItem(props.value);
    // console.log("props.value: ", props.value);
    // console.log("collection: ", collection);
  }, [props.value]);
  // console.log("props.value: ", props.value);

  return (
    <Box
      // className="listItem editItemsBox"
      // className="editItemsBox"
      // style={{ backgroundColor: "rgb(219, 216, 24)" }}
      sx={{
        // width: "100%",
        height: "50pt",
        backgroundColor: "whitesmoke",
        margin: "5pt 5pt",
        padding: "0pt 5pt 52pt 5pt",
        borderRadius: "5pt",
        //   backgroundColor: "primary.dark",
        //   "&:hover": {
        //     backgroundColor: "primary.main",
        //     opacity: [0.9, 0.8, 0.7],
        //   },
      }}
    >
      <Item maxWidth="60pt" float="left" margin="5pt 0" padding="0">
        <SpinButton
          // myStyle={{ float: "left", maxWidth: "60pt" }}
          // myStyle={{ float: "left", maxWidth: "60pt" }}
          value={item.number}
          label="Number"
          onChange={handleChange}
          name="number"
        />
      </Item>
      <Item maxWidth="100pt" float={"left"} margin={"7pt 0"} padding={"0"}>
        <Dropdown
          // myStyle={{ float: "left", maxWidth: "60pt" }}
          label="Packaging"
          options={props.options}
          value={item.packaging}
          onChange={handleChange}
          name="packaging"
        />
      </Item>
      <Item maxWidth="10pt" float={"left"} margin={"7pt 10pt"} padding={"0"}>
        a
      </Item>
      <Item maxWidth="60pt" float="left" margin="5pt 0" padding="0">
        <SpinButton
          // myStyle={{ float: "left", maxWidth: "60pt" }}
          // myStyle={{ float: "left", maxWidth: "60pt" }}
          value={item.quantity}
          label="Quantity"
          onChange={handleChange}
          name="quantity"
        />
      </Item>
      {/* <Item maxWidth="60pt">{item.qtyUnit} </Item> */}
      <Item maxWidth="100pt" float={"left"} margin={"7pt 0"} padding={"0"}>
        <Dropdown
          // myStyle={{ float: "left", maxWidth: "60pt" }}
          label="Units"
          options={props.options}
          value={item.qtyUnit}
          onChange={handleChange}
          name="qtyUnit"
        />
      </Item>
      {/* <select style={{ float: "left" }}>
            <option value="l">l</option>
            <option value="kg">kg</option>
            <option value="lata">lata</option>
            <option value="pc">pc</option>
          </select> */}
      {/* <Item maxWidth="240pt">{item.ingredient}</Item> */}
      <Item maxWidth="240pt" float="left">
        {/* <TextInput
          // myStyle={{ float: "left", maxWidth: "60pt" }}
          // myStyle={{ float: "left", maxWidth: "60pt" }}
          value={item.name}
          // label="ingredient"
          onChange={handleChange}
          name="ingredient"
        /> */}
        <SearchBox
          destructureVector={destructureVector}
          passClearState={props.passClearState}
          name="item"
          value={props.editMode ? item.name : ""}
          placeholder="Search for ..."
          onSelect={onSelect}
          find={
            collection === "Ingredients"
              ? IngredientDataService.findByName
              : CookwareDataService.findByName
          }
          // label="ingredient"
        />
      </Item>
      {/* <div>
            <div style={{ float: "left" }}>
              <SaveIcon onClick={onSave} />
            </div>
            <div style={{ float: "left" }}>
              <ClearIcon onClick={handleClick} />
            </div>
          </div> */}
      <Item maxWidth="40pt" float="right">
        <ClearIcon
          onClick={(event) => {
            event.preventDefault();
            props.onEdit(true);
            if (props.setAddingMode) {
              props.setAddingMode(!props.addingMode);
              setItem({});
            } else {
              props.setEditMode(!props.editMode);
              setItem(props.value);
            }
          }}
        />
      </Item>
      <Item maxWidth="40pt" float="right" style={{ backgroundColor: "green" }}>
        <SaveIcon
          // style={{ width: "20pt", height: "20pt", backgroundColor: "blue" }}
          style={{ width: "20pt", height: "20pt" }}
          value={item}
          name={"hello"}
          onClick={(event) => {
            // event.preventDefault();
            props.onEdit(false);
            // props.addStep();
            // console.log("event.name ", event);
            // console.log("props.editMode ", props.editMode);
            // console.log("EditItemRow:: indx, item ", indx, item);
            // props.onChange(event);
            if (props.setAddingMode) {
              props.setAddingMode(!props.addingMode);
              props.onSave(-1, item);
            } else {
              props.setEditMode(!props.editMode);
              // props.onChange(item);
              props.onSave(indx, item);
            }
          }}
        />
      </Item>
      {/* <div style={{ clear: "both" }}></div> */}
    </Box>
  );
}
