import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

import Item from "./Item";
import { truncate } from "../../helpers/helper";

export default function ItemRow(props) {
  const [item, setItem] = useState(props.value);
  const [isChecked, setIsChecked] = useState(props.isChecked);
  const indx = props.indx;
  const hasChkBox = props.hasChkBox;

  function handleChkbChange(indx) {
    // console.log(event.target);
    const chkbState = !isChecked;
    // console.log("indx, chkbState ", indx, chkbState);
    props.onChkbChange(indx, chkbState);
    setIsChecked(chkbState);
  }

  return (
    // style={{width: "100%"}}
    <Box className="Box xlistItem">
      {/* <div
              style={{ paddingTop: "5pt", backgroundColor: "blue" }}
              > */}
      {/* {props.stepLabel ? (
                  <div style={{ padding: "0 5pt", float: "left", width: "20%" }}>
                    {props.stepLabel} {props.indx + 1}:
                  </div>
                ) : (
                  ""
                )} */}
      {/* {indx} */}
      {/* {console.log(item)} */}
      {/* {console.log("isChecked", isChecked)} */}
      {hasChkBox ? (
        <Item float={"left"} style={{ margin: "2pt" }} maxWidth="40pt">
          <Checkbox
            style={{ padding: "0pt", float: "left" }}
            value={isChecked ? "on" : "off"}
            checked={isChecked}
            name={item.name}
            id={item.id}
            onChange={() => handleChkbChange(props.indx)}
            // inputProps={{ "aria-label": "controlled" }}
          />
        </Item>
      ) : null}
      <Item
        float={"left"}
        margin={"5pt 0 5pt 5pt"}
        style={{ margin: "0 0 0 5pt" }}
        maxWidth="40pt"
      >
        {item.quantity*(item.number?item.number:1)}
      </Item>
      <Item
        float={"left"}
        margin={"5pt 5pt 5pt 0 "}
        style={{ margin: "0 5pt 0 0" }}
        maxWidth="60pt"
      >
        {item.qtyUnit}
      </Item>
      <Item float={"left"} maxWidth="240pt">
        {item.name ? truncate(item.name, 20) : truncate(item.ingredient, 20)}
      </Item>
      <Item float={"right"} maxWidth="240pt">
        <button className="badge bg-warning mr-2">
          <Link to={`/ingredients/${item.id}`} className="nav-link">
            See product
            {/* {truncate(item.name, 20)} */}
          </Link>
        </button>
      </Item>

      {/* <div style={{ clear: "both" }}></div> */}
      <Item maxWidth="40pt" float="right">
        <DeleteIcon
          onClick={(event) => {
            event.preventDefault();
            props.onDelete(indx);
          }}
        />
      </Item>
      <Item maxWidth="40pt" float="right">
        <EditIcon
          onClick={(event) => {
            event.preventDefault();
            props.setEditMode(!props.editMode);
            props.onEdit(indx);
          }}
        />
      </Item>
      <div style={{ clear: "both" }}></div>
      {/* </div> */}
    </Box>
  );
}
