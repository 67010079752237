import React, { useState } from "react";

import Menu from "./Menu";

export default function ListMenu(props) {
  const [menus, setMenus] = useState(props.menus);
  const { deleteMenu, replaceMenu, convertDate, findByName, user } = props;

  return (
    <div
      className="MenuCards"
      style={{
        // float: "left",
        borderRadius: "15px",
        backgroundColor: "white",
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "20px",
        marginRight: "20px",
        padding: "10px 20px",
      }}
    >
      {/* <button
        onClick={() => {
          addMenu(menus);
        }}
      >
        <div style={{ display: "inline" }}>
          <span style={{ verticalAlign: "top" }}>Add </span>
          <AddCircleOutlineIcon />
        </div>
      </button>

      <button
        onClick={() => {
          handleSave(menus);
        }}
      >
        <div style={{ display: "inline" }}>
          <span style={{ verticalAlign: "top" }}>Save </span>
          <CheckCircleOutlineIcon />
        </div>
      </button>
      */}
      {/* {console.log("menus ", menus)} */}
      <div className="row">
        {menus.length > 0 ? (
          menus.map((menu, index) => (
            <div className="col-lg-6" key={index}>
              <Menu
                user={user}
                findByName={findByName}
                key={index}
                id={index}
                menu={menu}
                onDelete={deleteMenu}
                replaceMenu={replaceMenu}
                convertDate={convertDate}
              />
            </div>
          ))
        ) : (
          <div style={{ color: "black" }}> No menus for this period </div>
          // null
          // <Menu
          //   key={-1}
          //   id={-1}
          //   onDelete={deleteMenu}
          //   replaceMenu={replaceMenu}
          //   convertDate={convertDate}
          //   menu={{
          //     weekday: "Monday",
          //     lunch: "Pasta Carbonara",
          //     dinner: "sopa de fideos",
          //     date: "2022-01-14T12:00:00.000+00:00",
          //   }}
          // />
        )}
      </div>
    </div>
  );
}
