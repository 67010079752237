/*************************************
 * User Registration and User Login Flow
 * For JWT Authentication, we’re gonna call 2 endpoints:
 *
 *  POST api/auth/signup for User Registration
 *  POST api/auth/signin for User Login
 *************************/
import axios from "axios";
import server from "../config/server.config";
import { userGoesOffline } from "../context/WSContext";
import __DEBUG__ from "../config/debug.config";

const API_URL =
  server.host + (server.port === "" ? "" : ":" + server.port) + "/api/auth/";
// Finally, you can conditionally use the variables you created.
// const url =
//   process.env.NODE_ENV === "development"
//     ? process.env.REACT_APP_API_URL
//     : process.env.REACT_APP_API_URL;

// console.log("process.env.PORT", process.env.PORT);
// console.log("process.env.NODE_ENV", process.env.NODE_ENV);
// console.log("env", process.env);
// console.log("url", url);
// console.log("API_URL", API_URL);

const register = (forename, surname, username, email, password) => {
  return axios.post(API_URL + "signup", {
    forename,
    surname,
    username,
    email,
    password,
  });
};

/**
 * 
 * @param {*} username 
 * @param {*} password 
 * @returns axios promise
 * 
 * posts credentials to /api/auth/signin
 */
const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem(
          process.env.REACT_APP_LOCALHOST_KEY,
          JSON.stringify(response.data)
        );
      }
      // console.log("login response.data ", {...response.data, avatarImage:"..."});
      return response.data;
    })
    .catch((err) => {
      if (__DEBUG__) {
        // console.log(err.message);
        // console.log(err.response.status);
        // console.log(err.response.statusText);
        // console.log(err.response.data.message);
        // console.log(err);
        // throw new Error(err.response.data.message);
        // return err.response.data.message;
      }
    });
};


/**
 * 
 * @param {*} username 
 * @param {*} password 
 * @returns axios promise
 * 
 * posts credentials to /api/auth/signin
 */
const getUserInfo = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      // console.log("getUserInfo response.data ", response.data);
      return response.data;
    })
    .catch((err) => {
      if (__DEBUG__) {
        // console.log(err.message);
        // console.log(err.response.status);
        // console.log(err.response.statusText);
        console.log(err.response.data.message);
        // console.log(err);
        // return err.response.data.message;
      }
      throw new Error(err.response.data.message);
    });
};


const logout = async () => {
  const user = await getCurrentUser();
  // console.log("user ", {...user, avatarImage: ""});
  if (user) userGoesOffline(user.id);
  localStorage.removeItem(process.env.REACT_APP_LOCALHOST_KEY);
};

const getCurrentUser = async () => {
  return await JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));
};

const authServices = {
  register,
  login,
  logout,
  getCurrentUser,
  getUserInfo,
};

export default authServices;
