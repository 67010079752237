/**
 * This helped a lot
 * especially styling
 * https://www.bezkoder.com/react-node-express-mongodb-mern-stack/
 */

import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import Alert from "react-bootstrap/Alert";

import KeyIcon from "@mui/icons-material/Key";

// import UserService from "../../services/user.service";
import IngredientDataService from "../../services/ingredient.service";
import CategoryDataService from "../../services/category.service";
import ShoppingListService from "../../services/shoppingList.service";
import addIngredientsToShoppingList from "../../components/ShoppingList/addIngredientToShoppingList";
import CategorisedIngredients from "../../components/Ingredients/CategorisedIngredients";
import { truncate } from "../../helpers/helper";

import "../../components/Ingredients/Ingredients.css";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";
import server from "../../config/server.config";
const imageServerURL = server.cdn;

function addDefaultSrc(e) {
  e.target.src = imageNotAvailable;
}
const ensemble = "Ingredient";

// const imageServerURL = process.env.REACT_APP_IMAGE_SERVER_URL
//   ? process.env.REACT_APP_IMAGE_SERVER_URL
//   : "http://192.168.1.10:4000/";
// console.log(imageServerURL);

/**
 * component definition
 */

const IngredientsList = () => {
  const [ingredients, setIngredients] = useState([]);
  const [currentIngredient, setCurrentIngredient] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchName, setSearchName] = useState("");
  const [categoryGroup, setCategoryGroup] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isModerator, setIsModerator] = useState(false);
  const [isLimited, setIsLimited] = useState(true);
  const [message, setMessage] = useState("");
  const [isInError, setIsInError] = useState(false);
  const [inCart, setInCart] = useState(0);

  const myRef = useRef("there is no element");

  useEffect(() => {
    setIsModerator(currentUser?.roles.includes("ROLE_MODERATOR"));
    // console.log(currentUser);
  }, []);

  useEffect(() => {
    retrieveIngredients();
  }, []);

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const retrieveIngredients = () => {
    IngredientDataService.getAll({ name: searchName })
      .then((response) => {
        setIngredients(response.data.ingredients);
        // console.log(response.data.ingredients);
        // console.log(response);
      })
      .catch((e) => {
        console.log("retrieveIngredients: ", e);
      });
  };

  const refreshList = () => {
    retrieveIngredients();
    setCurrentIngredient(null);
    setCurrentIndex(-1);
  };

  const setActiveIngredient = (ingredient, index) => {
    console.log("ingredient ", ingredient);
    console.log("index active ingredient", index);
    setCurrentIngredient(ingredient);
    setCurrentIndex(index);
  };

  const removeAllIngredients = () => {
    IngredientDataService.removeAll()
      .then((response) => {
        console.log(response.data.ingredients);
        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const findByName = () => {
    IngredientDataService.findByName(searchName)
      .then((response) => {
        setIngredients(response.data.ingredients);
        // console.log(response.data.ingredients);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveCategoryGroup(ensemble);
  }, []);

  const retrieveCategoryGroup = (ensemble) => {
    CategoryDataService.getAll({ name: searchName, ensemble: ensemble })
      .then((response) => {
        setCategoryGroup(response.data.categoryGroup);
        // console.log("response.data.categoryGroup ", response.data.categoryGroup);
        // console.log(response);
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  async function handleAddIngredientToShoppingList(rejectIfInShoppingList) {
    const servings = 4;
    const serves = 4;
    const result = await addIngredientsToShoppingList(
      currentUser,
      [currentIngredient],
      servings,
      serves
    );
    setMessage(
      `Added ${currentIngredient.ingredient.name} to your Shopping List.`
    );
    // getIngredientInShoppingList(currentIngredient.ingredient, inCart);
    console.log(rejectIfInShoppingList);
    console.log(result);
    // console.log(inCart);
    setInCart((prevValue) => {
      // console.log(prevValue);
      return prevValue + 1;
    });
  }

  async function getIngredientInShoppingList(ingredient, inCart) {
    ShoppingListService.getIngredient(currentUser.id, ingredient.id)
      .then(
        (response) => {
          // let inCart = 0;
          // console.log("response.data.totalItems ", response.data.totalItems);
          // console.log("response.data.items ", response.data.items);
          // console.log(
          //   "response.data.items[0].index ",
          //   // response.data.items[0].items[0].index
          //   response.data.items[0].index
          // );
          // console.log(response.status);
          console.log(response.data);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // if (errorElement) {
          // errorElement.hidden = true;
          // errorElement.style.display = "none"; //"block";

          // errorElement.hidden = true;
          // errorElement.innerText = response.statusText;
          // errorElement.innerText = "";
          // errorElement.style.display = "block";
          // errorElement.style.display = "none";
          // }
          // setMessage(response.statusText);
          if (response.status === 200) {
            // response.data is the on the
            // ingredient id filtered shoppinglist
            if (response.data.length === 0) {
              setMessage("This item is not in your shopping cart.");
              inCart = 0;
            } else {
              console.log("response.data[0].number", response.data[0].number);
              console.log(
                "response.data[0].quantity",
                response.data[0].quantity
              );

              // inCart = response.data[0].quantity * response.data[0].number;
              inCart = response.data[0].number;
              // console.log("inCart", inCart);

              // setMessage(response.statusText);
              setMessage("");
              // setInCart(inCart);
            }
          } else {
            // setMessage(response.statusText);
            setMessage(response.statusText);
          }
          console.log("inCart ", inCart);
          setInCart(inCart);
          return inCart;
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...")
          // const errorElement = myRef.current;
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // console.log("error: ", error ? error : "no error");
          // console.log("_content: ", _content ? _content : "no _content");

          // if (errorElement) {
          //   errorElement.hidden = false;
          //   errorElement.innerText = "No items found. Error: " + _content;
          //   errorElement.style.display = "block";
          // }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(true);
          setMessage(_content);

          // Set it to an empty array, since the user exists,
          // but it has NO items
        }
        // ).catch((err) => (console.log(err)));
      )
      .catch((error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(error);
      });
    return inCart;
  }

  useEffect(() => {
    if (currentIngredient)
      getIngredientInShoppingList(currentIngredient.ingredient, inCart);
  }, [currentIngredient]);

  function IngredientInShoppingList({ inCart }) {
    // console.log("ingredient.id ", ingredient.id);
    return (
      <button className="badge" style={{ color: "black" }}>
        {inCart}
      </button>
    );
  }

  return (
    <div className="container">
      {/* {console.log("message ", message)} */}
      <Alert
        id="error"
        key="danger"
        variant="danger"
        style={{ display: "none" }}
      >
        {/* <p>{message}</p> */}{" "}
      </Alert>
      {isInError ? (
        <Alert
          ref={myRef}
          id="message"
          key="danger"
          variant="danger"
          // style={{ display: "none" }}
        >
          {/* <p>{message}</p> */}
          {"-------------->" + message}
        </Alert>
      ) : // null
      message !== "" ? (
        <Alert
          ref={myRef}
          id="message"
          key="info"
          variant="info"
          // style={{ display: "none" }}
        >
          {/* <p>{message}</p> */}
          {message}
        </Alert>
      ) : null}

      <KeyIcon style={{ position: "absolute", left: "0" }} />
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name"
              value={searchName}
              onChange={onChangeSearchName}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={findByName}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* <div className="list row"> */}
        <div className="col-md-6">
          <h4>Ingredients List</h4>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={removeAllIngredients}
          >
            Remove All
          </button>

          <button className="m-3 btn btn-sm btn-danger">
            <Link to={"/addingredient"} className="nav-link">
              Add
            </Link>
          </button>
          <CategorisedIngredients
            categoryGroup={categoryGroup}
            ingredients={ingredients}
            setActiveIngredient={setActiveIngredient}
            currentIndex={currentIndex}
          />
        </div>
        <div className="col-md-6">
          {currentIngredient ? (
            <div style={{ marginLeft: "10px" }}>
              <h4>Ingredient</h4>
              <div>
                <label>
                  <strong>Name:</strong>&nbsp;
                </label>
                {currentIngredient.ingredient.name}
              </div>
              <div>
                <label>
                  <strong>Description:</strong>&nbsp;
                </label>
                {isLimited
                  ? truncate(currentIngredient.ingredient.description, 200)
                  : currentIngredient.ingredient.description}
                {isLimited &&
                currentIngredient.ingredient.description.length > 200 ? (
                  <span
                    style={{ cursor: "pointer", color: "#0d6efd" }}
                    onClick={() => setIsLimited(false)}
                  >
                    &nbsp;more
                  </span>
                ) : null}
                {!isLimited &&
                currentIngredient.ingredient.description.length > 200 ? (
                  <span
                    style={{ cursor: "pointer", color: "#0d6efd" }}
                    onClick={() => setIsLimited(true)}
                  >
                    &nbsp;less
                  </span>
                ) : null}
              </div>
              <div>
                <label>
                  <strong>Category:</strong>&nbsp;
                </label>
                {currentIngredient.ingredient.category}
              </div>

              <div className="form-group">
                {"Pollucturum"}
                {" > "}
                {currentIngredient.ingredient.mainSection}
                {" > "}
                {currentIngredient.ingredient.section}
                {currentIngredient.ingredient.subsection ? " > " : null}
                {currentIngredient.ingredient.subsection
                  ? currentIngredient.ingredient.subsection
                  : null}
              </div>

              {currentIngredient.ingredient.externalLinks.length > 0 ? (
                <div>
                  <label>
                    <strong>External Links:</strong>&nbsp;
                  </label>
                  <ul>
                    {currentIngredient.ingredient.externalLinks.map(
                      (link, index) => {
                        return (
                          <li key={index}>
                            <a
                              href={
                                currentIngredient.ingredient.externalLinks[0]
                                  .link
                              }
                            >
                              {link.provider}
                            </a>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              ) : null}
              <div>
                <label>
                  <strong>Picture:</strong>&nbsp;
                </label>
                {currentIngredient.ingredient.media &&
                currentIngredient.ingredient.media.images &&
                currentIngredient.ingredient.media.images.head ? (
                  <div>
                    <>
                      {currentIngredient.ingredient.externalLinks.length > 0 ? (
                        <a
                          href={
                            currentIngredient.ingredient.externalLinks[0].link
                          }
                        >
                          <img
                            width={"25%"}
                            src={
                              imageServerURL +
                              currentIngredient.ingredient.media.images.head
                            }
                            onError={addDefaultSrc}
                            alt={`Image Head category ${currentIngredient.ingredient.name}`}
                          />
                        </a>
                      ) : (
                        <img
                          width={"25%"}
                          src={
                            imageServerURL +
                            currentIngredient.ingredient.media.images.head
                          }
                          onError={addDefaultSrc}
                          alt={`Image Head category ${currentIngredient.ingredient.name}`}
                        />
                      )}
                    </>
                    {currentIngredient.ingredient.externalLinks.length > 0 ? (
                      <a
                        href={
                          currentIngredient.ingredient.externalLinks[0].link
                        }
                        className="badge bg-warning"
                      >
                        Buy now!
                      </a>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <img
                      width={"25%"}
                      src={imageNotAvailable}
                      alt="Image Head ingredient"
                    />
                  </div>
                )}
              </div>
              <div>
                <label>
                  <strong>Price:</strong>&nbsp;
                </label>
                {currentIngredient.ingredient.price}
              </div>
              <div>
                <label>
                  <strong>Presentation:</strong>&nbsp;
                </label>
                {currentIngredient.ingredient.presentation}
              </div>
              <div>
                <label>
                  <strong>Units:</strong>&nbsp;
                </label>
                {currentIngredient.ingredient.qtyUnit}
              </div>
              <div>
                <label>
                  <strong>Quantity:</strong>&nbsp;
                </label>
                {currentIngredient.ingredient.quantity}
              </div>
              <div>
                <label>
                  <strong>Preferred provider:</strong>&nbsp;
                </label>
                {currentIngredient.ingredient.provider}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>&nbsp;
                </label>
                {currentIngredient.ingredient.published
                  ? "Published"
                  : "Pending"}
              </div>
              {/* {console.log("currentIngredient ", currentIngredient)} */}
              <button
                className="badge bg-primary mr-2"
                onClick={() => handleAddIngredientToShoppingList(false)}
              >
                Add to Shopping List
              </button>
              <IngredientInShoppingList inCart={inCart} />
              <Link
                to={"/ingredients/" + currentIngredient.id}
                className="badge bg-info"
              >
                Show
              </Link>
              {isModerator ? (
                <Link
                  to={"/ingredients/" + currentIngredient.id + "?e=true"}
                  className="badge bg-warning"
                >
                  Edit
                </Link>
              ) : null}
            </div>
          ) : (
            <div>
              <br />
              <p>Please click on a Ingredient...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default IngredientsList;
