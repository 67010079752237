import React, { useState, useEffect } from "react";

import EditItemRow from "./EditItemRow";
import ItemRow from "./ItemRow";

export default function GrowingExtendedListItem(props) {
  // const { collection, indx, myKey } = props;
  // console.log("props: ", props);

  const [editMode, setEditMode] = useState(props.editMode);
  const [item, setItem] = useState(props.value);

  // this is the pass-through from SearchBox upto whatever
  // Component uses GrowingExtendedListItem
  // for example GrowingList
  let clearChildState = null;

  function passClearState(childClearState) {
    // console.log("passClearState(childClearState)", childClearState);
    clearChildState = childClearState;
    props.passClearState(clearChildState);
  }

  // props.getState(item);

  function setTheEditMode(x) {
    props.setEditMode(x);
    setEditMode(x);
  }

  function onChange(theItem) {
    props.onChange(theItem);
    setItem(theItem);
  }

  function onSave(indx, item) {
    // console.log("GrowingExtendedListItem::onSave() indx, item", indx, item);
    setItem(item);
    props.onSave(indx, item);
  }

  let collection = props.collection;
  const indx = props.indx;

  useEffect(() => {
    collection = props.collection;
    // console.log("props: ", props);
    // console.log("collection: ", collection);
  }, [props.collection]);
  // console.log("props.value: ", props.value);

  // const item = props.value;
  // const collection = props.collection;
  // const indx = props.indx ;
  // console.log("props: ", props);
  // console.log("item: ", item);
  return (
    // <li style={{ padding: "4pt", border: "1pt red solid" }}>
    <div
      key={props.myKey}
      // style={{ listStyle: "none", float: "left" }}
      // style={{ listStyle: "none" }}
      className="FullWidthGrowingExtendedListItem"
    >
      {/* {props.stepLabel ? (
        <div style={{ padding: "0 5pt", float: "left", width: "20%" }}>
          {props.stepLabel} {props.indx + 1}:
        </div>
      ) : (
        ""
      )} */}
      {/* <div
        className="GrowingExtendedListItem"
        style={
          {
            // backgroundColor: "#fafafa",
            // backgroundColor: "#61dafa",
            // borderRadius: "5pt",
            // padding: "0 0 0 5pt",
            // minHeight: "30pt",
            // margin: "5pt",
            // border: "green 1pt solid",
          }
        }
      > */}
      {/* {console.log("isChecked", props.isChecked)} */}

      {editMode ? (
        <>
          {/* {console.log("options", props.options)} */}
          {/* {props.stepLabel ? (
              <div style={{ padding: "0 5pt", float: "left", width: "20%" }}>
                {props.stepLabel} {props.indx + 1}:
              </div>
            ) : (
              ""
            )} */}
          <EditItemRow
            getState={props.getState}
            passClearState={passClearState}
            collection={collection}
            indx={indx}
            value={item}
            editMode={editMode}
            setEditMode={setTheEditMode}
            onSave={onSave}
            onChange={onChange}
            onEdit={props.onEdit}
            // addStep={props.addStep}
            options={props.options}
          />
        </>
      ) : (
        <ItemRow
          hasChkBox={props.hasChkBox}
          onChkbChange={props.onChkbChange}
          isChecked={props.isChecked}
          editMode={editMode}
          setEditMode={setTheEditMode}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          indx={indx}
          value={item}
        />
      )}
      {/* </div> */}
    </div>
  );
}
