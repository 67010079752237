import axios from "axios";

// const server = require("./config/server.config");
import server  from "./server.config";

// console.log("server ", server);

const baseURL = server.host + ":" + server.port;

// console.log("baseURL ", baseURL);
// https://stackoverflow.com/questions/24543847/req-body-empty-on-posts
export default axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    // "Content-type": "application/json",
  },
});
