import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";

import GroupsIcon from "@mui/icons-material/Groups";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import StarRateIcon from "@mui/icons-material/StarRate";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
// import Alert from "react-bootstrap/Alert";

import RecipeDataService from "../../services/recipe.service";
// import ShoppingListService from "../../services/shoppingList.service";
import addIngredientsToShoppingList from "../ShoppingList/addIngredientToShoppingList";
import CookwareDataService from "../../services/cookware.service";
import InventoryDataService from "../../services/inventory.service";
import CategoryDataService from "../../services/category.service";

import chatService from "../../services/chat.service";

import DisplayMessage from "../MenuPlanning/DisplayMessage";
import GrowingList from "../List/GrowingList";
import SpinButton from "../ShoppingList/SpinButton";
import CardRecipeDetails from "./CardRecipeDetails";
import CookingInProgress from "./CookingInProgress";
import "./Recipes.css";
import BasicTimePicker from "./BasicTimePicker";
import server from "../../config/server.config";
import frontend from "../../config/frontend.config";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";
const imageServerURL = server.cdn;
// const imageServerURL = process.env.REACT_APP_IMAGE_SERVER_URL
//   ? process.env.REACT_APP_IMAGE_SERVER_URL
//   : "http://192.168.1.10:4000/";

function addDefaultSrc(e) {
  e.target.src = imageNotAvailable;
}

const ensembles = ["Cooking", "Tags", "CookWays", "Measure"];

// const frontend = "https://app.pollucturum.com/" // "http://192.168.1.20/";

/**
 * component definition
 */

function Recipe(props) {
  const { user: currentUser } = useSelector((state) => state.auth);
  const myRef = useRef(null);
  const [isNew, setIsNew] = useState(false);
  const [isModerator, setIsModerator] = useState(false);

  // const display = "display";
  // const {display} = props;
  let { id } = useParams();
  let navigate = useNavigate();
  const initialRecipe = {
    name: "",
    description: "",
    presentation: "",
    options: "",
    suggestions: "",
    instructions: [],
    tags: [],
    comments: [],
    ingredients: [],
    published: false,
    serves: 4,
    servings: 4,
    cost: 1,
    difficulty: 1,
    time: "1:00h",
  };

  const initialRecipeState = {
    id: null,
    recipe: initialRecipe,
  };

  const [cookWaysInput, setCookWaysInput] = useState(false);
  const [cookWaysIndex, setCookWaysIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [currentRecipe, setCurrentRecipe] = useState(initialRecipeState);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [ingredients, setIngredients] = useState([]);
  const [cookware, setCookware] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [categoryGroups, setCategoryGroups] = useState({});
  const [options, setOptions] = useState([
    //     [{ label: "", value: "", action: "" }],
    [],
    [],
    [],
    [],
  ]);
  const [cookingInProgress, setCookingInProgress] = useState(false);
  const [serves, setServes] = useState(4);

  const [rating, setRating] = useState(0);
  const [userRating, setUserRating] = useState(0);
  const [indexOfComment, setIndexOfComment] = useState(null);
  const [comment, setComment] = useState("");
  const [isFavoriteRecipe, setIsFavoriteRecipe] = useState(true);
  //   () => {
  //   const recipeIsFavorite = isRecipeAFavoriteRecipe();
  //   console.log("recipeIsFavorite ", recipeIsFavorite);
  //   return recipeIsFavorite;
  // }
  // );

  useEffect(() => {
    const retrieveCategoryGroup = (ensemble, indx) => {
      CategoryDataService.getAll({ name: "", ensemble: ensemble })
        .then((response) => {
          // var categoryGroup;
          // categoryGroup[indx]=response.data.categoryGroup;
          // console.log("categoryGroup[indx] ",indx, categoryGroup[indx]);
          // console.log(
          //   "response.data.categoryGroup ",
          //   response.data.categoryGroup
          // );
          // console.log("ensemble ", ensemble);
          // console.log("categoryGroup ", categoryGroup);
          const categoryGroup = response.data.categoryGroup;
          setCategoryGroups((prevCategoryGroups) => {
            const newCategoryGroups = {
              ...prevCategoryGroups,
              [ensembles[indx]]: categoryGroup,
            };
            // console.log("newCategoryGroups ", indx, newCategoryGroups);

            // move this into the getRecipe function since there currentRecipe is available
            // and it decouples setOptions from categoryGroups

            setOptions((prevOptions) => {
              const newOptions = [...prevOptions];
              // console.log("currentRecipe ", currentRecipe);
              newOptions[indx] = categoryGroup
                ? categoryGroup.categories.map((category, indx) => {
                    // console.log(
                    //   currentRecipe.recipe.cookWays.includes(category.name)
                    // );
                    return {
                      // label: currentRecipe.recipe.cookWays.includes(category.name)
                      //   ? category.name
                      //   : null,
                      label: category.name,
                      value: category.name,
                      action: "",
                    };
                  })
                : [{ label: "pcs", value: "pcs" }];
              // console.log("newOptions ", indx, newOptions);
              return newOptions;
            });

            return newCategoryGroups;
          });

          // also possible to write it as array construct
          //
          // setCategoryGroups((prevCategoryGroups) => {
          //   const a = [...prevCategoryGroups];
          //   a[indx] = response.data.categoryGroup;
          //   return a;
          // });

          // console.log(response);
          // return response.data.categoryGroup;
        })
        .catch((e) => {
          console.log("retrieveCategoryGroup: ", e);
        });
    };
    ensembles.map((ensemble, indx) => retrieveCategoryGroup(ensemble, indx));
    // const categoryGroups = ensembles.map((ensemble) =>
    //   retrieveCategoryGroup(ensemble)
    // );
    // console.log("categoryGroups ", categoryGroups);
    InventoryDataService.get(currentUser.id)
      .then((response) => {
        // console.log("response.data.items", response.data.items);
        response.data.items.map((obj, indx) => {
          inventory[indx] = obj;
        });
        setInventory((preValue) => {
          return response.data.items;
        });
        // return response.data.ingredient;
      })
      .catch((e) => {
        console.log(e);
        setMessage(e.message);
      });
  }, []);

  const getAvailability = (indx, weNeed, serves, servings) => {
    // console.log("getAvailability indx weNeed", indx, weNeed);
    const id = weNeed.ingredient.id;
    // const id = ingredient[indx].id;
    // console.log("getAvailability id inventory", id, inventory);

    for (let i = 0; i < inventory.length; i++) {
      const weHave = inventory[i];
      // console.log("getAvailability indx weHave", i, weHave);
      // console.log("getAvailability indx weHave", i, weHave);
      // console.log("getAvailability id weHave.id", id, weHave.id);
      if (id === weHave.id) {
        // console.log("match id id ", id, weHave.id);
        // console.log(
        //   "getAvailability i name quantity qtyUnit",
        //   i,
        //   weHave.name,
        //   weNeed.ingredient.name,
        //   weHave.quantity,
        //   weNeed.ingredient.quantity,
        //   weHave.qtyUnit,
        //   weNeed.ingredient.qtyUnit
        // );
        // console.log(
        //   "getAvailability qtyUnit ",
        //   weHave.qtyUnit === weNeed.ingredient.qtyUnit
        // );
        // console.log(
        //   "getAvailability quantity ",
        //   weHave.quantity,
        //   weNeed.ingredient.quantity,
        //   weHave.quantity >= weNeed.ingredient.quantity
        // );
        if (weHave.qtyUnit === weNeed.ingredient.qtyUnit) {
          if (
            weHave.quantity >=
            (weNeed.ingredient.quantity * serves) / servings
          ) {
            return true;
          }
        } else {
          // if not used same measures convert them
          let weHaveQuantity = weHave.quantity;
          let weNeedQuantity = weNeed.ingredient.quantity;
          let weHaveQtyUnit = weHave.qtyUnit;
          let weNeedQtyUnit = weNeed.ingredient.qtyUnit;

          if (weHaveQtyUnit === "uds") {
            weHaveQtyUnit = "l";
            weHaveQuantity = 1 * weHaveQuantity;
          }
          if (weHaveQtyUnit === "l") {
            weHaveQtyUnit = "ml";
            weHaveQuantity = 1000 * weHaveQuantity;
          }
          // console.log(
          //   "QtyUnit ",
          //   weHaveQtyUnit,
          //   weNeedQtyUnit,
          //   "Quantity ",
          //   weHaveQuantity,
          //   weNeedQuantity
          // );
          if (weHaveQuantity >= weNeedQuantity) {
            return true;
          }
        }
        return false;
      }
    }
  };

  useEffect(() => {
    const getCookware = (indx, value) => {
      // console.log("getCookware value", value);
      const id = value.cookware.id;
      // const id = cookware[indx].id;
      CookwareDataService.get(id)
        .then((response) => {
          // console.log("getCookware response.data", response.data);
          setCookware((preValue) => {
            const theCookware = {
              ...value.cookware,
              externalLinks: response.data.cookware.externalLinks,
            };
            preValue[indx] = theCookware;
            // console.log(
            //   "getCookware response.data",
            //   response.data.cookware.externalLinks
            // );
            // this is NOT yet available, since we are updating it here right now
            // console.log(
            //   "getCookware cookware[indx]",
            //   cookware[indx].externalLinks
            // );
            return [...preValue];
          });
          // return response.data.cookware;
        })
        .catch((e) => {
          console.log(e);
          setMessage(e.message);
        });
    };

    const getRecipe = (id) => {
      setLoading(true);
      // console.log("loading ", loading);
      // console.log("id ", id);
      // id = null;
      if (id !== "+") {
        RecipeDataService.get(id)
          .then((response) => {
            const currentRecipe = response.data;
            setCurrentRecipe(() => {
              // console.log("getRecipe ", response.statusText);
              // console.log("getRecipe ", response.data);
              // console.log("getRecipe ", response.data);
              // console.log("getRecipe ", currentRecipe);
              // console.log("getRecipe ingredients", response.data.recipe.ingredients);

              // this lifts the content of ingredients and cookware
              // one level up
              currentRecipe.recipe.ingredients.map((obj, indx) => {
                const availability = getAvailability(
                  indx,
                  obj,
                  serves,
                  currentRecipe.recipe.servings
                );
                // console.log("getRecipe availability ", availability);
                obj.ingredient.availability = availability;
                // obj.ingredient.id = obj.id;
                ingredients[indx] = obj.ingredient;
                // console.log(
                //   "getRecipe obj.ingredient.id , obj.id ",
                //   obj.ingredient.id,
                //   obj.ingredient.name,
                //   obj.id
                // );
              });
              // get the information before from the recipe itself,
              // later, in due course, the API will fill in the missing bits
              currentRecipe.recipe.cookware.map((obj, indx) => {
                cookware[indx] = obj.cookware;
                getCookware(indx, obj);
              });

              // currentRecipe.recipe.cookware.map((value, indx) => {
              //   getCookware(indx, value);
              //   return value.cookware;
              // });
              // setCookware((c) => {
              //   const theCookWare = currentRecipe.recipe.cookware.map(
              //     (value, indx) => {
              //       // cookware[indx] = value.cookware;
              //       // console.log("cookware[indx].id ", cookware[indx].id);
              //       // console.log("value.cookware ", value.cookware);
              //       // console.log("cookware[indx].id ", cookware[indx].id);
              //       const theCookware = getCookware(indx, value);
              //       return theCookware;
              //     }
              //   );
              //   console.log("theCookWare ", theCookWare);
              //   return theCookWare;
              // });
              // options are NOT set yet
              // intervene where they WILL be set
              // console.log("options ", options);
              // currentRecipe.recipe.cookWays.map((cookWay, indx) => {
              //   if (cookWay.label === options[2][indx].label)
              //     console.log("already in list");
              //   console.log("cookWay.label ", cookWay.label);
              // });
              // setOptions((prevOptions) => {
              //   const newOptions = [...prevOptions];
              //   console.log("currentRecipe ", currentRecipe);
              //   newOptions[2] = newOptions[2].map((category, indx) => {
              //     console.log(
              //       currentRecipe.recipe.cookWays.includes(category.name)
              //     );
              //     return {
              //       label: currentRecipe.recipe.cookWays.includes(category.name)
              //         ? category.name
              //         : null,
              //       value: category.name,
              //     };
              //   });
              //   console.log("newOptions ", newOptions);
              //   return newOptions;
              // });
              // console.log("getRecipe(): ingredients ", ingredients);
              // console.log("getRecipe(): response.statusText ", response.statusText);
              setMessage(response.statusText);
              setLoading(false);
              // const userRating = getRatingFromUser(currentRecipe);
              // console.log("getRecipe(): userRating ", userRating);
              // setUserRating(userRating);
              setUserRating(getRatingFromUser(currentRecipe));
              setIsFavoriteRecipe(isRecipeAFavoriteRecipe(currentRecipe));
              setIndexOfComment(getIndexOfUserRating(currentRecipe));
              // const userRating = getRatingFromUser(currentRecipe);
              // setUserRating(userRating ? userRating : 0);
              // console.log(
              //   "getRecipe(): currentRecipe.recipe.comments.rating ",
              //   currentRecipe.recipe.comments.rating
              // );
              setRating(currentRecipe.recipe.comments.rating);
              return currentRecipe;
            });
          })
          .catch((e) => {
            console.log(e);
            setMessage(e.message);
          });
        setIsNew(false);
      } else {
        console.log("no id given");
        setEditMode(true);
        setLoading(false);
        setIsNew(true);
      }
    };

    if (id) getRecipe(id);
  }, [id, isDisabled]);

  function getIndexOfItemInArray(array, value) {
    for (var i = 0, l = array?.length; i < l; i++) {
      // console.log(array[i], value);
      if (array[i].label === value) return i;
    }
    return null;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // const backend = "http://192.168.1.20:4000/";
    // const imageServerURL = process.env.REACT_APP_IMAGE_SERVER_URL
    //   ? process.env.REACT_APP_IMAGE_SERVER_URL
    //   : "http://192.168.1.10:4000/";
    // const prefix = "http://localhost:4000/assets/images/";
    const prefix = `${imageServerURL}assets/images/`;
    // console.log("currentRecipe.recipe.media ", currentRecipe.recipe.media);
    // console.log("recipe ", recipe);
    // console.log("event.target  ", event.target);
    // console.log("name, value  ", name, value);

    let recipe = {};

    if (name === "media-images-head") {
      const filename = event.target.files[0].name;
      // console.log("files  ", filename);
      const media = currentRecipe.recipe.media
        ? currentRecipe.recipe.media
        : {};
      media.images = currentRecipe.recipe.media.images
        ? currentRecipe.recipe.media.images
        : {};
      // media.images.head = value;
      media.images.head = prefix + filename;
      recipe = { ...currentRecipe.recipe, media: media };
    } else if (name === "media-images-presentation") {
      const filename = event.target.files[0].name;
      // console.log("files  ", filename);
      const media = currentRecipe.recipe.media
        ? currentRecipe.recipe.media
        : {};
      media.images = currentRecipe.recipe.media.images
        ? currentRecipe.recipe.media.images
        : {};
      // media.images.presentation = value;
      media.images.presentation = prefix + filename;
      recipe = { ...currentRecipe.recipe, media: media };
    } else if (!name) {
      // console.log("event.target  ", event.target);
      const { id, name } = event.target;
      // console.log("name, id  ", name, id);
      // if (id === "cookWayActionChecked") console.log("Checked", cookWaysIndex);
      setCookWaysInput(false);
      recipe = { ...currentRecipe.recipe };
    } else if (name === "cookWayAction") {
      const cookWays = [...currentRecipe.recipe.cookWays];
      // cookWays[cookWaysIndex].label=label;
      cookWays[cookWaysIndex].action = value;
      recipe = { ...currentRecipe.recipe, cookWays: cookWays };
    } else if (name === "cookWays") {
      // the dropdown provides each time only ONE selected item
      // it is not an input box with a comma separated list of items
      // const cookWays = [...currentRecipe.recipe.cookWays, value.split(",")];
      const indx = getIndexOfItemInArray(options[2], value);
      // console.log("indx ", indx);
      if (indx) {
        // if it has been added it is the last
        // setCookWaysIndex((prevValue) => prevValue + 1);
        setCookWaysIndex((prevValue) =>
          currentRecipe.recipe.cookWays.length
            ? // ATTENTION: the new element has NOT been added yet, so the " - 1 " is NOT applicable
              currentRecipe.recipe.cookWays.length
            : 0
        );
        setCookWaysInput(true);
        // setOptions((prevOptions) => {
        //   const newOptions = [...prevOptions];
        //   newOptions[2] = prevOptions[2].map((category, i) => {
        //     // console.log("newOptions ", i, category);
        //     return {
        //       label: indx === i ? null : category.label,
        //       value: category.value,
        //     };
        //   });
        //   // console.log("newOptions ", indx, newOptions);
        //   return newOptions;
        // });
      }
      const cookWays = [
        ...currentRecipe.recipe?.cookWays,
        { label: value, action: "" },
      ];
      recipe = { ...currentRecipe.recipe, cookWays: cookWays };
    } else if (name === "tags" || name === "category") {
      const items = value.split(",");
      recipe = { ...currentRecipe.recipe, [name]: items };
      // } else if (name === "tags") {
      //   const tags = value.split(",");
      //   recipe = { ...currentRecipe.recipe, tags: tags };
      // } else if (name === "cookWays") {
      //   const cookWays = value.split(",");
      //   recipe = { ...currentRecipe.recipe, cookWays: cookWays };
      // } else if (name === "category") {
      //   const category = value.split(",");
      //   recipe = { ...currentRecipe.recipe, category: category };
    } else if (name === "ingredients") {
      const ingredients = [];
      console.log("Emergency: setting ingredients to empty arry");
      alert("Emergency: setting ingredients to empty arry");
      recipe = { ...currentRecipe.recipe, ingredients: ingredients };
    } else if (name === "instructions") {
      const instructions = currentRecipe.recipe.instructions
        ? currentRecipe.recipe.instructions
        : [];
      recipe = { ...currentRecipe.recipe, instructions: instructions };
    } else {
      recipe = { ...currentRecipe.recipe, [name]: value };
    }
    // console.log("recipe ", recipe);
    // console.log("name, value  ", name, value);
    // console.log("event.target  ", event.target);

    setCurrentRecipe({ ...currentRecipe, recipe: recipe });
  };

  const updatePublished = (status) => {
    var recipe = {
      id: currentRecipe.id,
      name: currentRecipe.recipe.name,
      description: currentRecipe.description,
      published: status,
    };
    var data = {
      recipe: recipe,
    };
    RecipeDataService.update(currentRecipe.id, data)
      .then((response) => {
        setCurrentRecipe({ ...currentRecipe, recipe: recipe });
        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addRecipe = () => {
    const media = {};
    media.images = {};
    media.images.head = "";

    var data = {
      name: currentRecipe.recipe.name,
      description: currentRecipe.recipe.description,
      ingredients: currentRecipe.recipe.ingredients,
      instructions: currentRecipe.recipe.instructions,
      servings: currentRecipe.recipe.servings,
      published: false,
      media:
        currentRecipe.recipe.media &&
        currentRecipe.recipe.media.images &&
        currentRecipe.recipe.media.images.head
          ? {
              images: {
                head: currentRecipe.recipe.media.images.head,
              },
            }
          : media,
    };
    // console.log("addRecipe() data ", data);

    RecipeDataService.create(data)
      .then((response) => {
        // console.log("saveRecipe() response data name", response.data.name);
        // console.log(
        //   "saveRecipe() response data description",
        //   response.data.description
        // );
        // console.log("saveRecipe() response data id", response.data.id);
        // console.log("saveRecipe() response data", response.data);
        setMessage(response.statusText);
        setIsNew(false);
        id = response.data.id;
        setCurrentRecipe({
          id: response.data.id,
          recipe: response.data.recipe,
          // name: response.data.recipe.name,
          // description: response.data.recipe.description,
          // published: response.data.recipe.published,
          // media: {
          //   images: {
          //     head: response.data.recipe.media.images.head,
          //   },
          // },
        });
        // setSubmitted(true);
        // console.log("saveRecipe() ", response);
        navigate("/recipes/" + id);
      })
      .catch((e) => {
        console.log("saveRecipe() ", e);
        setMessage("saveRecipe() ", e);
      });
  };

  const updateRecipe = (theRecipe = currentRecipe) => {
    // setMessage("DISABLED - The recipe was updated successfully!");
    // setMessage("The recipe was updated successfully!");
    // console.log(
    //   "updateRecipe() currentRecipe.recipe.comments ",
    //   currentRecipe.recipe.comments
    // );
    // console.log(
    //   "updateRecipe() currentRecipe theRecipe ",
    //   currentRecipe,
    //   theRecipe
    // );
    RecipeDataService.update(currentRecipe.id, currentRecipe)
      .then((response) => {
        // console.log("response.data.message ", response.data.message);
        // setMessage("The recipe was updated successfully!");
        // setMessage(null);
        setMessage((preValue) => response.data.message);
      })
      .catch((e) => {
        console.log(e);
        setMessage(e.toString());
      });
  };

  function isRecipeAFavoriteRecipe(currentRecipe) {
    // console.log(
    //   // "currentUser"
    //   "currentUser.favoriteRecipes currentRecipe.id currentRecipe ",
    //   // currentUser,
    //   currentUser.favoriteRecipes,
    //   currentRecipe.id,
    //   currentRecipe
    // );
    const id = currentRecipe.id;
    let recipeIsFavorite = false;

    if (currentUser.favoriteRecipes) {
      recipeIsFavorite = currentUser?.favoriteRecipes?.reduce(
        (acc, currentValue) => {
          return acc || currentValue.id === id;
        },
        false
      );
    } else {
      // setCurrentUser((prevValue) => {
      //   const newUser = prevValue;
      //   newUser.favoriteRecipes = [];
      //    return newUser;});
    }

    // currentUser.favoriteRecipes, currentRecipe;
    // console.log(
    //   "isRecipeAFavoriteRecipe() recipeIsFavorite ",
    //   recipeIsFavorite
    // );
    return recipeIsFavorite;
  }

  async function handleSelectRecipeAsFavorite(id) {
    console.log("currentUser.favoriteRecipes ", currentUser.favoriteRecipes);
    const recipeIsFavorite = isRecipeAFavoriteRecipe(currentRecipe);
    if (recipeIsFavorite) {
      // has been favorite, so delete it from favoriteRecipes array
      const favoriteRecipes = currentUser.favoriteRecipes.filter(
        (recipe, indx) => {
          return recipe.id !== id;
        }
      );
      currentUser.favoriteRecipes = favoriteRecipes;
    } else {
      // selected as favorite, so add it to favoriteRecipes array
      if (!currentUser.favoriteRecipes) {
        currentUser.favoriteRecipes = [];
      }
      currentUser.favoriteRecipes.push({ id: id });
    }

    // now save the new favoriteRecipes array
    const userData = {
      username: currentUser.username,
      favoriteRecipes: currentUser.favoriteRecipes,
    };
    const { data } = await chatService.savePreferences(
      currentUser.id,
      userData
    );
    // console.log(" theme.name ", theme.name);
    // console.log("data ", data);
    // console.log("data.user ", { ...data.user, avatarImage: "" });
    // console.log("data.status ", data.status);
    // console.log("data.msg ", data.msg);
    // console.log("currentUser.accessToken ", currentUser.accessToken);

    if (data.status) {
      // const user = { ...data.user, accessToken: currentUser.accessToken };
      // const user = { accessToken: currentUser.accessToken, ...data.user };
      const user = { ...currentUser, ...data.user };
      // the existing token is overwriten in the local storage
      // console.log("currentUser ", currentUser.accessToken);
      // console.log("user ", user);

      localStorage.setItem(
        process.env.REACT_APP_LOCALHOST_KEY,
        JSON.stringify(user)
      );
      setIsFavoriteRecipe((prevValue) => {
        // return !isFavoriteRecipe;
        return !prevValue;
      });
    } else {
      const errorMessage = data.msg
        ? data.msg
        : "Some error occurred. Try again.";
      console.error(errorMessage);
    }
  }

  const handleBackClick = () => {
    navigate("/recipeslist");
  };

  const onSaveInstructions = (instructions) => {
    let recipe = {};
    recipe = { ...currentRecipe.recipe, instructions: instructions };
    // console.log(
    //   "GrowingList->onSave(): instructions recipe",
    //   instructions,
    //   recipe
    // );
    setCurrentRecipe({ ...currentRecipe, recipe: recipe });
  };

  const onSaveIngredients = (ingredients) => {
    // let objIngredients = currentRecipe.recipe
    //   ? [...currentRecipe.recipe.ingredients]
    //   : [];
    // let objIngredients = [];
    // console.log("recipe->GrowingList->onSave(): ingredients", ingredients);
    const objIngredients = ingredients.map((ingredient, indx) => {
      let objIngredient = {
        // _id: {
        //   $oid: "633822b2b307715d7863e068",
        // },
        // id: "633822b2b307715d7863e068",
        ingredient: ingredient,
        // __v: 0,
      };
      // console.log(
      //   "recipe->GrowingList->onSave(): indx, ingredient, objIngredient",
      //   indx,
      //   ingredient,
      //   objIngredient
      // );
      // console.log("GrowingList->onSave(): indx, objIngredient", indx, objIngredient);
      // objIngredients[indx] = objIngredient;
      return objIngredient;
    });

    let recipe = {};
    recipe = { ...currentRecipe.recipe, ingredients: objIngredients };
    // console.log(
    //   "recipe->GrowingList->onSave(): ingredients recipe",
    //   ingredients,
    //   recipe
    // );
    setCurrentRecipe({ ...currentRecipe, recipe: recipe });
  };

  const onSaveCookware = (cookware) => {
    // let objCookware = currentRecipe.recipe
    //   ? [...currentRecipe.recipe.Cookware]
    //   : [];
    let objCookware = [];

    cookware.map((cookware, indx) => {
      let objCookwareItem = {
        // _id: {
        //   $oid: "633822b2b307715d7863e068",
        // },
        // id: "633822b2b307715d7863e068",
        cookware: cookware,
        // __v: 0,
      };
      // console.log("GrowingList->onSave(): indx, cookware", indx, cookware);
      objCookware[indx] = objCookwareItem;
      return objCookwareItem;
    });

    let recipe = {};
    recipe = { ...currentRecipe.recipe, cookware: objCookware };
    // console.log("GrowingList->onSave(): cookware recipe", cookware, recipe);
    setCurrentRecipe({ ...currentRecipe, recipe: recipe });
  };

  const deleteRecipe = () => {
    RecipeDataService.delete(currentRecipe.id)
      .then((response) => {
        // console.log(response.data);
        navigate("/recipeslist");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleGoToRecipes = () => {
    navigate("/recipes");
  };

  const handleGoToRecipesList = () => {
    navigate("/recipeslist");
  };

  const handleChangeEditMode = () => {
    setEditMode(!editMode);
  };

  const handleAddIngredientsToShoppingList = () => {
    setIsDisabled(true);
    setLoading(true);
    // console.log(currentRecipe.recipe.servings);
    const servings = currentRecipe.recipe.servings
      ? currentRecipe.recipe.servings
      : 4;
    addIngredientsToShoppingList(
      currentUser,
      currentRecipe.recipe.ingredients,
      servings,
      serves
    );
    setLoading(false);
  };

  function deleteItem(indx) {
    // put it back into the dropdown list
    // this is not necessary any more, we leave it in, but disable it
    options[2][indx].label = options[2][indx].value;
    // eliminate it from cookWays
    setCurrentRecipe((prevRecipe) => {
      const recipe = prevRecipe.recipe;
      const cookWays = recipe.cookWays;
      cookWays.splice(indx, 1);
      const newRecipe = { ...recipe, cookWays: cookWays };
      const newObj = { ...prevRecipe, recipe: newRecipe };
      console.log("deleteMenuItem ", newObj);
      return newObj;
    });
    // else menu.dinner[indx] = {};
    console.log("deleteMenuItem ", indx);
    console.log(
      "deleteMenuItem ",
      cookWaysIndex > 0 ? cookWaysIndex - 1 : cookWaysIndex
    );
    setCookWaysIndex((prevValue) =>
      prevValue > 0 ? prevValue - 1 : prevValue
    );
  }

  function handleCookWaysSelection(indx) {
    if (indx === 0) {
      console.log("indx", indx);
      setCookingInProgress(!cookingInProgress);
    }
    const value = getIndexOfItemInArray(
      options[2],
      currentRecipe.recipe.cookWays[indx].label
    );
    // console.log(
    //   value,
    //   currentRecipe.recipe.cookWays[indx].label,
    //   currentRecipe.recipe.cookWays[indx].action
    // );
    // alert(value);
    // alert(currentRecipe.recipe.cookWays[indx].label);
  }

  // function setRecipe(recipe) {
  //   setServes(recipe.servings);
  //   console.log("recipe ", recipe);
  //   console.log("currentRecipe.recipe ", currentRecipe.recipe);
  //   // currentRecipe.recipe = recipe;
  //   // console.log("currentRecipe.recipe ", currentRecipe.recipe);
  //   // setCurrentRecipe(currentRecipe);
  // }

  useEffect(() => {
    setIsModerator(currentUser?.roles.includes("ROLE_MODERATOR"));
    // console.log(currentUser);
  }, []);

  const CookWaysDropDown = ({
    value,
    handleInputChange,
    cookWaysIndex,
    options,
  }) => {
    return (
      <select
        id="cookWays"
        name="cookWays"
        className="form-select"
        aria-label="Choose the way to prepare this recipe"
        // defaultValue={
        //   currentRecipe.recipe.cookWays?.length && cookWaysIndex
        //     ? currentRecipe.recipe.cookWays[cookWaysIndex].label
        //     : "Choose the way to prepare this recipe"
        // }
        value={value}
        onChange={handleInputChange}
        // style={{ width: "30%" }}
      >
        {[
          "Choose the way to prepare this recipe",
          ...options[2].map((v, i) => {
            return v.label;
          }),
          // "CookIt",
          // "DeliverIt",
          // "Buy Prepared Dish",
          // "Go to Restaurant",
        ].map((cookWay, indx) => {
          // const optionsObj =
          //   indx === cookWaysIndex + 1 ? (
          //     indx === 0 || !options[2][indx - 1].label ? (
          //       <option
          //         key={indx}
          //         value={cookWay}
          //         disabled
          //         selected
          //       >
          //         {cookWay}
          //       </option>
          //     ) : (
          //       <option key={indx} value={cookWay} selected>
          //         {cookWay}
          //       </option>
          //     )
          //   ) : indx === 0 || !options[2][indx - 1].label ? (
          //     <option
          //       key={indx}
          //       value={cookWay}
          //       disabled
          //       selected
          //     >
          //       {cookWay}
          //     </option>
          //   ) : (
          //     <option key={indx} value={cookWay} selected>
          //       {cookWay}
          //     </option>
          //   );
          // return optionsObj;

          const disableIt =
            // omit the first line: CTA
            indx === 0 ||
            // the entry is "deleted" if once choosen
            // !options[2][indx - 1].label ||
            // the recipe has "already" this cookWay
            getIndexOfItemInArray(currentRecipe.recipe.cookWays, cookWay) !=
              null;
          // ||
          // ?????
          // indx === cookWaysIndex + 1;
          // console.log("value ", value);
          // console.log("indx, cookWaysIndex ", indx, cookWaysIndex);
          // console.log(
          //   "disableIt, cookWay, getIndexOfItemInArray(currentRecipe.recipe.cookWays, cookWay) ",
          //   disableIt,
          //   cookWay,
          //   currentRecipe.recipe.cookWays,
          //   getIndexOfItemInArray(currentRecipe.recipe.cookWays, cookWay)
          // );
          // console.log(
          //   "options[2][indx - 1].label, options[2][indx - 1].value ",
          //   options[2][indx - 1]?.label,
          //   // ? "undefined" : "defined",
          //   options[2][indx - 1]?.value
          //   // ? "undefined" : "defined"
          // );
          return disableIt ? (
            <option key={indx} value={cookWay} disabled>
              {cookWay}
            </option>
          ) : (
            <option key={indx} value={cookWay}>
              {cookWay}
            </option>
          );
          // }
        })}
      </select>
    );
  };

  const goldStyle = { color: "gold" };

  function handleRate(userRating) {
    setUserRating(userRating);
    // console.log("handleRate() userRating", userRating);
    // for (let i = 0; i <= userRating; i++) {
    //   let element = document.getElementById("starRating" + i);
    //   element.classList.add("gold");
    // }
    // for (let i = userRating + 1; i <= 5; i++) {
    //   let element = document.getElementById("starRating" + i);
    //   element.classList.remove("gold");
    // }
  }

  /**
   * userRatedRecipe()
   * if currentUser rated this recipe
   * @returns true or false
   */
  function userRatedRecipe() {
    // console.log(
    //   // "currentRecipe"
    //   // "currentRecipe.recipe.comments currentUser.id currentUser ",
    //   "userRatedRecipe() currentRecipe.recipe.comments currentUser.id  ",
    //   // currentRecipe,
    //   currentRecipe.recipe.comments,
    //   currentUser.id
    //   // currentUser
    // );
    const id = currentUser.id;
    let recipeIsRated = false;

    // console.log(
    //   "userRatedRecipe() currentRecipe.recipe.comments.theComments.length ",
    //   currentRecipe.recipe.comments.theComments.length
    // );
    if (currentRecipe.recipe.comments.theComments.length > 0) {
      // console.log("now reducing ", id);
      recipeIsRated = currentRecipe.recipe.comments.theComments.reduce(
        (acc, currentValue) => {
          // console.log("userRatedRecipe() -----> currentValue", currentValue);
          // console.log(
          //   "userRatedRecipe() -----> currentValue.userid id (currentValue.userid === id)",
          //   currentValue.userid,
          //   id,
          //   currentValue.userid === id
          // );
          return acc || currentValue.userid === id;
        },
        false
      );
    } else {
      // setCurrentUser((prevValue) => {
      //   const newUser = prevValue;
      //   newUser.favoriteRecipes = [];
      //    return newUser;});
    }

    // currentUser.favoriteRecipes, currentRecipe;
    // console.log("userRatedRecipe() recipeIsRated ", recipeIsRated);
    return recipeIsRated;
  }

  /**
   * getIndexOfUserRating()
   * gets the index of the userRating by the currentUser if any
   * @returns index or null
   */
  function getIndexOfUserRating(theRecipe = currentRecipe) {
    for (var i = 0; i < theRecipe?.recipe.comments?.theComments.length; i++) {
      // console.log(
      //   "getIndexOfUserRating() ",
      //   theRecipe?.recipe.comments.theComments[i].userid,
      //   currentUser.id
      // );
      if (
        theRecipe?.recipe.comments?.theComments[i].userid === currentUser.id
      ) {
        // console.log("getIndexOfUserRating() i", i);
        return i;
      }
    }
    return null;
  }

  /**
   * getRatingFromUser()
   * gets the userRating given by the currentUser if any
   * @returns userRating of the currentUser
   */
  function getRatingFromUser(theRecipe = currentRecipe) {
    // console.log("getRatingFromUser() ------> theRecipe ", theRecipe);
    // console.log("getRatingFromUser() ------> currentUser ", currentUser);
    const indx = getIndexOfUserRating(theRecipe);
    // console.log("getRatingFromUser() ------> indx ", indx);
    if (indx === null) {
      // console.log("getRatingFromUser() ------> indx not found", indx);
      return null;
    } else {
      // console.log(
      //   "getRatingFromUser() ------> userRating",
      //   theRecipe.recipe.comments.theComments,
      //   theRecipe.recipe.comments.theComments[indx].userRating
      // );
      return theRecipe.recipe.comments?.theComments[indx].userRating;
    }
  }

  function handleRateIt() {
    setCurrentRecipe((prevValue) => {
      const currentRecipe = prevValue;
      console.log(
        "handleRateIt() ---------> currentRecipe.recipe.comments",
        currentRecipe.recipe.comments
      );
      if (!currentRecipe.recipe.comments) {
        const comments = {
          theComments: [],
          rating: 0,
          ratings: 0,
        };
        currentRecipe.recipe.comments = comments;
        console.log(
          "handleRateIt() ---------> comments !currentRecipe.recipe.comments.rating",
          comments,
          !currentRecipe.recipe.comments.rating
        );
      }
      // console.log(
      //   "handleRateIt() ---------> !currentRecipe.recipe.comments.rating",
      //   !currentRecipe.recipe.comments.rating
      // );
      // console.log(
      //   "handleRateIt() ---------> !currentRecipe.recipe.comments.ratings",
      //   !currentRecipe.recipe.comments.ratings
      // );
      if (!currentRecipe.recipe.comments.rating) {
        currentRecipe.recipe.comments.rating = 0;
        console.log(
          "handleRateIt() ---------> currentRecipe.recipe.comments.rating",
          currentRecipe.recipe.comments.rating
        );
      }
      if (!currentRecipe.recipe.comments.ratings) {
        currentRecipe.recipe.comments.ratings = 0;
        console.log(
          "handleRateIt() ---------> currentRecipe.recipe.comments.ratings",
          currentRecipe.recipe.comments.ratings
        );
      }
      // console.log(
      //   "handleRateIt() ---------> currentRecipe.recipe.comments.rating",
      //   currentRecipe.recipe.comments.rating
      // );
      // console.log(
      //   "handleRateIt() ---------> currentRecipe.recipe.comments.ratings",
      //   currentRecipe.recipe.comments.ratings
      // );

      if (userRatedRecipe()) {
        console.log("handleRateIt() userRatedRecipe()", true);
        const k = getIndexOfUserRating(currentRecipe);
        const n = currentRecipe.recipe.comments.ratings;
        console.log("handleRateIt() k n", k, n);
        // console.log("handleRateIt() userRating ", userRating);
        // console.log(
        //   "handleRateIt()  currentRecipe.recipe.comments.theComments[k].userRating",
        //   currentRecipe.recipe.comments.theComments[k].userRating
        // );
        // update the average value, i.e. the resulting userRating
        if (n === 0) {
          // this is the case, when the user left a comment
          // but did not rate the recipe nor did anyone else
          currentRecipe.recipe.comments.rating = userRating;
          currentRecipe.recipe.comments.ratings = 1;
        } else {
          currentRecipe.recipe.comments.rating =
            currentRecipe.recipe.comments.rating +
            (userRating -
              currentRecipe.recipe.comments.theComments[k].userRating) /
              n;
        }
        // update the userRating in the theComments array
        currentRecipe.recipe.comments.theComments[k].userRating = userRating;
        currentRecipe.recipe.comments.theComments[k].userComment = comment;
      } else {
        // console.log("handleRateIt() userRatedRecipe()", false);
        if (currentRecipe.recipe.comments.theComments.length === 0) {
          // this is the creation of the comments,
          // hence ratings should be set to 0 (ZERO)
          // and the rating will be identical to the user's userRating
          currentRecipe.recipe.comments.rating = 0;
          currentRecipe.recipe.comments.ratings = 0;
        }
        // console.log(
        //   "handleRateIt() currentRecipe.recipe.comments.theComments.length currentRecipe.recipe.comments.ratings",
        //   currentRecipe.recipe.comments.theComments.length,
        //   currentRecipe.recipe.comments.ratings
        // );
        const comment = "";
        // console.log("comment ", comment);
        currentRecipe.recipe.comments.theComments.push({
          id: id,
          userid: currentUser.id,
          userRating: userRating,
          comment: comment,
        });
        // only for a new entry does the count go up
        currentRecipe.recipe.comments.ratings++;
        console.log(
          "handleRateIt() increment ",
          currentRecipe.recipe.comments.ratings
        );
        // console.log("comment ", comment);
        const n = currentRecipe.recipe.comments.ratings;
        const k = currentRecipe.recipe.comments.theComments.length - 1;
        const oldRating = currentRecipe.recipe.comments.rating;
        console.log("handleRateIt() k n", k, n);
        currentRecipe.recipe.comments.rating =
          ((n - 1) * oldRating + userRating) / n;
      }

      // in fact
      console.log(
        "currentRecipe.recipe.comments.theComments.length ===  currentRecipe.recipe.comments.ratings ",
        currentRecipe.recipe.comments.theComments.length ===
          currentRecipe.recipe.comments.ratings
      );

      // console.log(
      //   "handleRateIt() userRating currentRecipe.recipe.comments currentRecipe.recipe.comments.theComments",
      //   userRating,
      //   currentRecipe.recipe.comments,
      //   currentRecipe.recipe.comments.theComments
      // );
      setRating(currentRecipe.recipe.comments.rating);
      updateRecipe(currentRecipe);
      return currentRecipe;
    });
    // console.log(
    //   "handleRateIt() currentRecipe.recipe.comments.theComments",
    //   currentRecipe.recipe.comments.theComments
    // );
  }

  function handleCommentIt() {
    // console.log("indexOfComment ", indexOfComment);
    // console.log(
    //   "currentRecipe.recipe.comments.theComments  ",
    //   currentRecipe.recipe.comments.theComments
    // );

    currentRecipe.recipe.comments.theComments[indexOfComment].userComment =
      comment;
    updateRecipe();
  }

  function handleCommentChange(event) {
    const { name, value } = event.target;
    const recipe = { ...currentRecipe.recipe };
    const newComments = [...recipe.comments.theComments];
    // console.log("newComments  ", newComments);

    const newComment = value;
    const indx = getIndexOfUserRating(currentRecipe);
    // console.log("indx  ", indx);
    // console.log(
    //   "!indx && indx >= 0, !indx , indx >= 0  ",
    //   !indx && indx >= 0,
    //   !indx,
    //   indx >= 0
    // );
    // console.log(
    //   "indx === null || indx === undefined, indx === null , indx === undefined ",
    //   indx === null || indx === undefined,
    //   indx === null,
    //   indx === undefined
    // );
    if (indx === null || indx === undefined) {
      newComments.push({
        id: id,
        userid: currentUser.id,
        userRating: userRating,
        userComment: newComment,
      });
      const i = newComments.length - 1;
      // console.log("indx i ", indx, i);
      setIndexOfComment(i);
    } else {
      if (!currentRecipe.recipe.comments) {
        const comments = {
          theComments: [],
          rating: 0,
          ratings: 0,
        };
        currentRecipe.recipe.comments = comments;
      }
      setIndexOfComment(indx);
      newComments[indx].userComment = newComment;
      // console.log(
      //   "indx newComments[indx].userComment ",
      //   indx,
      //   newComments[indx].userComment
      // );
    }
    recipe.comments.theComments = newComments;
    // console.log("recipe ", recipe);
    // console.log("name, value, comment ", name, value, comment);
    // console.log("newComments  ", newComments);
    // console.log("newComment  ", newComment);
    // console.log("event.target  ", event.target);
    // console.log("recipe.comments.theComments  ", recipe.comments.theComments);
    setComment(value);
    setCurrentRecipe({ ...currentRecipe, recipe: recipe });
  }

  function handleCookingInProgress() {
    setCookingInProgress(false);
    alert("This would now take the ingredients away from the inventory.");
  }
  function handleStopCooking() {
    setCookingInProgress(false);
  }
  /**
   * render
   */
  if (!loading) {
    // console.log(currentRecipe.recipe);
    return (
      <div>
        <DisplayMessage
          message={message}
          setMessage={setMessage}
          maxWidth={editMode ? "700px" : "28rem"}
        >
          {message}
        </DisplayMessage>
        {/* ---->{message} */}
        {currentRecipe ? (
          <div>
            {editMode ? (
              <div className="edit-form">
                <h4>Recipe</h4>
                <form>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={currentRecipe.recipe.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      name="description"
                      value={currentRecipe.recipe.description}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* {console.log(
                    "ingredients: ",
                    currentRecipe.recipe.ingredients
                  )}
                  {console.log("recipe: ", currentRecipe.recipe)} */}
                  <GrowingList
                    title={"Instructions"}
                    stepLabel={"Step"}
                    isExtended={false}
                    growingList={currentRecipe.recipe.instructions}
                    onSave={onSaveInstructions}
                    options={options[3]}
                  />
                  <div className="form-group">
                    <label htmlFor="suggestions">
                      <strong>&nbsp;Suggestions&nbsp;</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="suggestions"
                      name="suggestions"
                      value={currentRecipe.recipe.suggestions}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="options">
                      <strong>&nbsp;Options&nbsp;</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="options"
                      name="options"
                      value={currentRecipe.recipe.options}
                      onChange={handleInputChange}
                    />
                  </div>
                  <>
                    <div className="form-group">
                      <label htmlFor="presentation">
                        <strong>&nbsp;Presentation&nbsp;</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="presentation"
                        name="presentation"
                        value={currentRecipe.recipe.presentation}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="media-images-presentation">
                        <strong>&nbsp;Picture&nbsp;</strong>
                      </label>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        className="form-control"
                        id="media-images-presentation"
                        name="media-images-presentation"
                        onChange={handleInputChange}
                      />
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="media-images-presentation"
                        name="media-images-presentation"
                        value={
                          currentRecipe.recipe.media &&
                          currentRecipe.recipe.media.images &&
                          currentRecipe.recipe.media.images.presentation
                            ? currentRecipe.recipe.media.images.presentation
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                  </>
                  <GrowingList
                    title={"Ingredients"}
                    stepLabel={"Ingredient"}
                    isExtended={true}
                    growingList={ingredients}
                    onSave={onSaveIngredients}
                    options={options[3]}
                  />
                  <GrowingList
                    title={"Cookware"}
                    stepLabel={"Cookware"}
                    isExtended={true}
                    growingList={cookware}
                    onSave={onSaveCookware}
                    options={options[3]}
                  />
                  {/* <div className="form-group">
                    <label htmlFor="ingredients">Ingredients</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ingredients"
                      name="ingredients"
                      value={
                        currentRecipe.recipe.ingredients
                          ? currentRecipe.recipe.ingredients
                          : ""
                      }
                      onChange={handleInputChange}
                    />
                  </div> */}
                  <div className="form-group">
                    <label htmlFor="media-images-head">
                      <strong>&nbsp;Picture&nbsp;</strong>
                    </label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      className="form-control"
                      id="media-images-head"
                      name="media-images-head"
                      onChange={handleInputChange}
                    />
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="media-images-head"
                      name="media-images-head"
                      value={
                        currentRecipe.recipe.media &&
                        currentRecipe.recipe.media.images &&
                        currentRecipe.recipe.media.images.head
                          ? currentRecipe.recipe.media.images.head
                          : ""
                      }
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="category">
                      <strong>&nbsp;Category&nbsp;</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="category"
                      name="category"
                      value={currentRecipe.recipe.category}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="tags">
                      <strong>&nbsp;Tags&nbsp;</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tags"
                      name="tags"
                      value={currentRecipe.recipe.tags}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="cookWays">
                      <strong>&nbsp;Cook Ways&nbsp;</strong>
                    </label>

                    {/* {options[2].map((v, i) => {
                      return console.log(v.label, i);
                    })} */}

                    <div>
                      <div>
                        {currentRecipe.recipe.cookWays
                          ? currentRecipe.recipe.cookWays.map(
                              (cookWay, indx) => {
                                return (
                                  <div
                                    key={indx}
                                    onClick={() => {
                                      // console.log("onClick()", indx, cookWay);
                                      setCookWaysIndex(indx);
                                      setCookWaysInput(true);
                                    }}
                                    style={{
                                      float: "left",
                                      cursor: "pointer",
                                      padding: "5px",
                                      backgroundColor: "lightgrey",
                                      color:
                                        cookWaysIndex === indx
                                          ? "yellow"
                                          : "blue",
                                      border: "grey 1px solid",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {cookWay.label}
                                    <div
                                      onClick={() => deleteItem(indx)}
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                        backgroundColor: "#c0c0c0",
                                        padding: "2px",
                                        borderRadius: "15px",
                                        color: "#1976d2",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      <ClearIcon
                                        style={{ marginTop: "-2px" }}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )
                          : null}
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>

                    <div className="input-group mb-1">
                      {/* {console.log(
                        currentRecipe.recipe.cookWays?.length,
                        cookWaysIndex
                        // currentRecipe.recipe.cookWays[cookWaysIndex].label
                      )} */}

                      <CookWaysDropDown
                        value={
                          // currentRecipe.recipe.cookWays?.length
                          // //  && cookWaysIndex
                          //   ? currentRecipe.recipe.cookWays[cookWaysIndex].label
                          //   : "Choose the way to prepare this recipe"
                          cookWaysIndex !== null &&
                          currentRecipe.recipe.cookWays?.length &&
                          cookWaysIndex < currentRecipe.recipe.cookWays?.length
                            ? currentRecipe.recipe.cookWays[cookWaysIndex].label
                            : "Choose the way to prepare this recipe"
                        }
                        handleInputChange={handleInputChange}
                        cookWaysIndex={cookWaysIndex}
                        options={options}
                      />

                      {cookWaysInput ? (
                        <input
                          id="cookWayAction"
                          name="cookWayAction"
                          style={{ width: "60%" }}
                          type="text"
                          className="form-control"
                          placeholder="Choose the action to prepare this recipe"
                          value={
                            cookWaysIndex !== null &&
                            currentRecipe.recipe.cookWays?.length &&
                            cookWaysIndex <
                              currentRecipe.recipe.cookWays?.length
                              ? currentRecipe.recipe.cookWays[cookWaysIndex]
                                  .action
                              : ""
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <input
                          disabled
                          id="cookWayAction"
                          name="cookWayAction"
                          style={{ width: "60%" }}
                          type="text"
                          className="form-control"
                          placeholder="Choose the action to prepare this recipe"
                          value={
                            cookWaysIndex !== null &&
                            currentRecipe.recipe.cookWays?.length &&
                            cookWaysIndex <
                              currentRecipe.recipe.cookWays?.length
                              ? currentRecipe.recipe.cookWays[cookWaysIndex]
                                  .action
                              : ""
                          }
                          onChange={handleInputChange}
                        />
                      )}
                      <CheckIcon
                        id="cookWayActionChecked"
                        name="cookWayActionChecked"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "5px",
                        }}
                        onClick={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="form-group" style={{ margin: "10px 0 " }}>
                    {currentRecipe.recipe.published ? (
                      <button
                        className="badge bg-primary mr-2"
                        onClick={() => updatePublished(false)}
                      >
                        UnPublish
                      </button>
                    ) : (
                      <button
                        className="badge bg-primary mr-2"
                        onClick={() => updatePublished(true)}
                      >
                        Publish
                      </button>
                    )}
                    <label>
                      <strong>&nbsp;Status:&nbsp;</strong>
                    </label>
                    {currentRecipe.recipe.published ? "Published" : "Pending"}
                  </div>
                  <div className="form-group">
                    {/* <label>
                      <strong>Servings:</strong>
                    </label> */}
                    {/* {currentRecipe.recipe.servings} */}
                    <SpinButton
                      myStyle={{ margin: "10", padding: "10", width: "100px" }}
                      id="servings"
                      className="form-control"
                      value={
                        currentRecipe.recipe.servings
                          ? currentRecipe.recipe.servings
                          : initialRecipe.servings
                      }
                      label="Servings"
                      onChange={handleInputChange}
                      name="servings"
                    />
                    {/* {currentRecipe.recipe.cost} */}
                    <SpinButton
                      myStyle={{ margin: "10", padding: "10", width: "100px" }}
                      id="cost"
                      className="form-control"
                      value={
                        currentRecipe.recipe.cost
                          ? currentRecipe.recipe.cost
                          : initialRecipe.cost
                      }
                      label="Budget"
                      onChange={handleInputChange}
                      name="cost"
                    />
                    {/* {console.log(currentRecipe.recipe)} */}
                    {/* {currentRecipe.recipe.difficulty} */}
                    <SpinButton
                      myStyle={{ margin: "10", padding: "10", width: "100px" }}
                      id="difficulty"
                      className="form-control"
                      value={
                        currentRecipe.recipe.difficulty
                          ? currentRecipe.recipe.difficulty
                          : initialRecipe.difficulty
                      }
                      label="Difficulty"
                      onChange={handleInputChange}
                      name="difficulty"
                    />
                    <SpinButton
                      myStyle={{ margin: "10", padding: "10", width: "100px" }}
                      id="time"
                      className="form-control"
                      value={
                        currentRecipe.recipe.time
                          ? currentRecipe.recipe.time
                          : initialRecipe.time
                      }
                      label="Time"
                      onChange={handleInputChange}
                      name="time"
                    />
                    <BasicTimePicker
                      label={"Cooking Time"}
                      value={currentRecipe.recipe.time}
                      onChange={handleInputChange}
                    />
                    {currentRecipe.recipe.time}
                  </div>
                </form>

                <button
                  className="badge bg-primary mr-2"
                  onClick={handleBackClick}
                >
                  Back to recipes listing
                </button>
                {isNew ? (
                  <button
                    // type="submit"
                    className="badge bg-success"
                    onClick={addRecipe}
                  >
                    Add
                  </button>
                ) : (
                  <span>
                    <button
                      className="badge bg-danger mr-2"
                      onClick={deleteRecipe}
                    >
                      Delete
                    </button>
                    <button
                      // type="submit"
                      className="badge bg-success"
                      onClick={updateRecipe}
                    >
                      Update
                    </button>
                    <button
                      className="badge bg-info"
                      onClick={handleChangeEditMode}
                    >
                      Display
                    </button>
                  </span>
                )}
                {/* <p>{message}</p> */}
              </div>
            ) : (
              <div>
                {cookingInProgress ? (
                  <CookingInProgress
                    handleCookingInProgress={handleCookingInProgress}
                    handleStopCooking={handleStopCooking}
                  />
                ) : null}
                {/* <Card style={{ width: "18rem" }}>
                                  <Card.Img variant="top" src="holder.js/100px180" />
                                  <Card.Body>
                                    <Card.Title>Card Title</Card.Title>
                                    <Card.Text>
                                      Some quick example text to build on the card title and make up
                                      the bulk of the card's content.
                                    </Card.Text>
                                    <Button variant="primary">Go somewhere</Button>
                                  </Card.Body>
                                </Card>  */}

                {/* <Card style={{ width: "18rem" }}>  */}
                <Card
                  className="card"
                  style={{
                    width: "30rem",
                    maxWidth: "80%",
                    overflow: "hidden",
                  }}
                >
                  {currentRecipe.recipe.media &&
                  currentRecipe.recipe.media.images &&
                  currentRecipe.recipe.media.images.head ? (
                    <div>
                      <Card.Img
                        variant="top"
                        onError={addDefaultSrc}
                        src={
                          imageServerURL +
                          currentRecipe.recipe.media.images.head
                        }
                      />
                      {/* <label>
                                      <strong>Picture (server side):</strong>
                                    </label>
                                    {currentRecipe.recipe.media.images.head} */}
                      {/* <img
                                      width={"100%"}
                                      src={currentRecipe.recipe.media.images.head}
                                      alt="Image Head Recipe {currentRecipe.recipe.name}"
                                    /> */}
                    </div>
                  ) : (
                    <div style={{ maxHeight: "250px" }}>
                      <Card.Img variant="top" src={imageNotAvailable} />
                      {/* <img src={imageNotAvailable} alt="Image Head Recipe" /> */}
                      {/* <br />
                                    <p>No picture available yet...</p> */}
                    </div>
                  )}
                  <Card.Body>
                    <Card.Title>{currentRecipe.recipe.name}</Card.Title>
                    <div className="mb-3">
                      <label>
                        <strong>Favorite Recipe:</strong>
                      </label>
                      {/* <button
                        className="badge bg-warning mr-2"
                        style={{
                          color: "#0d6efd",
                          marginLeft: "10px",
                        }}
                        onClick={() =>
                          handleSelectRecipeAsFavorite(currentRecipe.recipe.id)
                        }
                      >
                        {"Mark as Favorite Recipe"}
                      </button> */}
                      <div>
                        {/* {console.log("isFavoriteRecipe", isFavoriteRecipe)} */}
                        {/* Slider Favorite Recipes */}
                        <FormGroup>
                          <FormControlLabel
                            // control={<Switch defaultChecked />}
                            control={
                              <Switch
                                checked={isFavoriteRecipe}
                                onChange={() =>
                                  handleSelectRecipeAsFavorite(currentRecipe.id)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Favorite Recipes"
                          />
                          {/* <FormControlLabel disabled control={<Switch />} label="Disabled" /> */}
                        </FormGroup>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label>
                        <strong>Rating/Opinions/Comments</strong>
                      </label>
                      <div className="mb-3">
                        My rating{" "}
                        {userRating === null ? (
                          <span
                            style={{
                              fontSize: "small",
                            }}
                          >
                            (You did not rate it yet)
                          </span>
                        ) : null}
                        : {userRating}
                        {/* {console.log("userRating ", userRating)} */}
                        {userRating >= 1 ? (
                          <StarRateIcon
                            id={"starRating1"}
                            style={goldStyle}
                            onClick={() => handleRate(1)}
                          />
                        ) : (
                          <StarOutlineIcon
                            style={goldStyle}
                            onClick={() => handleRate(1)}
                          />
                        )}
                        {userRating >= 2 ? (
                          <StarRateIcon
                            id={"starRating2"}
                            style={goldStyle}
                            onClick={() => handleRate(2)}
                          />
                        ) : (
                          <StarOutlineIcon
                            style={goldStyle}
                            onClick={() => handleRate(2)}
                          />
                        )}
                        {userRating >= 3 ? (
                          <StarRateIcon
                            id={"starRating3"}
                            style={goldStyle}
                            onClick={() => handleRate(3)}
                          />
                        ) : (
                          <StarOutlineIcon
                            style={goldStyle}
                            onClick={() => handleRate(3)}
                          />
                        )}
                        {userRating >= 4 ? (
                          <StarRateIcon
                            id={"starRating4"}
                            style={goldStyle}
                            onClick={() => handleRate(4)}
                          />
                        ) : (
                          <StarOutlineIcon
                            style={goldStyle}
                            onClick={() => handleRate(4)}
                          />
                        )}
                        {userRating >= 5 ? (
                          <StarRateIcon
                            id={"starRating5"}
                            style={goldStyle}
                            onClick={() => handleRate(5)}
                          />
                        ) : (
                          <StarOutlineIcon
                            style={goldStyle}
                            onClick={() => handleRate(5)}
                          />
                        )}
                        <button
                          className="badge bg-warning mr-2"
                          style={{
                            color: "#0d6efd",
                            marginLeft: "10px",
                          }}
                          onClick={handleRateIt}
                        >
                          {"Rate this recipe"}
                        </button>
                      </div>
                      <div className="mb-3">
                        Rating: {rating}
                        {/* {console.log("rating ", rating)} */}
                        {rating ? (
                          <div>
                            {/* https://www.freecodecamp.org/news/javascript-range-create-an-array-of-numbers-with-the-from-method/ */}
                            {Array.from(
                              { length: rating },
                              (value, index) => index
                            ).map((indx, index) => {
                              return (
                                <StarRateIcon key={indx} style={goldStyle} />
                              );
                            })}
                            {Array.from(
                              { length: 5 - rating },
                              (value, index) => rating + index
                            ).map((indx, index) => {
                              return (
                                <StarOutlineIcon key={indx} style={goldStyle} />
                              );
                            })}
                          </div>
                        ) : (
                          "no rating yet"
                        )}
                      </div>
                      <div className="mb-3">
                        <label>
                          <strong>Comments:</strong>
                        </label>
                      </div>
                      {/* {console.log("indexOfComment ", indexOfComment)} */}
                      {/* {console.log(currentRecipe.recipe)} */}
                      {/* {console.log(currentRecipe.recipe.comments)} */}
                      {/* {console.log(currentRecipe.recipe.comments.theComments)} */}
                      <div className="mb-3">
                        {/* <Form> */}
                        <div className="form-group">
                          <label htmlFor="comment">My comment</label>
                          <textarea
                            className="form-control"
                            id="comment"
                            name="comment"
                            rows="3"
                            value={
                              indexOfComment === null
                                ? ""
                                : currentRecipe.recipe.comments.theComments[
                                    indexOfComment
                                  ].userComment
                            }
                            onChange={handleCommentChange}
                          ></textarea>
                          {/* <input
                            type="text"
                            className="form-control"
                            id="comment"
                            name="comment"
                            value={
                              currentRecipe.recipe.comments.theComments[
                                indexOfComment
                              ].userComment
                            }
                            onChange={handleCommentChange}
                          /> */}
                        </div>
                        <button
                          className="badge bg-warning mr-2"
                          style={{
                            color: "#0d6efd",
                            marginLeft: "10px",
                          }}
                          onClick={handleCommentIt}
                        >
                          {"Comment this recipe"}
                        </button>
                        {/* </Form> */}
                      </div>
                      <div>
                        <label>
                          <strong>Other users' comments:</strong>
                        </label>
                      </div>
                      <div className="mb-3">
                        {/* <ul style={{ textAlign: "left", listStyle: "none" }}> */}
                        {currentRecipe.recipe.comments.theComments.map(
                          (comment, indx) => {
                            if (comment.userid === currentUser.id) return null;
                            const theComment = comment.userComment;
                            const theRating = comment.userRating;
                            const ratingComponent = () => (
                              <div>
                                Rating: {theRating}
                                {Array.from(
                                  { length: theRating },
                                  (value, index) => index
                                ).map((indx, index) => {
                                  return (
                                    <StarRateIcon
                                      key={indx}
                                      style={goldStyle}
                                    />
                                  );
                                })}
                                {Array.from(
                                  { length: 5 - theRating },
                                  (value, index) => theRating + index
                                ).map((indx, index) => {
                                  return (
                                    <StarOutlineIcon
                                      key={indx}
                                      style={goldStyle}
                                    />
                                  );
                                })}
                              </div>
                            );

                            return theComment ? (
                              <div
                                key={indx}
                                style={{
                                  border: "solid #d2d2d2 1px",
                                  borderRadius: "5px",
                                  // marginLeft: "5px",
                                  padding: "5px",
                                }}
                              >
                                {/* <li
                                  key={indx}
                                  style={{
                                    border: "solid red 1px",
                                  }}
                                > */}
                                {theComment}
                                {ratingComponent()}
                                {/* </li> */}
                              </div>
                            ) : null;
                          }
                        )}
                        {/* </ul> */}
                      </div>
                    </div>
                    <div>
                      <label>
                        <strong>Description:</strong>
                      </label>
                    </div>
                    <Card.Text>
                      {currentRecipe.recipe.description}
                      {/* Some quick example text to build on the card title and make up
                            the bulk of the card's content. */}
                    </Card.Text>
                    {/* This is the stored default number of servings{" "}
                    {currentRecipe.recipe.servings}. <br />
                    The actual displayed recipe will show the {serves} servings. */}
                    <CardRecipeDetails
                      recipe={currentRecipe.recipe}
                      setRecipeServes={setServes}
                      createdAt={currentRecipe.createdAt}
                    />
                    <Accordion
                      className="Accordion mb-3"
                      // defaultActiveKey=""
                      // flush
                      // defaultActiveKey={["0"]}
                      alwaysOpen
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Ingredients</Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <label>
                              <strong>Ingredients:</strong>&nbsp;
                            </label>
                            {/* {currentRecipe.recipe.ingredients
                            ? currentRecipe.recipe.ingredients.map( */}
                            {ingredients
                              ? ingredients.map((ingredient, indx) => {
                                  // console.log({ ingredient });
                                  // const available = ingredient.availability;
                                  const servings = currentRecipe.recipe.servings
                                    ? currentRecipe.recipe.servings
                                    : 4;
                                  // const available = true;
                                  const available = getAvailability(
                                    indx,
                                    { ingredient },
                                    serves,
                                    servings
                                  );
                                  ingredients[indx].availability = available;
                                  // console.log(ingredient.availability);
                                  return (
                                    <div
                                      key={indx}
                                      style={{
                                        margin: "2pt 0",
                                        padding: "5pt",
                                        backgroundColor: "lightgrey",
                                        borderRadius: "5pt",
                                      }}
                                    >
                                      {/* Ingredients */}
                                      {indx + 1}:{" "}
                                      {ingredient.quantity *
                                        (serves / servings)}
                                      &nbsp;
                                      {ingredient.qtyUnit}{" "}
                                      <a
                                        href={`${frontend}ingredients/${ingredient.id}`}
                                      >
                                        {ingredient.name}
                                      </a>
                                      {/* : {ingredient.id} */}
                                      <div
                                        style={{
                                          float: "right",
                                          // /* display: block; */
                                          // display: "flex",
                                          // flexDirection: "row",
                                          // "${({ user }) => (user.onlineStatus ? "green" : "red")}"
                                          backgroundColor: available
                                            ? "green"
                                            : "red",
                                          borderRadius: "10px",
                                          border: available
                                            ? "darkgreen solid 2px"
                                            : "darkred solid 2px",
                                          height: "10px",
                                          width: "10px",
                                          padding: "0px",
                                          margin: "0px 0 0 -10px",
                                          /* position: relative;
                                          top: 0px;
                                          left: 0px; */
                                        }}
                                      ></div>
                                    </div>
                                  );
                                })
                              : ""}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Instructions</Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <label>
                              <strong>Instructions:</strong>&nbsp;
                            </label>
                            {currentRecipe.recipe.instructions
                              ? currentRecipe.recipe.instructions.map(
                                  (instruction, indx) => {
                                    return (
                                      <div
                                        key={indx}
                                        style={{
                                          margin: "2pt 0",
                                          padding: "5pt",
                                          backgroundColor: "lightgrey",
                                          borderRadius: "5pt",
                                        }}
                                      >
                                        Step {indx + 1}: {instruction}
                                      </div>
                                    );
                                  }
                                )
                              : ""}
                            {currentRecipe.recipe.suggestions ? (
                              <>
                                <label>
                                  <strong>Suggestions:</strong>&nbsp;
                                </label>
                                <div
                                  style={{
                                    margin: "2pt 0",
                                    padding: "5pt",
                                    backgroundColor: "lightgrey",
                                    borderRadius: "5pt",
                                  }}
                                >
                                  {currentRecipe.recipe.suggestions}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {currentRecipe.recipe.options ? (
                              <>
                                <label>
                                  <strong>Options:</strong>&nbsp;
                                </label>
                                <div
                                  style={{
                                    margin: "2pt 0",
                                    padding: "5pt",
                                    backgroundColor: "lightgrey",
                                    borderRadius: "5pt",
                                  }}
                                >
                                  {currentRecipe.recipe.options}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {currentRecipe.recipe.presentation ? (
                              <>
                                {currentRecipe.recipe.media &&
                                currentRecipe.recipe.media.images &&
                                currentRecipe.recipe.media.images
                                  .presentation ? (
                                  <div>
                                    <Card.Img
                                      variant="top"
                                      onError={addDefaultSrc}
                                      src={
                                        imageServerURL +
                                        currentRecipe.recipe.media.images
                                          .presentation
                                      }
                                    />
                                    <label>
                                      <strong>
                                        Suggestion of presentation:
                                      </strong>
                                    </label>
                                    {
                                      currentRecipe.recipe.media.images
                                        .presentation
                                    }
                                    {/* <img
                                      width={"100%"}
                                      src={currentRecipe.recipe.media.images.presentation}
                                      alt="Image Head Recipe {currentRecipe.recipe.name}"
                                    /> */}
                                  </div>
                                ) : (
                                  <div style={{ maxHeight: "250px" }}>
                                    <Card.Img
                                      variant="top"
                                      src={imageNotAvailable}
                                    />
                                    {/* <img src={imageNotAvailable} alt="Image presentation Recipe" /> */}
                                    {/* <br />
                                    <p>No picture available yet...</p> */}
                                  </div>
                                )}
                                <label>
                                  <strong>Presentation:</strong>&nbsp;
                                </label>
                                <div
                                  style={{
                                    margin: "2pt 0",
                                    padding: "5pt",
                                    backgroundColor: "lightgrey",
                                    borderRadius: "5pt",
                                  }}
                                >
                                  {currentRecipe.recipe.presentation}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Cookware</Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <label>
                              <strong>Cookware:</strong>&nbsp;
                            </label>
                            {/* {currentRecipe.recipe.cookware
                            ? currentRecipe.recipe.cookware.map( */}
                            {cookware
                              ? cookware.map((cookware, indx) => {
                                  return (
                                    <div
                                      key={indx}
                                      style={{
                                        margin: "2pt 0",
                                        padding: "5pt",
                                        backgroundColor: "lightgrey",
                                        borderRadius: "5pt",
                                      }}
                                    >
                                      {/* Cookware */}
                                      {indx + 1}: {cookware.name}
                                      {cookware.externalLinks?.map(
                                        (link, indx) => {
                                          // console.log(
                                          //   "link.provider ",
                                          //   link.provider
                                          // );
                                          return (
                                            <span key={indx}>
                                              <b>
                                                {indx === 0
                                                  ? " Order now:"
                                                  : ""}
                                              </b>
                                              <a href={link.link}>
                                                {" "}
                                                {link.provider}{" "}
                                              </a>
                                            </span>
                                          );
                                        }
                                      )}
                                      {/* : {cookware.id} */}
                                      {/* {console.log(
                                        "cookware.externalLinks ",
                                        cookware.externalLinks
                                      )} */}
                                      {/* {console.log("cookware ", cookware)} */}
                                    </div>
                                  );
                                })
                              : ""}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div className="mb-3">
                      <label>
                        <strong>Category: </strong>
                      </label>
                      {currentRecipe.recipe.category?.length
                        ? currentRecipe.recipe.category.map(
                            (category, indx) => {
                              if (indx === 0) return " " + category;
                              return ", " + category;
                            }
                          )
                        : " None"}
                    </div>
                    <div className="mb-3">
                      <label>
                        <strong>Tag: </strong>
                      </label>
                      {currentRecipe.recipe.tags?.length
                        ? currentRecipe.recipe.tags.map((tag, indx) => {
                            if (indx === 0) return " " + tag;
                            return ", " + tag;
                          })
                        : " None"}
                    </div>
                    <div
                      className="mb-1 mt-1"
                      // style={{ float: "left" }}
                    >
                      <label>
                        <strong>Cook Ways: </strong>
                      </label>
                    </div>
                    {/* <div style={{ clear: "both" }}></div> */}
                    <div
                      className="mb-3"
                      // className="mb-1 mt-1"
                    >
                      {/* {currentRecipe.recipe.cookWays?.length
                        ? currentRecipe.recipe.cookWays.map((cookWay, indx) => {
                            if (indx === 0) return " " + cookWay.label;
                            return ", " + cookWay.label;
                          })
                        : " None"} */}
                      {currentRecipe.recipe.cookWays?.length
                        ? currentRecipe.recipe.cookWays.map((cookWay, indx) => {
                            return (
                              <div
                                key={indx}
                                style={{ float: "left" }}
                                className="mb-3 mt-1"
                              >
                                {cookWay.action === "" ? (
                                  <button
                                    className="badge bg-warning mr-2"
                                    style={{
                                      color: "#0d6efd",
                                      marginLeft: "10px",
                                    }}
                                    onClick={() =>
                                      handleCookWaysSelection(indx)
                                    }
                                  >
                                    {cookWay.label}
                                  </button>
                                ) : (
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={cookWay.action}
                                  >
                                    <button
                                      className="badge bg-warning mr-2"
                                      style={{
                                        color: "#0d6efd",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {cookWay.label}
                                    </button>
                                  </a>
                                )}
                              </div>
                            );
                          })
                        : " None"}
                    </div>
                    <div style={{ clear: "both" }}></div>
                    {/* <div>
                      <label>
                        <strong>Serves: </strong>&nbsp;
                      </label>
                      <GroupsIcon />{" "}
                      {currentRecipe.recipe.servings
                        ? currentRecipe.recipe.servings
                        : "4"}
                    </div>
                    <div>
                      <label>
                        <strong>Status:</strong>&nbsp;
                      </label>
                      {currentRecipe.recipe.published ? "Published" : "Pending"}
                    </div> */}
                    <Button variant="primary" onClick={handleGoToRecipesList}>
                      Recipes List
                    </Button>
                    {/* <Link to={"/recipes"} className="badge bg-warning">
                                  Recipes
                                </Link> */}
                    <Button variant="primary" onClick={handleGoToRecipes}>
                      Recipes
                    </Button>
                    {/* <Link to={"/recipes"} className="badge bg-info">
                                Edit
                              </Link> */}
                    {isModerator ? (
                      <Button variant="info" onClick={handleChangeEditMode}>
                        Edit
                      </Button>
                    ) : null}
                    <Button
                      disabled={isDisabled}
                      variant="info"
                      onClick={handleAddIngredientsToShoppingList}
                    >
                      Push Ingredients To Shopping Cart
                    </Button>
                  </Card.Body>
                </Card>
                {/* <p>{message}</p> */}
              </div>
            )}
          </div>
        ) : (
          <div>
            <br />
            <p>Please click on a Recipe...</p>
          </div>
        )}
      </div>
    );
  }
}

export default Recipe;
