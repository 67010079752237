import axios from "axios";
import React, { useEffect, useState } from "react";

import { useHtml5QrCodeScanner } from "react-html5-qrcode-reader";

import server from "../../config/server.config";

const API_URL =
  server.host + (server.port === "" ? "" : ":" + server.port) + "/";

function QRCodeReader() {
  const [gotBarCode, setGotBarCode] = useState(true);
  const { Html5QrcodeScanner } = useHtml5QrCodeScanner("./html5-qrcode.min.js");

  const barCodes = [];

  function authHeader() {
    const user = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
    );

    // console.log("user ", { ...user, avatarImage: "" });

    if (user && user.accessToken) {
      // for Node.js Express back-end
      console.log("authHeader() access token");
      return { "x-access-token": user.accessToken };
    } else {
      console.log("authHeader() no token");
      return {};
    }
  }

  // // barcodelookup
  // const APIURL = "https://api.barcodelookup.com/v3/products";
  // const api_key = "vm2jcz2eminsnp0y1zv5h324y95oqk";

  // edamam
  const accessPoint = "https://api.edamam.com/api/food-database/v2/parser";
  const app_id = "d1b1bb3e";
  const app_key = "b3b0e7cd70417148804c272eb5830a5d";

  async function lookUpBarCode(barCode) {
    // axios.get();
    // barCode = "8480017506900";
    // barCode = "8428551058400";
    // barCode = "5055534302347";

    try {
      const response = await axios
        .get(
          // `${accessPoint}?app_id=${app_id}&app_key=${app_key}&upc=${barCode}`,
          "https://api.edamam.com/api/food-database/v2/parser?app_id=d1b1bb3e&app_key=b3b0e7cd70417148804c272eb5830a5d&upc=8480017506900",
          // `${accessPoint}`,
          {
            timeout: 1000,
            // headers: authHeader(),
          }
        )
        .then(
          (response) => {
            console.log("response.data ", response.data);
            if (response.status === 200) {
            }
          },
          (error) => {
            // console.log("error ", error);
            // console.log("error.name ", error.name);
            // console.log("error.response ", error.response);
            console.log(
              "error.response.data.message ",
              error.response.data.message
            );
            console.log("error.response.status ", error.response.status);
          }
          // ).catch((err) => (console.log(err)));
        )
        .catch((err) => {
          console.log(err);
        });
      // console.log("response.data ", response.data);
    } catch (error) {
      console.error(error);
    }
    // try {
    //   // axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
    //   axios.defaults.headers.get["Origin"] = "http://localhost:8021";
    //   const response = await axios
    //     .get(
    //       // `${APIURL}?barcode=${barCode}&formatted=y&key=${api_key}`,
    //       "https://api.barcodelookup.com/v3/products?barcode=9780140157376&formatted=y&key=vm2jcz2eminsnp0y1zv5h324y95oqk",
    //       // `${accessPoint}`,
    //       {
    //         timeout: 1000,
    //         // "Access-Control-Allow-Origin": "http://127.0.0.1:8021",
    //         // "Access-Control-Allow-Origin": "*",
    //         // "Access-Control-Allow-Origin": "https://api.barcodelookup.com/",
    //         //   // headers: authHeader(),
    //       }
    //     )
    //     .then(
    //       (response) => {
    //         console.log("response.data ", response.data);
    //         if (response.status === 200) {
    //         }
    //       },
    //       (error) => {
    //         console.log("error ", error);
    //         console.log("error.name ", error.name);
    //         console.log("error.response ", error.response);
    //         console.log(
    //           "error.response.data.message ",
    //           error.response.data.message
    //         );
    //         console.log("error.response.status ", error.response.status);
    //       }
    //       // ).catch((err) => (console.log(err)));
    //     )
    //     .catch((err) => {
    //       console.log(err);
    //     });
    //   // console.log("response.data ", response.data);
    // } catch (error) {
    //   console.error(error);
    // }
    try {
      // axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
      // axios.defaults.headers.get["Origin"] = "http://localhost:8021";
      const response = await axios
        .get(`${API_URL}barcodes/${barCode}`, {
          timeout: 1000,
          headers: authHeader(),
        })
        .then(
          (response) => {
            // console.log("response ", response);
            // console.log("response.data ", response.data);
            // console.log("response.data.products ", response.data.products);
            // console.log("response.status ", response.status);
            if (response.status === 200 && response.data.products.length > 0) {
              console.log(
                "response.data.products[0] ",
                response.data.products[0]
              );
            }
          },
          (error) => {
            // console.log("error ", error);
            console.log("error.name ", error.name);
            // console.log("error.response ", error.response);
            console.log("error.response.data ", error.response.data);
            console.log("error.response.status ", error.response.status);
          }
          // ).catch((err) => (console.log(err)));
        )
        .catch((err) => {
          console.log(err);
        });
      // console.log("response.data ", response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (Html5QrcodeScanner) {
      // Creates anew instance of `HtmlQrcodeScanner` and renders the block.
      let html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        { fps: 10, qrbox: { width: 350, height: 250 } },
        /* verbose= */ false
      );
      const qrCode = document.getElementById("qr-code");
      html5QrcodeScanner.render(
        (data) => {
          console.log("success ->", data);
          barCodes.push(data);
          setGotBarCode(true);
          qrCode.innerHTML = barCodes;
          console.log("barCodes ->", barCodes);
        },
        (err) => {
          console.log("err ->", err);
          // qrCode.innerHTML = err;
        }
      );
    }
  }, [Html5QrcodeScanner]);

  // beware: id must be the same as the first argument of Html5QrcodeScanner
  return (
    <div>
      <div id="reader"></div>
      <div id="qr-code"></div>
      {gotBarCode ? (
        <div>
          <button
            className="badge bg-warning mr-2"
            style={{ color: "#0d6efd", marginLeft: "10px" }}
            onClick={() => lookUpBarCode(barCodes[0])}
          >
            Look up the bar code
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default QRCodeReader;
