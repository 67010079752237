import { useState, useRef, useEffect } from "react";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import SellIcon from "@mui/icons-material/Sell";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";

import SizeChooser from "./SizeChooser";
import Quantity from "./Quantity";
import server from "../../config/server.config";

import "./Payments.css";
import imageNotAvailable from "../../assets/img/image-not-available.jpg";

const BACKEND_URL = server.host + (server.port === "" ? "" : ":" + server.port);

// const imageServerURL = server.cdn;
const imageServerURL = BACKEND_URL + "/assets/images/";

function addDefaultSrc(e) {
  e.target.src = imageNotAvailable;
}

const initialProducts = [
  {
    id: "0",
    name: "P Basic",
    price: 3000,
    size: "",
    sizes: ["S", "M", "L", "XL", "XXL"],
    image: "camiseta-blanca.gif",
    description: ["100% cotton", "natural color", "Regular fit"],
    qty: 0,
  },
  {
    id: "1",
    name: "P Starter",
    price: 1000,
    size: "",
    sizes: ["S", "M", "L", "XL", "XXL"],
    image: "camiseta-negra.jpg",
    description: ["100% cotton", "natural color", "Regular fit"],
    qty: 0,
  },
  {
    id: "2",
    name: "P Blanca",
    price: 2000,
    size: "",
    sizes: ["S", "M", "L", "XL", "XXL"],
    image: "camiseta-blanca.jpg",
    description: ["100% cotton", "natural color", "Regular fit"],
    qty: 0,
  },
  {
    id: "3",
    name: "P T-Shirt",
    price: 1500,
    size: "",
    sizes: ["S", "M", "L", "XL", "XXL"],
    image: "t-tshirt.jpg",
    description: ["100% cotton", "natural color", "Regular fit"],
    qty: 0,
  },
  {
    id: "4",
    name: "P chef",
    price: 10000,
    size: "",
    sizes: ["S", "M", "L", "XL", "XXL"],
    image: "camiseta-blanca-chef.png",
    description: ["100% cotton", "natural color", "Regular fit"],
    qty: 0,
  },
];

let cart = [];

const Shop = () => {
  const [showButton, setShowButton] = useState(false);
  const [show, setShow] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [products, setProducts] = useState(initialProducts);
  // const [sizes, setSizes] = useState(["", "", "", "", ""]);
  const [refresh, setRefresh] = useState(0);
  const target = useRef(null);

  const { user: currentUser } = useSelector((state) => state.auth);

  const currency = "€ (EUR)";

  function handleSetSizes(id, size) {
    // setSizes((prevValue) => {
    //   prevValue[id] = size;
    //   // console.log("prevValue: ", prevValue);
    //   return prevValue;
    // });
    // setTotalAmount(totalAmount);
    // console.log("size: ", size);
    setProducts((prevValue) => {
      prevValue[id].size = size;
      // console.log("prevValue: ", prevValue);
      return [...prevValue];
    });
    // setRefresh(refresh + 1);
  }

  function handleDecrement(id, qty) {
    setProducts((prevValue) => {
      if (prevValue[id].qty > 0) {
        prevValue[id].qty = qty;
        // prevValue[id].qty -= 1;
      } // console.log("prevValue: ", prevValue);
      return [...prevValue];
    });
    // setRefresh(refresh + 1);
  }

  function handleIncrement(id, qty) {
    setProducts((prevValue) => {
      // prevValue[id].qty += 1;
      prevValue[id].qty = qty;
      // console.log("prevValue: ", prevValue);
      // console.log(`prevValue[${id}].qty: `, prevValue[id].qty);
      // return prevValue;
      return [...prevValue];
    });
    // setRefresh(refresh + 1);
  }

  function handleAddToCart(id) {
    // check if product already in cart,
    // if then increment the quantity,
    // not adding the product again
    // console.log(id);
    let productAlreadyInCart = false;
    let indx = 0;
    // for (var subscription in products) {
    //   console.log("for in: ", products[subscription]);
    // }
    for (var item of cart) {
      // console.log("for of: ", indx, item);
      if (item.id === id) {
        productAlreadyInCart = true;
        break;
      }
      indx++;
    }
    // console.log("productAlreadyInCart ", productAlreadyInCart);
    // console.log("indx ", indx);
    // console.log("products: ", products);
    // console.log("cart: ", cart);
    let qty = products[id].qty === 0 ? 1 : products[id].qty;
    if (productAlreadyInCart) {
      // console.log(
      //   `cart[${indx}].qty , products[${id}].qty `,
      //   cart[indx].qty,
      //   products[id].qty
      // );
      cart[indx].qty += qty;
    } else {
      cart.push({ ...products[id] });
      indx = cart.length - 1;
      cart[indx].qty = qty;
      // setTotalAmount((totalAmount) => {
      //   return totalAmount + cart[indx].price * qty;
      // });
    }
    setTotalAmount((totalAmount) => {
      return totalAmount + cart[indx].price * qty;
    });
    // console.log("cart: ", cart);
    // console.log("totalAmount ", totalAmount);
    // products.forEach((subscription) => {
    //   console.log("forEach: ", subscription);
    // });
    // console.log(cart);
    // console.log(cart.length);
    setShowButton(cart.length > 0);
    setRefresh(refresh + 1);
  }

  // useEffect(() => {
  //   // setProducts(products);
  //   console.log("useEffect: ", products);
  // }, [products,sizes]);

  return (
    <div className="App">
      <h1>Welcome to our online shop!</h1>
      {/* {refresh} */}
      <div style={{ fontSize: "0.75rem" }}>
        If you want to subscribe please go here to our{" "}
        <Button>
          <Link style={{ fontSize: "0.75rem" }} to={"/subscriptions"} className="nav-link">
            <span 
              // style={{fontSize: "0.75 rem"}}
            >Subscription Page</span>
          </Link>
        </Button>
      </div>
      {/* <Link to={"/payments/checkout"} className="nav-link">
        Checkout
      </Link> */}
      <h1>Our products</h1>
      {showButton ? (
        <Button variant="danger" ref={target} onClick={() => setShow(!show)}>
          {show ? "Hide cart" : "Show cart"}
        </Button>
      ) : null}

      {/* {console.log("cart: ", cart)} */}
      <div>
        <div className="list row">
          {products.map((product, indx) => {
            const { id } = product;
            // let nextLine;
            // if (indx % 3 === 0) {
            //   nextLine = true;
            // }
            // console.log("Recipes ", indx, id, createdAt, recipe);
            // console.log("Recipes ", indx, id);

            return (
              // <RecipeCard
              //   key={id}
              //   id={id}
              //   createdAt={createdAt}
              //   indx={indx}
              //   recipe={recipe}
              // />
              <div
                id={"product-" + indx}
                key={indx}
                className="col-xl-4 col-lg-6 col-md-12"
              >
                {/* <p>{indx}</p> */}
                {/* <Link
                  to={"/payments/subscription/" + product.id}
                  // className="badge bg-warning"
                > */}
                {/* Edit */}
                <Card
                  className="card"
                  style={{ width: "20rem", margin: "10px auto" }}
                >
                  {/* <Card.Title>{product.name}</Card.Title> */}
                  {/* {imageServerURL} */}
                  {true ? (
                    <div
                    // className="shrinkToFitImage"
                    >
                      <Card.Img
                        variant="top"
                        // src={
                        //   "http://localhost:4000/assets/images/camiseta-blanca.jpg"
                        // }
                        src={imageServerURL + product.image}
                        onError={addDefaultSrc}
                      />
                      {/* <label>
                                <strong>Picture (server side):</strong>
                                </label>
                                {recipe.media.images.head} */}
                      {/* <img
                                width={"100%"}
                                src={recipe.media.images.head}
                                alt="Image Head Recipe {recipe.name}"
                              /> */}
                    </div>
                  ) : (
                    <div>
                      <Card.Img
                        variant="top"
                        style={{ maxHeight: "250px" }}
                        src={imageNotAvailable}
                        alt="Image Head Recipe not available yet..."
                      />
                      {/* <img src={imageNotAvailable} alt="Image Head Recipe" /> */}
                      {/* <br />
                            <p>No picture available yet...</p> */}
                    </div>
                  )}
                  <Card.Body>
                    <Card.Title
                      style={{ textAlign: "center", fontSize: "3rem" }}
                    >
                      {product.name}
                    </Card.Title>
                    {/* <div>
                            <label>
                              <strong>Name:</strong>
                            </label>
                            {recipe.name}
                          </div> */}
                    <div>
                      <label>
                        <strong>Description:</strong>
                      </label>
                      <br />
                      <div>
                        {/* <Card.Text>
                      </Card.Text> */}
                        <ul>
                          {product.description.map((item, indx) => {
                            return <li key={indx}>{item}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                    <SizeChooser
                      id={product.id}
                      sizes={product.sizes}
                      size={product.size}
                      // setProducts={setProducts}
                      // products={products}
                      handleSetSizes={handleSetSizes}
                    ></SizeChooser>
                    {/* <div>
                      {product.sizes.map((size, indx) => {
                        return (
                          <button
                            key={indx}
                            className="badge bg-warning mr-2"
                            onClick={() => handleChooseSize(product.id, size)}
                            style={{ color: "#0d6efd", marginLeft: "10px" }}
                          >
                            {size}
                          </button>
                        );
                      })}
                    </div> */}
                    {/* {console.log("product: ", product)} */}
                    {/* {console.log("sizes: ", sizes[product.id])} */}
                    {/* {console.log("product size: ", product.size)} */}
                    {/* {sizes[product.id] === "" ? null : (
                      <div className="mb-1 mt-1">
                        <label>
                          <strong>Size: </strong>
                          {sizes[product.id]}
                        </label>
                        <button
                          key={indx}
                          className="badge bg-warning mr-2"
                          style={{ color: "#0d6efd", marginLeft: "10px" }}
                        >
                          {product.size ? product.size : "Please choose a size"}
                        </button>
                      </div>
                    )} */}
                    <Quantity
                      // quantity={product.qty}
                      id={product.id}
                      superHandleDecrement={handleDecrement}
                      superHandleIncrement={handleIncrement}
                    />
                    {/* <div className="mb-1 mt-1">
                      <label>
                        <strong>Quantity: </strong>
                      </label>
                      <button
                        key={indx}
                        className="badge bg-warning mr-2"
                        style={{ color: "#0d6efd", marginLeft: "10px" }}
                      >
                        {product.qty}
                      </button>
                    </div> */}
                    <div className="mb-1 mt-1">
                      <label>
                        <strong>Price: </strong>
                      </label>
                      <button
                        key={indx}
                        className="badge bg-warning mr-2"
                        style={{ color: "#0d6efd", marginLeft: "10px" }}
                      >
                        {product.price/100} {currency}
                      </button>
                    </div>
                    {/* {console.log("indx: ", indx)} */}
                    <Button
                      variant="primary"
                      onClick={() => handleAddToCart(product.id)}
                      disabled={product.size === ""}
                    >
                      Add to cart
                    </Button>
                  </Card.Body>
                </Card>
                {/* </Link> */}
                {/* <div> {createdAt} </div>
                          <div> {recipe.name} </div>
                          <div> {recipe.description} </div>
                          <div> {recipe.category} </div>
                          <div> {recipe.subcategory} </div> */}
              </div>
            );
          })}
        </div>
      </div>
      <Overlay target={target.current} show={show} placement="right">
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              position: "fixed", // "sticky",   //"relative", // "absolute",
              // right: "0pt",
              // top: "0pt",
              backgroundColor: "rgba(100, 203, 255, 0.859)",
              // backgroundColor: "rgba(255, 100, 100, 0.85)",
              padding: "20px 10px",
              color: "white",
              borderRadius: 7,
              ...props.style,
            }}
          >
            <div className="list row">
              {cart.map((item, idx) => {
                return (
                  <div key={idx} className="col-xl-12 col-lg-12 col-md-12">
                    {item.qty} {item.name}({item.size}) @ {item.price/100} € ={" "}
                    {item.qty * item.price/100} €
                  </div>
                );
              })}
            </div>
            <div style={{ color: "black" }}>
              The total amout to check out: {totalAmount/100} €
            </div>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <Button style={{ className: "button" }}>
                <Link
                  style={{ color: "white" }}
                  to={"/shop/checkout"}
                  // state={{ id: 1, name: "sabaoon", shirt: "green" }}
                  // state={[{ item: "Online Video Editor", qty: "3", itemCode: "99" }]}
                  state={cart}
                >
                  Check Out
                </Link>
              </Button>
            </div>
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default Shop;
