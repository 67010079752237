import { useSelector } from "react-redux";

import "./Payments.css";


const CheckOutFailure = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  return (
    <div className="App">
      <h1>CheckOut Failure - Something went wrong</h1>
      <div className="avatar">
        <img
          width={"50pt"}
          height={"50pt"}
          src={`data:image/svg+xml;base64,${currentUser.avatarImage}`}
          alt="avatar"
        />
        <div className="onlineStatus"></div>
        <div className="">
          Hello {currentUser.fullname}. CheckOut Failed.
        </div>
      </div>
      Our apologies.
      <br />
      Please try again.
    </div>
  );
};

export default CheckOutFailure;
