/**
 * This helped a lot
 * especially styling
 * https://www.bezkoder.com/react-node-express-mongodb-mern-stack/
 */


import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { Pagination } from "@mui/material";


// import UserService from "../../services/user.service";
import IngredientDataService from "../../services/ingredient.service";

import "./Ingredients.css";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";


/**
 * component definition
 */

const IngredientsList = () => {
  const [ingredients, setIngredients] = useState([]);
  const [currentIngredient, setCurrentIngredient] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchName, setSearchName] = useState("");

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const pageSizes = [5, 10, 20];

  const getRequestParams = (searchName, page, pageSize) => {
    let params = {};

    if (searchName) {
      params["name"] = searchName;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };


  const retrieveIngredients = () => {
    // const pageSize = 10;
    // const page = 1;
    const params = getRequestParams(searchName, page, pageSize);

    IngredientDataService.getAllPaginated(params)
      .then((response) => {
        const { ingredients, totalPages } = response.data;

        setIngredients(ingredients);
        setCount(totalPages);

        // console.log(response.data.ingredients);
      })
      .catch((e) => {
        console.log("retrieveIngredients: ", e);
      });
  };

  useEffect(() => {
    retrieveIngredients();
  }, [page, pageSize]);



  const refreshList = () => {
    retrieveIngredients();
    setCurrentIngredient(null);
    setCurrentIndex(-1);
  };

  const setActiveIngredient = (ingredient, index) => {
    // console.log("ingredient ", ingredient);
    // console.log("index ", index);
    setCurrentIngredient(ingredient);
    setCurrentIndex(index);
  };

  const removeAllIngredients = () => {
    IngredientDataService.removeAll()
      .then((response) => {
        console.log(response.data.ingredients);
        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const findByName = () => {
    IngredientDataService.findByName(searchName)
      .then((response) => {
        setIngredients(response.data.ingredients);
        // console.log(response.data.ingredients);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  return (
    <div className="list row">
      <div className="col-md-8">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search by name"
            value={searchName}
            onChange={onChangeSearchName}
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={findByName}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <h4>Ingredients List</h4>

        <div className="mt-3">
          {"Items per Page: "}
          <select onChange={handlePageSizeChange} value={pageSize}>
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>

          <Pagination
            className="my-3"
            count={count}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>

        <ul className="list-group">
          {ingredients &&
            ingredients.map((ingredient, index) => (
              <li
                className={
                  "list-group-item " + (index === currentIndex ? "active" : "")
                }
                onClick={() => setActiveIngredient(ingredient, index)}
                key={index}
              >
                {ingredient.ingredient.name}
              </li>
            ))}
        </ul>

        <button
          className="m-3 btn btn-sm btn-danger"
          onClick={removeAllIngredients}
        >
          Remove All
        </button>

        <button className="m-3 btn btn-sm btn-danger">
          <Link to={"/addingredient"} className="nav-link">
            Add
          </Link>
        </button>
      </div>
      <div className="col-md-6">
        {currentIngredient ? (
          <div>
            <h4>Ingredient</h4>
            <div>
              <label>
                <strong>Name:</strong>&nbsp;
              </label>
              {currentIngredient.ingredient.name}
            </div>
            <div>
              <label>
                <strong>Description:</strong>&nbsp;
              </label>
              {currentIngredient.ingredient.description}
            </div>
            <div>
              <label>
                <strong>Presentation:</strong>&nbsp;
              </label>
              {currentIngredient.ingredient.presentation}
            </div>
            <div>
              <label>
                <strong>Category:</strong>&nbsp;
              </label>
              {currentIngredient.ingredient.category}
            </div>
            <div>
              <label>
                <strong>Picture:</strong>&nbsp;
              </label>
              {currentIngredient.ingredient.media &&
              currentIngredient.ingredient.media.images &&
              currentIngredient.ingredient.media.images.head ? (
                <div>
                  <img
                    width={"25%"}
                    src={currentIngredient.ingredient.media.images.head}
                    alt={`Image Head category ${currentIngredient.ingredient.name}`}
                  />
                </div>
              ) : (
                <div>
                  <img src={imageNotAvailable} alt="Image Head ingredient" />
                </div>
              )}
            </div>
            <div>
              <label>
                <strong>Units:</strong>&nbsp;
              </label>
              {currentIngredient.ingredient.qtyUnit}
            </div>
            <div>
              <label>
                <strong>Quantity:</strong>&nbsp;
              </label>
              {currentIngredient.ingredient.quantity}
            </div>
            <div>
              <label>
                <strong>Status:</strong>&nbsp;
              </label>
              {currentIngredient.ingredient.published ? "Published" : "Pending"}
            </div>
            {/* {console.log("currentIngredient ", currentIngredient)} */}
            <Link
              to={"/ingredients/" + currentIngredient.id}
              className="badge bg-info"
            >
              Show
            </Link>
            <Link
              to={"/ingredients/" + currentIngredient.id}
              className="badge bg-warning"
            >
              Edit
            </Link>
          </div>
        ) : (
          <div>
            <br />
            <p>Please click on a Ingredient...</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default IngredientsList;