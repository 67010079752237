/**
 * boilerplate
 */

// import React from "react";
import React, { useState, useEffect, createRef, useRef } from "react";
import { createContext } from "react";

import { useSelector } from "react-redux";
import { Link, useLoaderData } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useTheme } from "@mui/material/styles";
import { Fab } from "@mui/material";
import { Zoom } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyIcon from "@mui/icons-material/Key";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";

import RecipeCard from "./RecipeCard";
import StyledDiv from "../Playground/StyledDiv";

import Modal from "../Modal/Modal";
import CategoryDataService from "../../services/category.service";
import LoadingSpinner from "../Utils/LoadingSpinner";

// assets
import "../../assets/css/Recipes.css";
// import "../Modal/Modal.css";

import UserService from "../../services/user.service";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";

// try out the "context" thing
// import MoodEmoji from "./MoodEmoji/MoodEmoji";
/**
 * component definition
 */
const moods = {
  happy: "😊",
  sad: "😢",
};

export const MoodContext = createContext(moods);

/**
 * for the moment being, the filtering is done on a local copy,
 * no API calls involved, yet!!!!
 * @returns
 */
const ensembles = ["Cooking", "Tags"];

function Recipes() {
  const initialOptions = {
    cocina: "",
    selections: {},
  };

  const { message } = useSelector((state) => state.message);
  const { user: currentUser } = useSelector((state) => state.auth);

  const [recipe, setRecipe] = useState({});
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [categoryGroups, setCategoryGroups] = useState({});
  const [categoryOptions, setCategoryOptions] = useState([[{}], [{}]]);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showFavoriteRecipes, setShowFavoriteRecipes] = useState(
    currentUser ? true : false
  );
  const [IP, setIP] = useState("000.000.000.000");
  const [value, setValue] = useState(0);
  const [areLabelsShown, setAreLabelsShown] = useState(false);

  const [options, setOptions] = useState(initialOptions);

  const [cooking, setCooking] = useState([
    "Cocina internacional",
    "Cocina española",
    "Cocina de la India",
    // "Cocina italiana",
    // "Cocina alemana",
    // "Cocina francesa",
    // "Cocina peruana",
    "Cocina tailandesa",
    "Cocina china",
  ]);
  const [tags, setTags] = useState([
    "Casera",
    "Tradicional",
    // "Sin gluten",
    // "Vegetariano",
    // "Vegano",
    // "Sin lactosa",
    "Saludable",
    "Deportista",
  ]);

  const index = 0;
  let title = "dfgggf";
  /**
   * theming and the look&feel
   */

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabStyle1 = {
    position: "absolute",
    bottom: "16px",
    right: "16px",
  };
  const fabStyle2 = {
    position: "absolute",
    bottom: "16px",
    right: "80px",
  };
  const fab = {
    color: "primary",
    sx1: fabStyle1,
    sx2: fabStyle2,
    icon: AddIcon,
    label: "Add",
  };

  let childFuncRef = createRef(null);

  // useEffect(() => {
  //   ensembles.map((ensemble, indx) => retrieveCategoryGroup(ensemble, indx));
  //   // const categoryGroups = ensembles.map((ensemble) =>
  //   //   retrieveCategoryGroup(ensemble)
  //   // );
  //   // console.log("categoryGroups ", categoryGroups);
  //   // cooking = [];
  //   // tags = [];
  // }, []);

  /**
   * database functions
   */

  const retrieveCategoryGroup = (ensemble, indx) => {
    CategoryDataService.getAll({ name: "", ensemble: ensemble })
      .then((response) => {
        // var categoryGroup;
        // categoryGroup[indx]=response.data.categoryGroup;
        // console.log("categoryGroup[indx] ",indx, categoryGroup[indx]);
        // console.log(
        //   "response.data.categoryGroup ",
        //   response.data.categoryGroup
        // );
        // console.log("ensemble ", ensemble);
        // console.log("categoryGroup ", categoryGroup);
        setCategoryGroups((prevCategoryGroups) => {
          const newCategoryGroups = {
            ...prevCategoryGroups,
            [ensembles[indx]]: response.data.categoryGroup,
          };
          // console.log("newCategoryGroups ", indx, newCategoryGroups);
          setCategoryOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[indx] = response.data.categoryGroup
              ? response.data.categoryGroup.categories.map((category) => ({
                  label: category.name,
                  value: category.name,
                }))
              : null;
            // console.log("newOptions ", indx, newOptions);

            ["Cooking", "Tags"].map((ensemble, i) => {
              const simpleArray = newOptions[i].map((category) => {
                // console.log(category.label);
                return category.label;
              });
              // console.log(simpleArray);
              if (ensemble === "Cooking") setCooking(simpleArray);
              else setTags(simpleArray);
            });
            // console.log(cooking);
            // console.log(tags);

            return newOptions;
          });
          return newCategoryGroups;
        });

        // also possible to write it as array construct
        //
        // setCategoryGroups((prevCategoryGroups) => {
        //   const a = [...prevCategoryGroups];
        //   a[indx] = response.data.categoryGroup;
        //   return a;
        // });

        // console.log(response);
        // return response.data.categoryGroup;
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  // const dataLoaded = JSON.parse(useLoaderData());
  const dataLoaded = useLoaderData();

  useEffect(() => {
    if (dataLoaded) { console.log(dataLoaded);
      setIP(dataLoaded.ip);
    }
    // fetch(`https://ipecho.io/json`)
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((data) => {
    //     console.log("data", data);
    //     return data;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     return err;
    //   });
    // console.log(dataLoaded);
  }, [dataLoaded]);


  async function getRecipes() {
    return UserService.getUserRecipes()
      .then(
        (response) => {
          // console.log(response.data.recipes);
          // console.log(response.data);
          // console.log(response.status);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          setRecipes((prevValue) => {
            // console.log(prevValue);
            // console.log(response.data);
            // return [...prevValue, ...response.data];
            return [...response.data.recipes];
          });
          setFilteredRecipes((prevValue) => {
            return [...response.data.recipes];
          });
          applyFilter();
          setLoading(false);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          const errorElement = document.getElementById("error");
          if (errorElement) {
            errorElement.hidden = false;
            errorElement.innerText = "No recipes found. Error: " + _content;
          }
          // Set it to an empty array, since the user exists,
          // but it has NO recipes
          setRecipes([]);
          setLoading(false);
        }
        // ).catch((err) => (console.log(err)));
      )
      .catch(() => {
        setLoading(false);
      });
  }

  function handleSave(recipes) {
    // console.log("handleSave: ");
    // console.log("notes: ", notes);

    // just replace the list of todos, i.e. the tasks
    // console.log("todo.current: ", todo.current);

    UserService.postUserRecipes(currentUser, recipes).then(
      (response) => {
        // console.log(response.data);
        // console.log(response.status);
        // console.log(response.statusText);
        // console.log(response.headers);
        // console.log(response.config);
        document.getElementById("error").hidden = false;
        document.getElementById("error").innerText = response.data.message;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        document.getElementById("error").hidden = false;
        document.getElementById("error").innerText = _content;
      }
    );
  }

  /**
   * helper functions/services
   */

  const handleAddOnClick = (event) => {
    // const { name, value } = event.target;
    // alert(name, value);
    // console.log(event);
    title = "Add a recipe";
    childFuncRef.current.updateTitle(title);
    setShow(true);
  };

  const handleEditOnClick = (event) => {
    // const { name, value } = event.target;
    // alert(name, value);
    // console.log(event);
    title = "Edit a recipe";
    childFuncRef.current.updateTitle(title);
    setShow(true);
  };

  const onSubmit = (anRecipe) => {
    // console.log(anRecipe);
    setRecipe(anRecipe);
    addRecipe(anRecipe);
  };

  const onClose = () => setShow(false);

  function handleOnChange() {
    setShowFavoriteRecipes((prevValue) => {
      // return !showFavoriteRecipes;
      return !prevValue;
    });
    applyFilter();
  }

  function addRecipe(anRecipe) {
    // console.log(anRecipe);
    setRecipes((prevValue) => {
      const newRecipes = [...prevValue, anRecipe];
      handleSave(newRecipes);
      return newRecipes;
    });
  }

  function deleteRecipe(id) {
    if (id !== -1) {
      setRecipes((prevValue) => {
        const newRecipes = prevValue.filter((recipe, index) => {
          return id !== index;
        });
        handleSave(newRecipes);
        return newRecipes;
      });
    }
  }

  useEffect(() => {
    ensembles.map((ensemble, indx) => retrieveCategoryGroup(ensemble, indx));
    setLoading(true);
    async function fetchRecipes() {
      // You can await here
      const response = await getRecipes();
      // ...
      return response;
    }
    fetchRecipes();
    setOptions(initialOptions);
    // const categoryGroups = ensembles.map((ensemble) =>
    //   retrieveCategoryGroup(ensemble)
    // );
    // console.log("categoryGroups ", categoryGroups);
    // cooking = [];
    // tags = [];
  }, []);

  useEffect(() => {
    applyFilter();
  }, [recipes, showFavoriteRecipes]);

  const showRecipes = () => {
    return (
      <div>
        <div className="list row">
          {
            filteredRecipes.length === 0 ? (
              <div>Sorry no recipes match your filter criteria.</div>
            ) : (
              // React.Children.toArray(
              filteredRecipes.map((obj, indx) => {
                const { id, createdAt, recipe } = obj;
                // let nextLine;
                // if (indx % 3 === 0) {
                //   nextLine = true;
                // }
                // console.log("Recipes ", indx, id, createdAt, recipe);
                // console.log("Recipes ", indx, id);

                return (
                  <RecipeCard
                    key={id}
                    id={id}
                    createdAt={createdAt}
                    indx={indx}
                    recipe={recipe}
                  />
                );
              })
            )
            // )
          }
        </div>
      </div>
    );
  };

  const showRecipe = () => {
    console.log("recipe: ", recipe);
    return (
      <div>
        {recipe.recipe.name}
        <br />
        {recipe.recipe.description}
        <br />
        {recipe.recipe.category}
        <br />
        {recipe.recipe.subcategory}
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  };

  // function CheckInlineExample() {
  //   return (
  //     <Form>
  //       {["checkbox", "radio"].map((type) => (
  //         <div key={`inline-radio`} className="mb-3">
  //           <Form.Check
  //             inline
  //             label="1"
  //             name="group1"
  //             type={type}
  //             id={`inline-radio-1`}
  //           />
  //           <Form.Check
  //             inline
  //             label="2"
  //             name="group1"
  //             type={type}
  //             id={`inline-radio-2`}
  //           />
  //           <Form.Check
  //             inline
  //             disabled
  //             label="3 (disabled)"
  //             type={type}
  //             id={`inline-radio-3`}
  //           />
  //         </div>
  //       ))}
  //     </Form>
  //   );
  // }

  function updateFilter(type) {
    // console.log("type ", type);
    const opts = { ...options };
    if (type.startsWith("Cocina")) {
      opts.cocina = type;
    } else {
      opts.selections[type] = opts.selections[type]
        ? !opts.selections[type]
        : (opts.selections[type] = true);
    }
    // console.log("opts ", opts);
    // console.log("opts.selections ", opts.selections);
    setOptions(() => opts);
  }

  function isFilterSet() {
    // console.log(
    //   "isFilterSet() ",
    //   options.cocina,
    //   Object.keys(options.selections).length
    // );
    let filterSet = options.cocina !== "";
    // console.log("isFilterSet() ", filterSet);
    filterSet = filterSet || Object.keys(options.selections).length > 0;
    // but if all the filters have been manually reset?
    // the collection of objects exists, but all entries are "false"
    for (var selection in options.selections) {
      // console.log(selection, options.selections[selection]);
      // run code
      filterSet = filterSet || options.selections[selection];
    }
    // console.log("isFilterSet() filterSet", filterSet);
    return filterSet;
  }

  function resetFilter() {
    setAreLabelsShown(false);
    setOptions(initialOptions);
    setFilteredRecipes(recipes);
    applyFilter();
  }

  function applyFilter() {
    setAreLabelsShown(false);
    // console.log("applyFilter() options ", options);
    // console.log("applyFilter() isFilterSet() ", isFilterSet());
    // console.log("applyFilter() showFavoriteRecipes ", showFavoriteRecipes);
    const filterIsSet = isFilterSet();
    if (showFavoriteRecipes || isFilterSet()) {
      // console.log("applyFilter() filtering ", showFavoriteRecipes);
      // console.log("applyFilter() recipes ", recipes);
      const theFilteredRecipes = recipes.filter((recipe, index) => {
        // console.log("recipe ", recipe.recipe);
        // const isFavoriteRecipe = currentUser.favoriteRecipes.includes(recipe);
        const isFavoriteRecipe = currentUser?.favoriteRecipes?.reduce(
          (acc, currentValue) => {
            return acc || currentValue.id === recipe.id;
          },
          false
        );
        const matchedTags = recipe.recipe.tags.filter((tag, index) => {
          // console.log(
          //   "applyFilter() matching ",
          //   options.cocina,
          //   tag,
          //   options.selections[tag]
          // );
          // order here is important !!!!!
          // undefined on 1st term, but keeps evaluating 2nd (true/false)
          // other way around returns undefined
          return options.selections[tag] || options.cocina === tag;
        });
        // console.log(
        //   "matchedTags.length filterIsSet showFavoriteRecipes isFavoriteRecipe",
        //   matchedTags.length,
        //   filterIsSet,
        //   showFavoriteRecipes,
        //   isFavoriteRecipe
        // );
        // console.log("matchedTags.length ", matchedTags.length);
        // console.log("filterIsSet ", filterIsSet);
        // console.log("showFavoriteRecipes ", showFavoriteRecipes);
        // console.log("isFavoriteRecipe", isFavoriteRecipe);
        // console.log("matchedTags ", matchedTags);
        if (showFavoriteRecipes) {
          if (filterIsSet)
            return matchedTags.length > 0 && isFavoriteRecipe ? recipe : null;
          else return isFavoriteRecipe ? recipe : null;
        } else {
          if (filterIsSet) return matchedTags.length > 0 ? recipe : null;
          else return recipe;
        }
      });
      // console.log("applyFilter() theFilteredRecipes", theFilteredRecipes);
      setFilteredRecipes(theFilteredRecipes);
    } else {
      setFilteredRecipes(recipes);
    }
  }

  // export default CheckInlineExample;
  // function handleLabelOnClick(showLabels) {
  //   setAreLabelsShown(showLabels);
  //   // if (showLabels) alert("clicked");
  // }
  /**
   * the JSX element
   */
  // console.log("public url: ", process.env.PUBLIC_URL);
  // console.log("NODE_ENV: ", process.env.NODE_ENV);
  // console.log("REACT_APP_API_URL: ", process.env.REACT_APP_API_URL);
  // console.log("REACT_APP_API_PORT: ", process.env.REACT_APP_API_PORT);

  if (loading) {
    // console.log("loading: ", loading);
    // console.log("go spinning");
    return (
      <div className="loader">
        Loading ... <LoadingSpinner loading={loading} />
      </div>
    );
  }
  if (!loading) {
    return (
      // <MoodContext.Provider value={moods}>
      //   <MoodEmoji emoji={moods.happy} />
      //   <MoodEmoji>
      //   Recipes
      //   </MoodEmoji>
      // <div className="Recipes" id="root">
      <div className="container">
        <div>{IP}</div>
        <KeyIcon style={{ position: "absolute", left: "0" }} />
        {areLabelsShown ? null : (
          <div
            style={{
              position: "relative",
              top: "-50px",
              left: "50%",
              backgroundColor: "ButtonHighlight",
              borderRadius: "20px",
              color: "#1976d2",
              width: "100px",
              textAlign: "center",
              padding: "5px",
              // :hover "{pointer: "pointer"}"
            }}
            onClick={() => setAreLabelsShown(true)}
          >
            Etiquetas
          </div>
        )}
        {areLabelsShown ? (
          <div
            className="row"
            style={{
              position: "block",
              top: "0px",
              margin: "auto",
              backgroundColor: "#c2f0fd",
              borderRadius: "20px",
              color: "#1976d2",
              // width: "800px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            <Button
              style={{
                position: "relative",
                top: "0px",
                right: "5px",
                width: "40px",
              }}
              onClick={() => setAreLabelsShown(false)}
            >
              <ClearIcon
                style={{
                  marginLeft: "-5px",
                  paddingLeft: "0px",
                }}
                //  onClick={handleClick}
              />
            </Button>
            <Button
              style={{
                position: "relative",
                top: "0px",
                right: "5px",
                width: "40px",
              }}
              onClick={resetFilter}
            >
              <DeleteIcon
                style={{
                  marginLeft: "-5px",
                  paddingLeft: "0px",
                }}
                //  onClick={handleClick}
              />
            </Button>
            <Button
              style={{
                position: "relative",
                top: "0px",
                right: "5px",
                width: "40px",
              }}
              onClick={applyFilter}
            >
              <CheckIcon
                style={{
                  marginLeft: "-5px",
                  paddingLeft: "0px",
                }}
                //  onClick={handleClick}
              />
            </Button>
            <Row className="mb-3">
              <Col>
                <div style={{ margin: "auto", width: "300px" }}>
                  <Form>
                    <ul
                      key={"uniqueKey"}
                      // key={"inline-radio"} // not necessary, the name property was key
                      style={{ textAlign: "left", listStyle: "none" }}
                    >
                      {/* {console.log(cooking)} */}
                      {
                        // [
                        //   "Cocina internacional",
                        //   "Cocina española",
                        //   "Cocina de la India",
                        //   "Cocina italiana",
                        //   "Cocina alemana",
                        //   "Cocina francesa",
                        //   "Cocina peruana",
                        //   "Cocina tailandesa",
                        //   "Cocina china",
                        // ]
                        cooking &&
                          cooking.map((cooking, index) => (
                            <li key={index}>
                              <Form.Check
                                inline
                                name="Cocina"
                                type={"radio"}
                                id={`inline-radio-${index}`}
                                label={cooking}
                                value={cooking === options.cocina}
                                checked={
                                  cooking === options.cocina ? "checked" : ""
                                }
                                onChange={() => updateFilter(cooking)}
                              />
                            </li>
                          ))
                      }
                      {/* </div> */}
                    </ul>
                  </Form>
                </div>
              </Col>
              <Col>
                <div style={{ margin: "auto", width: "300px" }}>
                  <ul style={{ textAlign: "left", listStyle: "none" }}>
                    {
                      // [
                      //   "Casera",
                      //   "Tradicional",
                      //   "Sin gluten",
                      //   "Vegetariano",
                      //   "Vegano",
                      //   "Sin lactosa",
                      //   "Saludable",
                      //   "Deportista",
                      // ]
                      tags &&
                        tags.map((cooking, index) => (
                          <li key={index}>
                            <Form.Check
                              type={"checkbox"}
                              id={cooking}
                              label={cooking}
                              value={options.selections[cooking]}
                              checked={
                                options.selections[cooking] ? "checked" : ""
                              }
                              onChange={() => updateFilter(cooking)}
                            />
                          </li>
                        ))
                    }
                    {/* <ul>"Definición volumen"</ul>" "Dieta pepito" "Regimen dorita" */}
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
        {/* <CheckInlineExample /> */}

        <div>
          {/* Slider Favorite Recipes */}
          <FormGroup>
            <FormControlLabel
              // control={<Switch defaultChecked />}
              control={
                <Switch
                  checked={showFavoriteRecipes}
                  onChange={handleOnChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Favorite Recipes"
            />
            {/* <FormControlLabel disabled control={<Switch />} label="Disabled" /> */}
          </FormGroup>
        </div>

        <div className="Recipes">
          <header className="header">
            <h1>Recipes</h1>
            <StyledDiv>
              Choose from hundreds of recipes. From simple, easy to
              sophisticated, elaborated.
            </StyledDiv>
          </header>
          <main className="main">
            {/* <div className="list row">
          <div className="col-sm-1" style={{ border: "solid red 1px" }}>
            sm
          </div>
          <div className="col-md-2" style={{ border: "solid red 1px" }}>
            md
          </div>
          <div className="col-lg-3" style={{ border: "solid red 1px" }}>
            lg
          </div>
          <div className="col-xl-4" style={{ border: "solid red 1px" }}>
            xl
          </div>
          <div className="col-xxl-5" style={{ border: "solid red 1px" }}>
            xxl
          </div>
        </div> */}
            {/* main */}
            {showRecipes()}
            {/* {loading && <LoadingSpinner loading={loading} />} */}
          </main>
          <footer className="footer">
            The cooking realm - pollucturum.com - Copyright 2021-
            {new Date(Date.now()).getFullYear()}
          </footer>

          {/* <form name="message" method="post">
        <section>
          <label className="left">
            Name
            <input size="30" id="name" type="text" value="" name="name" />
          </label>
          <label className="right">
            Email
            <input id="email" type="text" value="" name="email" />
          </label>
        </section>

        <br style={{ clear: "both" }} />
        <br />
        <section>
          <label>
            Subject
            <input id="subject" type="text" value="" name="subject" />
          </label>
          <label>
            Message
            <input id="message" type="text" value="" name="message" />
          </label>
        </section>
        </form> */}

          <Modal
            ref={childFuncRef}
            title={`${title} a recipe`}
            onClose={() => setShow(false)}
            show={show}
            onSubmit={onSubmit}
            // theForm={"NullForm"}
            theForm={"RecipeForm"}
          >
            {/* <p>This is modal body, in fact props.children</p> */}
          </Modal>

          <Zoom
            // key={1}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${
                value === index ? transitionDuration.exit : 0
              }ms`,
            }}
            unmountOnExit
          >
            <Fab
              sx={fab.sx1}
              aria-label={fab.label}
              onClick={handleAddOnClick}
              color={fab.color}
            >
              <AddIcon />
            </Fab>
          </Zoom>
          <Zoom
            // key={2}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${
                value === index ? transitionDuration.exit : 0
              }ms`,
            }}
            unmountOnExit
          >
            <Fab
              sx={fab.sx2}
              aria-label={fab.label}
              onClick={handleEditOnClick}
              color={fab.color}
            >
              <EditIcon />
            </Fab>
          </Zoom>
        </div>
      </div>
      // </MoodContext.Provider>
    );
  }
  return null;
}

export default Recipes;
