import ClearIcon from "@mui/icons-material/Clear";

const CookingInProgress = ({ handleCookingInProgress, handleStopCooking }) => {
  return (
    <div
      style={{
        position: "sticky",
        // left: "20%",
        // top: "70%",
        left: "0%",
        top: "0%",
        zIndex: "1000",
        backgroundColor: "white",
        padding: "50px",
        borderRadius: "10px",
        border:"solid lightgrey 0.5px",
        opacity: "0.85",
        width: "200px"
      }}
    >
      <h5>
        Cooking in progress...
        <ClearIcon
          style={{
            position: "absolute",
            left: "85%",
            top: "5%",
            cursor: "pointer",
          }}
          onClick={handleStopCooking}
        />
      </h5>
      <button
        className="badge bg-warning mr-2"
        style={{
          color: "#0d6efd",
          marginLeft: "10px",
        }}
        onClick={handleCookingInProgress}
      >
        {"Finished cooking!"}
      </button>
    </div>
  );
};
export default CookingInProgress;
