import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function DaySpinner({ theDate, setTheDate }) {
  const dateString = theDate.toDateString();
  // var yesterday = (function (d) {
  //   d.setDate(d.getDate() - 1);
  //   return d;
  // })(new Date());
  // console.log(yesterday);

  function theDayBefore(d) {
    let t = new Date(d);
    t.setDate(t.getDate() - 1);
    return t;
  }
  function theDayAfter(d) {
    let t = new Date(d);
    t.setDate(t.getDate() + 1);
    return t;
  }

  function theMonthBefore(d) {
    let r = new Date(d.getFullYear(), d.getMonth() - 1, d.getDate());
    return r;
  }
  function theMonthAfter(d) {
    let r = new Date(d.getFullYear(), d.getMonth()+1, d.getDate() );
    // console.log(r);
    return r;
  }

  function handleOnClick(direction) {
    // console.log(
    //   dayBefore.toDateString(),
    //   "---->",
    //   theDate.toDateString(),
    //   "---->",
    //   dayAfter.toDateString()
    // );
    switch (direction) {
      case "wayBack":
        var dayBefore = theMonthBefore(theDate);
        setTheDate(dayBefore);
        break;
      case "back":
        var dayBefore = theDayBefore(theDate);
        setTheDate(dayBefore);
        break;
      case "forward":
        var dayAfter = theDayAfter(theDate);
        setTheDate(dayAfter);
        break;
      case "wayForward":
        var dayAfter = theMonthAfter(theDate);
        setTheDate(dayAfter);
        break;
      default:
        break;
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flex: "0 1",
      }}
    >
      <div
        onClick={() => handleOnClick("wayBack")}
        style={{
          padding: "7px 0 0 16px",
          backgroundColor: "lightgrey",
          borderRadius: "20px",
          height: "40px",
          width: "70px",
          display: "inherit",
        }}
      >
        <ArrowBackIosNewIcon />
        <div
          style={{
            marginLeft: "-10px",
            verticalAlign: "middle",
            display: "inherit",
          }}
        >
          <ArrowBackIosNewIcon />
          {/* LeftArrow */}
        </div>
      </div>

      <div
        onClick={() => handleOnClick("back")}
        style={{
          padding: "7px 0 0 16px",
          backgroundColor: "lightgrey",
          borderRadius: "20px",
          height: "40px",
          width: "60px",
        }}
      >
        <ArrowBackIosNewIcon />
        {/* LeftArrow */}
      </div>
      <div
        style={{
          padding: "7px 0 0 18px",
          backgroundColor: "lightgrey",
          borderRadius: "20px",
          height: "40px",
          width: "160px",
        }}
      >
        {dateString}
      </div>
      <div
        onClick={() => handleOnClick("forward")}
        style={{
          padding: "7px 0 0 18px",
          backgroundColor: "lightgrey",
          borderRadius: "20px",
          height: "40px",
          width: "60px",
        }}
      >
        <ArrowForwardIosIcon />
        {/* RightArrow */}
      </div>
      <div
        onClick={() => handleOnClick("wayForward")}
        style={{
          padding: "7px 0 0 18px",
          backgroundColor: "lightgrey",
          borderRadius: "20px",
          height: "40px",
          width: "70px",
          display: "inherit",
        }}
      >
        <ArrowForwardIosIcon />
        <div
          style={{
            marginLeft: "-10px",
            verticalAlign: "middle",
            display: "inherit",
          }}
        >
          <ArrowForwardIosIcon />
        </div>
        {/* RightArrow */}
      </div>
    </div>
  );
}

export default DaySpinner;
