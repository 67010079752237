import React from "react";

import Card from "./Card";
import Avatar from "./Avatar";

import Georg from "../../assets/img/Georg.jpg";
// import Sophia from "../../assets/img/Sophia.jpeg";
// import Johanna from "../../assets/img/Johanna.jpg";
// import Kilian from "../../assets/img/Kilian.jpg";
// import Reduan from "../../assets/img/Reduan.jpeg";

import contacts from "../../models/contacts.js";

// console.log(contacts);
// console.log(typeof contacts);

function constructCard(contact) {
  return (
    <Card
      key={contact.id}
      id={contact.id}
      class={contact.class}
      name={contact.name}
      imgURL={contact.imgURL}
      phone="+687 415 669"
      email="soahcha@gmail.com"
    />
  );
}

function constructCards() {
  // for (let i = 0; i < contacts.length; i++) {
  //   console.log(contacts[i].name);
  // }
  let stuff = [];
  for (let i = 0; i < contacts.length; i++) {
    stuff.push(constructCard(contacts[i]));
  }
  // console.log(stuff);
  return stuff;
  // contacts.forEach(
  // (contact) => {
  // console.log(contact.name);
  // }
  // );
}

constructCards();

// name, imgURL, phone, email
function Cards() {
  return (
    <div className="cards">
      <div>
        <h1 className="heading">My Contacts</h1>
        <Avatar imgURL={Georg} />

        {/* {constructCards()} */}
        {/* {document.write(contacts[0].name)} */}
        {/* {
          contacts.map(
            (contact) => {
              return constructCard(contact);
            }
          )
        } */}
        {contacts.map(constructCard)}
        {/* {console.log(contacts.map(constructCard))} */}
        {/* <Card
          class="card"
          name="Sophia Ahmed Chavarino"
          imgURL={Sophia}
          phone="+687 415 669"
          email="soahcha@gmail.com"
        />

        <Card
          name="Georg Schreiber"
          imgURL={Georg}
          phone="+34 687 415 692"
          email="georgschreiber@yahoo.es"
        />

        <Card
          name="Johanna Schreiber Ahmed"
          imgURL={Johanna}
          phone="+34 687 415 680"
          email="j.schah@gmail.com"
        />

        <Card
          name="Kilian Schreiber Ahmed"
          imgURL={Kilian}
          phone="+34 637 298 212"
          email="kiruman.dumbledore@gmail.com"
        />

        <Card
          name="Reduan Schreiber Ahmed"
          imgURL={Reduan}
          phone="+34 697 137 316"
          email="reduan.schreiberahmed@gmail.com"
        /> */}
      </div>
    </div>
  );
}

export default Cards;
