const sections = {
  // _id: {
  //   $oid: "63eabc695b989b34d3ee7287",
  // },
  "Platos Preparados": {
    "Pizzas Refrigeradas": {},
    Tortillas: {},
    Carne: {},
    Pescado: {},
    Verduras: {},
    Legumbres: {},
    "Pastas y Arroces": {},
    Ensaladas: {},
    "Empanadas, Empanadillas y Hojaldres": {},
    "Bocadillos y Sandwich": {},
    "Croquetas y Aperitivos": {},
    "Gazpachos y Salmorejos": {},
  },
  Frescos: {
    Carne: {
      Pollo: {},
      Pavo: {},
      Cerdo: {},
      Vacuno: {},
      Mixto: {},
      Cordero: {},
      Conejo: {},
    },
    "Charcutería y Quesos": {
      Cocidos: {},
      Curados: {},
      Quesos: {},
      "Foie, Patés y Sobrasadas": {},
    },
    "Pescado y Marisco": {
      "Pescado y Marisco Fresco": {},
      "Gulas y Surimi": {},
      "Ahumados y Salazones": {},
    },
    Frutas: {
      Cítricos: {},
      Manzanas: {},
      Peras: {},
      Plátanos: {},
      Uvas: {},
      "Frutas de Temporada": {},
      "Frutas Tropicales": {},
      "Frutos del Bosque": {},
    },
    "Verduras y Hortalizas": {
      "Ajos, Cebollas y Puerros": {},
      "Brócolis y Coliflores": {},
      "Calabacín, Calabaza y Berenjena": {},
      "Espinacas y Alcachofas": {},
      "Judías y Guisantes": {},
      "Lechugas, Escarolas y Endivias": {},
      "Patatas y Zanahorias": {},
      "Setas y Champiñones": {},
      "Tomates, Pimientos y Pepinos": {},
      "Verduras y Ensaladas Preparadas": {},
      "Otras Verduras": {},
    },
  },
  Despensa: {
    "Lácteos y Huevos": {
      Leche: {},
      "Bebidas Vegetales": {},
      Huevos: {},
      Yogures: {},
      Postres: {},
      "Mantequilla y Margarina": {},
      Nata: {},
    },
    "Desayunos y Dulces": {
      Galletas: {},
      Cereales: {},
      Bollería: {},
      Café: {},
      "Mermeladas, Miel y Frutas en Almíbar": {},
      "Azúcar y Edulcorantes": {},
      "Caramelos, Chicles y Golosinas": {},
      "Chocolates y Bombones": {},
      "Cacao y Cremas de Cacao": {},
      "Preparación de Postres": {},
      Infusiones: {},
    },
    Conservas: {
      "Conservas de Pescado": {},
      "Conservas Vegetales": {},
      "Conservas de Carne": {},
    },
    Sopas: {
      Caldos: {},
      Sopas: {},
      "Cremas y Purés": {},
    },
    "Pastas, Harinas y Masas": {
      Pastas: {},
      "Masas y Hojaldres": {},
      "Harinas y Levaduras": {},
    },
    "Arroz y Legumbres": {
      Arroz: {},
      Legumbres: {},
    },
    "Aceites, Vinagres y Aliños": {
      Aceites: {},
      "Vinagres y Aliños": {},
    },
    Salsas: {
      Tomate: {},
      "Mayonesa y Otras Salsas": {},
    },
    Aperitivos: {},
    "Sal y Especias": {},
    "Patés y Foies": {},
    Pan: {
      Biscotes: {},
      "Pan de Hamburguesas y Perritos": {},
      "Pan de Horno": {},
      "Pan de Molde": {},
      "Picos de Pan": {},
      "Pan Rallado": {},
      "Crackers y Tartaletas": {},
      "Snack de Pan": {},
    },
    "Cocina Internacional": {
      Mejicana: {},
      Oriental: {},
      Otras: {},
    },
    Dietéticos: {
      "Complementos Nutricionales": {},
      "Pérdida de Peso": {},
      Semillas: {},
    },
  },
  Bebidas: {
    Aguas: {},
    "Bebidas Isotónicas y Energéticas": {},
    Cervezas: {
      Nacionales: {},
      Especiales: {},
      "Con Limón": {},
      "Sin Alcohol": {},
    },
    "Batidos y Horchata": {},
    Refrescos: {},
    Zumos: {},
  },

  Bodega: {
    Espumosos: {},
    Alcoholes: {
      Brandy: {},
      Ginebra: {},
      Ron: {},
      Vermouth: {},
      Vodka: {},
      Whisky: {},
    },
    Licores: {
      Anís: {},
      Cremas: {},
      "De Hierbas": {},
      Pacharán: {},
      "Sin Alcohol": {},
      "Otros Licores": {},
    },
    Vino: {
      Tinto: {},
      Blanco: {},
      Rosado: {},
      "Generoso y Dulce": {},
    },
    "Vinos de Mesa, Sangrías y Tintos de Verano": {},
  },

  Congelados: {
    Precocinados: {},
    "Pizzas, Bases y Masas": {},
    "Pescado y Marisco": {},
    "Carne y Pollo": {},
    "Verduras y Hortalizas": {},
    Repostería: {},
    Helados: {},
    Hielo: {},
  },
  "Cuidado Personal": {
    Afeitado: {},
    Depilación: {},
    "Baño e Higiene Personal": {
      Champús: {},
      "Cuidado del Cabello": {},
      Colonias: {},
      Desodorantes: {},
      "Geles de Duchas y Esponjas": {},
      Jabón: {},
    },
    Botiquín: {},
    "Cuidado Bucal": {},
    "Cuidado Corporal": {
      Cosmética: {},
      "Cuidado de Manos y Pies": {},
      "Hidratación Corporal": {},
      "Limpieza Facial": {},
      "Crema Solar": {},
      "Crema Facial": {},
    },
    "Cuidado Íntimo": {},
    "Salud Sexual": {},
  },

  Bebé: {
    Pañales: {},
    Toallitas: {},
    "Cuidado del Bebé": {},
    "Leche para Bebés": {},
    Papillas: {},
    "Potitos y Tarritos": {},
    "Bebidas, Galletas y Yogures": {},
  },

  "Cuidado del Hogar": {
    "Utensilios de Limpieza": {},
    "Cuidado de la Ropa": {},
    Lavavajillas: {},
    Papel: {},
    "Productos de Limpieza": {
      Baño: {},
      Cocina: {},
      Hogar: {},
    },
    "Conservación de Alimentos": {},
    Ambientadores: {},
    Bazar: {},
    Calzado: {},
    Insecticidas: {},
  },
  Mascotas: {
    Perros: {},
    Gatos: {},
    "Otros Animales": {},
  },
};
export default sections;
