import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Pagination } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import RecipeDataService from "../../services/recipe.service";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";

const RecipesPages = () => {
  const [recipes, setRecipes] = useState([]);
  const [currentRecipe, setCurrentRecipe] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchName, setSearchName] = useState("");

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const pageSizes = [3, 6, 9];

  let navigate = useNavigate();

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const getRequestParams = (searchName, page, pageSize) => {
    let params = {};

    if (searchName) {
      params["name"] = searchName;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveRecipes = () => {
    const params = getRequestParams(searchName, page, pageSize);

    RecipeDataService.getAllPaginated(params)
      .then((response) => {
        const { recipes, totalPages } = response.data;

        setRecipes(recipes);
        setCount(totalPages);

        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveRecipes, [page, pageSize]);

  const refreshList = () => {
    retrieveRecipes();
    setCurrentRecipe(null);
    setCurrentIndex(-1);
  };

  const setActiveRecipe = (recipe, index) => {
    setCurrentRecipe(recipe);
    setCurrentIndex(index);
  };

  const removeAllRecipes = () => {
    RecipeDataService.removeAll()
      .then((response) => {
        console.log(response.data);
        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const handleEditClick = () =>{
    navigate(`/recipes/${currentRecipe.id}`);
  };

  return (
    <div className="container">
      <KeyIcon style={{ position: "absolute", left: "0" }} />
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name"
              value={searchName}
              onChange={onChangeSearchName}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={retrieveRecipes}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4>Recipes List</h4>

          <div className="mt-3">
            {"Items per Page: "}
            <select onChange={handlePageSizeChange} value={pageSize}>
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </div>

          <ul className="list-group">
            {recipes &&
              recipes.map((recipe, index) => (
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => setActiveRecipe(recipe, index)}
                  key={index}
                >
                  {recipe.recipe.name}
                </li>
              ))}
          </ul>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={removeAllRecipes}
          >
            Remove All
          </button>
          <button className="m-3 btn btn-sm btn-danger">
            <Link to={"/addRecipe"} className="nav-link">
              Add a recipe
            </Link>
          </button>
        </div>
        <div className="col-md-6">
          {currentRecipe ? (
            <div>
              {/* <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src="holder.js/100px180" />
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card> */}

              {/* <Card style={{ width: "18rem" }}> */}
              <Card style={{ width: "100%" }}>
                {currentRecipe.recipe.media &&
                currentRecipe.recipe.media.images &&
                currentRecipe.recipe.media.images.head ? (
                  <div
                    style={{
                      margin: "auto",
                    }}
                  >
                    <Card.Img
                      style={{
                        maxWidth: "80%",
                        maxHeight: "350px",
                        margin: "20px",
                      }}
                      variant="top"
                      src={currentRecipe.recipe.media.images.head}
                    />
                    <label>
                      <strong>Picture (server side):</strong>
                    </label>
                    {currentRecipe.recipe.media.images.head}
                    {/* <img
                    width={"100%"}
                    src={currentRecipe.recipe.media.images.head}
                    alt="Image Head Recipe {currentRecipe.recipe.name}"
                  /> */}
                  </div>
                ) : (
                  <div>
                    <Card.Img variant="top" src={imageNotAvailable} />
                    {/* <img src={imageNotAvailable} alt="Image Head Recipe" /> */}
                    <br />
                    <p>No picture available yet...</p>
                  </div>
                )}
                <Card.Body>
                  <Card.Title>Recipe</Card.Title>
                  <div>
                    <label>
                      <strong>Name: </strong>&nbsp;
                    </label>
                    {currentRecipe.recipe.name}
                  </div>
                  <div>
                    <label>
                      <strong>Description: </strong>&nbsp;
                    </label>
                    {currentRecipe.recipe.description}
                  </div>
                  <div>
                    <label>
                      <strong>Status: </strong>&nbsp;
                    </label>
                    {currentRecipe.recipe.published ? "Published" : "Pending"}
                  </div>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                  <Button variant="primary" onClick={handleEditClick}>
                    Edit
                  </Button>
                  <Link
                    to={"/recipes/" + currentRecipe.id}
                    className="badge bg-warning"
                  >
                    Show
                  </Link>
                </Card.Body>
              </Card>
            </div>
          ) : (
            // <div>
            //   <h4>Recipe</h4>
            //   {currentRecipe.recipe.media &&
            //   currentRecipe.recipe.media.images &&
            //   currentRecipe.recipe.media.images.head ? (
            //     <div>
            //       <label>
            //         <strong>Picture (server side):</strong>
            //       </label>
            //       {currentRecipe.recipe.media.images.head}
            //       <img
            //         width={"100%"}
            //         src={currentRecipe.recipe.media.images.head}
            //         alt="Image Head Recipe {currentRecipe.recipe.name}"
            //       />
            //     </div>
            //   ) : (
            //     <div>
            //       <br />
            //       <p>No picture available yet...</p>
            //       <img src={imageNotAvailable} alt="Image Head Recipe" />
            //     </div>
            //   )}
            //   <div>
            //     <label>
            //       <strong>Name:</strong>
            //     </label>
            //     {currentRecipe.recipe.name}
            //   </div>
            //   <div>
            //     <label>
            //       <strong>Description:</strong>
            //     </label>
            //     {currentRecipe.recipe.description}
            //   </div>
            //   <div>
            //     <label>
            //       <strong>Status:</strong>
            //     </label>
            //     {currentRecipe.recipe.published ? "Published" : "Pending"}
            //   </div>

            //   <Link
            //     to={"/recipes/" + currentRecipe.id}
            //     className="badge bg-warning"
            //   >
            //     Edit
            //   </Link>
            // </div>
            <div>
              <br />
              <p>Please click on a Recipe...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecipesPages;