import React, { useEffect, useState, useRef } from "react";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";

// import { coursesTypes, mealTypes } from "./menu.config";
import addIngredientsToShoppingList from "../ShoppingList/addIngredientToShoppingList";
import RecipeDataService from "../../services/recipe.service";
import SearchBox from "../Playground/SearchBox";
import MiniCard from "./MiniCard";
import Repeater from "../Recipes/Repeater";

function Menu(props) {
  let emptyMenu;
  // console.log(props.menu);
  if (props.menu) {
    emptyMenu = {
      // lunch: props.menu.lunch.name,
      // dinner: props.menu.dinner.name,
      lunch: props.menu.lunch,
      dinner: props.menu.dinner,
      weekday: props.menu.weekday,
      date: props.menu.date,
      inCart: props.menu.inCart,
    };
  } else {
    emptyMenu = {
      // lunch: "lunch",
      // dinner: "dinner",
      lunch: {},
      dinner: {},
      weekday: "weekday",
      date: "date",
      inCart: false,
    };
  }
  const [mealType, setMealType] = useState(null);
  const [courses, setCourses] = useState(null);
  const [menu, setMenu] = useState(props.menu ? props.menu : emptyMenu);
  const [showCart, setShowCart] = useState(
    props.menu.inCart ? ! props.menu.inCart : true
  );
  const id = props.id;

  //this is the reference to the child clearState function
  // let clearChildState = null;
  const clearChildState = useRef(null);

  // this method assigns the child clearState function
  // to the parent reference
  function assignClearChildState(childClearStateFunc) {
    // console.log("assignClearChildState ", childClearStateFunc);
    clearChildState.current = childClearStateFunc;
  }
  // this method gets the child state for completeness of
  // the childComp tag
  function getStateCallback(item) {
    //do something to use the child state
    console.log("item ", item);
  }

  function resetForm() {
    // console.log("clearChildState() ", clearChildState.current);
    clearChildState.current("");
  }

  function onSelect(name, value, course) {
    // console.log("onSelect() name", name);
    // console.log("value", value);
    // console.log("id", id);
    // alert("Selected value: " + value + " " + id);
    setMenu((prevValue) => {
      const menu = [...prevValue[name]];
      // console.log(menu);
      // console.log(prevValue);
      // const course = name === "lunch" ? course : courseForDinner;
      menu[course] = { name: value.name, id: value.id, cookWay: "LetSCook" };
      console.log(menu);
      return { ...prevValue, [name]: menu };
    });
    // console.log(menu);
    return;
  }

  function changeMenu(event, indx) {
    event.preventDefault();
    // console.log(event.target);
    // console.log(event.target.index);
    // console.log(event.target.getAttribute("index"));
    let { name, value, index } = event.target;
    if (!index) index = event.target.getAttribute("index");
    // console.log("name, value, index ", name, value, index);
    setMenu((prevValue) => {
      const meal = [...prevValue[name]];
      meal[index] = value;
      // console.log("meal ", name, meal);
      const newMenu = { ...prevValue, [name]: meal };
      // console.log("newMenu ", newMenu);
      return { ...prevValue, [name]: meal };
      // return { ...prevValue, [name]: value };
    });
  }

  // const getRecipe = async (id) => {
  //   console.log("id ", id);
  //   // id = null;
  //   const ingredients = await RecipeDataService.get(id)
  //     .then((response) => {
  //       const currentRecipe = response.data;
  //       // console.log("getRecipe ", currentRecipe);
  //       // console.log("getRecipe ingredients", currentRecipe.recipe.ingredients);
  //       return currentRecipe.recipe.ingredients;
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       return [];
  //     });
  //   return ingredients;
  // };

  // async function getIngredients(name, id) {
  //   // console.log(name, id);
  //   // const ingredients = await getRecipe(id);
  //   const ingredients = await RecipeDataService.get(id);
  //   // console.log("getIngredients(): ingredients ", ingredients);
  //   return ingredients;
  // }

  async function procureIngredients(menu) {
    // alert("ingredients for recipe of each dish added to shopping cart");
    const lunch = await menu.lunch.courses.map(async (item, indx) => {
      item.procured = true;
      // console.log(item.name);
      // const ingredients = await getIngredients(item.name, item.id);
      const promiseForRecipes = await RecipeDataService.get(item.id);
      // allDishes = [...allDishes, ...promiseForRecipes];
      // console.log("promiseForRecipes ", promiseForRecipes);
      return promiseForRecipes;
    });
    const dinner = await menu.dinner.courses.map(async (item, indx) => {
      item.procured = true;
      // console.log(item.name);
      // const promiseForRecipes = await getIngredients(item.name, item.id);
      const promiseForRecipes = await RecipeDataService.get(item.id);
      // allDishes = [...allDishes, ...promiseForRecipes];
      // console.log("allDishes ", allDishes);
      // console.log("promiseForRecipes ", promiseForRecipes);
      return promiseForRecipes;
    });

    // // meals have the information of how many people are eating
    // const meals = ["lunch", "dinner"];
    // const serves = meals.map((meal, indx) => {
    //   // console.log("menu[meal].serves ", menu[meal].serves);
    //   return menu[meal].serves;
    // });
    // serves.push(lunch.length);
    // serves.push(dinner.length);

    const allDishes = [...lunch, ...dinner];
    // console.log("allDishes ", allDishes);
    Promise.all(allDishes)
      .then((dishes) => {
        // console.log("dishes ", ...dishes);
        // console.log("dishes.length ", dishes.length);
        const data = [];
        const servings = [];
        const serves = [];
        for (let i = 0; i < dishes.length; i++) {
          const stretch = dishes[i].data.recipe.ingredients.length;
          data.push(...dishes[i].data.recipe.ingredients);
          // console.log("i ", i);
          // console.log(
          //   "ingredients of dishes",
          //   ...dishes[i].data.recipe?.ingredients
          // );

          // meals have the information of how many people are eating: serves
          // console.log("menu[meals[i]] ", menu[meals[i]]);
          // serves.push(menu[meals[i]].serves);
          let menuServes;
          if (i < lunch.length) {
            menuServes = menu["lunch"].serves;
          } else {
            menuServes = menu["dinner"].serves;
          }
          // console.log("menuServes ", menuServes);
          const theServes = Array.from(
            { length: stretch },
            (value, indx) => menuServes
          );
          serves.push(...theServes);

          // recipes have the number the recipe is thought for: servings
          const theServings = dishes[i].data.recipe.servings
            ? dishes[i].data.recipe.servings
            : 4;
          // console.log("dishes[i].data.recipe.servings ", theServings);
          const div1 = Array.from(
            { length: stretch },
            (value, indx) => theServings
          );
          servings.push(...div1);
        }
        // console.log("dishes ", dishes);
        // console.log("data ", data);
        // const servings = dishes.data.recipe.servings;
        // console.log("servings ", servings);
        // console.log("serves ", serves);
        // console.log(
        //   "dishes.data.recipe.servings ",
        //   dishes.data.recipe.servings
        // );
        if (data.length){
          addIngredientsToShoppingList(props.user, data, servings, serves);
          setShowCart(false); 
          menu.inCart = true;
          console.log("-----------------> menu.inCart ", menu.inCart);
          props.replaceMenu(id, menu);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    setMenu(props.menu);
  }, [props.menu]);

  // useEffect(() => {
  //   const mealType = props.menu["lunch"].mealType;
  //   const indx = mealTypes.indexOf(mealType);
  //   console.log("Menu(): mealType indx ", mealType, indx);
  //   console.log("Menu(): props.menu indx ", props.menu, indx);
  //   setCourses(coursesTypes[indx]);
  //   setMealType(mealType);
  // }, []);

  return (
    <div
      className="MenuCard"
      // className="term"
      style={{
        // float: "left",
        backgroundColor: "gray",
        marginTop: "10px",
        marginBottom: "10px",
        marginLeft: "0px",
        marginRight: "0px",
      }}
    >
      {/* {"jslkjsjfdsa"} */}
      {/* {console.log("menu.inCart ", menu.inCart)} */}
      {/* {console.log("showCart ", showCart)} */}
      {/* {menu.inCart} */}
      <div
        style={{
          fontWeight: "500",
          lineHeight: "1.2",
          color: "#ccc",
          fontSize: "1.2em",
          // marginTop: "10px",
          // marginBottom: "15px",
        }}
      >
        {/* {index} */}
        <div
          style={{
            float: "left",
            textalign: "right",
            marginRight: "auto",
            marginLeft: 0,
            // marginBottom: "20px",
            marginTop: "5px",
          }}
        >
          {menu.weekday}&nbsp;
          {props.convertDate(menu.date)}
        </div>
        <button
          disabled={!showCart}
          style={{
            opacity: showCart?1:0.2,
            float: "right",
            borderRadius: "10px",
            textalign: "right",
            marginRight: 0,
            marginLeft: "auto",
          }}
          onClick={() => procureIngredients(menu)}
        >
          <ShoppingCartIcon /> Buy
        </button>
        <div style={{ clear: "both" }}></div>
      </div>
      <form className="createMenu">
        <div style={{ display: "flex" }}>
          <div className="row" style={{ flexGrow: "1", flex: "1 0" }}>
            {["lunch", "dinner"].map((meal, idx) => {
              // console.log("Menus: meal ", meal);
              // console.log("Menus: menu[meal] ", menu[meal]);
              return (
                <div className="col-md-6" key={idx}>
                  <div className="row">
                    <div className="col-3">{capitalizeFirstLetter(meal)}</div>
                    <div className="col-3" id="serves">
                      <GroupsIcon />{" "}
                      {menu[meal].serves ? menu[meal].serves : "4"}
                    </div>
                    <div className="col-3">
                      <Repeater
                        icon={PersonIcon}
                        count={menu[meal].serves ? menu[meal].serves : 4}
                      />
                    </div>
                    <div className="col-3"></div>
                  </div>

                  {/* {console.log(menu[meal])} */}
                  {/* {console.log(menu[meal].serves)} */}
                  {menu[meal].courses?.map((item, course) => {
                    // console.log("Menus: item ", item);
                    return (
                      <div
                        style={{
                          border: "black solid 1px",
                          borderRadius: "10px",
                          fontSize: "small",
                          padding: "5px",
                        }}
                        key={course}
                        // style={{float:"left"}}
                      >
                        <span style={{ fontSize: "small" }}>
                          {courses ? courses[course] : null}
                          {/* {courses[course]} */}
                        </span>
                        {/* <input
                          // disabled
                          index={course}
                          key={course}
                          name={meal}
                          placeholder={item.name}
                          value={item.name}
                          onChange={(event) => changeMenu(event, course)}
                          // style={{ display: formShown ? "inline" : "none" }}
                        /> */}
                        {true ? (
                          // <div>
                          <MiniCard recipe={item} />
                        ) : (
                          // </div>
                          <SearchBox
                            // getState={getState}
                            getState={getStateCallback}
                            // passClearState={passClearState}
                            passClearState={assignClearChildState}
                            destructureVector="matchingRecipes"
                            name={meal}
                            onSelect={(name, value) =>
                              onSelect(name, value, course)
                            }
                            // placeholder={meal + " " + courses[course]}
                            placeholder={item.name}
                            // the recipes are now objects {name, id}
                            // value={{ name: " ", id: "0" }}
                            find={props.findByName}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {/* <div className="col">
              Lunch
              {console.log(menu.lunch)}
              {menu.lunch.courses.map((item, indx) => {
                return (
                  <div
                    key={indx}
                    // style={{float:"left"}}
                  >
                    <span style={{ fontSize: "small" }}>{courses[indx]}</span>
                    <input
                      // disabled
                      index={indx}
                      key={indx}
                      name="lunch"
                      placeholder={item.name}
                      value={item.name}
                      onChange={(event) => changeMenu(event, indx)}
                      // style={{ display: formShown ? "inline" : "none" }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="col">
              Dinner
              {menu.dinner.courses.map((item, indx) => {
                return (
                  <div key={indx}>
                    <span style={{ fontSize: "small" }}>{courses[indx]}</span>
                    <input
                      // disabled
                      index={indx}
                      key={indx}
                      name="dinner"
                      placeholder={item.name}
                      value={item.name}
                      onChange={(event) => changeMenu(event, indx)}
                      // style={{ display: formShown ? "inline" : "none" }}
                    />
                  </div>
                );
              })}
            </div> */}
          </div>
        </div>
        {/* <input
          name="dinner"
          placeholder={menu.dinner ? menu.dinner.name : "Dinner"}
          value={menu.dinner.name}
          onChange={changeMenu}
          // style={{ display: formShown ? "inline" : "none" }}
        /> */}
        <button
          className="saveButton"
          onClick={(event) => {
            event.preventDefault();
            props.replaceMenu(id, menu);
          }}
        >
          <div style={{ display: "inline" }}>
            <span style={{ verticalAlign: "top" }}>Save</span>
            <CheckCircleOutlineIcon />
          </div>
        </button>
        <button
          className="deleteButton"
          onClick={(event) => {
            event.preventDefault();
            props.onDelete(id);
          }}
        >
          <div style={{ display: "inline" }}>
            <span style={{ verticalAlign: "top" }}>Delete</span>
            <DeleteForeverIcon />
          </div>
        </button>
      </form>
    </div>
  );
}

export default Menu;
