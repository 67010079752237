// export default function authHeader() {
//   const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));

//   if (user && user.accessToken) {
//     return { Authorization: "Bearer " + user.accessToken };
//   } else {
//     return {};
//   }
// }

// Note: For Node.js Express back-end, please use x-access-token header like this:

export default function authHeader() {
  // console.log(
  //   "process.env.REACT_APP_LOCALHOST_KEY ",
  //   process.env.REACT_APP_LOCALHOST_KEY
  // );
  const user = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
  );

  // console.log("user ", { ...user, avatarImage: "" });
  // console.log("user.accessToken ", user.accessToken);

  if (user && user.accessToken) {
    // for Node.js Express back-end
    return {
            'x-access-token': user.accessToken,
            // 'Authorization': "Basic QWxhZGRpbjpvcGVuIHNlc2FtZQ=="
           };
  } else {
    return {};
  }
}

