// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";

function ShowCategory({ currentCategory: category, setEditMode }) {
  return (
    <div
      style={{
        // border: "solid black 1px",
        backgroundColor: "whitesmoke",
        padding: "20px",
        borderRadius: "20px",
      }}
    >
      <h4>Category</h4>
      <div style={{ paddingTop: "10px" }}>
        <label>
          <strong>Name:</strong>&nbsp;
        </label>
        {category.name}
      </div>
      <div style={{ paddingTop: "10px" }}>
        <label>
          <strong>Description:</strong>&nbsp;
        </label>
        {category.description}
      </div>
      <div style={{ paddingTop: "10px" }}>
        <label>
          <strong>Presentation:</strong>&nbsp;
        </label>
        {category.presentation}
      </div>
      <div style={{ paddingTop: "10px" }}>
        <label>
          <strong>Picture:</strong>&nbsp;
        </label>
        {category.media &&
        category.media.images &&
        category.media.images.head ? (
          <div>
            {/* {category.media.images.head} */}
            <img
              width={"50%"}
              src={category.media.images.head}
              alt={`Image Head category ${category.name}`}
            />
          </div>
        ) : (
          <div>
            <img
              width={"50%"}
              src={imageNotAvailable}
              alt="Image Head category"
            />
          </div>
        )}
      </div>
      <div style={{ paddingTop: "10px" }}>
        <label>
          <strong>Status:</strong>&nbsp;
        </label>
        {category.published ? "Published" : "Pending"}
      </div>
      {/* {console.log("category ", category)} */}
      <button onClick={() => setEditMode(true)} className="badge bg-warning">
        Edit
      </button>
    </div>
  );
}

export default ShowCategory;
