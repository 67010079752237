import React, {
  View,
  Image,
  ActivityIndicator,
} from "react";

// import { makeStyles } from "@mui/material/styles";
import Modal from "@mui/material/Modal";

import Colors from "../../config/Colors";
// assets
import logo from "../../assets/img/logo.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";


// function getModalStyle() {
function getModalStyle(height, width) {
  // const top = (height-76)/2;
  // const left = (width-40)/2;

  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "absolute"
  };
}


// const useStyles = makeStyles((theme) => ({
//   paper: {
//     // flexDirection: "column",
//     height: "100%",
//     width: "100%",
//     // backgroundColor: "#fff",
//     alignItems: "center",
//     paddingTop: 2,
//     justifyContent: "center",
//     // width: 400,
//     // backgroundColor: theme.palette.background.paper,
//     // border: "2px solid #000",
//     // boxShadow: theme.shadows[5],
//     // padding: theme.spacing(2, 4, 3),
//   },
// }));

const myStyles = {
  modalBackground: {
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    backgroundColor: "#rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  activityIndicatorWrapper: {
    backgroundColor: "#FFFFFF",
    height: 100,
    width: 100,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
};

const LoadingSpinner = ({loading}) => {
  // console.log("loading: ", loading)
  // const classes = useStyles();
  const { height, width } = useWindowDimensions();

  // getModalStyle is not a pure function, we roll the style only on the first render
  // const [modalStyle] = React.useState(getModalStyle);
  // const [open, setOpen] = React.useState(loading);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const body = (
    // <div style={modalStyle} className={classes.paper}>
    <div
      // className={classes.paper}
      // style={{ position: "absolute", top: height / 2, left: width / 2 }}
      // style={getModalStyle(height, width)}
    >
      {/* <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">
        Spinning. */}
      <img src={logo} className="App-logo" alt="logo" />
      {/* </p>
      <LoadingSpinner /> */}
    </div>
  );

  // return <h1>Hey some async call in progress ! </h1>;
  return (
    <div>
      {/* <button type="button" onClick={handleOpen}>
        Open Modal
      </button> */}
      <Modal
        open={loading}
        // onClose={handleClose}
        onClose={()=>{}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
        {/* <View style={myStyles.modalBackground}>
          <View style={myStyles.activityIndicatorWrapper}>
            <ActivityIndicator animating={loading} color={Colors.primary} /> */}
        {/* If you want to image set source here */}
        {/* <Image
            source={require('../assets/images/loader.gif')}
            style={{ height: 80, width: 80 }}
            resizeMode="contain"
            resizeMethod="resize"
          /> */}
        {/* </View>
        </View> */}
      </Modal>
    </div>
  );
}

export default LoadingSpinner;
