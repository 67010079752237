/**
 * boilerplate
 */

// import React from "react";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

// import Form from "react-bootstrap/Form";

import GroupsIcon from "@mui/icons-material/Groups";

import StyledDiv from "../Playground/StyledDiv";

// assets
import "../../assets/css/Recipes.css";

import UserService from "../../services/user.service";
import InventoryService from "../../services/inventory.service";
import LoadingSpinner from "../Utils/LoadingSpinner";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";
import Checkbox from "@mui/material/Checkbox";

/**
 * component definition
 */

const RecipesFromInventory = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [items, setItems] = useState([]);
  // const { message } = useSelector((state) => state.message);
  const [message, setMessage] = useState();
  const [currentIndex, setCurrentIndex] = useState(-1);

  // set default value of recipes to NULL to distinguish between
  // 'searched but no results' or 'NOT searched yet'
  const [recipes, setRecipes] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * database functions
   */

  async function getInventory() {
    return InventoryService.getInventory(currentUser)
      .then(
        (response) => {
          // console.log("response.data.totalItems ", response.data.totalItems);
          // console.log("response.data.items ", response.data.items);
          // console.log(
          //   "response.data.items[0].index ",
          //   // response.data.items[0].items[0].index
          //   response.data.items[0].index
          // );
          // console.log(response.status);
          // console.log(response.data);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // if (errorElement) {
          // errorElement.hidden = true;
          // errorElement.style.display = "none"; //"block";

          // errorElement.hidden = true;
          // errorElement.innerText = response.statusText;
          // errorElement.innerText = "";
          // errorElement.style.display = "block";
          // errorElement.style.display = "none";
          // }
          // setMessage(response.statusText);
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // setIsInError(false);
          setLoading(false);
          setMessage("Ok");
          // console.log("Ok");

          setItems((prevValue) => {
            // console.log(prevValue);
            // console.log(response.data);
            const availableItems = response.data.items.map((item, indx) => ({
              ...item,
              available: true,
            }));
            const filterVector = availableItems.map(
              (item, indx) => item.available
            );
            // console.log("filterVector ", filterVector);
            fetchRecipes(filterVector);

            // console.log(availableItems);
            // return [...prevValue, ...response.data];
            // return [...response.data.items[0].items];
            return availableItems;
          });
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...")
          // const errorElement = myRef.current;
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // console.log("error: ", error ? error : "no error");
          // console.log("_content: ", _content ? _content : "no _content");

          // if (errorElement) {
          //   errorElement.hidden = false;
          //   errorElement.innerText = "No items found. Error: " + _content;
          //   errorElement.style.display = "block";
          // }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // setIsInError(true);
          setMessage(_content);

          // Set it to an empty array, since the user exists,
          // but it has NO items
          setItems([]);
          setLoading(false);
        }
        // ).catch((err) => (console.log(err)));
      )
      .catch(() => {
        setLoading(false);
      });
  }

  async function getRecipes(filterVector) {
    return UserService.postUserFilteredRecipes(currentUser, filterVector)
      .then(
        (response) => {
          // console.log("response.data.recipes ", response.data.recipes);
          // console.log("filterVector ", filterVector);
          // console.log(response.data);
          // console.log(response.status);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          setRecipes((prevValue) => {
            // console.log(prevValue);
            // console.log(response.data);
            // return [...prevValue, ...response.data];
            return [...response.data.recipes];
          });
          setLoading(false);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          const errorElement = document.getElementById("error");
          if (errorElement) {
            errorElement.hidden = false;
            errorElement.innerText = "No recipes found. Error: " + _content;
          }
          // Set it to an empty array, since the user exists,
          // but it has NO recipes
          console.log("error ", error);
          setRecipes([]);
          setLoading(false);
        }
        // ).catch((err) => (console.log(err)));
      )
      .catch(() => {
        setLoading(false);
      });
  }

  /**
   * helper functions/services
   */

  async function fetchRecipes(filterVector) {
    // You can await here
    const response = await getRecipes(filterVector);
    // ...
    setLoading(false);
    return response;
  }

  function setAvailableItem(indx, available) {
    // console.log("items[indx], indx, available ", items[indx], indx, available);
    setLoading(true);
    items[indx].available = available;
    const filterVector = items.map((item, indx) => item.available);
    // console.log("filterVector ", filterVector);
    fetchRecipes(filterVector);
  }

  useEffect(() => {
    setLoading(true);
    async function fetchInventory() {
      // You can await here
      const response = await getInventory();
      // ...
      return response;
    }
    fetchInventory();
  }, []);

  const showRecipes = () => {
    return (
      <div>
        <div className="list row">
          {/* {recipes.length} */}
          {recipes ? (
            recipes.length === 0 ? (
              <div
                style={{ margin: "10pt", fontSize: "2rem", fontWeight: "bold" }}
              >
                We are sorry, we could not find any recipes with the ingredients
                you have at hand.
              </div>
            ) : (
              // React.Children.toArray(
              recipes.map((obj, indx) => {
                // let nextLine;
                // if (indx % 3 === 0) {
                //   nextLine = true;
                // }
                return showRecipe(obj, indx);
              })
              // )
            )
          ) : (
            <div
              style={{ margin: "10pt", fontSize: "2rem", fontWeight: "bold" }}
            >
              Please be patient, while we retrieve those recipes you can cook
              with the available ingredients.
            </div>
          )}
        </div>
      </div>
    );
  };

  const showRecipe = (obj, indx) => {
    const { recipe } = obj;
    // console.log("recipe: ", recipe);
    return (
      <div key={indx} className="col-xl-4 col-lg-6 col-md-12">
        {/* {showRecipe(recipe)} */}
        {/* <p>{indx}</p> */}
        <Link
          to={"/recipes/" + obj.id}
          // className="badge bg-warning"
        >
          {/* Edit */}
          <Card
            className="card"
            style={{
              width: "18rem",
              margin: "10px auto",
              overflow: "hidden",
            }}
          >
            {recipe.media && recipe.media.images && recipe.media.images.head ? (
              <div>
                <Card.Img
                  variant="top"
                  src={
                    "https://cdn.pollucturum.com/" + recipe.media.images.head
                  }
                />
                {/* <label>
                    <strong>Picture (server side):</strong>
                    </label>
                    {recipe.media.images.head} */}
                {/* <img
                    width={"100%"}
                    src={recipe.media.images.head}
                    alt="Image Head Recipe {recipe.name}"
                  /> */}
              </div>
            ) : (
              <div>
                <Card.Img
                  variant="top"
                  src={imageNotAvailable}
                  alt="Image Head Recipe not available yet..."
                />
                {/* <img src={imageNotAvailable} alt="Image Head Recipe" /> */}
                {/* <br />
                <p>No picture available yet...</p> */}
              </div>
            )}
            <Card.Body>
              <Card.Title>{recipe.name}</Card.Title>
              {/* <div>
                <label>
                  <strong>Name:</strong>
                </label>
                {recipe.name}
              </div> */}
              <div>
                <label>
                  <strong>Description:</strong>
                </label>
                {/* <br />
                {recipe.description} */}
              </div>
              <Card.Text>
                {recipe.description}
                {recipe.media.images.head}
                {/* Some quick example text to build
                on the card title and make up the bulk of the card's
                content. */}
              </Card.Text>
              <div>
                <label>
                  <strong>Category:</strong>
                </label>
                {recipe.category}
              </div>
              {/* {  recipe.subcategory ? ( <div>
            <label>
              <strong>Subcategory:</strong>
            </label>
            {recipe.subcategory}
              </div>) : ("")
              } */}
              <div>
                <label>
                  <strong>Created at:</strong>{" "}
                </label>
                {obj.createdAt}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>&nbsp;
                </label>
                {recipe.published ? "Published" : "Pending"}
              </div>
              <div>
                <label>
                  <strong>Serves: </strong>&nbsp;
                </label>
                <GroupsIcon /> {recipe.servings ? recipe.servings : "4"}
              </div>
              {/* <Button variant="primary" onClick={handleEditClick}>
                  Edit
                </Button>
                <Link
                  to={"/recipes/" + obj.id}
                  className="badge bg-warning"
                >
                  Edit
                </Link> */}
            </Card.Body>
          </Card>
        </Link>
        {/* <div> {obj.createdAt} </div>
              <div> {recipe.name} </div>
              <div> {recipe.description} </div>
              <div> {recipe.category} </div>
              <div> {recipe.subcategory} </div> */}
      </div>
    );
    // return (
    //   <div>
    //     {recipe.name}
    //     <br />
    //     {recipe.description}
    //     <br />
    //     {recipe.category}
    //     <br />
    //     {recipe.subcategory}
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //   </div>
    // );
  };

  /**
   * the JSX element
   */

  const ListItem = (props) => {
    const { value: item, index } = props;
    const [isChecked, setIsChecked] = useState(item.available);
    return (
      <li
        className={
          "list-group-item " + (props.index === currentIndex ? "active" : "")
        }
        // onClick={() => setAvailableItem(index, !isChecked)}
        key={index}
      >
        <Checkbox
          key={index}
          onClick={() => {
            setAvailableItem(index, !isChecked);
            setIsChecked(!isChecked);
          }}
          value={isChecked ? "on" : "off"}
          checked={isChecked}
        />
        &nbsp;
        {item.name}
      </li>
    );
  };

  return (
    <div>
      <div style={{ margin: "10pt", textAlign: "center" }}>
        <h1>Recipes matching your Inventory</h1>
        <StyledDiv>
          A selection of recipes you can cook with stuff in your pantry.
        </StyledDiv>
      </div>
      <main className="main">
        {loading && <LoadingSpinner loading={loading} />}

        <div className="list row">
          {/* First column */}

          <div className="col-smd-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
            <div className="Recipes">
              <header className="header">
                {/* <h4>Inventory</h4> */}
                {/* <StyledDiv>With these ingredients in your pantry...</StyledDiv> */}
                <h1>With these ingredients in your pantry...</h1>
              </header>
              <Accordion
                className="Accordion"
                // defaultActiveKey=""
                // flush
                // defaultActiveKey={["0"]}
                // alwaysOpen
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header
                  // className={"list-group-item "}
                  // style={{
                  //   fontWeight: "bold",
                  //   paddingLeft: "5px",
                  //   fontSize: "large",
                  // }}
                  >
                    {/* <span
                style={{
                  fontWeight: "bold",
                  paddingLeft: "5px",
                  fontSize: "large",
                }}
              > */}
                    With these ingredients in your pantry...
                    {/* </span> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    <main className="main">
                      {/* <h4>Inventory List</h4> */}
                      <ul className="list-group">
                        {items &&
                          items.map((item, index) => (
                            <ListItem
                              key={index}
                              // onClick={onClick}
                              value={item}
                              index={index}
                              checked={item.available}
                            />
                          ))}
                      </ul>
                    </main>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <footer className="footer">
                The cooking realm - pollucturum.com - Copyright 2021-
                {new Date(Date.now()).getFullYear()}
              </footer>
            </div>
          </div>

          {/* Second column */}

          <div className="col-sm-6 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
            <header className="header">
              {/* <h4>Recipes</h4> */}
              {/* <StyledDiv>You can cook these recipes.</StyledDiv> */}
              <h1>You can cook these recipes.</h1>
            </header>
            {/* <div className="list row">
              <div className="col-sm-1" style={{ border: "solid red 1px" }}>
                sm
              </div>
              <div className="col-md-2" style={{ border: "solid red 1px" }}>
                md
              </div>
              <div className="col-lg-3" style={{ border: "solid red 1px" }}>
                lg
              </div>
              <div className="col-xl-4" style={{ border: "solid red 1px" }}>
                xl
              </div>
              <div className="col-xxl-5" style={{ border: "solid red 1px" }}>
                xxl
              </div>
            </div> */}
            {/* main */}
            <main className="main">{showRecipes()}</main>
            <footer className="footer">
              The cooking realm - pollucturum.com - Copyright 2021-
              {new Date(Date.now()).getFullYear()}
            </footer>

            {/* <form name="message" method="post">
            <section>
              <label className="left">
                Name
                <input size="30" id="name" type="text" value="" name="name" />
              </label>
              <label className="right">
                Email
                <input id="email" type="text" value="" name="email" />
              </label>
            </section>

            <br style={{ clear: "both" }} />
            <br />
            <section>
              <label>
                Subject
                <input id="subject" type="text" value="" name="subject" />
              </label>
              <label>
                Message
                <input id="message" type="text" value="" name="message" />
              </label>
            </section>
          </form> */}
          </div>
        </div>
      </main>

      {/* <footer className="footer">
        The cooking realm - pollucturum.com - Copyright 2021-
        {new Date(Date.now()).getFullYear()}
      </footer> */}
    </div>
  );
};

export default RecipesFromInventory;
