import React from "react";
// import { Link } from "react-router-dom";
import server from "../../config/server.config";
import { Public as PublicIcon } from "@mui/icons-material";

const API_URL =
  server.host + (server.port === "" ? "" : ":" + server.port);

function About() {
  return (
    <div className="container">
      <PublicIcon style={{ position: "absolute", left: "0" }} />
      <h2>About</h2>
      <h3>This is the about page.</h3>
      <a className="badge bg-warning" href={`${API_URL}/index.html#pricing`}>
        Pricing
      </a>
      (Not available yet. Please accept our apologies.)
      {/* <p>
        The idea behind this app is to have in ONE place the tedious everyday
        tasks you have to accomplish but aren't pleasant.
      </p>
      <p>So here it goes: You have your</p>
      <ul>
        <li>ToDo List,</li>
        <li>Notes,</li>
        <li>a list of frequent contacts</li>
        <li>and much more.</li>
      </ul> */}
      {/* <p>
        But the most important tool is the menu planning with all its helpers.
      </p>
      <ul>
        <li>Menu Planning</li>
        <li>Shopping List</li>
        <li>Inventory</li>
        <li>Collection of Recipes with</li>
        <ul>
          <li>Step-by-step instructions and</li>
          <li>of course, ALL, but ALL the needed ingredients</li>
        </ul>
      </ul> */}
      {/* <p>
        You can plan your weekly menu or just browse recipes. Once you find a
        dish you like or a recipe you want to cook, just with{" "}
        <b>
          One-Button-Click you can add all the ingredients needed to your
          Shopping List
        </b>
        . Then, when you shopped, you can fill your inventory by just confirming
        the shopped items. And when your larder is full you can just ask the app
        to provide suggestions based on what is in your pantry.
      </p>

      <p>
        You can create your own recipes, and then share these with friends. Or
        add own much beloved ingredients.
      </p>

      <p>You can choose between hundreds or thousands of recipes.</p> */}
    </div>
  );
}

export default About;