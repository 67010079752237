import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// import { makeStyles } from "@mui/material/styles";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyIcon from "@mui/icons-material/Key";

import UserService from "../../services/user.service";
import LoadingSpinner from "../../components/Utils/LoadingSpinner";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import DisplayMessage from "../../components/MenuPlanning/DisplayMessage";
import CreateMenuEntry from "../../components/MenuPlanning/CreateMenuEntry";
import ListMenu from "../../components/MenuPlanning/ListMenu";

// import WeekPicker from "./WeekPicker/WeekPicker";
import DatePickerDay from "../../components/MenuPlanning/WeekPicker/DatePickerDay.tsx";
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import { formatDate } from "../../components/Auth/helper";
// styling
// assets
import "../../assets/css/MenuPlanning.css";
// import { MenuList } from "@mui/material";
import CalendarOverview from "../../components/MenuPlanning/CalendarOverview";
import Expire from "../../components/MenuPlanning/Expire";
import WeekPlan from "../../components/MenuPlanning/WeekPlan";

// const useStyles = makeStyles((theme) => ({
//   MenuPlanning: {
//     backgroundColor: "##c0c0c0",
//   },
//   MenuCards: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "space-between",
//     margin: "0 auto",
//     maxWidth: "90%",
//   },
//   MenuCard: {
//     color: "yellow",
//     textAlign: "center",
//     maxWidth: "20rem",
//     marginBottom: "3rem",
//     padding: "2rem",
//     borderRadius: "3px",
//     boxShadow: "0 2px 0 rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.1)",
//     backgroundColor: "#fff",
//     transition: "all 100ms ease-in-out",
//     MenuButton: {
//       color: "#f5ba13",
//       cursor: "pointer",
//     },
//   },
// }));

const weekdays = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

const options = {
  // weekday: "long",
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

const MenuPlanning = () => {
  const today = new Date();

  const { user: currentUser } = useSelector((state) => state.auth);
  const [menus, setMenus] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [message, setMessage] = useState(null);
  const [theDate, setTheDate] = useState(today);

  const initStart = startOfWeek(today, { weekStartsOn: 1 });
  const initEnd = endOfWeek(today, { weekStartsOn: 1 });

  const [start, setStart] = useState(initStart);
  const [end, setEnd] = useState(initEnd);

  const theMenus = useRef([]);

  // let recipesList = [];
  // const myRef = useRef(null);
  // const classes = useStyles();

  // should this be/become a state????
  let loading = !Array.isArray(menus);
  function setLoading(isLoading) {
    loading = isLoading;
  }

  // console.log("currentUser: ", currentUser);

  // console.log("empty: ", menus);
  // console.log("empty: ", menus ? menus.length : NaN);
  function compare(a, b) {
    // console.log("a", a.date, "b", b.date);
    const x = new Date(a.date);
    const y = new Date(b.date);
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  // closure does NOT work since the (start, end) pair is then a constant "parameter"
  // const myFilter = myComplexFilter(start, end);

  // function myComplexFilter(start, end) {
  //   function theFilter(obj, i){
  //     const x = new Date(obj.date);
  //     console.log(
  //       "start ",
  //       start,
  //       // "obj.date ",
  //       // obj.date,
  //       "end ",
  //       end,
  //       "x ",
  //       x,
  //       "isWithinInterval ",
  //       isWithinInterval(x, { start, end })
  //     );
  //     return isWithinInterval(x, { start, end });
  //     // if (isWithinInterval(x, { start, end })) {
  //     //   return true;
  //     // } else {
  //     //   return false;
  //     // }
  //     // return false;
  //     // return start <= x && x <= end;
  //   }
  //   return theFilter;
  // }

  // function myWrappedFilter(sortedMenus, start, end) {
  //   const sortedAndFilteredMenus = sortedMenus.filter(myFilter(start, end));
  // }

  function myFilter(start, end) {
    function theFilter(obj, i, a) {
      // console.log("start", start, "end", end, "obj ", obj, "i ", i, "a ", a);
      const x = new Date(new Date(obj.date).toDateString());
      // const starting = new Date(new Date(start).toDateString());
      // console.log(
      //   "myFilter() x starting isWithinInterval ",
      //   x,
      //   start,
      //   isWithinInterval(start, { start, end })
      // );
      return isWithinInterval(x, { start, end });
    }
    return theFilter;
  }

  function updateMenusToShow(start, end) {
    // console.log("updateMenusToShow() ");
    // console.log("updateMenusToShow() ", theMenus.current);
    setMenus((prevValue) => {
      const sortedMenus = [...theMenus.current];
      sortedMenus.sort(compare);
      // console.log("updateMenusToShow() sortedMenus ", sortedMenus);

      const sortedAndFilteredMenus = sortedMenus.filter(myFilter(start, end));

      // console.log(
      //   "updateMenusToShow() sortedAndFilteredMenus ",
      //   sortedAndFilteredMenus
      // );
      // console.log("updateMenusToShow() start ", formatDate(start));
      // console.log("updateMenusToShow() end ", formatDate(end));
      return sortedAndFilteredMenus;
    });
  }

  async function getMenus() {
    // console.log("getMenus(): ");
    return UserService.getUserMenus()
      .then(
        (response) => {
          // console.log("response: ", response);
          // console.log("response.data: ", response.data);
          // console.log("response.data.menus: ", response.data.menus);
          // console.log("response.statusText: ", response.statusText);
          if (
            response.data.menus.length === 0 &&
            response.statusText === "OK"
          ) {
            // console.log(
            //   "getMenus(): ",
            //   response.data.menus,
            //   response.data.menus.length
            // );
            theMenus.current = [];
            setMenus((prevValue) => []);
            setMessage(
              "Hey guys, your planning is empty. Start right now adding some dishes to your planning"
            );
          } else {
            theMenus.current = response.data.menus;
            updateMenusToShow(start, end);
            // setMenus((prevValue) => {
            //   const sortedMenus = [...response.data.menus];
            //   sortedMenus.sort(compare);
            //   const sortedAndFilteredMenus = sortedMenus.filter(myFilter);
            //   return sortedAndFilteredMenus;
            // });
            setMessage(response.statusText);
          }
          // return [...response.data.menus];
        },
        (error) => {
          const _error =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          // console.log(
          //   "error.response error.response.data: ",
          //   error.response,
          //   error.response.data
          // );
          // console.log("error.message: ", error.message);
          // console.log("error.toString(): ", error.toString());
          // console.log("error: ", _error);

          setMessage(error.toString());
          // const errorElement = document.getElementById("error");
          // const errorElement = myRef.current;
          // console.log("errorElement ", errorElement);
          // errorElement.hidden = false;
          // errorElement.innerText = _error;

          const lunch = {
            mealType: "",
            courses: [{ name: "Lunch", id: "", cookWay: "", procured: false }],
          };
          const dinner = {
            mealType: "",
            courses: [{ name: "Dinner", id: "", cookWay: "", procured: false }],
          };
          const _menus = weekdays.map((weekday, index) => ({
            date: Date.now(),
            weekday: weekday,
            lunch: lunch,
            dinner: dinner,
          }));
          // console.log("_menus: ", _menus);
          setMenus(_menus);
        }
      )
      .catch(() => {
        setLoading(false);
      });
  }

  async function getRecipes() {
    return UserService.getUserRecipes()
      .then(
        (response) => {
          // console.log(response.data.recipes);
          // console.log(response.data.recipes[0].recipe.name);
          const recipesList = response.data.recipes.map((recipeObj, indx) => {
            return { name: recipeObj.recipe.name, id: recipeObj.id };
            // console.log(recipeObj.recipe.name);
          });

          // console.log(recipesList);
          // console.log(response.status);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          setRecipes((prevValue) => {
            // console.log(prevValue);
            // console.log(response.data);
            // return [...prevValue, ...response.data];
            return [...recipesList];
          });
          setLoading(false);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          const errorElement = document.getElementById("error");
          if (errorElement) {
            errorElement.hidden = false;
            errorElement.innerText = "No recipes found. Error: " + _content;
          }
          // Set it to an empty array, since the user exists,
          // but it has NO recipes
          setRecipes([]);
          setLoading(false);
        }
        // ).catch((err) => (console.log(err)));
      )
      .catch(() => {
        setLoading(false);
      });
  }

  function handleSave(menus) {
    console.log("handleSave: ");
    // console.log("theMenus: ", theMenus.current);
    console.log("menus: ", menus);
    // addMenu already inserts the new menu, no need for this
    // const newMenus = [...theMenus.current, ...menus];

    const newMenus = [...menus];
    // console.log(newMenus);
    UserService.postUserMenus(currentUser, newMenus).then(
      (response) => {
        // console.log(response.data);
        // console.log(response.status);
        // console.log(response.statusText);
        // console.log(response.data.message);
        const _content =
          response.status +
          " " +
          response.statusText +
          " " +
          response.data.message;
        setMessage(_content);
        // console.log(response.headers);
        // console.log(response.config);
        // document.getElementById("error").hidden = false;
        // document.getElementById("error").innerText = response.data.message;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(_content);
        // const errorElement = document.getElementById("error");
        // console.log("errorElement ", errorElement);
        // errorElement.hidden = false;
        // errorElement.innerText = _content;
      }
    );
  }

  function addMenu(id, menu) {
    // console.log("addMenu: id, menu ", id, menu);
    // const toDoObject = {
    //   key: menus.length, // this is not maintaining order while messed with adding and deleting items
    //   toDo: toDoItem,
    // };
    // deleteMenu(id);
    setMenus((prevValue) => {
      const newMenus = [...prevValue, menu];
      // console.log("addMenu: menu, newMenus", menu, newMenus);
      theMenus.current = newMenus;
      handleSave(newMenus);
      return newMenus;
    });
  }

  function replaceMenu(id, menu) {
    console.log("replaceMenu: ", id, menu);
    // const toDoObject = {
    //   key: menus.length, // this is not maintaining order while messed with adding and deleting items
    //   toDo: toDoItem,
    // };
    // deleteMenu(id);
    setMenus((prevValue) => {
      const newMenus = [...prevValue];
      newMenus[id] = {
        weekday: weekdays[new Date(menu.date).getDay()],
        ...menu,
      };

      console.log("replaceMenu: ", newMenus);

      handleSave(newMenus);
      return newMenus;
    });
  }

  // console.log("menus: ", menus);
  // console.log("render");
  // console.log("loading: ", loading);
  function deleteMenu(id) {
    console.log("id: ", id);
    console.log("menus: ", menus);
    if (id !== -1) {
      setMenus((prevValue) => {
        const newMenus = prevValue.filter((menu, index) => {
          return id !== index;
        });
        console.log("newMenus: ", newMenus);
        handleSave(newMenus);
        return newMenus;
      });
    }
  }

  function weekChosen(start, end) {
    // console.log("weekChosen(): start ", start, "end ", end);
    setStart((prevValue) => start);
    setTheDate(start);
    setEnd((prevValue) => end);
    // setStart(start);
    // setEnd(end);
    updateMenusToShow(start, end);
  }

  // loading = false;
  // console.log("menus: ", menus);
  function convertDate(date) {
    const theDate = new Date(date);
    return theDate.toLocaleDateString("de-DE", options);
  }

  // const utc = Date.UTC(2022, 1, 10, 12, 0, 0);
  // const now = new Date(Date.now());

  let findByName = function (value) {
    return new Promise(function (resolve, reject) {
      // console.log("availableRecipes ", availableRecipes);

      // The producing code (this may take some time)
      const matchingRecipes = recipes.filter((recipe, indx) => {
        // console.log("recipe value ", recipe.name, value);
        return recipe.name.toLowerCase().includes(value.toLowerCase());
      });
      // console.log("matchingRecipes ", matchingRecipes);

      if (matchingRecipes.length > 0) {
        // resolve({ response: { data: { matchingRecipes } } });
        // console.log("response ", { response: { data: { matchingRecipes } } });
        resolve({ data: { matchingRecipes } });
        // console.log("data ", { data: { matchingRecipes } } );
      } else {
        reject([]);
      }
    });
  };

  useEffect(() => {
    async function fetchMenus() {
      // You can await here
      const response = await getMenus();
      // ...
      return response;
    }
    async function fetchRecipes() {
      // You can await here
      const response = await getRecipes();
      // ...
      return response;
    }
    if (!loading) {
      setLoading(true);
      // console.log("useEffect");
      // setTimeout(() =>
      // , 5000)

      fetchMenus();
      fetchRecipes();
    }
  }, []);

  useEffect(() => {
    const weekday = new Date(theDate).getDay();
    // console.log("weekday theDate ", weekday, theDate);

    // creating a new object, if not theDate will be modified DANGER
    // var date = new Date(theDate);
    // const start = new Date(date.setDate(date.getDate() - weekday + 1));
    // date = new Date(theDate);
    // const end = new Date(date.setDate(date.getDate() + 7 - weekday));

    const start = startOfWeek(theDate, { weekStartsOn: 1 });
    const end = endOfWeek(theDate, { weekStartsOn: 1 });
    // console.log("start ", start);
    // console.log("end ", end);

    setStart((prevValue) => start);
    setEnd((prevValue) => end);

    updateMenusToShow(start, end);
  }, [theDate]);

  // console.log("loading: ", loading);
  if (loading) {
    // console.log("go spinning");
    return (
      <div className="loader">
        Loading ... <LoadingSpinner loading={loading} />
      </div>
    );
  }

  if (!loading && menus) {
    return (
      // <div className="container" style={{border: "2px solid red"}}>
      <div className="MenuPlanning" style={{ paddingBottom: "20px" }}>
        <KeyIcon />
        <header className="jumbotron mb-5" style={{ borderRadius: "10px" }}>
          <WeekPlan
            menus={menus}
            theDate={theDate}
            setTheDate={setTheDate}
            start={start}
            end={end}
          />
        </header>

        {/* <header className="jumbotron mb-5" style={{ borderRadius: "10px" }}>
          <h3>{currentUser.username}, your Weekly Menu Planning</h3> */}

          {/* <h3>{menus}</h3> */}
          {/* {menus} */}
          {/* {menus.map((obj, index) => {
          return <div key={index}>{obj.title}</div>;
        })} */}
          {/* {menus.length > 0
              ? "Hola " +
                now.toLocaleDateString("de-DE", options) +
                " ddfs " +
                utc
              : ""} */}
          {/* {console.log(menus[0].lunch[0].name)} */}
          {/* {console.log("menus.length: ", menus.length)} */}
          {/* .toLocaleDateString('de-DE', options)
            .toLocaleDateString('de-DE', options) */}
          {/* <h3>
            {menus.length > 0
              ? ` Week from ${convertDate(menus[0].date)} to ${convertDate(
                  menus[menus.length - 1].date
                )}`
              : ""}
          </h3> */}
          {/* <h5> */}
            {/* <div style={{ float: "left", marginTop: "-20pt" }}> */}
            {/* <div className="row">
              <div className="col-md-6">
                {start !== end
                  ? ` Week from ${convertDate(start)} to ${convertDate(end)}`
                  : ""}
              </div>
              <div
                className="col-md-6"
                style={{ textAlign: "center", padding: "0", margin: "0" }}
              >
                <DatePickerDay
                  style={{ margin: "0 auto" }}
                  weekChosen={weekChosen}
                />
              </div>
            </div>
          </h5>
        </header> */}
        {/* <WeekPicker /> */}
        {/* {message}
        <DisplayMessage message={message} setMessage={setMessage}>
          {message}
        </DisplayMessage> */}
        {/* <Expire delay="5000">
          <DisplayMessage message={message} setMessage={setMessage}>
            {message}
          </DisplayMessage>
        </Expire> */}
        {/* {console.log("recipes ", recipes)} */}
        {/* {console.log("menus ", menus)} */}
        {/* <CreateMenuEntry
          menus={menus}
          findByName={findByName}
          // availableRecipes={recipes}
          onAdd={addMenu}
          onReplace={replaceMenu}
          theDate={theDate}
        /> */}
        {/* {console.log("menus ", menus)} */}
        <CalendarOverview
          user={currentUser}
          setTheDate={setTheDate}
          theDate={theDate}
          findByName={findByName}
          menus={[...theMenus.current]}
          onAdd={addMenu}
          onDelete={deleteMenu}
          onReplace={replaceMenu}
          convertDate={convertDate}
        />

        <ListMenu
          // tremendous hack to force the parent
          // to update the child component
          findByName={findByName}
          user={currentUser}
          key={Date.now()}
          menus={menus}
          onDelete={deleteMenu}
          replaceMenu={replaceMenu}
          convertDate={convertDate}
        />
      </div>
    );
  }
  return null;
};

export default MenuPlanning;
