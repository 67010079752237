import React, { useState, useEffect } from "react";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const GrowingListItem = (props) => {
  const { indx, value, stepLabel } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [item, setItem] = useState(value ? value : "");

  const handleClick = () => {
    setIsDisabled(!isDisabled);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log(
    //   "name value",
    //   name,
    //   value
    // );
    setItem(value);
  };

  const handleDeleteClick = () => {
    // console.log("delete(indx) ", indx);
    props.onDelete(indx);
  };

  const onSave = () => {
    props.onSave(indx, item);
    handleClick();
  };

  // useEffect(() => {
  //   setItem(props.instructions[indx]);
  //   console.log("props.instructions ", indx, props.instructions[indx]);
  // }, [props.instructions]);

  return (
    <div
      key={indx}
      className="GrowingListItem"
      // style={{
      //   backgroundColor: "#fafafa",
      //   borderRadius: "5pt",
      //   padding: "0 0 0 5pt",
      //   margin: "2pt",
      //   // border: "green 1pt solid",
      // }}
    >
      {/* {console.log("props.instructions ", props.instructions)} */}
      <div style={{ float: "left", width: "15%" }}>
        {stepLabel} {indx + 1}: &nbsp;
      </div>
      {/* <div style={{ float: "left" }}>
        Mood: {mood} Instructions {props.instructions}
      </div> */}
      <div style={{ float: "left", margin: "2pt", width: "80%" }}>
        <textarea
          // <input
          disabled={isDisabled}
          // style={{ backGroundColor: "grey" }}
          type="text"
          className="form-control"
          id="item"
          name="item"
          value={item}
          onChange={handleInputChange}
        />
      </div>
      {isDisabled ? (
        <div>
          <div style={{ float: "left" }}>
            <EditIcon onClick={handleClick} />
          </div>
          <div style={{ float: "left" }}>
            <DeleteOutlineIcon onClick={handleDeleteClick} />
          </div>
        </div>
      ) : (
        <div>
          <div style={{ float: "left" }}>
            <SaveIcon onClick={onSave} />
          </div>
          <div style={{ float: "left" }}>
            <ClearIcon onClick={handleClick} />
          </div>
        </div>
      )}
      <div style={{ clear: "both" }}></div>
    </div>
  );
};
export default GrowingListItem;
