import { useState } from "react";

function SizeChooser(props) {
  const { 
    // setProducts, products, 
    id, sizes, handleSetSizes } = props;
  const [size, setSize] = useState(props.size);

  function handleChooseSize(id, size) {
    handleSetSizes(id, size);
    setSize(size);
  }

  return (
    <div>
      <div>
        {sizes.map((size, indx) => {
          return (
            <button
              key={indx}
              className="badge bg-warning mr-2"
              onClick={() => handleChooseSize(id, size)}
              style={{ color: "#0d6efd", marginLeft: "10px" }}
            >
              {size}
            </button>
          );
        })}
      </div>
      <div>
        <label>
          <strong>Size:&nbsp;</strong>
        </label>
        {size}
      </div>
    </div>
  );
}

export default SizeChooser;
