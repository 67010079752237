// Rounded switch
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { useState } from "react";

import "../assets/css/switch.css";

export default function ThemeSwitcher({ toggleTheme, choosenTheme }) {
  // , overflow: "auto"
  // const [isSet, setIsSet] = useState(choosenTheme === "dark");

  // function handleOnClick(event) {
  function handleOnClick() {
    // event.preventDefault();
    // console.log(event.target.checked, event.target.value);
    // console.log(event.target);
    // setIsSet(!isSet);
    toggleTheme();
  }
  // console.log(isSet, choosenTheme);
  return (
    <div style={{ width: "60px" }}>
      <label className="switch">
        <input
          type="checkbox"
          name="switch"
          id="switch"
          // value={choosenTheme !== "dark"}
          onClick={handleOnClick}
          defaultChecked={choosenTheme !== "dark"}
        />
        <span
          className="slider round"
          // onClick={() => alert("changed")}
          // onClick={handleOnClick}
        >
          <DarkModeIcon style={{ marginLeft: "2px", marginTop: "4px" }} />
          <LightModeIcon
            style={{ color: "#eef51b", marginLeft: "32px", marginTop: "-52px" }}
          />
        </span>
      </label>
    </div>
  );
}
