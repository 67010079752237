// esversion:11
// 
// this modal is based of the following medium article
// https://medium.com/tinyso/how-to-create-a-modal-component-in-react-from-basic-to-advanced-a3357a2a716a
//
// maybe have a look at to contrast
// https://dev.to/franciscomendes10866/how-to-create-a-modal-in-react-3coc
// 
import React, { lazy, Suspense, useEffect, useState, forwardRef } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";


// assets
import "./Modal.css";

// import TheForm from "../Ingredients/IngredientForm";

// import IngredientForm from "../Ingredients/IngredientForm";
// const TheForm = IngredientForm;

// import * as whatForm from "../Ingredients/IngredientForm";
// const { TheForm } = whatForm;

// const importView = (formName) =>
//   (
//     import(`../Ingredients/${formName}`).catch(() =>
//       import(`../Ingredients/NullForm`)
//     )
//   );

const Modal = forwardRef((props, ref) => {
  const [theTitle, setTheTitle] = useState(props.title);

  const { theForm : formName  } = props;
  let formFolder;
  // // var lazyComponentName = "MyLazy";
  if (formName === "IngredientForm") {
    formFolder = "Ingredients";
  } else {
    formFolder = "Recipes";
  }
  const TheForm = React.lazy(() => import("../" + formFolder + "/" + formName));
  // console.log(theForm);
  // console.log(formName);
  // // const TheForm = importView(formName);

  // function resetIngredient() {
  //   setIngredient(emptyIngredient);
  // }

  //(props) => {

  // let theTitle = props.title;
  // console.log("props.title", props.title);
  // console.log("theTitle", theTitle);
  
  const updateTitle = (title) => setTheTitle(title);
  React.useImperativeHandle(ref, () => ({
    updateTitle,
  }));

  const { onClose } = props;

  useEffect(() => {
    const closeOnEscapeKeyDown = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        onClose();
      }
    };
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [onClose]);

  const rootElement = document.getElementById("root");
  // console.log("rootElement ", rootElement);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">{theTitle}</h4>
          </div>
          <div className="modal-body">
            {props.children}
            <Suspense fallback={<div>Loading...</div>}>
              <TheForm onSubmit={props.onSubmit} onClose={props.onClose} />
            </Suspense>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </CSSTransition>,
    rootElement
  );
});

export default Modal;
