import ___DEBUG___ from "./debug.config";

// const __DEBUG__ = true && ___DEBUG___;
// const __DEBUG__ = false && ___DEBUG___;
const __DEBUG__ = ___DEBUG___;

const frontend = process.env.REACT_APP_PUBLIC_URL
    ? process.env.REACT_APP_PUBLIC_URL
    : // "http://192.168.1.20/",
      "https://app.pollucturum.com";

export default frontend;
