import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import Dropdown from "../ShoppingList/Dropdown";

import CookwareDataService from "../../services/cookware.service";
import CategoryDataService from "../../services/category.service";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";

import "./Cookware.css";
const ensembles = ["Kitchenware", "Measure"];

/**
 * component definition
 */

const Cookware = (props) => {
  const [categoryGroups, setCategoryGroups] = useState({});
  const [options, setOptions] = useState([
    [],
    [],
  ]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isModerator, setIsModerator] = useState(false);

  useEffect(() => {
    const isModerator = currentUser?.roles.includes("ROLE_MODERATOR");
    setIsModerator(isModerator);
    setEditMode(isModerator);
    // console.log(currentUser);
  }, []);

  // const display = "display";
  // const {display} = props;

  const { id } = useParams();
  let navigate = useNavigate();

  const initialCookware = {
    name: "",
    description: "",
    presentation: "",
    category: "",
    units: "",
    quantity: "",
    picture: "",
    published: false,
  };

  const initialCookwareState = {
    id: null,
    cookware: initialCookware,
  };

  const [editMode, setEditMode] = useState(false);
  const [currentCookware, setCurrentCookware] = useState(initialCookwareState);
  const [message, setMessage] = useState("");

  const getCookware = (id) => {
    CookwareDataService.get(id)
      .then((response) => {
        setCurrentCookware(response.data);
        // console.log("currentCookware ", response.data);
      })
      .catch((e) => {
        console.log(e);
        setMessage(e.message);
      });
  };

  useEffect(() => {
    if (id) getCookware(id);
  }, [id]);

  useEffect(() => {
    ensembles.map((ensemble, indx) => retrieveCategoryGroup(ensemble, indx));
    // const categoryGroups = ensembles.map((ensemble) =>
    //   retrieveCategoryGroup(ensemble)
    // );
    // console.log("categoryGroups ", categoryGroups);
  }, []);

  const retrieveCategoryGroup = (ensemble, indx) => {
    CategoryDataService.getAll({ name: "", ensemble: ensemble })
      .then((response) => {
        // var categoryGroup;
        // categoryGroup[indx]=response.data.categoryGroup;
        // console.log("categoryGroup[indx] ",indx, categoryGroup[indx]);
        // console.log(
        //   "response.data.categoryGroup ",
        //   response.data.categoryGroup
        // );
        // console.log("ensemble ", ensemble);
        // console.log("categoryGroup ", categoryGroup);
        setCategoryGroups((prevCategoryGroups) => {
          const newCategoryGroups = {
            ...prevCategoryGroups,
            [ensembles[indx]]: response.data.categoryGroup,
          };
          // console.log("newCategoryGroups ", indx, newCategoryGroups);
          setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[indx] = response.data.categoryGroup
              ? response.data.categoryGroup.categories.map((category) => ({
                  label: category.name,
                  value: category.name,
                }))
              : [{ label: "pcs", value: "pcs" }];
            // console.log("newOptions ", indx, newOptions);
            return newOptions;
          });
          return newCategoryGroups;
        });

        // also possible to write it as array construct
        //
        // setCategoryGroups((prevCategoryGroups) => {
        //   const a = [...prevCategoryGroups];
        //   a[indx] = response.data.categoryGroup;
        //   return a;
        // });

        // console.log(response);
        // return response.data.categoryGroup;
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let cookware = {};
    if (name === "externalLinks" || name === "externalLinksProvider") {
      const externalLinks = [];
      externalLinks[0] = { ...currentCookware.cookware.externalLinks[0] };
      // console.log("name, value ", name, value);

      if (name === "externalLinks") {
        externalLinks[0]["link"] = value;
      } else if (name === "externalLinksProvider") {
        externalLinks[0]["provider"] = value;
      }
      cookware = { ...currentCookware.cookware, externalLinks: externalLinks };
    } else if (name === "media-images-head") {
      // console.log("cookware ", cookware);
      const media = {};
      media.images = {};
      media.images.head = value;
      cookware = { ...currentCookware.cookware, media: media };
    } else {
      cookware = { ...currentCookware.cookware, [name]: value };
    }
    // console.log("cookware ", cookware);
    setCurrentCookware({ ...currentCookware, cookware: cookware });
  };

  const updatePublished = (status) => {
    var cookware = {
      id: currentCookware.id,
      name: currentCookware.cookware.name,
      description: currentCookware.cookware.description,
      published: status,
    };
    var data = {
      cookware: cookware,
    };
    CookwareDataService.update(currentCookware.id, data)
      .then((response) => {
        setCurrentCookware({ ...currentCookware, cookware: cookware });
        // console.log("currentCookware ", response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateCookware = () => {
    CookwareDataService.update(currentCookware.id, currentCookware)
      .then((response) => {
        // console.log(response.data);
        setMessage("The cookware was updated successfully!");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleBackClick = () => {
    navigate("/cookware");
  };

  const deleteCookware = () => {
    CookwareDataService.remove(currentCookware.id)
      .then((response) => {
        // console.log(response.data);
        navigate("/cookware");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleGoToCookware = () => {
    navigate("/cookware");
  };

  const handleChangeEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <div>
      {currentCookware ? (
        <div style={{ margin: "10px" }}>
          {editMode ? (
            <div className="edit-form">
              <h4>Cookware</h4>
              <form>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={currentCookware.cookware.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    name="description"
                    value={currentCookware.cookware.description}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="presentation">Presentation</label>
                  <input
                    type="text"
                    className="form-control"
                    id="presentation"
                    name="presentation"
                    value={currentCookware.cookware.presentation}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="row">
                  <div className="col">
                    {/* <div className="form-group">
                  <label htmlFor="category">Category</label>
                  <input
                    type="text"
                    className="form-control"
                    id="category"
                    name="category"
                    value={currentCookware.cookware.category}
                    onChange={handleInputChange}
                  />
                </div> */}
                    <div className="form-group">
                      {/* <label htmlFor="category">Category</label>
                    <input
                      name="category"
                      type="text"
                      className="form-control"
                      id="category"
                      required
                      value={cookware.category}
                      onChange={handleInputChange}
                    /> */}
                      <Dropdown
                        myStyle={{
                          margin: "15pt 0pt 0pt 0pt",
                          // float: "left",
                          // maxWidth: "60pt",
                          width: "100%",
                        }}
                        label="Category"
                        options={options[0]}
                        // options={categoryGroups.Cookware.categories}
                        value={currentCookware.cookware.category}
                        className="form-control"
                        id="category"
                        required
                        onChange={handleInputChange}
                        name="category"
                      />
                    </div>
                    <div className="form-group">
                      <Dropdown
                        myStyle={{
                          margin: "15pt 0pt 0pt 0pt",
                          // float: "left",
                          // maxWidth: "60pt",
                          width: "100%",
                        }}
                        label="Units"
                        // options={options}
                        options={options[1]}
                        value={currentCookware.cookware.qtyUnit}
                        className="form-control"
                        id="qtyUnit"
                        required
                        onChange={handleInputChange}
                        name="qtyUnit"
                      />
                      <br />
                    </div>
                    {/* <div className="form-group">
                  <label htmlFor="qtyUnit">Units</label>
                  <input
                    type="text"
                    className="form-control"
                    id="qtyUnit"
                    name="qtyUnit"
                    value={currentCookware.cookware.qtyUnit}
                    onChange={handleInputChange}
                  />
                </div> */}
                    <div className="form-group">
                      <label htmlFor="quantity">Quantity</label>
                      <input
                        type="text"
                        className="form-control"
                        id="quantity"
                        name="quantity"
                        value={currentCookware.cookware.quantity}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="externalLinksProvider">
                        External Links Provider
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="externalLinksProvider"
                        name="externalLinksProvider"
                        value={
                          currentCookware.cookware.externalLinks &&
                          currentCookware.cookware.externalLinks.length > 0 &&
                          currentCookware.cookware.externalLinks[0].provider
                            ? currentCookware.cookware.externalLinks[0].provider
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                      <label htmlFor="externalLinks">External Links</label>
                      <input
                        type="text"
                        className="form-control"
                        id="externalLinks"
                        name="externalLinks"
                        value={
                          currentCookware.cookware.externalLinks &&
                          currentCookware.cookware.externalLinks.length > 0 &&
                          currentCookware.cookware.externalLinks[0].link
                            ? currentCookware.cookware.externalLinks[0].link
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      {currentCookware.cookware.published ? (
                        <button
                          className="badge bg-primary mr-2"
                          onClick={() => updatePublished(false)}
                        >
                          UnPublish
                        </button>
                      ) : (
                        <button
                          className="badge bg-primary mr-2"
                          onClick={() => updatePublished(true)}
                        >
                          Publish
                        </button>
                      )}
                      <label>
                        <strong>&nbsp; Status: &nbsp;</strong>
                      </label>
                      {currentCookware.cookware.published
                        ? "Published"
                        : "Pending"}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <label>
                        <strong>Picture:</strong>&nbsp;
                      </label>
                      {currentCookware.cookware.media &&
                      currentCookware.cookware.media.images &&
                      currentCookware.cookware.media.images.head ? (
                        <div>
                          <img
                            width={"100%"}
                            src={currentCookware.cookware.media.images.head}
                            alt={`Image Head category ${currentCookware.cookware.name}`}
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            width={"100%"}
                            src={imageNotAvailable}
                            alt="Image Head cookware"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="media-images-head">Picture</label>
                  <input
                    type="text"
                    className="form-control"
                    id="media-images-head"
                    name="media-images-head"
                    value={
                      currentCookware.cookware.media &&
                      currentCookware.cookware.media.images &&
                      currentCookware.cookware.media.images.head
                        ? currentCookware.cookware.media.images.head
                        : ""
                    }
                    onChange={handleInputChange}
                  />
                </div>
              </form>
              <button
                className="badge bg-primary mr-2"
                onClick={handleBackClick}
              >
                Back to cookware listing
              </button>
              <button className="badge bg-danger mr-2" onClick={deleteCookware}>
                Delete
              </button>
              <button
                type="submit"
                className="badge bg-success"
                onClick={updateCookware}
              >
                Update
              </button>
              <button className="badge bg-info" onClick={handleChangeEditMode}>
                Display
              </button>
              <p>{message}</p>
            </div>
          ) : (
            <div>
              {/* <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src="holder.js/100px180" />
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>  */}

              {/* <Card style={{ width: "18rem" }}>  */}
              <Card className="card" style={{ width: "18rem" }}>
                {currentCookware.cookware.media &&
                currentCookware.cookware.media.images &&
                currentCookware.cookware.media.images.head ? (
                  <div>
                    <Card.Img
                      variant="top"
                      src={currentCookware.cookware.media.images.head}
                    />
                    {/* <label>
                    <strong>Picture (server side):</strong>
                  </label>
                  {currentCookware.cookware.media.images.head} */}
                    {/* <img
                    width={"100%"}
                    src={currentCookware.cookware.media.images.head}
                    alt="Image Head Cookware {currentCookware.cookware.name}"
                  /> */}
                  </div>
                ) : (
                  <div>
                    <Card.Img variant="top" src={imageNotAvailable} />
                    {/* <img src={imageNotAvailable} alt="Image Head Cookware" /> */}
                    {/* <br />
                  <p>No picture available yet...</p> */}
                  </div>
                )}
                <Card.Body>
                  <Card.Title>{currentCookware.cookware.name}</Card.Title>
                  <div>
                    {/* <label>
                      <strong>Name:</strong>
                    </label> */}
                  </div>
                  <div>
                    <label>
                      <strong>Description:</strong>
                    </label>
                  </div>
                  <Card.Text>
                    {currentCookware.cookware.description}
                    {/* Some quick example text to build on the card title and make up
                  the bulk of the card's content. */}
                  </Card.Text>
                  <div>
                    <label>
                      <strong>Presentation:</strong>
                    </label>
                  </div>
                  <Card.Text>{currentCookware.cookware.presentation}</Card.Text>
                  <div>
                    <label>
                      <strong>Category:</strong>
                    </label>
                  </div>
                  <Card.Text>{currentCookware.cookware.category}</Card.Text>
                  <div>
                    <label>
                      <strong>Units:</strong>
                    </label>
                  </div>
                  <Card.Text>{currentCookware.cookware.qtyUnit}</Card.Text>
                  <div>
                    <label>
                      <strong>Quantity:</strong>
                    </label>
                  </div>
                  <Card.Text>{currentCookware.cookware.quantity}</Card.Text>
                  <div>
                    <label>
                      <strong>External Links:</strong>&nbsp;
                    </label>
                    <ul>
                      {currentCookware &&
                        currentCookware.cookware &&
                        currentCookware.cookware.externalLinks &&
                        currentCookware.cookware.externalLinks.map(
                          (link, index) => {
                            return (
                              <li key={index}>
                                {link.provider}
                                <a href={link.link}>{link.link}</a>
                              </li>
                            );
                          }
                        )}
                    </ul>
                  </div>
                  <div>
                    <label>
                      <strong>Status:</strong>&nbsp;
                    </label>
                    {currentCookware.cookware.published
                      ? "Published"
                      : "Pending"}
                  </div>
                  <div>
                    <label>
                      <strong>ID:</strong>&nbsp;
                    </label>
                    {currentCookware.cookware.id}
                  </div>
                  <Button variant="primary" onClick={handleGoToCookware}>
                    Cookware
                  </Button>
                  {/* <Link to={"/cookware"} className="badge bg-warning">
                    Cookware
                  </Link> */}
                  {/* <Link to={"/cookware"} className="badge bg-info">
                    Edit
                  </Link> */}
                  {isModerator ? (
                    <Button variant="info" onClick={handleChangeEditMode}>
                      Edit
                    </Button>
                  ) : null}
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a Cookware...</p>
        </div>
      )}
    </div>
  );
};
export default Cookware;
