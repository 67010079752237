/**
 * This helped a lot
 * especially styling
 * https://www.bezkoder.com/react-node-express-mongodb-mern-stack/
 */

import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import KeyIcon from "@mui/icons-material/Key";

// import UserService from "../../services/user.service";
import RecipeDataService from "../../services/recipe.service";
import "./Recipes.css";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";
import server from "../../config/server.config";
const imageServerURL = server.cdn;

// const imageServerURL = process.env.REACT_APP_IMAGE_SERVER_URL
//   ? process.env.REACT_APP_IMAGE_SERVER_URL
//   : "http://192.168.1.10:4000/";

function addDefaultSrc(e) {
  e.target.src = imageNotAvailable;
}


/**
 * component definition
 */

const RecipesList = () => {
  const [recipes, setRecipes] = useState([]);
  const [currentRecipe, setCurrentRecipe] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    retrieveRecipes();
  }, []);

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const retrieveRecipes = () => {

    RecipeDataService.getAll({ name: searchName })
      .then((response) => {
        setRecipes(response.data.recipes);
        // console.log(response.data.recipes);
      })
      .catch((e) => {
        console.log("retrieveRecipes: ", e);
      });
  };

  const refreshList = () => {
    retrieveRecipes();
    setCurrentRecipe(null);
    setCurrentIndex(-1);
  };

  const setActiveRecipe = (recipe, index) => {
    // console.log("recipe ", recipe);
    // console.log("index ", index);
    setCurrentRecipe(recipe);
    setCurrentIndex(index);
  };

  const removeAllRecipes = () => {
    RecipeDataService.removeAll()
      .then((response) => {
        console.log(response.data.recipes);
        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const findByName = () => {
    RecipeDataService.findByName(searchName)
      .then((response) => {
        setRecipes(response.data.recipes);
        console.log(response.data.recipes);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="container">
      <KeyIcon style={{ position: "absolute", left: "0" }} />
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name"
              value={searchName}
              onChange={onChangeSearchName}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={findByName}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4>Recipes List</h4>
          <ul className="list-group">
            {recipes &&
              recipes.map((recipe, index) => (
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => setActiveRecipe(recipe, index)}
                  key={index}
                >
                  {recipe.recipe.name}
                </li>
              ))}
          </ul>
          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={removeAllRecipes}
          >
            Remove All
          </button>

          <button className="m-3 btn btn-sm btn-danger">
            <Link to={"/recipes/+"} className="nav-link">
              Add
            </Link>
          </button>
        </div>
        <div className="col-md-6">
          {currentRecipe ? (
            <div>
              <h4>Recipe</h4>
              {currentRecipe.recipe.media &&
              currentRecipe.recipe.media.images &&
              currentRecipe.recipe.media.images.head ? (
                <div>
                  <img
                    style={{ maxWidth: "80%", maxHeight: "350px" }}
                    variant="top"
                    onError={addDefaultSrc}
                    alt="Image Head Recipe {currentRecipe.recipe.name}"
                    src={
                      imageServerURL + currentRecipe.recipe.media.images.head
                    }
                  />
                  <label>
                    <strong>Picture (server side):</strong>
                  </label>
                  {currentRecipe.recipe.media.images.head}
                  {/* <img
                    width={"100%"}
                    src={currentRecipe.recipe.media.images.head}
                    alt="Image Head Recipe {currentRecipe.recipe.name}"
                  /> */}
                </div>
              ) : (
                <div>
                  <img
                    style={{ maxWidth: "80%", maxHeight: "350px" }}
                    variant="top"
                    src={imageNotAvailable}
                    alt="Image Head Recipe {currentRecipe.recipe.name}"
                  />
                  {/* <img src={imageNotAvailable} alt="Image Head Recipe" /> */}
                  <br />
                  <p>No picture available yet...</p>
                </div>
              )}
              <div>
                <label>
                  <strong>Name:</strong>&nbsp;
                </label>
                {currentRecipe.recipe.name}
              </div>
              <div>
                <label>
                  <strong>Description:</strong>&nbsp;
                </label>
                {currentRecipe.recipe.description}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>&nbsp;
                </label>
                {currentRecipe.recipe.published ? "Published" : "Pending"}
              </div>
              <div>
                <label>
                  <strong>Serves:</strong>&nbsp;
                </label>
                {currentRecipe.recipe.servings
                  ? currentRecipe.recipe.servings
                  : "4"}
              </div>
              {/* {console.log("currentRecipe ", currentRecipe)} */}
              <Link
                to={"/recipes/" + currentRecipe.id}
                className="badge bg-info"
              >
                Show
              </Link>
              <Link
                to={"/recipes/" + currentRecipe.id}
                className="badge bg-warning"
              >
                Edit
              </Link>
            </div>
          ) : (
            <div>
              <br />
              <p>Please click on a Recipe...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default RecipesList;