// import { createStore, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from "redux-thunk";

import rootReducer from "./index";

const middleware = [thunk];

const store = configureStore(
  {reducer: rootReducer},
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
