export const darkTheme = {
  name: "dark",
  backgroundColor: "#0d0d30",
  color: "white",
  form: { backgroundColor: "#381ae2" },
  // "#0d0d30",

  container: {
    backgroundColor: "#0d0d30",
    opacity: 1,
    color: "white",
  },
  button: {
    backgroundColor: "#4e0eff",
    // "#b12f2f34",
    // "##997af0",
    color: "white",
    hoverColor: "#997af0;",
  },
  palette: { palette: [{ grey: "grey" }], grey: "grey" },
};

export const ligthtTheme = {
  name: "light",
  backgroundColor: "#26a7ff",
  color: "#0d0d30",
  form: {
    backgroundColor: "#4e0eff",
  },
  container: {
    backgroundColor: "#26a7ff",
    opacity: 1,
    color: "#0d0d30",
  },
  button: {
    backgroundColor: "#9a86f3",
    color: "#0d0d30",
    hoverColor: "#4e0eff",
  },
  palette:{palette :[{ grey: "grey" }],
  grey: "grey",}
};
// #381ae2
// #4e0eff;
