import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import authHeader from "../../services/auth-header";

import "./Payments.css";

import imageNotAvailable from "../../assets/img/image-not-available.jpg";
import server from "../../config/server.config";

const BACKEND_URL = server.host + (server.port === "" ? "" : ":" + server.port);

const public_stripe_key = process.env.REACT_APP_PUBLIC_STRIPE_KEY;

// const imageServerURL = server.cdn;
const imageServerURL = BACKEND_URL + "/assets/images/";


function addDefaultSrc(e) {
  e.target.src = imageNotAvailable;
}

// const order = [{ item: "Online Video Editor", qty: "3", itemCode: "99" }];

const SubscriptionPage = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  // console.log("currentUser.id: ", currentUser.id);
  // console.log("authHeader(): ", authHeader());
  const handleSubscription = async () => {
    const stripePromise = await loadStripe(public_stripe_key);
    const header1 = { "Content-Type": "Application/JSON" };
    const header2 = authHeader();
    const headers = { ...header1, ...header2 };
    // headers:
    // console.log("headers: ", headers);
    if (order) {
      try {
        const response = await fetch(
          BACKEND_URL + "/api/payments/create-stripe-session-subscription",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(order),
          }
        );
        // console.log("response ", response);
        if (response.status === 409) {
          const data = await response.json();
          if (data && data.redirectUrl) {
            window.location.href = data.redirectUrl; // redirect to billing portal if user is already subscribed
          }
        } else {
          const session = await response.json();
          stripePromise.redirectToCheckout({
            sessionId: session.id,
          });
        }
      } catch (error) {
        console.log("error ", error);
      }
    }
  };

  const { id } = useParams();
  const location = useLocation();
  // console.log(location);

  const order = location.state;

  let totalAmout = 0;
  if (order && order.length > 0) {
    // console.log(order);
    for (var item of order) {
      totalAmout += item.fee * item.qty;
    }
  }

  return (
    <div className="App">
      <div>{location.state ? location.state.name : null}</div>
      {id ? `Here is the: ${id} of this page.` : null}
      <div className="avatar">
        <img
          width={"50pt"}
          height={"50pt"}
          src={`data:image/svg+xml;base64,${currentUser.avatarImage}`}
          alt="avatar"
        />
        <div className="onlineStatus"></div>
        <div className="">
          Hello {currentUser.fullname}.
          {/* Today we hava a special offer for you. */}
        </div>
      </div>
      <div
      // style={{
      //   margin: "30px",
      //   borderWidth: "3px 9px 9px 9px",
      //   borderStyle: "solid",
      //   borderColor: "#FF6633",
      //   height: "100px",
      //   borderRadius: "10px",
      // }}
      >
        {order && order.length > 0 ? (
          <div>
            <div className="list row">
              {order?.map((item, indx) => {
                return (
                  <div
                    id={"order-" + indx}
                    key={indx}
                    className="col-xl-12 col-lg-12 col-md-12"
                  >
                    <div
                    // style={{
                    //   margin: "30px",
                    //   padding: "10px",
                    //   borderWidth: "3px 9px 9px 9px",
                    //   borderStyle: "solid",
                    //   borderColor: "#FF6633",
                    //   // height: "100px",
                    //   borderRadius: "10px",
                    // }}
                    >
                      <Card
                        className="card"
                        style={{ width: "20rem", margin: "10px auto" }}
                      >
                        {true ? (
                          <div className="shrinkToFitImage">
                            <Card.Img
                              variant="top"
                              // src={
                              //   "http://localhost:4000/assets/images/camiseta-blanca.jpg"
                              // }
                              src={imageServerURL + item.image}
                              onError={addDefaultSrc}
                            />
                            {/* <label>
                                <strong>Picture (server side):</strong>
                                </label>
                                {recipe.media.images.head} */}
                            {/* <img
                                width={"100%"}
                                src={recipe.media.images.head}
                                alt="Image Head Recipe {recipe.name}"
                              /> */}
                          </div>
                        ) : (
                          <div>
                            <Card.Img
                              variant="top"
                              style={{ maxHeight: "250px" }}
                              src={imageNotAvailable}
                              alt="Image Head Recipe not  available yet..."
                            />
                            {/* <img src={imageNotAvailable} alt="Image Head Recipe" /> */}
                            {/* <br />
                            <p>No picture available yet...</p> */}
                          </div>
                        )}
                        <Card.Title
                          style={{ textAlign: "center", fontSize: "3rem" }}
                        >
                          {item.name}
                        </Card.Title>
                        <Card.Body>
                          {/* <p>Index: {indx}</p>
                          <p>Id: {item.id}</p>
                          <p>Name: {item.name}</p>
                          <p>Image: {item.image}</p>
                          <p>Description: </p> */}
                          {/* <p>Fee: {item.fee}</p>
                          <p>Billing Frequency: {item.frequency}</p>
                          <p>Recurrent: {item.recurrent}</p> */}
                          {/* {item.description}</p> */}
                          {/* <ul>
                            {item.description.map((item, indx) => {
                              return (
                                <li key={indx}>
                                  <div>{item}</div>
                                </li>
                              );
                            })}
                          </ul> */}
                          {/* <p>Quantity: {item.qty}</p> */}
                          <div className="list row">
                            {/* <div className="col-xl-8 col-lg-8 col-md-8">
                              Quantity: {item.qty}
                            </div> */}
                            <div className="col-xl-12 col-lg-12 col-md-12">
                              Billing Frequency: {item.frequency}
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12">
                              Monthly Fee: {item.fee/100} € (EUR)
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                );
              })}
            </div>
            <h1>The total amout of your order is: {totalAmout/100} EUR / month</h1>
            <Button onClick={() => handleSubscription()}>Subscribe Now!</Button>
          </div>
        ) : (
          <div>
            <div>
              <h1>Your order is empty!</h1>
            </div>
            <Button style={{ className: "button" }}>
              <Link
                style={{ color: "white" }}
                to={"/subscriptions"}
                // state={{ id: 1, name: "sabaoon", shirt: "green" }}
                // state={[{ item: "Online Video Editor", qty: "3", itemCode: "99" }]}
                state={{}}
              >
                Go Back to the Shop
              </Link>
            </Button>
          </div>
        )}
        {/* {order[0].id}
        {order[0].name}
        {order[0].fee}
        {order[0].frequency}
        {order[0].image}
        {order[0].recurrent}
        {order[0].description}
        {order[0].qty} */}
        {/* {order[0].itemCode} */}
        {/* Online Video Editor <br />
        Charges - 200INR Per Month <br />
        Quantity - 3 Copies <br /> */}
        {/* <button onClick={() => handleSubscription()}> Subscribe Now! </button> */}
      </div>
    </div>
  );
};

export default SubscriptionPage;
