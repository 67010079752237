/**
 * example how to retrieve a reference to an html object
 */

// import React, { useRef, useEffect } from "react";
import React from "react";

import StarIcon from "@mui/icons-material/Star";

/**
 * this component can be used into another for example <Sample/>
 */
export default function Repeater({ count, icon: Icon, fontSize }) {
  /**
   * 1) spanRef is now a React.RefObject<HTMLSpanElement>
   * initially created with null value
   */
  // const spanRef = useRef (null);

  /**
   * 2) later, when spanRef changes because html span element with ref attribute,
   * follow useEffect hook will triggered because of dependent [spanRef].
   * in an if ( spanRef.current ) that states if spanRef assigned to valid html obj
   * we do what we need : in this case through current.innerHTML
   */
  function showStars() {
    // var starStr = Array(star)
    //   .fill(null)
    //   .reduce(
    //     (a) =>
    //       a +
    //       <StarIcon className="fa fa-star" aria-hidden="true" style={{color:"#D91E18"}}/>,
    //     ""
    //   );
    // console.log(starStr);
    // return starStr;
    var a = [];
    for (let i = 0; i < count; i++) {
      // console.log(i);
      a.push(i);
    }

    // var i=0;
    // var starStr = Array(star)
    //   .fill(i++)
    //   .map((indx) => (
    //     <StarIcon
    //       key={indx}
    //       className="fa fa-star"
    //       aria-hidden="true"
    //       style={{ color: "#D91E18" }}
    //     />
    //   ));
    // return (starStr);
    const jsxElement = (indx) => (
      <Icon
        key={indx}
        className="fa fa-star"
        aria-hidden="true"
        style={{ color: "#D91E18", fontSize: fontSize ? fontSize : "0.75rem" }}
      />
    );
    return a.map((v, indx) => {
      // console.log(indx);
      return jsxElement(indx);
    });
  }

  // useEffect(() => {
  //   if (spanRef.current) {
  //     spanRef.current.innerHTML = showStars(count);
  //   }
  // }, [spanRef]);

  return <span>{showStars()}</span>;
  // <div>
  //   my custom text follows
  //   <br />
  //   {/* ref={spanRef] will update the React.RefObject `spanRef` when html obj ready */}
  //   <span ref={spanRef} />
  // </div>
}
