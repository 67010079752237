import axios from "axios";
import authHeader from "./auth-header";
import server from "../config/server.config";

const API_URL =
  server.host + (server.port === "" ? "" : ":" + server.port) + "/api/";

// console.log(server);
// console.log(server.port);
// console.log(API_URL);

const getPublicContent = () => {
  const a = axios.get(API_URL + "user/all", { headers: authHeader() });
  // console.log("axios.get: ", a);
  return a;
};

const getUserBoard = (userId) => {
  return axios.get(API_URL + `user/user/${userId}`, { headers: authHeader() });
};

const getModeratorBoard = (userId) => {
  return axios.get(API_URL + `user/mod/${userId}`, { headers: authHeader() });
};

// const getAdminBoard = async (userId) => {
const getAdminBoard = (userId) => {
  const api_url = API_URL + `user/admin/${userId}`;
  // console.log("api_url ", api_url);
  // console.log("userId ", userId);
  const params = {
    id: userId,
    // userId: userId,
    // name: "John Doe",
  };
  // return await axios.get(api_url, { headers: authHeader() });
  return axios.get(api_url, { headers: authHeader() });
  // return await axios.get(api_url, { headers: authHeader() });
  // return await axios.get(api_url, {
  //   headers: authHeader(),
  //   params: { id: userId },
  // });
  // return await axios.get(api_url, { headers: authHeader(), params: params });
  // const request = await axios.get(api_url, { params, headers: authHeader() });
  // // console.log("request ", request)
  // return request;
};

const getUserToDo = () => {
  return axios.get(API_URL + "user/todos", {
    timeout: 1000,
    headers: authHeader(),
  });
};

const postUserToDo = (user, todo) => {
  const userid = user.id;
  const key = 1;
  const data = {
    id: todo.id,
    userid: userid,
    key: key,
    tasks: todo.tasks,
  };
  // console.log(todo);
  return axios.post(API_URL + "user/todos", data, {
    // `method` is the request method to be used when making the request
    method: "post", // default "get"
    headers: authHeader(),
    // `data` is the data to be sent as the request body
    // Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
    // When no `transformRequest` is set, must be of one of the following types:
    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
    // - Browser only: FormData, File, Blob
    // - Node only: Stream, Buffer
    // syntax alternative to send data into the body
    // method post
    // only the value is sent, not the key
    // data: "Country=Brasil&City=Belo Horizonte",

    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 1000, // default is `0` (no timeout)
  });
};

const getUserNotes = () => {
  return axios.get(API_URL + "user/notes", {
    timeout: 1000,
    headers: authHeader(),
  });
};

const postUserNotes = (user, notes) => {
  const data = {
    userid: user.id,
    notes: notes,
  };
  // console.log(notes);
  return axios.post(API_URL + "user/notes", data, {
    // `method` is the request method to be used when making the request
    method: "post", // default "get"
    headers: authHeader(),
    // `data` is the data to be sent as the request body
    // Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
    // When no `transformRequest` is set, must be of one of the following types:
    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
    // - Browser only: FormData, File, Blob
    // - Node only: Stream, Buffer
    // syntax alternative to send data into the body
    // method post
    // only the value is sent, not the key
    // data: "Country=Brasil&City=Belo Horizonte",

    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 1000, // default is `0` (no timeout)
  });
};

const getUserMenus = () => {
  return axios.get(API_URL + "user/menus", {
    timeout: 1000,
    headers: authHeader(),
  });
};

const postUserMenus = (user, menus) => {
  const data = {
    userid: user.id,
    menus: menus,
  };
  // console.log(menus);
  return axios.post(API_URL + "user/menus", data, {
    // `method` is the request method to be used when making the request
    method: "post", // default "get"
    headers: authHeader(),
    // `data` is the data to be sent as the request body
    // Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
    // When no `transformRequest` is set, must be of one of the following types:
    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
    // - Browser only: FormData, File, Blob
    // - Node only: Stream, Buffer
    // syntax alternative to send data into the body
    // method post
    // only the value is sent, not the key
    // data: "Country=Brasil&City=Belo Horizonte",

    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 1000, // default is `0` (no timeout)
  });
};

const getUserIngredients = () => {
  return axios.get(API_URL + "user/ingredients", {
    timeout: 1000,
    headers: authHeader(),
  });
};

const postUserIngredients = (user, ingredients) => {
  const data = {
    userid: user.id,
    ingredients: ingredients,
  };
  // console.log(menus);
  return axios.post(API_URL + "user/ingredients", data, {
    // `method` is the request method to be used when making the request
    method: "post", // default "get"
    headers: authHeader(),
    // `data` is the data to be sent as the request body
    // Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
    // When no `transformRequest` is set, must be of one of the following types:
    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
    // - Browser only: FormData, File, Blob
    // - Node only: Stream, Buffer
    // syntax alternative to send data into the body
    // method post
    // only the value is sent, not the key
    // data: "Country=Brasil&City=Belo Horizonte",

    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 1000, // default is `0` (no timeout)
  });
};

const getUserRecipes = () => {
  return axios.get(API_URL + "recipes", {
    timeout: 1000,
    headers: authHeader(),
  });
};

const postUserFilteredRecipes = (user, filterVector) => {
  // return axios.get(API_URL + "recipes", {
  //   timeout: 1000,
  //   headers: authHeader(),
  // });
  const data = {
    userid: user.id,
    filterVector: filterVector,
  };
  // console.log(data);
  return axios.post(API_URL + "recipes/filter", data, {
    // `method` is the request method to be used when making the request
    method: "post", // default "get"
    headers: authHeader(),
    timeout: 1000, // default is `0` (no timeout)
  });
};

const postUserRecipes = (user, recipes) => {
  const data = {
    userid: user.id,
    recipes: recipes,
  };
  // console.log(menus);
  return axios.post(API_URL + "recipes", data, {
    // `method` is the request method to be used when making the request
    method: "post", // default "get"
    headers: authHeader(),
    timeout: 1000, // default is `0` (no timeout)
  });
};

const userServices = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getUserToDo,
  postUserToDo,
  getUserNotes,
  postUserNotes,
  getUserMenus,
  postUserMenus,
  getUserIngredients,
  postUserIngredients,
  getUserRecipes,
  postUserFilteredRecipes,
  postUserRecipes,
};

export default userServices;
