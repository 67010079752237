const coursesTypes = [
  ["Starter", "Main", "Dessert"],
  ["Main", "Dessert"],
  ["Main"]
];
const mealTypes = ["Full 3 course meal", "Main dish with desert", "Only main dish"];

module.exports = {
  coursesTypes,
  mealTypes,
};