import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import Dropdown from "../ShoppingList/Dropdown";
import BasicMenu from "./BasicMenu";
import sections from "./sections.definition";

import IngredientDataService from "../../services/ingredient.service";
import CategoryDataService from "../../services/category.service";
import addIngredientsToShoppingList from "../ShoppingList/addIngredientToShoppingList";
import { truncate } from "../../helpers/helper";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";
import server from "../../config/server.config";

import "./Ingredients.css";
import providers from "./providers.definition";
const ensembles = ["Ingredient", "Packaging", "Measure", "MainSections"];
// const providers = ["Día", "amazon", "Mercadona", "bofrost"];


const imageServerURL = server.cdn;
// console.log(imageServerURL);
/**
 * component definition
 */

const Ingredient = (props) => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // console.log(searchParams.get("e"));
  // console.log(searchParams.get("e") === "true");

  const [categoryGroups, setCategoryGroups] = useState({});
  const [options, setOptions] = useState([
    [], //{ label: "", value: "" }
    [],
    [],
    [],
    [],
    [],
  ]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isModerator, setIsModerator] = useState(false);
  const [isLimited, setIsLimited] = useState(true);
  // const [sections, setSections]=useState({});

  useEffect(() => {
    const isModerator = currentUser?.roles.includes("ROLE_MODERATOR");
    setIsModerator(isModerator);
    setEditMode(searchParams.get("e") === "true" && isModerator);
    // console.log(currentUser);
  }, []);

  // const display = "display";
  // const {display} = props;
  // const params = useParams();
  // console.log(params);

  const initialIngredient = {
    name: "",
    description: "",
    presentation: "",
    category: "",
    packaging: "",
    price: 0,
    unit: "",
    qtyUnit: "",
    quantity: "",
    number: 1,
    provider: "",
    picture: "",
    published: false,
    mainSection: "",
    section: "",
    subsection: "",
  };

  const initialIngredientState = {
    id: null,
    ingredient: initialIngredient,
  };

  const [editMode, setEditMode] = useState(
    searchParams.get("e") === "true" ? true && isModerator : false
  );
  const [currentIngredient, setCurrentIngredient] = useState(
    initialIngredientState
  );
  const [message, setMessage] = useState("");
  const [ignoreInventory, setIgnoreInventory] = useState(false);

  const [lvl1, setLvl1] = useState("");
  const [lvl2, setLvl2] = useState("");
  const [lvl3, setLvl3] = useState("");
  const [level1, setLevel1] = useState([]);
  const [level2, setLevel2] = useState([]);
  const [level3, setLevel3] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const getIngredient = (id) => {
    IngredientDataService.get(id)
      .then((response) => {
        const newIngredientObj = { ...response.data };
        const newIngredient = {
          ...initialIngredient,
          ...response.data.ingredient,
        };
        // console.log("response.data", response.data);
        // console.log("newIngredient", newIngredient);
        if (newIngredient.mainSection) {
          const { s, ss } = retrieveSubSections(
            newIngredient.mainSection,
            newIngredient.section
          );
          // console.log(s);
          // console.log(ss);
          setLevel2(s);
          setLevel3(ss);
          setLvl1(newIngredient.mainSection);
          setLvl2(newIngredient.section);
          setLvl3(newIngredient.subsection);
        }
        setCurrentIngredient({
          ...newIngredientObj,
          ingredient: newIngredient,
        });
        // console.log("initialIngredientState", initialIngredientState);
        // console.log("response.data", response.data);
        // console.log("newIngredient", newIngredient);
      })
      .catch((e) => {
        console.log(e);
        setMessage(e.message);
      });
  };

  useEffect(() => {
    if (id) getIngredient(id);
  }, [id, isDisabled]);

  useEffect(() => {
    ensembles.map((ensemble, indx) => retrieveCategoryGroup(ensemble, indx));
    // const categoryGroups = ensembles.map((ensemble) =>
    //   retrieveCategoryGroup(ensemble)
    // );
    // console.log("categoryGroups ", categoryGroups);
    setLevel1((x) => {
      const keys = Object.keys(sections);
      // console.log(keys);
      return keys;
    });
    // retrieveSections("Frescos");
  }, []);

  const retrieveSubSections = (key, subkey) => {
    // console.log("key, subkey ", key, subkey);

    const keys = Object.keys(sections[key]);
    // console.log("keys ", keys);

    const section = {
      s: Object.keys(sections[key]),
      ss:
        subkey === ""
          ? Object.keys(sections[key][keys[0]])
          : Object.keys(sections[key][subkey]),
    };
    // console.log(
    //   section,
    //   subkey,
    //   subkey === ""
    //     ? Object.keys(sections[key][keys[0]])
    //     : Object.keys(sections[key][subkey])
    // );
    return section;
  };

  const retrieveSections = (key, subkey) => {
    // console.log(sections);
    // convert object to key's array

    const keys = Object.keys(sections[key]);

    const subsection = retrieveSubSections(key, keys[0]);

    console.log(key, keys, sections[key], subsection);

    // print all keys
    // console.log(keys0);

    // iterate over object
    // keys.forEach((key1, index) => {
    //   console.log(`1: ${key1}: ${sections[key1]}`);

    //   const keys = Object.keys(sections[key1]);
    //   console.log("keys ", keys);

    //   keys?.forEach((key2, index) => {
    //     console.log(`--> 2: ${key2}: ${sections[key1][key2]}`);

    //     if (sections[key1][key2]) {
    //       console.log(sections[key1][key2]);
    //       // console.log(`--------------> ${sections[key1][key2]}`);
    //       const keys = Object.keys(sections[key1][key2]);
    //       console.log(keys);
    //       if (keys !== undefined)
    //         keys?.forEach((key3, index) => {
    //           console.log(`-----> 3: ${key3}: ${sections[key1][key2][key3]}`);
    //         });
    //     }
    //   });
    // });
  };

  const retrieveCategoryGroup = (ensemble, indx) => {
    CategoryDataService.getAll({ name: "", ensemble: ensemble })
      .then((response) => {
        // var categoryGroup;
        // categoryGroup[indx]=response.data.categoryGroup;
        // console.log("categoryGroup[indx] ",indx, categoryGroup[indx]);
        // console.log(
        //   "response.data.categoryGroup ",
        //   response.data.categoryGroup
        // );
        // console.log("ensemble ", ensemble);
        // console.log("categoryGroup ", categoryGroup);
        setCategoryGroups((prevCategoryGroups) => {
          const newCategoryGroups = {
            ...prevCategoryGroups,
            [ensembles[indx]]: response.data.categoryGroup,
          };
          // console.log("newCategoryGroups ", indx, newCategoryGroups);
          setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[indx] = response.data.categoryGroup
              ? response.data.categoryGroup.categories.map((category) => ({
                  label: category.name,
                  value: category.name,
                }))
              : [{ label: "pcs", value: "pcs" }];
            // console.log("newOptions ", indx, newOptions);
            return newOptions;
          });
          return newCategoryGroups;
        });

        // also possible to write it as array construct
        //
        // setCategoryGroups((prevCategoryGroups) => {
        //   const a = [...prevCategoryGroups];
        //   a[indx] = response.data.categoryGroup;
        //   return a;
        // });

        // console.log(response);
        // return response.data.categoryGroup;
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

function addDefaultSrc(e) {
   e.target.src = imageNotAvailable;
}

  function loadSubSections(event) {
    const { name, value } = event.target;
    // console.log(event.target);
    switch (name) {
      case "mainSection":
        setLvl1(value);
        const { s: s1, ss: ss1 } = retrieveSubSections(value, "");
        // console.log(s1);
        // console.log(ss1);
        setLevel2(s1);
        setLevel3(ss1);
        break;
      case "section":
        // console.log("name", name);
        // console.log("value ", value);

        setLvl2(value);
        const { s: s2, ss: ss2 } = retrieveSubSections(lvl1, value);
        // console.log(s2);
        // console.log(ss2);
        setLevel3(ss2);
        break;
      case "subsection":
        setLvl3(value);
        break;

      default:
        break;
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let ingredient = {};
    if (name === "externalLinks" || name === "externalLinksProvider") {
      const externalLinks = [];
      externalLinks[0] = { ...currentIngredient.ingredient.externalLinks[0] };
      // console.log("name, value ", name, value);

      if (name === "externalLinks") {
        externalLinks[0]["link"] = value;
      } else if (name === "externalLinksProvider") {
        externalLinks[0]["provider"] = value;
      }
      ingredient = {
        ...currentIngredient.ingredient,
        externalLinks: externalLinks,
      };
    } else if (name === "media-images-head") {
      // console.log("ingredient ", ingredient);
      console.log("value ", value);
      const media = {};
      media.images = {};
      media.images.head = value;
      ingredient = { ...currentIngredient.ingredient, media: media };
    } else {
      ingredient = { ...currentIngredient.ingredient, [name]: value };
    }
    // console.log("ingredient ", ingredient);
    setCurrentIngredient({ ...currentIngredient, ingredient: ingredient });
  };

  const updatePublished = (status) => {
    var ingredient = {
      id: currentIngredient.id,
      name: currentIngredient.ingredient.name,
      description: currentIngredient.ingredient.description,
      published: status,
    };
    var data = {
      ingredient: ingredient,
    };
    IngredientDataService.update(currentIngredient.id, data)
      .then((response) => {
        setCurrentIngredient({ ...currentIngredient, ingredient: ingredient });
        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateIngredient = () => {
    IngredientDataService.update(currentIngredient.id, currentIngredient)
      .then((response) => {
        // console.log(response.data);
        setMessage("The ingredient was updated successfully!");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleBackClick = () => {
    navigate("/ingredients");
  };

  const deleteIngredient = () => {
    IngredientDataService.remove(currentIngredient.id)
      .then((response) => {
        // console.log(response.data);
        navigate("/ingredients");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleGoToIngredients = () => {
    navigate("/ingredients");
  };

  async function handleAddIngredientToShoppingList  () {
    // setIsDisabled(true);
    const servings = 4;
    const serves = 4;
    const result = await addIngredientsToShoppingList(
      currentUser,
      [currentIngredient],
      servings,
      serves,
      ignoreInventory
    );
    // console.log(currentIngredient, [currentIngredient]);
    console.log("ignoreInventory", ignoreInventory);
    console.log(result);
  };

  const handleChangeEditMode = () => {
    setEditMode(!editMode);
  };

  function calculateUnitPrice(ingredient) {
    let scale;
    let priceUnit;
    switch (ingredient.qtyUnit) {
      case "g":
        // unit is already scaled, therefore priceUnit is "€/kg" and NOT "€/g"
        priceUnit = "€/kg";
        scale = 1000;
        break;
      case "kg":
        priceUnit = "€/kg";
        scale = 1;
        break;
      case "ml":
        priceUnit = "€/ml";
        scale = 1000;
        break;
      case "uds":
        priceUnit = "€/ud";
        scale = 1;
        break;
      case "l":
        priceUnit = "€/l";
        scale = 1;
        break;
      default:
        break;
    }
    const unitPrice =
      Number.parseFloat(
        (ingredient.price / (ingredient.number * ingredient.quantity)) * scale
      ).toFixed(2) + priceUnit;

    return unitPrice;
  }

  return (
    <div>
      {/* {console.log(editMode)} */}
      <BasicMenu />
      {currentIngredient ? (
        <div style={{ margin: "10px" }}>
          {editMode ? (
            <div className="edit-form">
              <h4>Ingredient</h4>
              <form>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={currentIngredient.ingredient.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    name="description"
                    value={currentIngredient.ingredient.description}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="row">
                  <div className="col">
                    {" "}
                    {/* <div className="form-group">
                  <label htmlFor="category">Category</label>
                  <input
                    type="text"
                    className="form-control"
                    id="category"
                    name="category"
                    value={currentIngredient.ingredient.category}
                    onChange={handleInputChange}
                  />
                    </div> */}
                    <div className="form-group">
                      {/* <label htmlFor="category">Category</label>
                    <input
                      name="category"
                      type="text"
                      className="form-control"
                      id="category"
                      required
                      value={ingredient.category}
                      onChange={handleInputChange}
                    /> */}
                      <Dropdown
                        myStyle={{
                          margin: "15pt 0pt 0pt 0pt",
                          // float: "left",
                          // maxWidth: "60pt",
                          width: "100%",
                        }}
                        label="Category"
                        options={options[0]}
                        // options={categoryGroups.Ingredient.categories}
                        value={currentIngredient.ingredient.category}
                        className="form-control"
                        id="category"
                        required
                        onChange={handleInputChange}
                        name="category"
                      />
                    </div>
                    <div className="form-group">
                      <Dropdown
                        myStyle={{
                          margin: "15pt 0pt 0pt 0pt",
                          // float: "left",
                          // maxWidth: "60pt",
                          width: "100%",
                        }}
                        label="Main Section"
                        // options={options}
                        options={level1}
                        value={currentIngredient.ingredient.mainSection}
                        className="form-control"
                        id="mainSection"
                        required
                        onChange={(e) => {
                          handleInputChange(e);
                          loadSubSections(e);
                        }}
                        name="mainSection"
                      />
                      <br />
                    </div>
                    {level2.length ? (
                      <div className="form-group">
                        <Dropdown
                          myStyle={{
                            margin: "15pt 0pt 0pt 0pt",
                            // float: "left",
                            // maxWidth: "60pt",
                            width: "100%",
                          }}
                          label="Section"
                          // options={options}
                          options={level2}
                          value={currentIngredient.ingredient.section}
                          className="form-control"
                          id="section"
                          required
                          onChange={(e) => {
                            handleInputChange(e);
                            loadSubSections(e);
                          }}
                          name="section"
                        />
                        <br />
                      </div>
                    ) : null}
                    {level3.length ? (
                      <div className="form-group">
                        <Dropdown
                          myStyle={{
                            margin: "15pt 0pt 0pt 0pt",
                            // float: "left",
                            // maxWidth: "60pt",
                            width: "100%",
                          }}
                          label="Subsection"
                          // options={options}
                          options={level3}
                          value={currentIngredient.ingredient.subsection}
                          className="form-control"
                          id="subsection"
                          required
                          onChange={(e) => {
                            handleInputChange(e);
                            loadSubSections(e);
                          }}
                          name="subsection"
                        />
                        <br />
                      </div>
                    ) : null}
                    <div className="form-group">
                      {"Pollucturum"}
                      {" > "}
                      {currentIngredient.ingredient.mainSection}
                      {" > "}
                      {currentIngredient.ingredient.section}
                      {" > "}
                      {currentIngredient.ingredient.subsection}
                    </div>
                    {console.log(currentIngredient.ingredient.price)}
                    <div className="form-group">
                      <label htmlFor="price">Price</label>
                      <input
                        type="text"
                        className="form-control"
                        id="price"
                        name="price"
                        value={currentIngredient.ingredient.price}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="provider">Preferred Provider</label>
                      <Dropdown
                        myStyle={{
                          margin: "15pt 0pt 0pt 0pt",
                          // float: "left",
                          // maxWidth: "60pt",
                          width: "100%",
                        }}
                        label="Provider"
                        // options={options}
                        options={providers}
                        value={currentIngredient.ingredient.provider}
                        className="form-control"
                        id="provider"
                        required
                        onChange={handleInputChange}
                        name="provider"
                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        id="provider"
                        name="provider"
                        value={currentIngredient.ingredient.provider}
                        onChange={handleInputChange}
                      /> */}
                    </div>
                    <div className="form-group">
                      <label htmlFor="presentation">Presentation</label>
                      <input
                        type="text"
                        className="form-control"
                        id="presentation"
                        name="presentation"
                        value={currentIngredient.ingredient.presentation}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="quantity">Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id="number"
                            name="number"
                            value={currentIngredient.ingredient.number}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="packaging">Packaging</label>
                          <Dropdown
                            myStyle={{
                              // margin: "15pt 0pt 0pt 0pt",
                              // float: "left",
                              // maxWidth: "60pt",
                              width: "100%",
                            }}
                            // label="Packaging"
                            // options={options}
                            options={options[1]}
                            value={currentIngredient.ingredient.packaging}
                            className="form-control"
                            id="packaging"
                            required
                            onChange={handleInputChange}
                            name="packaging"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="quantity">Quantity</label>
                          <input
                            type="text"
                            className="form-control"
                            id="quantity"
                            name="quantity"
                            value={currentIngredient.ingredient.quantity}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <Dropdown
                            myStyle={{
                              margin: "15pt 0pt 0pt 0pt",
                              // float: "left",
                              // maxWidth: "60pt",
                              width: "100%",
                            }}
                            label="Unit"
                            // options={options}
                            options={options[2]}
                            value={currentIngredient.ingredient.qtyUnit}
                            className="form-control"
                            id="qtyUnit"
                            required
                            onChange={handleInputChange}
                            name="qtyUnit"
                          />
                          <br />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="qtyUnit">Units</label>
                      <input
                        type="text"
                        className="form-control"
                        id="qtyUnit"
                        name="qtyUnit"
                        value={currentIngredient.ingredient.qtyUnit}
                        onChange={handleInputChange}
                      />
                    </div> */}
                    <div className="form-group">
                      {currentIngredient.ingredient.published ? (
                        <button
                          className="badge bg-primary mr-2"
                          onClick={() => updatePublished(false)}
                        >
                          UnPublish
                        </button>
                      ) : (
                        <button
                          className="badge bg-primary mr-2"
                          onClick={() => updatePublished(true)}
                        >
                          Publish
                        </button>
                      )}
                      <label>
                        <strong>&nbsp; Status: &nbsp;</strong>
                      </label>
                      {currentIngredient.ingredient.published
                        ? "Published"
                        : "Pending"}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <label>
                        <strong>Picture:</strong>&nbsp;
                      </label>
                      {currentIngredient.ingredient.media &&
                      currentIngredient.ingredient.media.images &&
                      currentIngredient.ingredient.media.images.head ? (
                        <div>
                          <img
                            width={"100%"}
                            src={
                              imageServerURL +
                              currentIngredient.ingredient.media.images.head
                            }
                            alt={`Image Head category ${currentIngredient.ingredient.name}`}
                            onError={addDefaultSrc}
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            width={"100%"}
                            src={imageNotAvailable}
                            alt="Image Head ingredient"
                          />
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="media-images-head">Picture</label>
                      <input
                        type="text"
                        className="form-control"
                        id="media-images-head"
                        name="media-images-head"
                        value={
                          currentIngredient.ingredient.media &&
                          currentIngredient.ingredient.media.images &&
                          currentIngredient.ingredient.media.images.head
                            ? currentIngredient.ingredient.media.images.head
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* {console.log(currentIngredient.ingredient)}
                    {console.log(currentIngredient.ingredient.externalLinks)} */}
                    <div className="form-group">
                      <label htmlFor="externalLinksProvider">
                        External Links Provider
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="externalLinksProvider"
                        name="externalLinksProvider"
                        value={
                          currentIngredient.ingredient.externalLinks &&
                          currentIngredient.ingredient.externalLinks.length >
                            0 &&
                          currentIngredient.ingredient.externalLinks[0].provider
                            ? currentIngredient.ingredient.externalLinks[0]
                                .provider
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                      <label htmlFor="externalLinks">External Links</label>
                      <input
                        type="text"
                        className="form-control"
                        id="externalLinks"
                        name="externalLinks"
                        value={
                          currentIngredient.ingredient.externalLinks &&
                          currentIngredient.ingredient.externalLinks.length >
                            0 &&
                          currentIngredient.ingredient.externalLinks[0].link
                            ? currentIngredient.ingredient.externalLinks[0].link
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <p>{message}</p>
              <button
                className="badge bg-primary mr-2"
                onClick={handleBackClick}
              >
                Back to ingredients listing
              </button>
              <button
                className="badge bg-danger mr-2"
                onClick={deleteIngredient}
              >
                Delete
              </button>
              <button
                type="submit"
                className="badge bg-success"
                onClick={updateIngredient}
              >
                Update
              </button>
              <button className="badge bg-info" onClick={handleChangeEditMode}>
                Display
              </button>
            </div>
          ) : (
            <div>
              {/* <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src="holder.js/100px180" />
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>  */}

              {/* <Card style={{ width: "18rem" }}>  */}
              <Card
                className="card"
                style={{ maxWidth: "700px", width: "24rem" }}
                // rounded="true"
              >
                {currentIngredient.ingredient.media &&
                currentIngredient.ingredient.media.images &&
                currentIngredient.ingredient.media.images.head ? (
                  <div>
                    <Card.Img
                      variant="top"
                      src={
                        imageServerURL +
                        currentIngredient.ingredient.media.images.head
                      }
                      onError={addDefaultSrc}
                    />
                    {/* <label>
                    <strong>Picture (server side):</strong>
                  </label>
                  {currentIngredient.ingredient.media.images.head} */}
                    {/* <img
                    width={"100%"}
                    src={currentIngredient.ingredient.media.images.head}
                    alt="Image Head Ingredient {currentIngredient.ingredient.name}"
                  /> */}
                  </div>
                ) : (
                  <div>
                    <Card.Img variant="top" src={imageNotAvailable} />
                    {/* <img src={imageNotAvailable} alt="Image Head Ingredient" /> */}
                    {/* <br />
                  <p>No picture available yet...</p> */}
                  </div>
                )}
                <Card.Body>
                  <Card.Title style={{ fontWeight: "bold" }}>
                    {currentIngredient.ingredient.name}
                  </Card.Title>
                  <div>
                    {/* <label>
                      <strong>Name:</strong>
                    </label> */}
                  </div>
                  <div>
                    <label>
                      <strong>Description:</strong>
                    </label>
                  </div>
                  <Card.Text>
                    {/* &hellip; */}
                    {/* {"&hellip;"} */}
                    {isLimited
                      ? truncate(currentIngredient.ingredient.description, 200)
                      : currentIngredient.ingredient.description}
                    {isLimited &&
                    currentIngredient.ingredient.description.length > 200 ? (
                      <span
                        style={{ cursor: "pointer", color: "#0d6efd" }}
                        onClick={() => setIsLimited(false)}
                      >
                        &nbsp;more
                      </span>
                    ) : null}
                    {!isLimited &&
                    currentIngredient.ingredient.description.length > 200 ? (
                      <span
                        style={{ cursor: "pointer", color: "#0d6efd" }}
                        onClick={() => setIsLimited(true)}
                      >
                        &nbsp;less
                      </span>
                    ) : null}
                    {/* Some quick example text to build on the card title and make up
                  the bulk of the card's content. */}
                  </Card.Text>
                  <div>
                    <label>
                      <strong>Category:</strong>
                    </label>
                  </div>
                  <Card.Text>{currentIngredient.ingredient.category}</Card.Text>
                  <div className="form-group">
                    {"Pollucturum"}
                    {" > "}
                    {currentIngredient.ingredient.mainSection}
                    {" > "}
                    {currentIngredient.ingredient.section}
                    {currentIngredient.ingredient.subsection ? " > " : null}
                    {currentIngredient.ingredient.subsection
                      ? currentIngredient.ingredient.subsection
                      : null}
                  </div>

                  <div>
                    <label>
                      <strong>Preferred provider:</strong>
                    </label>
                  </div>
                  <Card.Text>{currentIngredient.ingredient.provider}</Card.Text>
                  <div className="row">
                    <div className="col">
                      <div>
                        <label>
                          <strong>Price:</strong>
                        </label>
                      </div>
                      <Card.Text>
                        {currentIngredient.ingredient.price}
                      </Card.Text>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="unitPrice">
                          <strong>Unit Price</strong>
                        </label>
                        <Card.Text id="unitPrice" name="unitPrice">
                          {calculateUnitPrice(currentIngredient.ingredient)}
                        </Card.Text>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label>
                      <strong>Presentation:</strong>
                    </label>
                  </div>
                  <Card.Text>
                    {currentIngredient.ingredient.presentation}
                  </Card.Text>
                  <div>
                    <div className="row">
                      <div className="col">
                        <label>
                          <strong>Number:</strong>
                        </label>
                        <Card.Text>
                          {currentIngredient.ingredient.number}
                        </Card.Text>
                      </div>
                      <div className="col">
                        <label>
                          <strong>Packaging:</strong>
                        </label>
                        <Card.Text>
                          {currentIngredient.ingredient.packaging}
                        </Card.Text>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col">
                        <label>
                          <strong>Quantity:</strong>
                        </label>
                        <Card.Text>
                          {currentIngredient.ingredient.quantity}
                        </Card.Text>
                      </div>
                      <div className="col">
                        <label>
                          <strong>Unit:</strong>
                        </label>
                        <Card.Text>
                          {currentIngredient.ingredient.qtyUnit}
                        </Card.Text>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label>
                      <strong>External Links:</strong>&nbsp;
                    </label>
                    <ul>
                      {currentIngredient &&
                        currentIngredient.ingredient &&
                        currentIngredient.ingredient.externalLinks &&
                        currentIngredient.ingredient.externalLinks.map(
                          (link, index) => {
                            return (
                              <li key={index}>
                                {link.provider}
                                <br />
                                <a href={link.link}>
                                  {truncate(link.link, 50)}
                                </a>
                              </li>
                            );
                          }
                        )}
                    </ul>
                  </div>
                  <div>
                    <label>
                      <strong>Status:</strong>&nbsp;
                    </label>
                    {currentIngredient.ingredient.published
                      ? "Published"
                      : "Pending"}
                  </div>
                  <div>
                    <label>
                      <strong>ID:</strong>&nbsp;
                    </label>
                    {currentIngredient.ingredient.id}
                  </div>
                  <div>
                    {/* Slider Favorite Recipes */}
                    <FormGroup>
                      <FormControlLabel
                        // control={<Switch defaultChecked />}
                        control={
                          <Switch
                            checked={ignoreInventory}
                            onChange={() =>
                              setIgnoreInventory(!ignoreInventory)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Ignore the Inventory"
                      />
                      {/* <FormControlLabel disabled control={<Switch />} label="Disabled" /> */}
                    </FormGroup>
                  </div>

                  <Button variant="primary" onClick={handleGoToIngredients}>
                    Ingredients
                  </Button>
                  {(searchParams.get("b") === "true" || true) &&
                  currentIngredient &&
                  currentIngredient.ingredient &&
                  currentIngredient.ingredient.externalLinks &&
                  currentIngredient.ingredient.externalLinks.length > 0 ? (
                    <a
                      href={currentIngredient.ingredient.externalLinks[0].link}
                      className="badge bg-warning"
                    >
                      <Button disabled={isDisabled} variant="warning">
                        Buy It Now &nbsp;
                        <ShoppingCartIcon />
                      </Button>
                    </a>
                  ) : null}
                  <Button
                    disabled={isDisabled}
                    variant="warning"
                    onClick={handleAddIngredientToShoppingList}
                  >
                    Add to Shoppinglist
                  </Button>

                  {/* <Link to={"/ingredients"} className="badge bg-warning">
                    Ingredients
                  </Link> */}
                  {/* <Link to={"/ingredients"} className="badge bg-info">
                    Edit
                  </Link> */}
                  {isModerator ? (
                    <Button variant="info" onClick={handleChangeEditMode}>
                      Edit
                    </Button>
                  ) : null}
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a Ingredient...</p>
        </div>
      )}
    </div>
  );
};
export default Ingredient;
