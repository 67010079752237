// Assuming logo.png is in the same folder as JS file
import Georg from "../assets/img/Georg.jpg";
import Sophia from "../assets/img/Sophia.jpeg";
import Johanna from "../assets/img/Johanna.jpg";
import Kilian from "../assets/img/Kilian.jpg";
import Reduan from "../assets/img/Reduan.jpeg";

const contacts = [
  {
    id: 1,
    class: "card",
    name: "Sophia Ahmed Chavarino",
    imgURL: Sophia,
    phone: "+687 415 669",
    email: "soahcha@gmail.com",
  },
  {
    id: 2,
    class: "card",
    name: "Georg Schreiber",
    imgURL: Georg,
    phone: "+34 687 415 692",
    email: "georgschreiber@yahoo.es",
  },
  {
    id: 3,
    class: "card",
    name: "Johanna Schreiber Ahmed",
    imgURL: Johanna,
    phone: "+34 687 415 680",
    email: "j.schah@gmail.com",
  },
  {
    id: 4,
    class: "card",
    name: "Kilian Schreiber Ahmed",
    imgURL: Kilian,
    phone: "+34 637 298 212",
    email: "kiruman.dumbledore@gmail.com",
  },
  {
    id: 5,
    class: "card",
    name: "Reduan Schreiber Ahmed",
    imgURL: Reduan,
    phone: "+34 697 137 316",
    email: "reduan.schreiberahmed@gmail.com",
  },
];

export default contacts;
