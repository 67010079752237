/**
 * boilerplate
 *
 * 08/12/2022 changed from pages to component
 */

import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// import KeyIcon from "@mui/icons-material/Key";

// import Dropdown from "../ShoppingList/Dropdown";
// import options from "../ShoppingList/qtyUnitOptions";

import CategoryDataService from "../../services/category.service";

import "./Categories.css";

/**
 * component definition
 */

const AddCategory = ({ ensemble, handleAddCategory }) => {
  // const { message } = useSelector((state) => state.message);
  // const { user: currentUser } = useSelector((state) => state.auth);

  // const navigate = useNavigate();

  const initialCategoryState = {
    id: null,
    name: "",
    description: "",
    presentation: "",
    published: false,
    media: {
      images: {
        head: "",
      },
    },
  };

  const [categoryGroup, setCategoryGroup] = useState([]);
  const [category, setCategory] = useState(initialCategoryState);
  const [submitted, setSubmitted] = useState(false);

  const handleBackClick = () => {
    // 👇️ replace set to true
    // navigate("/categoryGroup", { replace: true });
    handleAddCategory(submitted);
  };

  useEffect(() => {
    retrieveCategoryGroup(ensemble);
  }, []);

  const retrieveCategoryGroup = (ensemble) => {
    CategoryDataService.getAll({ name: "", ensemble: ensemble })
      .then((response) => {
        setCategoryGroup(response.data.categoryGroup);
        // console.log("response.data.categoryGroup ", response.data.categoryGroup);
        // console.log(response.data);
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log("category ", category);
    let theCategory = {};
    if (name === "media-images-head") {
      const media = {};
      media.images = {};
      media.images.head = value;
      theCategory = { ...category, media: media };
    } else {
      theCategory = { ...category, [name]: value };
    }
    // console.log("theCategory ", theCategory);
    setCategory(theCategory);
  };

  const saveCategory = () => {
    console.log("saveCategory() category", category);
    // console.log("saveCategory() categoryGroup", categoryGroup);
    // const newItem = {
    //   name: category.name,
    //   description: category.description,
    //   presentation: category.presentation,
    //   published: false,
    //   media: {
    //     images: {
    //       head: category.media.images.head,
    //     },
    //   },
    // };
    // const newCategories = [...categoryGroup.categories, newItem];
    // category.id = uuidv4();
    const newCategories = [...categoryGroup.categories, category];
    categoryGroup.categories = newCategories;
    // console.log("saveCategory() newCategories", newCategories);
    // console.log("saveCategory() categoryGroup", categoryGroup);
    var data = {
      ensemble: ensemble,
      categoryGroup: categoryGroup,
    };
    CategoryDataService.create(data)
      .then((response) => {
        // console.log("saveCategory() response data name", response.data.name);
        // console.log(
        //   "saveCategory() response data description",
        //   response.data.description
        // );
        // console.log("saveCategory() response data id", response.data.id);
        // console.log("saveCategory() response data", response.data);
        // setCategory({
        //   id: response.data.id,
        //   name:
        //     response.data.category.length > 0
        //       ? response.data.category[0].name
        //       : "unknown",
        //   description:
        //     response.data.category.length > 0
        //       ? response.data.category[0].description
        //       : "unknown",
        //   presentation:
        //     response.data.category.length > 0
        //       ? response.data.category[0].presentation
        //       : "unknown",
        //   published:
        //     response.data.category.length > 0
        //       ? response.data.category[0].published
        //       : "unknown",
        //   media: {
        //     images: {
        //       head:
        //         response.data.category.length > 0
        //           ? response.data.category[0].media.images.head
        //           : "unknown",
        //     },
        //   },
        // });

        // if save is called, then category has been already set!!!!!!!!
        // setCategory(categoryGroup);
        setSubmitted(true);
        console.log("saveCategory() ", response.data);
      })
      .catch((e) => {
        console.log("saveCategory() ", e);
      });
  };

  const newCategory = () => {
    setCategory(initialCategoryState);
    setSubmitted(false);
  };

  return (
    <div
      className="container"
      style={{
        // border: "solid black 1px",
        backgroundColor: "whitesmoke",
        padding: "20px",
        borderRadius: "20px",
      }}
    >
      {/* <p>{ensemble}</p> */}
      {/* <KeyIcon style={{ position: "absolute", left: "0" }} /> */}
      <div className="submit-form">
        {submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <button className="btn btn-success" onClick={newCategory}>
              Add
            </button>
            <button className="btn btn-success" onClick={handleBackClick}>
              Back
            </button>
          </div>
        ) : (
          <div>
            <h4>
              Please enter the NEW category for <b>{ensemble}</b>{" "}
            </h4>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                name="name"
                ensemble="text"
                className="form-control"
                id="name"
                required
                value={category.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                name="description"
                ensemble="text"
                className="form-control"
                id="description"
                required
                value={category.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="presentation">Presentation</label>
              <input
                name="presentation"
                ensemble="text"
                className="form-control"
                id="presentation"
                required
                value={category.presentation}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="media-images-head">Picture</label>
              <input
                ensemble="text"
                className="form-control"
                id="media-images-head"
                required
                value={
                  category.media &&
                  category.media.images &&
                  category.media.images.head
                    ? category.media.images.head
                    : ""
                }
                onChange={handleInputChange}
                name="media-images-head"
              />
            </div>
            <button onClick={saveCategory} className="btn btn-success">
              Submit
            </button>
            <button onClick={handleBackClick} className="btn btn-success">
              Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default AddCategory;
