/************************************
 * Profile Page
 *
 * This page gets current User from Local Storage by calling
 * AuthService.getCurrentUser() method and show user information
 * (with token).
 *
 *************************************/
import React, { useState, useEffect } from "react";
// import AuthService from "../services/auth.service";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import { withTheme } from "styled-components";

import Form from "react-bootstrap/Form";

import KeyIcon from "@mui/icons-material/Key";

import Members from "./Members";
import chatService from "../../services/chat.service";
import axios from "axios";

import __DEBUG__ from "../../config/debug.config";
import authHeader from "../../services/auth-header";

import server from "../../config/server.config";

const API_URL = server.host + (server.port === "" ? "" : ":" + server.port);

const FormContainer = styled.div`
  /* height: 100vh; */
  /* width: 100vw; */
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  /* padding: 1rem 1rem; */
  background-color: ${(props) => props.theme.backgroundColor};
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: left;
    img {
      height: 3rem;
    }
    h1 {
      color: white;
      text-transform: lowercase;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: ${(props) => props.theme.formBackgroundColor};
    border-radius: 2rem;
    /* padding: 3rem 5rem; */
    padding: 1rem 1rem;
    .themeswitcher {
      /* display: grid;
      grid-template-rows: 25% 75%; */
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      align-items: stretch;
      gap: 2rem;
      color: white;
    }
    input {
      background-color: transparent;
      padding: 1rem;
      border: 0.1rem solid #4e0eff;
      border-radius: 0.4rem;
      color: white;
      width: 100%;
      font-size: 1rem;
      &:focus {
        border: 0.1rem solid #997af0;
        outline: none;
      }
    }
    label {
      background-color: transparent;
      /* padding: 1rem; */
      /* border: 0.1rem solid #4e0eff;
      border-radius: 0.4rem; */
      color: white;
      width: 100%;
      font-size: 1rem;
    }
    button {
      background-color: #997af0;
      color: white;
      padding: 1rem 2rem;
      border: none;
      font-weight: bold;
      cursor: pointer;
      border-radius: 0.4rem;
      font-size: 1rem;
      text-transform: uppercase;
      transition: 0.5s ease-in-out;
      &:hover {
        background-color: #4e0eff;
      }
    }
    span {
      color: white;
      text-transform: uppercase;
      a {
        color: #4e0eff;
        text-decoration: none;
        font-weight: bold;
      }
    }
    .linklist {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 3rem;
      align-items: center;
      flex: 0 1;
      /* .link {
    float: left;
  } */
    }
  }
`;

const Profile = ({ toggleTheme, theme }) => {
  // const currentUser = AuthService.getCurrentUser();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [user, setUser] = useState(currentUser);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState("");
  const [message, setMessage] = useState("Please send your invitation emails!");

  const navigate = useNavigate();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: theme.name,
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log("user ", { ...user, [name]: value });
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      const { forename, surname, fullname, username, email } = user;
      // console.log(user);
      // console.log("currentUser ", currentUser);
      const id = currentUser.id ? currentUser.id : currentUser._id;
      // const id = currentUser.id;
      const userData = {
        // id: currentUser.id,
        forename,
        surname,
        fullname,
        username,
        email,
        theme: theme.name,
      };
      const { data } = await chatService.savePreferences(id, userData);
      // console.log(" theme.name ", theme.name);
      // console.log("data ", data);
      // console.log("data.user ", { ...data.user, avatarImage: "" });
      // console.log("data.status ", data.status);
      // console.log("data.msg ", data.msg);
      // console.log("currentUser.accessToken ", currentUser.accessToken);

      if (data.status) {
        // const user = { ...data.user, accessToken: currentUser.accessToken };
        // const user = { accessToken: currentUser.accessToken, ...data.user };
        const user = { ...currentUser, ...data.user };
        // the existing token is overwriten in the local storage
        // console.log("currentUser ", currentUser.accessToken);
        // console.log("handleSubmit() user ", user);

        localStorage.setItem(
          process.env.REACT_APP_LOCALHOST_KEY,
          JSON.stringify(user)
        );
        // return navigate("/chat");
      } else {
        const errorMessage = data.msg
          ? data.msg
          : "Some error occurred. Try again.";
        toast.error(errorMessage, toastOptions);
      }
    }
    // alert("alert");
  };

  function handleValidation() {
    const { username, forename, email } = user;
    // console.log(user);
    if (username === "") {
      toast.error("Username and Password are required.", toastOptions);
      return false;
    } else if (forename.length < 3) {
      toast.error("Forename should at least have 3 characters.", toastOptions);
      return false;
    } else if (username.length < 3) {
      toast.error("Username should at least have 3 characters.", toastOptions);
      return false;
    } else if (email.indexOf("@") < 0) {
      toast.error("Please enter a valid email address.", toastOptions);
      return false;
    } else if (email === "") {
      toast.error("Your email address is required.", toastOptions);
      return false;
    }
    return true;
  }

  const handleEmailsChange = (event) => {
    const { name, value } = event.target;
    // console.log("emails ",value );
    setEmails(value);
  };

  function postMemberEmails(data) {
    return axios.post(API_URL + `/api/bo/members/invite`, data, {
      headers: authHeader(),
    });
  }

  const handleSubmitEmails = async (event) => {
    event.preventDefault();
    if (handleEmailValidation()) {
      // console.log(user);
      // console.log("currentUser ", currentUser);
      const id = currentUser.id ? currentUser.id : currentUser._id;
      // const id = currentUser.id;
      const userData = {
        id: id,
        emails,
      };

      const { data } = await postMemberEmails(userData);
      // console.log(" theme.name ", theme.name);
      // console.log("data ", data);
      // console.log("data.user ", { ...data.user, avatarImage: "" });
      // console.log("data.status ", data.status);
      // console.log("data.msg ", data.msg);
      // console.log("currentUser.accessToken ", currentUser.accessToken);
      console.log("handleSubmitEmails() data ", data);
      if (data.msg === "Everything ok.") {
        setEmails("");
        setMessage("Thank you. Your members have been sent the invitation email.")
      }
      if (data.status) {
        // const user = { ...data.user, accessToken: currentUser.accessToken };
        // const user = { accessToken: currentUser.accessToken, ...data.user };
        // the existing token is overwriten in the local storage
        // console.log("currentUser ", currentUser.accessToken);
        // console.log("handleSubmit() user ", user);
        console.log("handleSubmitEmails() data ", data);

        // return navigate("/chat");
      } else {
        const errorMessage = data.msg
          ? data.msg
          : "Some error occurred. Try again.";
        toast.error(errorMessage, toastOptions);
      }
    }
    // alert("alert");
  };

  function handleEmailValidation() {
    console.log(emails);
    if (emails.indexOf("@") < 0) {
      toast.error("Please enter valid email addresses.", toastOptions);
      return false;
    } else if (emails === "") {
      toast.error("At least one email address is required.", toastOptions);
      return false;
    }
    return true;
  }

  // console.log("user ", currentUser );
  // console.log(user);
  // console.log("currentUser ", currentUser);

  return (
    <div className="container">
      <KeyIcon style={{ position: "absolute", left: "0" }} />
      <header className="jumbotron">
        <h3>Profile</h3>
        <h3>
          <strong>{currentUser.username}</strong>
        </h3>
      </header>
      <main>
        <div style={{ backgroundColor: "#f5ba13" }}>
          {currentUser.subscriberTypes &&
          (currentUser.subscriberTypes.includes("SUBSCRIBERTYPE_FAMILY") ||
            currentUser.subscriberTypes.includes("SUBSCRIBERTYPE_CLUB")) ? (
            <div>
              <div>
                <h3>Family/Club</h3>
                {/* to do: re-authenticate */}
                {/* <a
                className="badge bg-warning"
                href={`${API_URL}/index.html#pricing`}
              >
                Send invitation links to the members of your family/club
              </a> */}
                <p>
                  Please provide the emails of the members you want to add to
                  your family/club, separated by semicolons.
                </p>
                <Form onSubmit={(event) => handleSubmitEmails(event)}>
                  <Form.Group>
                    <label htmlFor="email">Email addresses</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      value={emails ? emails : ""}
                      onChange={handleEmailsChange}
                    />
                  </Form.Group>
                  {/* <button type="submit">Save</button> */}
                  <Form.Group>
                    <button
                      className="btn btn-primary btn-block"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Send</span>
                    </button>
                    <span style={{ margin: "10px 0px 0px 20px" }}>
                      {message}
                    </span>
                  </Form.Group>
                </Form>
              </div>
              <Members user={currentUser} theme={theme} />
            </div>
          ) : null}
        </div>
        <h3>Preferences</h3>
        <FormContainer>
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="form-group">
              <label htmlFor="forename">Forename</label>
              <input
                type="text"
                className="form-control"
                id="forename"
                name="forename"
                value={user.forename ? user.forename : ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="surname">Surname</label>
              <input
                type="text"
                className="form-control"
                id="surname"
                name="surname"
                value={user.surname ? user.surname : ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="fullname">Fullname</label>
              <input
                type="text"
                className="form-control"
                id="fullname"
                name="fullname"
                value={user.fullname ? user.fullname : ""}
                onChange={handleInputChange}
              />
            </div>
            <button type="submit">Save</button>
            <div>
              <span>Please save your preferences!</span>
            </div>
          </form>
        </FormContainer>
        <ToastContainer />

        <p>
          <strong>Token:</strong> {currentUser.accessToken.substring(0, 20)} ...{" "}
          {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
        </p>
        <p>
          <strong>Id:</strong> {currentUser.id}
        </p>
        <p>
          <strong>Email:</strong> {currentUser.email}
        </p>
        <strong>Authorities:</strong>
        <ul>
          {currentUser.roles &&
            currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
        </ul>
        <strong>Subscription:</strong>
        <ul>
          {currentUser.subscriberTypes &&
            currentUser.subscriberTypes.map((subscriberType, index) => (
              <li key={index}>{subscriberType}</li>
            ))}
        </ul>
      </main>
    </div>
  );
};

export default withTheme(Profile);
