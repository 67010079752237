import React from "react";
import ReactCalendar from "react-calendar";

import "react-calendar/dist/Calendar.css";

function Calendar({ setTheDate, theDate }) {
  // console.log(theDate);

  function handleSetTheDate(value){
    const date = new Date(value.toDateString());
    setTheDate(date);
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        padding: "20px",
        borderRadius: "10px",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div
      // style={{
      //   margin: "0 auto",
      // }}
      >
        <ReactCalendar
          style={{ width: "100%" }}
          onChange={handleSetTheDate}
          value={theDate}
        />
      </div>
    </div>
  );
}

export default Calendar;
