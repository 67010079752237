import axios from "axios";

import {
  setAvatarRoute,allUsersRoute,
  userRoute,
  // , host
} from "../utils/APIRoutes";

import authHeader from "./auth-header";

class chatService {
  getContacts(id) {
    return axios.get(`${allUsersRoute}/${id}`, {
      headers: authHeader(),
    });
  }

  getPreferences(id) {
    return axios.get(`${userRoute}/${id}`, {
      headers: authHeader(),
    });
  }

  savePreferences(id, userData) {
    console.log(
      "savePreferences() id, userData authHeader() {userRoute}/{id}",
      id,
      userData,
      authHeader(),
      `${userRoute}/${id}`
    );
    return axios.post(
      `${userRoute}/${id}`,
      userData,
      {
        headers: authHeader(),
      },
    );
  }

  setAndSaveAvatar(id, userData) {
    return axios.post(
      `${setAvatarRoute}/${id}`,
      userData,
      {
        headers: authHeader(),
      },
    );
  }
}
export default new chatService();
