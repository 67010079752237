import ___DEBUG___ from "./debug.config";

// const __DEBUG__ = true && ___DEBUG___;
// const __DEBUG__ = false && ___DEBUG___;
const __DEBUG__ = ___DEBUG___;

const server = {
  // change that to the IPv4 address of the NAS or Akenaton
  // or whatever machine runs the node server
  // host: "http://192.168.1.17",
  // host: "http://192.168.1.13",
  // host: "http://192.168.1.18",
  host: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : // : "http://localhost",
      // host: "http://192.168.1.100",
      // "http://192.168.1.200",
      "https://pollucturum.com",
  wss: process.env.REACT_APP_WS_SERVER_URL
    ? process.env.REACT_APP_WS_SERVER_URL
    : // : "http://localhost",
      // host: "http://192.168.1.100",
      // "http://192.168.1.200",
      "https://pollucturum.com",
  cdn: process.env.REACT_APP_IMAGE_SERVER_URL
    ? process.env.REACT_APP_IMAGE_SERVER_URL
    : // : "http://localhost",
      // host: "http://192.168.1.100",
      "https://cdn.pollucturum.com/",
  // "http://192.168.1.200/cdn",
  // host: "http://192.168.1.20",
  // host: "http://nas-gsjkr.ddns.net",
  // ATTENTION: empty string evaluates to falsy
  port:
    process.env.REACT_APP_API_PORT || process.env.REACT_APP_API_PORT === ""
      ? process.env.REACT_APP_API_PORT
      : 4000,
};

if (__DEBUG__) {
  console.log(server.port);
  console.log(server);
  console.log(process.env.REACT_APP_API_PORT);
  console.log(process.env.REACT_APP_API_PORT === "");
  console.log(process.env.REACT_APP_API_PORT === undefined);
  console.log(process.env);
}
export default server;
