/**
 * boilerplate
 */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import KeyIcon from "@mui/icons-material/Key";

import Dropdown from "../ShoppingList/Dropdown";
// import options from "../ShoppingList/qtyUnitOptions";

import CookwareDataService from "../../services/cookware.service";
import CategoryDataService from "../../services/category.service";

import "./Cookware.css";
const ensembles = ["Kitchenware", "Measure"];

/**
 * component definition
 */

const AddCookware = () => {
  const { message } = useSelector((state) => state.message);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [categoryGroups, setCategoryGroups] = useState({});
  const [options, setOptions] = useState([[],[]]);

  const navigate = useNavigate();
  // var categoryGroups = [];

  const handleBackClick = () => {
    // 👇️ replace set to true
    navigate("/Cookware", { replace: true });
  };

  useEffect(() => {
    ensembles.map((ensemble, indx) => retrieveCategoryGroup(ensemble, indx));
    // const categoryGroups = ensembles.map((ensemble) =>
    //   retrieveCategoryGroup(ensemble)
    // );
    // console.log("categoryGroups ", categoryGroups);
  }, []);

  const retrieveCategoryGroup = (ensemble, indx) => {
    CategoryDataService.getAll({ name: "", ensemble: ensemble })
      .then((response) => {
        // var categoryGroup;
        // categoryGroup[indx]=response.data.categoryGroup;
        // console.log("categoryGroup[indx] ",indx, categoryGroup[indx]);
        // console.log(
        //   "response.data.categoryGroup ",
        //   response.data.categoryGroup
        // );
        // console.log("ensemble ", ensemble);
        // console.log("categoryGroup ", categoryGroup);
        setCategoryGroups((prevCategoryGroups) => {
          const newCategoryGroups = {
            ...prevCategoryGroups,
            [ensembles[indx]]: response.data.categoryGroup,
          };
          // console.log("newCategoryGroups ", indx, newCategoryGroups);
          setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[indx] = response.data.categoryGroup
              ? response.data.categoryGroup.categories.map((category) => ({
                  label: category.name,
                  value: category.name,
                }))
              : null;
            // console.log("newOptions ", indx, newOptions);
            return newOptions;
          });
          return newCategoryGroups;
        });

        // also possible to write it as array construct
        //
        // setCategoryGroups((prevCategoryGroups) => {
        //   const a = [...prevCategoryGroups];
        //   a[indx] = response.data.categoryGroup;
        //   return a;
        // });

        // console.log(response);
        // return response.data.categoryGroup;
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  const initialCookwaretate = {
    id: null,
    name: "",
    description: "",
    presentation: "",
    category: "",
    quantity: 1,
    qtyUnit: "",
    published: false,
    media: {
      images: {
        head: "",
      },
    },
  };

  const [cookware, setCookware] = useState(initialCookwaretate);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log("cookware ", cookware);
    let theCookware = {};
    if (name === "media-images-head") {
      const media = {};
      media.images = {};
      media.images.head = value;
      theCookware = { ...cookware, media: media };
    } else {
      theCookware = { ...cookware, [name]: value };
    }
    // console.log("theCookware ", theCookware);
    setCookware(theCookware);
  };

  const saveCookware = () => {
    var data = {
      name: cookware.name,
      description: cookware.description,
      presentation: cookware.presentation,
      category: cookware.category,
      quantity: cookware.quantity,
      qtyUnit: cookware.qtyUnit,
      published: false,
      media: {
        images: {
          head: cookware.media.images.head,
        },
      },
    };
    CookwareDataService.create(data)
      .then((response) => {
        // console.log("saveCookware() response data name", response.data.name);
        // console.log(
        //   "saveCookware() response data description",
        //   response.data.description
        // );
        // console.log("saveCookware() response data id", response.data.id);
        // console.log("saveCookware() response data", response.data);
        setCookware({
          id: response.data.id,
          name: response.data.cookware.name,
          description: response.data.cookware.description,
          presentation: response.data.cookware.presentation,
          category: response.data.cookware.category,
          quantity: response.data.cookware.quantity,
          qtyUnit: response.data.cookware.qtyUnit,
          published: response.data.cookware.published,
          media: {
            images: {
              head: response.data.cookware.media.images.head,
            },
          },
        });
        setSubmitted(true);
        // console.log("saveCookware() ", response.data);
      })
      .catch((e) => {
        console.log("saveCookware() ", e);
      });
  };

  const newCookware = () => {
    setCookware(initialCookwaretate);
    setSubmitted(false);
  };

  return (
    <div className="container">
      {/* {console.log(categoryGroups.Cookware)} */}
      {/* {console.log(options[0])} */}
      <KeyIcon style={{ position: "absolute", left: "0" }} />
      <div className="submit-form">
        {submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <button className="btn btn-success" onClick={newCookware}>
              Add
            </button>
            <button className="btn btn-success" onClick={handleBackClick}>
              Back
            </button>
          </div>
        ) : (
          <div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                name="name"
                type="text"
                className="form-control"
                id="name"
                required
                value={cookware.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                name="description"
                type="text"
                className="form-control"
                id="description"
                required
                value={cookware.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="presentation">Presentation</label>
              <input
                name="presentation"
                type="text"
                className="form-control"
                id="presentation"
                required
                value={cookware.presentation}
                onChange={handleInputChange}
              />
              {/* <label htmlFor="qtyUnit">Quantity Unit</label>
            <input
              name="qtyUnit"
              type="text"
              className="form-control"
              id="qtyUnit"
              required
              value={cookware.qtyUnit}
              onChange={handleInputChange}
            /> */}
            </div>
            <div className="form-group">
              {/* <label htmlFor="category">Category</label>
              <input
                name="category"
                type="text"
                className="form-control"
                id="category"
                required
                value={cookware.category}
                onChange={handleInputChange}
              /> */}
              <Dropdown
                myStyle={{
                  margin: "15pt 0pt 0pt 0pt",
                  // float: "left",
                  // maxWidth: "60pt",
                  width: "100%",
                }}
                label="Category"
                options={options[0] ? options[0] : []}
                // options={categoryGroups.Cookware.categories}
                value={cookware.category}
                className="form-control"
                id="category"
                required
                onChange={handleInputChange}
                name="category"
              />
            </div>
            <div className="form-group">
              <Dropdown
                myStyle={{
                  margin: "15pt 0pt 0pt 0pt",
                  // float: "left",
                  // maxWidth: "60pt",
                  width: "100%",
                }}
                label="Units"
                // options={options}
                options={options[1] ? options[1] : []}
                value={cookware.qtyUnit}
                className="form-control"
                id="qtyUnit"
                required
                onChange={handleInputChange}
                name="qtyUnit"
              />
              <br />
            </div>
            <div className="form-group">
              <label htmlFor="quantity">Quantity</label>
              <input
                name="quantity"
                type="text"
                className="form-control"
                id="quantity"
                required
                value={cookware.quantity}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="media-images-head">Picture</label>
              <input
                type="text"
                className="form-control"
                id="media-images-head"
                required
                value={
                  cookware.media &&
                  cookware.media.images &&
                  cookware.media.images.head
                    ? cookware.media.images.head
                    : ""
                }
                onChange={handleInputChange}
                name="media-images-head"
              />
            </div>
            <button onClick={saveCookware} className="btn btn-success">
              Submit
            </button>
            <button onClick={handleBackClick} className="btn btn-success">
              Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default AddCookware;
