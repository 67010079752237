import http from "../config/http-common";
import axios from "axios";
import authHeader from "./auth-header";

import server from "../config/server.config";

const API_URL =
  server.host + (server.port === "" ? "" : ":" + server.port) + "/api/user/";

class PurchaseHistoryDataService {

  getAll(params) {
    const response = http.get("/api/user/purchasehistory", {
      headers: authHeader(),
      params: params,
    });
    // console.log("response ", response);
    return response;
  }

  get(id) {
    return http.get(`/api/user/purchasehistory/${id}`, { headers: authHeader() });
  }

  create(data) {
    // console.log("data ", data);
    return http.post("/api/user/purchasehistory/add", data, { headers: authHeader() });
  }

  update(id, data) {
    console.log("data ", data)
    return http.put(`/api/user/purchasehistory/${id}`, data, { headers: authHeader() });
  }

  delete(id) {
    return http.delete(`/api/user/purchasehistory/${id}`, { headers: authHeader() });
  }

  deleteAll() {
    return http.delete(`/api/user/purchasehistory`, { headers: authHeader() });
  }

  findByName(name) {
    return http.get(`/api/user/purchasehistory?name=${name}`, {
      headers: authHeader(),
    });
  }

  getPurchaseHistory(user) {
    return axios.get(`${API_URL}purchasehistory/${user.id}`, {
      // timeout: 1000,
      headers: authHeader(),
    });
  }

  postPurchaseHistory(user, items) {
    const data = {
      userid: user.id,
      // shoppinglist: items,
      items: items,
    };
    // console.log(data);
    return axios.post(API_URL + "purchasehistory", data, {
      // `method` is the request method to be used when making the request
      method: "post", // default "get"
      headers: authHeader(),
      timeout: 1000, // default is `0` (no timeout)
    });
  }


}
export default new PurchaseHistoryDataService();



