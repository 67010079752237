// https://itc-engineering-blog.netlify.app/blogs/x-date-pickers

import * as React from "react";
import { Box, styled, TextField } from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  PickersDayProps,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enGB from "date-fns/locale/en-GB";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";
import addDays from "date-fns/addDays";
import isValid from "date-fns/isValid";

const maxDate = addDays(new Date(), 100);

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const DatePickerDay: React.FC = (props) => {
  const [value, setValue] = React.useState<Date | null>(null);
  const handleChange = (newValue: Date | null) => {
    // alert(newValue);
    if (isValid(newValue)) {
      // console.log(newValue);
      setValue(newValue);

      const start = startOfWeek(newValue);
      const end = endOfWeek(newValue);
      // console.log("start ", start, "end ", end);
      props.weekChosen(start, end);
    }
  };
  const styles = {
    mobiledialogprops: {
      ".MuiDatePickerToolbar-title": {
        fontSize: "1.5rem",
      },
      'div[class^="PrivatePickers"] div[class^="css-"]>span:nth-of-type(1)': {
        color: "rgba(255, 0, 0, 0.6)", // 日 = 赤
      },
      'div[class^="PrivatePickers"] div[class^="css-"]>span:nth-of-type(7)': {
        color: "rgba(0, 0, 255, 0.6)", // 土 = 青
      },
      'div[class^="PrivatePickers"] div[class^="css-"]>span': {
        margin: 0, // 見だしの曜日のところのマージン
      },
    },
    paperprops: {
      'div[class^="PrivatePickers"] div[class^="css-"]>span:nth-of-type(1)': {
        color: "rgba(255, 0, 0, 0.6)", // 日 = 赤
      },
      'div[class^="PrivatePickers"] div[class^="css-"]>span:nth-of-type(7)': {
        color: "rgba(0, 0, 255, 0.6)", // 土 = 青
      },
      'div[class^="PrivatePickers"] div[class^="css-"]>span': {
        margin: 0, // 見だしの曜日のところのマージン
      },
    },
  };
  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    const switchDayColor = (getday: number) => {
      switch (
      getday // Sun=0,Sat=6
      ) {
        case 0:
          return { color: "red" };
        case 6:
          return { color: "blue" };
        default:
          return {};
      }
    };

    // console.log(selectedDates);

    const newPickersDayProps = {
      ...pickersDayProps, // デフォルトのPickersDayコンポーネントのプロパティ
      ...{
        showDaysOutsideCurrentMonth: true, // カレンダーの表示月以外の日もレンダリング
        disableMargin: true, // 日付同士の margin: 0
        disabled: pickersDayProps.outsideCurrentMonth // カレンダーの表示月以外の日は選択不可
          ? true
          : pickersDayProps.disabled,
        sx: switchDayColor(date.getDay()),
      },
    };
    if (!value) {
      return <PickersDay {...newPickersDayProps} />; // 選択されていない場合、色塗り無しでレンダリング
    }

    const start = startOfWeek(value);
    const end = endOfWeek(value);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      // 選択されている場合、色塗り有りでレンダリング
      <CustomPickersDay
        {...newPickersDayProps}
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={enGB} // ja追加
      dateFormats={{ monthAndYear: "yyyy-MM", year: "yyyy" }} // カレンダー左上の日付表示 年選択を○○年表示
      localeText={{
        previousMonth: "Previous", // < のツールチップ
        nextMonth: "Next", // > のツールチップ
        cancelButtonLabel: "Cancel", // スマホ画面のCANCELボタン
        okButtonLabel: "OK", // スマホ画面のOKボタン
      }}
    >
      <Box
        sx={{
          m: 2,
          width: "200px",
        }}
      >
        <DatePicker
          label="Choose the Week"
          // 選択範囲は2021年～
          minDate={new Date("2023-01-01")}
          // 未来の日付無効
          maxDate={maxDate}
          value={value}
          onChange={handleChange}
          // 選択済みの日付の表示
          format="dd-MM-yyyy"
          mask="__-__-____"
          slotProps={{
            textField: {
              // width: 'fullWidth',
              variant: 'outlined',
              placeholder: "**dd**MM****yyyy",
            },
            toolbar: {
              toolbarFormat: "dd-MM-yyyy",
            },
            dialog: { sx: styles.mobiledialogprops },
            desktopPaper: { sx: styles.paperprops },
          }}
          // renderInput={(params) => (
          //   <TextField
          //     {...params}
          //     fullWidth
          //     inputProps={{
          //       ...params.inputProps,
          //       // placeholder: "****年**月**日の週", // プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
          //       placeholder: "**dd**MM****yyyy", // プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
          //     }}
          //   />
          // )}
          // 週末の色調整 週選択対応
          renderDay={renderWeekPickerDay}
        // slotProps={{
        //   toolbar: {
        //     toolbarFormat: "dd-MM-yyyy",
        //   },

        //   dialog: { sx: styles.mobiledialogprops },
        //   desktopPaper: { sx: styles.paperprops },
        // }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DatePickerDay;