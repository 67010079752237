import React, { useState, useEffect } from "react";
/**
 * https://stackoverflow.com/questions/61447326/make-a-alert-message-disappear-after-x-time-in-react-functional-component
 * @param {*} props
 * @returns the component if it should be visible and null if not
 */
function Expire(props) {
  const [isVisible, setIsVisible] = useState(true);
  const [children, setChildren] = useState(props.children);

  useEffect(() => {
    setChildren(props.children);
    setIsVisible(true);
    setTimer(props.delay);
  }, [props.children]);

  const setTimer = (delay) => {
    setTimeout(() => setIsVisible(false), delay);
  };

  return isVisible ? <div>{children}</div> : null;
}

export default Expire;
