import React, { useState, useEffect } from "react";

import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";

// import { PropaneSharp } from "@mui/icons-material";

const SearchBox = (props) => {
  const [destructureVector, setDestructureVector] = useState(
    props.destructureVector
  );
  const [destructureVectorSingle, setDestructureVectorSingle] = useState(
    destructureVector.slice(-1) === "s"
      ? props.destructureVector.slice(0, -1)
      : props.destructureVector
  );
  const [value, setValue] = useState(props.value ? props.value : "");
  const [values, setValues] = useState([]);
  const [resultsShown, setResultsShown] = useState(true);

  // const { destructureVector } = props;
  // let destructureVector;

  // useEffect(() => {
  //   destructureVector = props.destructureVector;
  //   console.log("destructureVector: ", destructureVector);
  // }, [props.destructureVector]);
  // console.log("props.destructureVector: ", props.destructureVector);
  // console.log("props.value: ", props.value);

  // this method clears the state in child component
  const clearState = (emptyItem) => {
    // console.log(
    //   "SearchBox::clearState() emptyItem",
    //   emptyItem
    //   //  indx, emptyItem",
    //   //   indx,
    //   //   emptyItem
    // );
    setValue("");
    setResultsShown(true);
    // props.onChange(emptyItem);
  };

  // pass the clearState function back to parent
  props.passClearState(clearState);

  // props.getState(item);

  const onSelect = (event, indx) => {
    // solution:
    // shadow vector with the available choices and the chosen item
    // values[indx]
    // name is not default property of <li>
    // value is not default property of <li>, but set to 0
    console.log("values[indx] ", indx, values);
    // const { name, value, id } = event.target;
    // console.log("value ", value);
    // console.log(event.target);

    // console.log("values[indx][destructureVector] ", values[indx][destructureVector]);
    // console.log("values[indx] ", values[indx]);
    // console.log("props.destructureVector ", props.destructureVector);
    if (destructureVector === "matchingRecipes") {
      // 22/12/2022 values became array of objects {name, id} and
      // so became name an object
      const chosenObj = values[indx];
      console.log("chosenObj, values, indx ", chosenObj, values, indx);
      setResultsShown(false);
      setValue((preValue) => {
        return chosenObj.name;
      });
      props.onSelect(props.name, chosenObj, value);
    } else {
      const { name, description, id } = values[indx][destructureVectorSingle];
      // console.log("values[indx] ", values[indx]);
      // console.log(
      //   "values[indx][destructureVectorSingle] ",
      //   values[indx][destructureVectorSingle]
      // );
      console.log("name, description, id ", name, description, id);
      setResultsShown(false);
      setValue((preValue) => {
        return name;
      });
      props.onSelect(name, description, id);
    }
    // setResultsShown(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log("name, value ", name, value);
    setValue(value);
    // if (value === "") setValues([]);
    // findByName();
  };

  const findByName = () => {
    // console.log("findByName() ", value);
    // IngredientDataService.findByName(value);
    props
      .find(value)
      .then((response) => {
        // const name = "matchingRecipes";
        // const name = "ingredients";
        // const name = destructureVector;
        // console.log("name ", name);

        // if nothing found the function returns "[]"
        // returns array of objects
        const { [destructureVector]: values } = response.data; //.name;
        // console.log("destructureVector values ", destructureVector, values);
        // console.log("response.data ", response.data);
        // console.log("response.data[name] ", response.data[name]);
        setValues(values);
        // console.log(value);
        // console.log(response.data.ingredients);
        // console.log(response.data);
        // console.log(response.data.ingredients[0][destructureVector].name);
      })
      .catch((e) => {
        // console.log(e);
        // console.log(response);
        setValues([]);
      });
  };

  function toggleResultsShown() {
    // console.log("toggleResultsShown() ran. resultsShown is: ", resultsShown);
    setResultsShown(!resultsShown);
  }

  useEffect(() => {
    // if (!resultsShown) setResultsShown(true);
    if (resultsShown) {
      if (value === "") {
        setValues([]);
      } else if (value.length < 3) {
      } else {
        findByName();
        setResultsShown(true);
      }
    }
    // console.log("useEffect() ran. value is: ", value);
    // console.log("useEffect() ran. resultsShown is: ", resultsShown);
  }, [value]); // 👈️ add state variables you want to track

  return (
    <React.Fragment>
      {props.label}
      <div className="list row">
        <div className={resultsShown ? "col-sm-12" : "col-sm-11"}>
          {/* <div className="input-group mb-3"> */}
          <div className="input-group">
            {/* {console.log("resultsShown", resultsShown)} */}
            {/* {console.log("value", value)} */}
            {/* {console.log("value.name", value.name)} */}
            <input
              disabled={!resultsShown}
              name="search"
              type="text"
              className="form-control"
              placeholder={
                props.placeholder ? props.placeholder : "Search by name"
              }
              // value={resultsShown ? value : value.name}
              value={value}
              onChange={handleChange}
            />
            {/* <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={findByName}
              >
                Search
              </button>
            </div> */}
          </div>
        </div>
        {resultsShown ? (
          ""
        ) : (
          <div className="col-sm-1">
            <EditIcon
              onClick={(event) => {
                event.preventDefault();
                toggleResultsShown();
              }}
            />
          </div>
        )}
      </div>
      {/* <input
            type={"text"}
            value={foundValue}
            // onChange={handleChange}
            disabled={isDisabled}
            placeholder={"Search"}
          /> */}
      {/* <h4>Ingredients List</h4> */}
      {/* {console.log("values ", values)} */}
      {resultsShown ? (
        <ul className="list-group">
          {values &&
            values.map((value, index) => (
              <li
                style={{ cursor: "pointer" }}
                className="list-group-item"
                onClick={(event) => onSelect(event, index)}
                key={index}
                name={
                  value[destructureVectorSingle] &&
                  value[destructureVectorSingle].name
                    ? value[destructureVectorSingle].name
                    : value.name
                }
                value={
                  value[destructureVectorSingle] &&
                  value[destructureVectorSingle].name
                    ? value[destructureVectorSingle].name
                    : value.name
                }
                id={
                  value[destructureVectorSingle] &&
                  value[destructureVectorSingle].id
                    ? value[destructureVectorSingle].id
                    : value.id
                }
              >
                {value[destructureVectorSingle] &&
                value[destructureVectorSingle].name
                  ? value[destructureVectorSingle].name
                  : value.name}
                {/* {console.log(value, value[destructureVectorSingle])} */}
                {/* {value[destructureVectorSingle] && value[destructureVectorSingle].name
                  ? value[destructureVectorSingle].name
                  : value.id} */}

                {/* <Checkbox
                  style={{ float: "right" }}
                  // checked={checked}
                  name={value[destructureVectorSingle].name}
                  id={value[destructureVectorSingle].id}
                  onChange={handleChkbChange}
                  inputProps={{ "aria-label": "controlled" }}
                /> */}
              </li>
            ))}
        </ul>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default SearchBox;
