import React, { useState, useEffect, createRef, useRef } from "react";
import { useSelector } from "react-redux";

import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";

// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";

// import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Fab } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";

import Button from "react-bootstrap/Button";

import Dropdown from "../../components/ShoppingList/Dropdown";
import CategorisedIngredients from "../../components/Ingredients/CategorisedIngredients";
import ListItems from "../../components/ShoppingList/ListItems";
import EditItemRow from "../../components/ShoppingList/EditItemRow";
import {
  saveShoppingList,
  savePurchaseHistory,
} from "../../components/ShoppingList/ShoppingList.Aux";
import DaySpinner from "../../components/MenuPlanning/DaySpinner";

import CategoryDataService from "../../services/category.service";
import ShoppingListService from "../../services/shoppingList.service";
import PurchaseHistoryService from "../../services/purchaseHistory.service";
import InventoryService from "../../services/inventory.service";
import saveInventory from "../../components/Inventory/Inventory.Aux";
import { compactList as compactInventory } from "../../helpers/helper";

/**
 * auth_db.shoppinglists is a collection of shoppingLists
 * each object is asigned to one and only one user, identified by the userid
 * each shoppingList is an object with
 * - id (of the object in the collection)
 * - userid
 * - items[] an array that contains the shopping list items which themselves
 *   are objects and contain the data (quantity, index, qtyUnit, ingredient,
 *   for the time being)
 *
 * Next step could be to introduce an array of shopping list for the same user
 * or alternatively several objects of the base collection with the same userid.
 */

// import { green } from "@mui/material/colors";

// assets
import "../../components/ShoppingList/ShoppingList.css";

// const useStyles = makeStyles((theme) => ({
//   grow: {
//     flexGrow: 1,
//   },
//   sectionMobile: {
//     display: "flex",
//     [theme.breakpoints.up("md")]: {
//       display: "none",
//     },
//   },
//   listItemsBox: {
//     color: "green", fontSize: "20pt", border: "5pt yellow solid"
//   }
// }));

let items = [
  { index: 1, ingredient: "pimientos", quantity: 5, qtyUnit: "uds" },
  { index: 2, ingredient: "tomates", quantity: 2, qtyUnit: "uds" },
  { index: 3, ingredient: "tomate triturado", quantity: 2, qtyUnit: "kg" },
  { index: 4, ingredient: "tomate frito", quantity: 4, qtyUnit: "l" },
  { index: 5, ingredient: "tomate en pasta", quantity: 3, qtyUnit: "latas" },
  { index: 6, ingredient: "huevos", quantity: 3, qtyUnit: "uds" },
  { index: 7, ingredient: "guisantes", quantity: 3, qtyUnit: "kg" },
  { index: 8, ingredient: "zanahorias", quantity: 3, qtyUnit: "uds" },
  { index: 9, ingredient: "acelgas", quantity: 1, qtyUnit: "fajo" },
  { index: 10, ingredient: "esparragos", quantity: 3, qtyUnit: "frascos" },
];

const emptyItem = {
  index: 1,
  ingredient: "",
  name: "",
  quantity: 5,
  qtyUnit: "uds",
  description: "",
};

const ensembles = ["Measure"];
const ensemble = "Ingredient";
const providers = ["Provider", "Día", "amazon", "Mercadona", "bofrost"];

const options = {
  // weekday: "long",
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

function ShoppingList() {
  const today = new Date();
  const [theDate, setTheDate] = useState(today);
  const [item, setItem] = useState(emptyItem);
  const [addingMode, setAddingMode] = useState(false);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  // const { message } = useSelector((state) => state.message);
  const [message, setMessage] = useState("");
  const { user: currentUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [isInError, setIsInError] = useState(false);
  const [provider, setProvider] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [categoryGroup, setCategoryGroup] = useState([]);
  const [categoryGroups, setCategoryGroups] = useState({});
  const [options, setOptions] = useState([
    //     [{ label: "", value: "", action: "" }],
    [],
  ]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [markThemAll, setMarkThemAll] = useState(false);

  let errorElement = null;
  // const listOfBoughtItems = [];
  // const classes = useStyles();
  let boughtItems = [];
  let leftItems = [];

  const myRef = useRef("there is no element");
  const cartValue = useRef(0);

  /**
   * database functions
   */

  const retrieveCategoryGroup = (ensemble) => {
    CategoryDataService.getAll({ name: "", ensemble: ensemble })
      .then((response) => {
        setCategoryGroup(response.data.categoryGroup);
        // console.log("response.data.categoryGroup ", response.data.categoryGroup);
        // console.log(response);
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  useEffect(() => {
    retrieveCategoryGroup(ensemble);
  }, []);

  async function getShoppingList() {
    return ShoppingListService.getShoppingList(currentUser)
      .then(
        (response) => {
          // console.log("response.data.totalItems ", response.data.totalItems);
          // console.log("response.data.items ", response.data.items);
          // console.log(
          //   "response.data.items[0].index ",
          //   // response.data.items[0].items[0].index
          //   response.data.items[0].index
          // );
          // console.log(response.status);
          // console.log(response.data);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // if (errorElement) {
          // errorElement.hidden = true;
          // errorElement.style.display = "none"; //"block";

          // errorElement.hidden = true;
          // errorElement.innerText = response.statusText;
          // errorElement.innerText = "";
          // errorElement.style.display = "block";
          // errorElement.style.display = "none";
          // }
          // setMessage(response.statusText);
          if (response.status === 200) {
            if (response.data.items.length === 0) {
              setMessage(
                "You have no items in your shopping cart. Please add items you need to your shopping list and then... enjoy shopping."
              );
            } else {
              // setMessage(response.statusText);
              setMessage("");
            }
          } else {
            // setMessage(response.statusText);
            setMessage(response.statusText);
          }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(false);
          setLoading(false);
          setIngredients((prevValue) => {
            // console.log(prevValue);
            // console.log(
            //   "getShoppingList() response.data.items ",
            //   response.data.items
            // );
            // return [...prevValue, ...response.data];
            // return [...response.data.items[0].items];
            let cart = 0;
            const listOfItemsToBuy = response.data.items.map((item, indx) => {
              console.log("item ", item);
              console.log("item.number ", item.number);
              const number = item.number ? item.number : 1;
              if (item.price)
                cart += Number.parseFloat(
                  (
                    Number.parseFloat(item.price) * Number.parseFloat(number)
                  ).toFixed(2)
                );
              return {
                ingredient: { ...item, index: indx, bought: false },
              };
            });
            cartValue.current = cart.toFixed(2);
            // console.log(
            //   "getShoppingList() listOfItemsToBuy ",
            //   listOfItemsToBuy
            // );
            return listOfItemsToBuy;
          });
          setItems((prevValue) => {
            // console.log(prevValue);
            // console.log(response.data);
            // return [...prevValue, ...response.data];
            // return [...response.data.items[0].items];
            const listOfItemsToBuy = response.data.items.map((item, indx) => ({
              ...item,
              index: indx,
              bought: false,
            }));
            // console.log(response.data.items);
            // console.log(listOfItemsToBuy);
            // return [...response.data.items];
            setFilteredItems(listOfItemsToBuy);
            return listOfItemsToBuy;
          });
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...")
          // const errorElement = myRef.current;
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // console.log("error: ", error ? error : "no error");
          // console.log("_content: ", _content ? _content : "no _content");

          // if (errorElement) {
          //   errorElement.hidden = false;
          //   errorElement.innerText = "No items found. Error: " + _content;
          //   errorElement.style.display = "block";
          // }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(true);
          setMessage(_content);

          // Set it to an empty array, since the user exists,
          // but it has NO items
          setItems([]);
          setLoading(false);
        }
        // ).catch((err) => (console.log(err)));
      )
      .catch(() => {
        setLoading(false);
      });
  }

  async function getPurchaseHistory() {
    return PurchaseHistoryService.getPurchaseHistory(currentUser)
      .then(
        (response) => {
          // console.log("response.data.totalItems ", response.data.totalItems);
          // console.log("response.data.items ", response.data.items);
          // console.log(
          //   "response.data.items[0].index ",
          //   // response.data.items[0].items[0].index
          //   response.data.items[0].index
          // );
          // console.log(response.status);
          // console.log(response.data);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // if (errorElement) {
          // errorElement.hidden = true;
          // errorElement.style.display = "none"; //"block";

          // errorElement.hidden = true;
          // errorElement.innerText = response.statusText;
          // errorElement.innerText = "";
          // errorElement.style.display = "block";
          // errorElement.style.display = "none";
          // }
          // setMessage(response.statusText);
          if (response.status === 200) {
            if (response.data.items.length === 0) {
              setMessage(
                "You have no items in your purchase history. Please add items you need to your shopping list and then... enjoy shopping."
              );
            } else {
              // setMessage(response.statusText);
              setMessage("");
            }
          } else {
            // setMessage(response.statusText);
            setMessage(response.statusText);
          }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(false);
          setLoading(false);
          setPurchases((prevValue) => {
            // console.log(prevValue);
            // console.log(response.data);
            // return [...prevValue, ...response.data];
            // return [...response.data.items[0].items];
            const listOfItemsToBuy = response.data.items.map((item, indx) => ({
              ...item,
              index: indx,
              bought: false,
            }));
            // console.log(response.data.items);
            // console.log(listOfItemsToBuy);
            // return [...response.data.items];
            return listOfItemsToBuy;
          });
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...")
          // const errorElement = myRef.current;
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          console.log("error: ", error ? error : "no error");
          console.log("_content: ", _content ? _content : "no _content");

          // if (errorElement) {
          //   errorElement.hidden = false;
          //   errorElement.innerText = "No items found. Error: " + _content;
          //   errorElement.style.display = "block";
          // }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(true);
          setMessage(_content);

          // Set it to an empty array, since the user exists,
          // but it has NO items
          setPurchases([]);
          setLoading(false);
        }
        // ).catch((err) => (console.log(err)));
      )
      .catch(() => {
        setLoading(false);
      });
  }

  async function getInventory() {
    return InventoryService.getInventory(currentUser)
      .then(
        (response) => {
          // console.log("response.data.totalItems ", response.data.totalItems);
          // console.log("response.data.items ", response.data.items);
          // console.log(
          //   "response.data.items[0].index ",
          //   // response.data.items[0].items[0].index
          //   response.data.items[0].index
          // );
          // console.log("getInventory() ", response.status);
          // console.log(response.data);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // if (errorElement) {
          // errorElement.hidden = true;
          // errorElement.style.display = "none"; //"block";

          // errorElement.hidden = true;
          // errorElement.innerText = response.statusText;
          // errorElement.innerText = "";
          // errorElement.style.display = "block";
          // errorElement.style.display = "none";
          // }
          // setMessage(response.statusText);
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          const inventoryItems = [...response.data.items];
          setInventoryItems((prevValue) => {
            // console.log(prevValue);
            // console.log(response.data);
            // return [...prevValue, ...response.data];
            // return [...response.data.items[0].items];
            return [...response.data.items];
          });

          // console.log("inventoryItems ", inventoryItems);
          // console.log("boughtItems ", boughtItems);

          const theItems = combineInventory(inventoryItems, boughtItems);
          setIsInError(true);
          setLoading(false);
          setMessage("OK");
          return theItems;
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...")
          // const errorElement = myRef.current;
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // console.log("error: ", error ? error : "no error");
          // console.log("_content: ", _content ? _content : "no _content");

          // if (errorElement) {
          //   errorElement.hidden = false;
          //   errorElement.innerText = "No items found. Error: " + _content;
          //   errorElement.style.display = "block";
          // }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(true);
          setMessage(_content);

          // Set it to an empty array, since the user exists,
          // but it has NO items
          setItems([]);
          setLoading(false);
        }
        // ).catch((err) => (console.log(err)));
      )
      .then((response) => {
        // console.log("2nd then: response ", response);
        // console.log("compact the inventory");

        const compactedInventory = compactInventory(response);
        // console.log("2nd then: response ", response);
        // console.log("2nd then: compactedInventory ", compactedInventory);

        // console.log("save the inventory");
        // const errorElement = myRef.current;
        // const errorElement = document.getElementById("error");
        // saveShoppingList(errorElement, currentUser, compactedShoppingList);
        saveInventory(currentUser, compactedInventory);
      })
      .catch((error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setIsInError(true);
        setMessage(_content);
        setLoading(false);
      });
  }

  function combineInventory(items, itemsToAddToInventory) {
    const theItems = [...items, ...itemsToAddToInventory];
    setInventoryItems(theItems);
    return theItems;
  }

  function onChkbChange(indx, chkbState) {
    // console.log("ShoppingList(): indx, chkbState ", indx, chkbState);
    items[indx].bought = chkbState;
    // console.log("ShoppingList(): items ", items);
  }

  function addToInventory(boughtItems) {
    // console.log(boughtItems);
    // console.log("bought");
    async function fetchInventory() {
      // You can await here
      const response = await getInventory();
      // ...
      return response;
    }
    if (boughtItems.length > 0) {
      fetchInventory();
      // console.log("fetchInventory");
    }
  }

  function onMoveToCart() {
    boughtItems = items.filter((item, indx) => item.bought);
    leftItems = items.filter((item, indx) => !item.bought);
    // console.log(boughtItems);
    let amazonShopCart = "";
    if (boughtItems.length > 0)
      amazonShopCart = boughtItems.reduce(
        (previousValue, currentValue, currentIndex, array) => {
          // console.log(
          //   "previousValue, currentValue, currentIndex, array ",
          //   previousValue,
          //   currentValue,
          //   currentIndex,
          //   array
          // );
          return previousValue + " " + currentValue.name;
        },
        ""
      );
    // console.log("amazonShopCart ", amazonShopCart);
    alert(`Push these items ${amazonShopCart} into amazon's shopping cart!`);
    doFiltering(provider, leftItems, markThemAll);
  }

  function onConfirmPurchase() {
    boughtItems = items.filter((item, indx) => item.bought);
    leftItems = items.filter((item, indx) => !item.bought);

    console.log("boughtItems ", boughtItems);
    console.log("leftItems ", leftItems);

    // the items left are still in the shopping list
    addToInventory(boughtItems);
    setItems(leftItems);
    saveShoppingList(currentUser, leftItems);
    updatePurchaseHistory(boughtItems);
    doFiltering(provider, leftItems, markThemAll);
  }

  function updatePurchaseHistory(boughtItems) {
    setPurchases((prevValue) => {
      // console.log("prevValue ", prevValue);

      const newPurchases = boughtItems.map((item, indx) => {
        // console.log("item ", item);
        let alreadyPurchased = false;
        let theIndex;
        for (let i = 0; i < prevValue.length; i++) {
          // console.log("prevValue vs item ", prevValue[i].name, item.name);
          if (prevValue[i].id === item.id) {
            // console.log("alreadyPurchased ", prevValue[i].name, item.name);
            alreadyPurchased = true;
            theIndex = i;
            break;
          } else {
            alreadyPurchased = false;
          }
        }
        // console.log("alreadyPurchased ", theIndex, alreadyPurchased);

        let purchase;
        const rightNow = theDate; // Date.now();
        if (alreadyPurchased) {
          const purchasedOn = prevValue[theIndex].purchasedOn;
          purchase = {
            name: item.name,
            id: item.id,
            recurrent: false,
            frequency: "",
            lastPurchased: rightNow,
            firstPurchased: prevValue.firstPurchased,
            purchasedOn: [...purchasedOn, { purchaseDate: rightNow }],
          };
        } else {
          purchase = {
            name: item.name,
            id: item.id,
            recurrent: false,
            frequency: "",
            lastPurchased: rightNow,
            firstPurchased: rightNow,
            purchasedOn: [{ purchaseDate: rightNow }],
          };
        }

        // console.log("purchase ", purchase);
        return purchase;
      });
      // const purchases = { items: [...prevValue, ...newPurchases] };
      const purchases = [...prevValue, ...newPurchases];
      // console.log("purchases ", purchases);
      savePurchaseHistory(currentUser, purchases);
      return purchases;
    });
  }

  function onDelete(id) {
    // console.log("before ", items);
    // console.log("id ", id);
    let theItems = [...items];

    // delete the entry with index "id"
    theItems.splice(id, 1);
    // compactify the array, move all elements with index >= id one down
    theItems.filter((item, index) => {
      if (index >= id) {
        // console.log("index, id, item.index", index, id, item.index);
        item.index--;
        // console.log("index, id, item.index", index, id, item.index);
        // item.index = item.index - 1;
      }
    });
    // here all the index elements have to be updated
    console.log("after ", theItems, theItems.length);
    setItems(theItems);
    // const errorElement = document.getElementById("error");
    saveShoppingList(currentUser, theItems);
    doFiltering(provider, theItems, markThemAll);
  }

  function onEdit(id) {
    // console.log(id);
  }

  function onSave(id, value) {
    let theItems = [...items];
    if (id === -1) {
      theItems.push(value);
      theItems[theItems.length - 1].index = theItems.length;
    } else {
      theItems[id] = value;
    }
    // console.log("ShoppingList/onSave currentUser", currentUser);
    // console.log("ShoppingList/onSave id, value, theItems", id, value, theItems);
    setItems(theItems);
    // const errorElement = document.getElementById("error");
    saveShoppingList(currentUser, theItems);
  }

  function handleClick() {
    setAddingMode(!addingMode);
  }

  useEffect(() => {
    const retrieveCategoryGroup = (ensemble, indx) => {
      CategoryDataService.getAll({ name: "", ensemble: ensemble })
        .then((response) => {
          // var categoryGroup;
          // categoryGroup[indx]=response.data.categoryGroup;
          // console.log("categoryGroup[indx] ",indx, categoryGroup[indx]);
          // console.log(
          //   "response.data.categoryGroup ",
          //   response.data.categoryGroup
          // );
          // console.log("ensemble ", ensemble);
          // console.log("categoryGroup ", categoryGroup);
          const categoryGroup = response.data.categoryGroup;
          setCategoryGroups((prevCategoryGroups) => {
            const newCategoryGroups = {
              ...prevCategoryGroups,
              [ensembles[indx]]: categoryGroup,
            };
            // console.log("newCategoryGroups ", indx, newCategoryGroups);

            // move this into the getRecipe function since there currentRecipe is available
            // and it decouples setOptions from categoryGroups

            setOptions((prevOptions) => {
              const newOptions = [...prevOptions];
              // console.log("currentRecipe ", currentRecipe);
              newOptions[indx] = categoryGroup
                ? categoryGroup.categories.map((category, indx) => {
                    // console.log(
                    //   currentRecipe.recipe.cookWays.includes(category.name)
                    // );
                    return {
                      // label: currentRecipe.recipe.cookWays.includes(category.name)
                      //   ? category.name
                      //   : null,
                      label: category.name,
                      value: category.name,
                      action: "",
                    };
                  })
                : [{ label: "pcs", value: "pcs" }];
              // console.log("newOptions ", indx, newOptions);
              return newOptions;
            });

            return newCategoryGroups;
          });

          // also possible to write it as array construct
          //
          // setCategoryGroups((prevCategoryGroups) => {
          //   const a = [...prevCategoryGroups];
          //   a[indx] = response.data.categoryGroup;
          //   return a;
          // });

          // console.log(response);
          // return response.data.categoryGroup;
        })
        .catch((e) => {
          console.log("retrieveCategoryGroup: ", e);
        });
    };
    ensembles.map((ensemble, indx) => retrieveCategoryGroup(ensemble, indx));
    // const errorElement = document.getElementById("error");
    // errorElement.hidden = true;
    // errorElement.style.display = "none"; //"block";
    // errorElement = myRef.current;
    // console.log(
    //   "errorElement: ",
    //   errorElement ? errorElement : "no useEffect ..."
    // );

    // saveShoppingList(errorElement, currentUser, items);
    setLoading(true);
    async function fetchShoppingList() {
      // You can await here
      const response = await getShoppingList();
      const purchaseHistoryResponse = await getPurchaseHistory();
      // ...
      return response;
    }
    fetchShoppingList();
    // console.log("updating");
  }, []);

  // console.log("listItemsBox ", listItemsBox);

  const fabStyle = {
    position: "absolute",
    bottom: "16px",
    right: "16px",
  };
  const fab = {
    color: "primary",
    sx: fabStyle,
    icon: AddIcon,
    label: "Add",
  };

  // useEffect(
  //   (value) => {
  //     doFiltering(value, items, markThemAll);
  //   },
  //   [markThemAll]
  // );

  function doFiltering(value, items, markThemAll) {
    if (!value) value = "Provider";
    let theItems = items
      // .filter((item, index) => item.provider === value || value === "Provider")
      .map((item, indx) => {
        // console.log("item ", item);
        // console.log("markThemAll ", markThemAll);
        return { ...item, index: indx, bought: markThemAll };
      });
    // console.log("value ", value);
    // console.log("items ", items.length);
    // console.log("filtered items ", theItems.length);
    console.log("theItems ", theItems);
    // update the "bought" status of the items
    setItems(theItems);
    // filter the items with respect to provider
    theItems = items.filter(
      (item, index) => item.provider === value || value === "Provider"
    );
    setIngredients((prevValue) => {
      // console.log(prevValue);
      // console.log(
      //   "getShoppingList() response.data.items ",
      //   response.data.items
      // );
      // return [...prevValue, ...response.data];
      // return [...response.data.items[0].items];
      let cart = 0;
      const listOfItemsToBuy = theItems.map((item, indx) => {
        // console.log("item ", item);
        if (item.price)
          cart += Number.parseFloat(
            (
              Number.parseFloat(item.price) * Number.parseFloat(item.number)
            ).toFixed(2)
          );
        return {
          ingredient: { ...item, index: indx, bought: markThemAll },
        };
      });
      cartValue.current = cart.toFixed(2);
      console.log("getShoppingList() listOfItemsToBuy ", listOfItemsToBuy);
      return listOfItemsToBuy;
    });
    setFilteredItems(theItems);
  }

  function handleDropdownChange(event) {
    const { name, value } = event.target;
    setProvider(value);
    doFiltering(value, items, markThemAll);
  }

  const setActiveIngredient = (ingredient, index) => {
    // console.log("ingredient ", ingredient);
    // console.log("index ", index);
    // setCurrentIngredient(ingredient);
    setCurrentIndex(index);
  };

  function handleMarkThemAll() {
    // console.log(markThemAll);
    setMarkThemAll((markThemAll) => {
      markThemAll = !markThemAll;
      doFiltering(provider, filteredItems, markThemAll);
      return markThemAll;
    });
    // console.log(markThemAll);
  }

  if (!loading) {
    return (
      <div className="ShoppingList">
        <KeyIcon style={{ position: "absolute", left: "0" }} />
        <header className="ShoppingList-header">
          <h1>
            <ShoppingCartIcon /> Shopping List <ShoppingBasketIcon />
          </h1>
          <h2>Create, edit and delete items you want to shop</h2>

          <div className="row" style={{ width: "100%", fontSize: "1rem" }}>
            <div className="col-3" style={{ textAlign: "right" }}>
              Select your
            </div>
            <div className="col-5">
              <Form>
                <div className="form-group">
                  {/* <label htmlFor="provider">Preferred Provider</label> */}
                  <Dropdown
                    myStyle={{
                      // margin: "15pt 0pt 0pt 0pt",
                      // float: "left",
                      // maxWidth: "60pt",
                      width: "100%",
                    }}
                    label="Provider"
                    // options={options}
                    options={providers}
                    value={provider}
                    className="form-control"
                    id="provider"
                    required
                    onChange={handleDropdownChange}
                    name="provider"
                  />
                </div>
              </Form>
            </div>
            <div className="col-4">
              Price of actual cart: {cartValue.current} €.
            </div>
          </div>
          {/* <input
                        type="text"
                        className="form-control"
                        id="provider"
                        name="provider"
                        value={currentIngredient.ingredient.provider}
                        onChange={handleInputChange}
                      /> */}
        </header>
        {isInError ? (
          <Alert
            ref={myRef}
            id="error"
            key="danger"
            variant="danger"
            // style={{ display: "none" }}
          >
            {/* <p>{message}</p> */}
            {message}
          </Alert>
        ) : // null
        message !== "" ? (
          <Alert
            ref={myRef}
            id="error"
            key="info"
            variant="info"
            // style={{ display: "none" }}
          >
            {/* <p>{message}</p> */}
            {message}
          </Alert>
        ) : null}
        {/* {console.log("items ", items)} */}
        {filteredItems.length > 0 ? (
          <div>
            <Button
              variant="info"
              onClick={onConfirmPurchase}
              style={{ margin: "10pt" }}
            >
              Confirm you purchased the marked items (checked out items)
            </Button>
            <DaySpinner setTheDate={setTheDate} theDate={theDate} />
            <Button
              variant="info"
              onClick={handleMarkThemAll}
              style={{ margin: "10pt" }}
            >
              {markThemAll ? "Unmark all items" : "Mark all items"}
            </Button>
            {console.log("markThemAll ", markThemAll)}
            {/* {console.log("theDate ", theDate)} */}
            <Button
              variant="info"
              onClick={onMoveToCart}
              style={{ margin: "10pt" }}
            >
              Put checked out items in the shopping cart of your favourite
              provider (amazon)
            </Button>
          </div>
        ) : // "nothing here"
        null}

        {/* <div>
          <FormControl>
            {console.log("name, label, value ", name, label, value)}
            {console.log("options ", options)}
            <InputLabel id={"provider"}>Provider</InputLabel>
            <Select
              // disabled={disabled}
              id={"provider"}
              name={"provider"}
              value={provider}
              onChange={handleDropdownChange}
            >
              {providers.map((provider, idx) => {
                // console.log("provider", provider);
                return (
                  <MenuItem key={idx} value={provider}>
                    {provider}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div> */}

        <div style={{ margin: "10pt" }}>
          <span
            style={{
              backgroundColor: "lightgrey",
              padding: "10pt",
              color: "black",
              margin: "10pt",
              borderRadius: "10pt",
            }}
          >
            There are {filteredItems.length} items in your shoppinglist
          </span>
        </div>
        {filteredItems.length > 0 ? (
          <ListItems
            title={"Ingredients"}
            hasChkBox={true}
            onChkbChange={onChkbChange}
            isChecked={markThemAll}
            key={Date.now()}
            cssClass={"listItemsBox"}
            items={filteredItems}
            onDelete={onDelete}
            onEdit={onEdit}
            onSave={onSave}
            options={options[0]}
          />
        ) : // "nothing here"
        null}
        {addingMode ? (
          <div
            style={{
              // width: "600pt",
              width: "100%",
              float: "left",
              borderRadius: "5pt",
              backgroundColor: "white",
            }}
          >
            <EditItemRow
              collection={"Ingredients"}
              value={item}
              addingMode={addingMode}
              setAddingMode={setAddingMode}
              // setEditMode={setEditMode}
              editMode={true}
              onSave={onSave}
              onEdit={onEdit}
            />
          </div>
        ) : (
          <Fab
            sx={fab.sx}
            aria-label={fab.label}
            onClick={(event) => {
              event.preventDefault();
              setAddingMode(!addingMode);
            }}
            color={fab.color}
          >
            {/* <AddIcon /> */}
            <AddIcon
              onClick={(event) => {
                event.preventDefault();
                setAddingMode(!addingMode);
              }}
            />
          </Fab>
        )}
        <div style={{ width: "92%" }}>
          {/* {console.log("ingredients ", ingredients)} */}
          {/* {console.log("categoryGroup ", categoryGroup)} */}
          {ingredients.length ? (
            <CategorisedIngredients
              categoryGroup={categoryGroup}
              // ingredients={[{ ingredient: list?.items }]}
              ingredients={ingredients}
              setActiveIngredient={setActiveIngredient}
              currentIndex={currentIndex}
            />
          ) : null}
        </div>
        <div className="mt-5">
          <h2>Your shopping/purchases history</h2>
          <div style={{ width: "92%" }}>
            <Accordion
              className="Accordion"
              // defaultActiveKey=""
              // flush
              // defaultActiveKey={["0"]}
              // alwaysOpen
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header
                // className={"list-group-item "}
                // style={{
                //   fontWeight: "bold",
                //   paddingLeft: "5px",
                //   fontSize: "large",
                // }}
                >
                  {/* <span
                style={{
                  fontWeight: "bold",
                  paddingLeft: "5px",
                  fontSize: "large",
                }}
              > */}
                  Your shopping/purchases history
                  {/* </span> */}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="list row">
                    {purchases?.map((purchase, indx) => {
                      return (
                        <div className="col-xl-3 col-lg-4 col-md-6" key={indx}>
                          <Card
                            className="card"
                            style={{ width: "18rem", margin: "10px auto" }}
                          >
                            <Card.Body>
                              <Card.Title>
                                Product name: {purchase.name}
                              </Card.Title>
                              <div>
                                <label>
                                  <strong>Description:</strong>
                                </label>
                              </div>
                              <Card.Text>Product id: {purchase.id}</Card.Text>
                              <Card.Text>
                                Product first purchased:{" "}
                                {new Date(
                                  purchase.firstPurchased
                                ).toLocaleDateString("de-DE", options)}
                              </Card.Text>
                              <Card.Text>
                                Product last purchased:{" "}
                                {new Date(
                                  purchase.lastPurchased
                                ).toLocaleDateString("de-DE", options)}
                              </Card.Text>
                              <div>
                                Product purchased on:{" "}
                                <ul>
                                  {purchase.purchasedOn?.map((item, indx) => {
                                    return (
                                      <li key={indx}>
                                        {new Date(
                                          item.purchaseDate
                                        ).toLocaleDateString("de-DE", options)}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                              {purchase.recurrent ? (
                                <>
                                  <Card.Text>
                                    Product recurrent: {purchase.recurrent}
                                  </Card.Text>
                                  <Card.Text>
                                    Product frequency: {purchase.frequency}
                                  </Card.Text>
                                </>
                              ) : null}
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
}

export default ShoppingList;
