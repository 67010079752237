import React, { useState, useEffect, useRef } from "react";

import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";

import GrowingExtendedListItem from "../ShoppingList/GrowingExtendedListItem";

import GrowingListItem from "./GrowingListItem";

const emptyItem = {
  index: 1,
  name: "",
  quantity: 0,
  qtyUnit: "uds",
  description: "",
};

const GrowingList = (props) => {
  const { title, stepLabel, isExtended, growingList, onSave: onSaveGL, options } = props;
  // let mood = Date.now();
  const startingLength = growingList.length;
  // const [growingList, setitems] = useState(growingList);
  const [item, setItem] = useState(isExtended ? emptyItem : "");
  const [step, setStep] = useState(startingLength);
  const [isDisabled, setIsDisabled] = useState(true);
  // const [state, setState] = useState(Date.now());
  const [editMode, setEditMode] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [addingMode, setAddingMode] = useState(false);

  let collection = props.title;
  // console.log("collection: ", collection);
  // console.log("startingLength: ", startingLength);

  useEffect(() => {
    collection = props.title;
    // console.log("collection: ", collection);
  }, [props.title]);
  // console.log("props.value: ", props.value);

  // let data = Array.isArray(growingList) ? growingList : [];

  function onEdit(isDisabled) {
    setIsDisabled(isDisabled);
    // console.log(id);
  }
  useEffect(() => {
  }, [editMode, isDisabled]);

  //this is the reference to the child clearState function
  // let clearGrandChildState = null;
  const clearGrandChildState = useRef(null);

  // this method assigns the child clearState function 
  // to the parent reference
  function assignClearChildState(grandChildClearStateFunc) {
    // console.log("assignClearChildState ", grandChildClearStateFunc);
    clearGrandChildState.current = grandChildClearStateFunc;
  }
  // this method gets the child state for completeness of
  // the childComp tag
  function getStateCallback(item) {
    //do something to use the child state
    console.log("item ", item)
  }
  
  function resetForm() {
    console.log("isExtended ", isExtended);
    console.log("clearChildState() ", clearGrandChildState.current);
    // if (clearGrandChildState.current)
    //   clearGrandChildState.current(isExtended ? emptyItem : "");
    if (isExtended) clearGrandChildState.current(emptyItem );
  }

  const addStep = (event) => {
    setStep(step+1);
    setEditMode(true);
    setIsDisabled(false);
    setItem(isExtended ? emptyItem : "");
    resetForm();
    // if (isExtended) resetForm();
    // const innerStep = step + 1;
    // setStep(innerStep);
    // console.log("addStep(): step innerStep ", step, innerStep);
    // handleClickOnSave();
    // setitems(growingList);
  };

  /**
   *
   * @param {*} indx
   * @param {*} value - is the value object (e.g. ingredient)
   *
   * is called from GrowingExtendedListItem which in turn gets
   * its call from EditItemRow
   * hypothesis: by calling it NO state is changed and hence
   * the app won't be rendered.
   */
  const onSave = (indx, value) => {
    // event,
    // console.log("GrowingList/onSave() ", indx, value);
    // console.log("event.name ", event.name);
    // console.log("GrowingList/onSave() event.name ", event.name);
    if (indx === undefined) {
      growingList.push(value);
    } else {
      growingList[indx] = value;
      // console.log(
      //   "GrowingList/onSave()  indx, value, growingList[indx] ",
      //   indx,
      //   value,
      //   growingList[indx]
      // );
    }
    // setitems(growingList);
    onSaveGL(growingList);
    // console.log(value,
    //   "GrowingList::onSave(): value indx growingList ",
    //   value,
    //   indx,
    //   growingList
    // );
    // data = Array.isArray(growingList) ? growingList : [];
    // setItem(value);
    setIsUpdated(true);
  };

  const handleClickOnSave = () => {
    // const value = item;
    // setItem(value);
    onSave(step, item);
    // mood = Date.now();
    // console.log(
    //   "handleClickOnSave() step item growingList",
    //   step,
    //   item,
    //   growingList
    // );
    setIsDisabled(true);
    // setItem(emptyItem);
  };

  const onDelete = (indx) => {
    // console.log("growingList.length ", growingList.length);
    growingList.splice(indx, 1);
    const innerStep = step - 1;
    setStep(innerStep);
    // setState(Date.now());
    // console.log("delete(indx) ", indx);
    // console.log("innerStep ", innerStep);
    // console.log("growingList.length ", growingList.length);
    onSaveGL(growingList);
  };

  const handleInputChange = (event) => {
    setIsDisabled(false);
    const { name, value } = event.target;
    // if (name === "growingList") {
    // const growingList = [];
    setItem(value);
  };

  const onChange = (value) => {
    setIsDisabled(false);
    setItem(value);
    // console.log("value ", value);
  };

  const fabStyle = {
    position: "absolute",
    bottom: "16px",
    right: "16px",
  };
  const fab = {
    color: "primary",
    sx: fabStyle,
    icon: AddIcon,
    label: "Add",
  };
  // console.log("growingList: ", growingList);
  // console.log("growingList.length ", growingList.length);

  // useEffect(() => {
  //   setState(Date.now());
  // }, [item, data]);

  // useEffect(() => {
  //   function handleStatusChange(status) {
  //     console.log(status);
  //   }
  //   handleStatusChange("normal call() " + isUpdated);
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     handleStatusChange("cleanup() " + isUpdated);
  //   };
  // }, [item, isUpdated]);

  return (
    <div
      className="form-group GrowingList"
      // style={{
      //   margin: "0pt",
      //   padding: "5pt",
      //   // backgroundColor: "#dfdfdf",
      //   backgroundColor: "rgb(123,123,123)",
      //   borderRadius: "5pt",
      //   // border: "red 1pt solid",
      // }}
    >
      <Fab
        sx={fab.sx}
        aria-label={fab.label}
        onClick={addStep}
        color={fab.color}
      >
        <AddIcon />
      </Fab>
      {/* {console.log("step ", step)} */}
      {/* <button className="m-3 btn btn-sm btn-danger" onClick={addStep}> */}
      {isDisabled ? (
        <div style={{ float: "right" }}>
          <AddIcon style={{ float: "right" }} onClick={addStep} />
        </div>
      ) : (
        // ""
        <div
          onClick={handleClickOnSave}
          style={{ float: "right", cursor: "pointer" }}
        >
          <SaveIcon />
          Save this {stepLabel}
        </div>
      )}
      {/* </button> */}
      <div>
        <label htmlFor="growingList">{title}</label>
      </div>
      {/* {growingList ? console.log("growingList ", growingList) : ""}
      {growingList
        ? console.log("growingList[step] ", step, growingList[step])
        : ""} */}
      <div
        className="RowWithGrowingExtendedListItem"
        // style={{ display: "block" }}
      >
        <div style={{ float: "left", width: "20%", margin: "5pt" }}>
          {stepLabel} {step + 1}:
        </div>
        {isExtended ? (
          <GrowingExtendedListItem
            // isChecked={true}
            // this passes the assignClearChildState function 
            // from parent to child so the child can use it 
            // to pass the clearState back
            getState={getStateCallback}
            passClearState={assignClearChildState}
            collection={collection}
            indx={step}
            value={item}
            setItem={setItem}
            addingMode={addingMode}
            setAddingMode={setAddingMode}
            editMode={editMode}
            setEditMode={setEditMode}
            // addStep={addStep}
            onSave={onSave}
            onChange={onChange}
            onEdit={onEdit}
            id="growingList"
            options={options}
          />
        ) : (
          <div style={{ float: "left", width: "80%" }}>
            <input
              type="text"
              className="form-control"
              id="growingList"
              name="growingList"
              value={item}
              onChange={handleInputChange}
            />
          </div>
        )}
        <div style={{ clear: "both" }}></div>
      </div>
      {growingList.length ? (
        <div>
          <hr style={{ borderTopWidth: "3px" }} />
        </div>
      ) : null}
      {/*
       *
       * The list of already added items
       *
       */}
      <div
        // key={state}
        style={{
          backgroundColor: "#cdcdcd",
          borderRadius: "5pt",
          padding: "2pt",
          // margin: "5pt",
          // border: "green 1pt solid",
        }}
      >
        {/* <div> */}
        {/* {data.map((value, indx) => { */}
        {growingList.map((value, indx) => {
          // console.log("growingList.map() value indx", value, indx);
          return isExtended ? (
            <div key={indx} className="RowWithGrowingExtendedListItem">
              <div style={{ float: "left", margin: "5pt", width: "20%" }}>
                {stepLabel} {indx + 1}: &nbsp;
              </div>
              <GrowingExtendedListItem
                // isChecked={true}
                collection={collection}
                getState={getStateCallback}
                passClearState={assignClearChildState}
                key={value}
                indx={indx}
                myKey={indx + indx}
                // myKey={item.index.toString()}
                // key={idx}
                addingMode={addingMode}
                setAddingMode={setAddingMode}
                editMode={false}
                setEditMode={setEditMode}
                stepLabel={stepLabel}
                // value={typeof value === "string" ? value : value.ingredient}
                value={value}
                onSave={onSave}
                onDelete={onDelete}
                onEdit={onEdit}
                onChange={onChange}
                options={options}
              />
              <div style={{ clear: "both" }}></div>
            </div>
          ) : (
            <GrowingListItem
              key={value}
              indx={indx}
              value={typeof value === "string" ? value : value.ingredient}
              stepLabel={stepLabel}
              onSave={onSave}
              onDelete={onDelete}
              // mood={mood}
              // growingList={growingList}
            />
          );
        })}
      </div>
    </div>
  );
};
export default GrowingList;
