import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { withTheme } from "styled-components";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../assets/img/loader.gif";
import { Buffer } from "buffer";
import ThemeSwitcher from "../ThemeSwitcher";

import chatService from "../../services/chat.service";

function SetAvatar({ toggleTheme, theme }) {
  const api = "https://api.multiavatar.com/451554425";

  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(undefined);
  const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isRetry, setIsRetry] = useState(false);
  const [isRetryReady, setIsRetryReady] = useState(false);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  async function setProfilePicture() {
    console.log(selectedAvatar);
    if (selectedAvatar === undefined) {
      toast.error("Please select an avatar.", toastOptions);
      return false;
    } else {
      const { data } = await chatService.setAndSaveAvatar(
        currentUser.id,
        { image: avatars[selectedAvatar] }
      );
      if (data.isSet) {
        currentUser.isAvatarImageSet = true;
        currentUser.avatarImage = data.image;
        localStorage.setItem(
          process.env.REACT_APP_LOCALHOST_KEY,
          JSON.stringify(currentUser)
        );
        return navigate("/chat");
      } else {
        toast.error(
          "Error setting your avatar. Please try again.",
          toastOptions
        );
        return false;
      }
    }
  }
  // function getImages() {}
  async function getImages() {
    const myPromises = [];
    for (let i = 0; i < 4; i++) {
      try {
        myPromises.push(
          axios.get(`${api}/${Math.round(Math.random() * 1000)}`)
          // .then((image) => {
          //   // const  = getImage();
          //   // console.log(image);
          //   // console.log(image.data);
          //   const imageData = image.data;
          //   // console.log(imageData);
          //   const buffer = Buffer.from(imageData.toString("base64"));
          //   // console.log(buffer);
          //   // data.push(buffer.toString());
          //   data.push(buffer);
          //   console.log(data);
          // })
          // .catch((e) => {
          //   console.log(e);
          // })
        );
      } catch (error) {
        console.log(error);
      }
    }
    Promise.all(myPromises)
      .then((values) => {
        const data = [];
        // console.log(values);
        for (let i = 0; i < 4; i++) {
          const imageData = values[i].data;
          const buffer = Buffer.from(imageData);
          data.push(buffer.toString("base64"));
          // console.log(data[i]);
          // values.forEach()
        }
        setAvatars(data);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.message);
        // console.log(error.response);
        // console.log(error.response.status);
        // console.log(error.response.statusText);
        // console.log(error.response.data);
        if (error.response.status === 503) {
          setIsLoading(false);
          toastOptions.autoClose = 60000;
          toast.error(
            "Service Temporarily Unavailable. Please retry in 60 seconds.",
            toastOptions
          );
          setIsRetry(true);
          setIsRetryReady(false);
          setTimeout(() => setIsRetryReady(true), 60000);
          // status: 503
          // statusText: "Service Unavailable"
          // data: "Limit reached: 10 calls/min"
        } else {
          console.log(error);
        }
      });
  }

  useEffect(() => {
    async function getAvatars() {
      await getImages();
    }
    async function getUser() {
      if (!localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
        return navigate("/");
      }
      const user = await JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
      );
      // const user = localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY);
      const isSet = user.isAvatarImageSet;
      if (isSet) {
        // enable the user to change its avatar
        // return navigate("/chat");
        toast.error(
          "Your profile is set already. Do you want to change your avatar?",
          toastOptions
        );
      }
      setCurrentUser(user);
    }
    getUser();
    getAvatars();
  }, []);

  function checkRetry() {}

  async function handleRetryOnClick(event) {
    console.log("Retry Button clicked");
    console.log(event);
    await getImages();
    setIsRetry(false);
    event.currentTarget.disabled = true;
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Container>
          <img src={loader} alt="loader" className="loader" />
        </Container>
      ) : (
        <Container>
          {/* <button
            type="button"
            disabled
            // {!isRetryReady}
            // onClick={handleRetryOnClick}
          >
            Retry
          </button>

          <button
            disabled={false}
            // {!isRetryReady}
            // onClick={handleRetryOnClick}
          >
            Retry
          </button> */}
          {isRetry ? (
            <div>
              {/* This will NOT work, since this is a
              bootstrap styled button, but not a bootstrap-react
              component, hence the very presence of "disabled"
              either in "true" or "false" state won't be interpreted
              as in a component as a prop but as a pure HTML qualifier
              and its mere presence will disable all variations.           
              <button
                type="button"
                // {!isRetryReady}
                onClick={handleRetryOnClick}
                disabled={isRetryReady ? "false" : "true"}
              >
                Retry
              </button> */}

              {isRetryReady ? (
                <button
                  type="button"
                  // {!isRetryReady}
                  onClick={handleRetryOnClick}
                >
                  Retry
                </button>
              ) : (
                <button
                  type="button"
                  disabled
                  // s
                  //  onClick={handleRetryOnClick}
                >
                  Retry
                </button>
              )}
            </div>
          ) : null}
          <ThemeSwitcher toggleTheme={toggleTheme} choosenTheme={theme.name} />
          <div className="header-container">
            <h1>Your avatar or profile picture</h1>
          </div>
          {currentUser.isAvatarImageSet ? (
            <div className="avatars">
              <div className={"avatar selected"}>
                <img
                  src={`data:image/svg+xml;base64,${currentUser.avatarImage}`}
                  alt="avatar"
                />{" "}
              </div>
            </div>
          ) : (
            <div>
              {" "}
              <p>No avatar chosen yet.</p>{" "}
            </div>
          )}

          <div className="title-container">
            <h1>Pick an avatar as your profile picture</h1>
          </div>
          <div className="avatars">
            {avatars.map((avatar, index) => {
              return (
                <div
                  key={index}
                  className={`avatar ${
                    selectedAvatar === index ? "selected" : ""
                  }`}
                >
                  <img
                    src={`data:image/svg+xml;base64,${avatar}`}
                    alt="avatar"
                    onClick={() => setSelectedAvatar(index)}
                  />
                </div>
              );
            })}
          </div>
          <button className="submit-btn" onClick={setProfilePicture}>
            Set as Profile Picture
          </button>
          <div className="linklist">
            <div className="link">
              <span>
                <Link to="/">Home</Link>
              </span>
            </div>
            <div className="link">
              <span>
                <Link to="/preferences">Preferences</Link>
              </span>
            </div>
            <div className="link">
              <span>
                <Link to="/chat">Chat</Link>{" "}
              </span>
            </div>
          </div>
        </Container>
      )}
      <ToastContainer />{" "}
    </React.Fragment>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  align-items: center;
  background-color: ${(props) => props.theme.backgroundColor};
  height: 90vh;
  width: 100vw;
  button {
    background-color: ${(props) => props.theme.button.backgroundColor};
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  button:hover {
    background-color: ${(props) => props.theme.button.hoverColor};
  }
  [type="button"]:disabled {
    background-color: grey;
    cursor: progress;
  }
  .loader {
    max-inline-size: 100%;
  }

  .title-container {
    h1 {
      color: ${(props) => props.theme.color};
    }
  }

  .header-container {
    h1 {
      color: ${(props) => props.theme.color};
    }
  }

  .avatars {
    /* width: 100vw; */
    display: flex;
    flex: 0 1;
    flex-wrap: wrap;
    gap: 2rem;
    .avatar {
      border: 0.4rem solid transparent;
      padding: 0.4rem;
      border-radius: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s ease-in-out;
      img {
        height: 6rem;
        width: 6rem;
      }
    }
    .selected {
      border: 0.4rem solid #4e0eff;
    }
  }
  .submit-btn,
  button {
    background-color: ${(props) => props.theme.button.backgroundColor};
    color: ${(props) => props.theme.button.color};
    padding: 1rem 2rem;
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.4rem;
    font-size: 1rem;
    text-transform: uppercase;
    transition: 0.5s ease-in-out;
    &:hover {
      background-color: ${(props) => props.theme.button.backgroundColor};
    }
  }
  span {
    color: ${(props) => props.theme.button.color};
    text-transform: uppercase;
    a {
      color: #4e0eff;
      text-decoration: none;
      font-weight: bold;
    }
  }
  .linklist {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 3rem;
    align-items: center;
    flex: 0 1;
    /* .link {
      float: left;
    } */
  }
`;

export default withTheme(SetAvatar);
