import ensembles from "./ensemble.definition";

function CategoriesListHeader({
  ensemble,
  setEnsemble,
  searchName,
  setSearchName,
  findByName,
}) {
  function handleOnChange(e) {
    e.preventDefault();
    // console.log(e.target.value);
    const { value } = e.target;
    setEnsemble(value);
    setSearchName("");
    // setCurrentCategory(null);
    // setCurrentIndex(-1);
    // retrieveCategoryGroup(value);
  }

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  return (
    // <div className="col-md-8" style={{ border: "solid black 1px" }}>
    <div className="col-md-8">
      <select
        className="form-select"
        aria-label="Default select example"
        defaultValue={ensemble}
        onChange={(e) => handleOnChange(e)}
      >
        { ensembles.map((category, indx) => {
          // console.log(
          //   "ensemble, category ",
          //   ensemble,
          //   category,
          //   ensemble === category
          // );
          // if (ensemble === category) {
          //   return (
          //     <option key={indx} value={category} selected>
          //       {category}
          //     </option>
          //     // <option value="Ingredient">Ingredient</option>
          //     // <option value="Cooking">Cooking</option>
          //     // <option value="Tags">Tags</option>
          //   );
          // } else {
          return (
            <option key={indx} value={category}>
              {category}
            </option>
          );
          // }
        })}
      </select>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by name"
          value={searchName}
          onChange={onChangeSearchName}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={findByName}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}
export default CategoriesListHeader;
