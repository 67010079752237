import http from "../config/http-common";

import authHeader from "./auth-header";


class CookwareDataService {
  getAllPaginated(params) {
    const response = http.get("/api/cookware", {
      headers: authHeader(),
      params: params,
    });
    // console.log("response ", response);
    return response;
  }

  getAll(params) {
    const response = http.get("/api/cookware/all", {
      headers: authHeader(),
      params: params,
    });
    // console.log("response ", response);
    return response;
  }

  get(id) {
    return http.get(`/api/cookware/${id}`, { headers: authHeader() });
  }

  create(data) {
    // console.log("data ", data);
    return http.post("/api/cookware/add", data, { headers: authHeader() });
  }

  update(id, data) {
    // console.log("data ", data)
    return http.put(`/api/cookware/${id}`, data, { headers: authHeader() });
  }

  delete(id) {
    return http.delete(`/api/cookware/${id}`, { headers: authHeader() });
  }

  deleteAll() {
    return http.delete(`/api/cookware`, { headers: authHeader() });
  }

  findByName(name) {
    // console.log("findByName API call: name", name)
    return http.get(`/api/cookware?name=${name}`, {
      headers: authHeader(),
    });
  }
}
export default new CookwareDataService();
