import { createContext } from "react";
import { io } from "socket.io-client";

import { host } from "../utils/APIRoutes";

var socketForWS;
/**
 *
 * @param {*} ws - the websocket context object
 * @param {*} id - the id of the user the socket is opened for
 * @returns socketForWS - on success the websocket for that user, otherwise null
 *
 * creates the websocket and
 * attaches to it all the necessary event listeners.
 *
 *
 */
function setUpSocketForWS(ws, user) {
  if (!socketForWS) {
    const { id: id } = user;
    socketForWS = io(host);
    socketForWS.emit("add-user", id);
    // console.log("add-user", id, user.username);
    // console.log("add-user",  {...user, avatarImage:""});
    user.onlineStatus = true;
    ws.socketForWS = socketForWS;
    if (socketForWS) {
      socketForWS.on("msg-receive", (msg) => {
        ws.showNotifications(msg);
        // console.log("setArrivedMessage() ", msg, {
        //   from: msg.from,
        //   to: msg.to,
        //   id: id,
        //   fromSelf: false,
        //   message: msg.msg,
        //   message_id: msg.id,
        // });
        // setArrivedMessage({ fromSelf: false, message: msg });
        // setArrivedMessage({ ...msg, fromSelf: false });
        if (ws.setArrivedMessage) {
          ws.setArrivedMessage({
            from: msg.from,
            to:msg.to,
            message: msg.msg,
            message_id: msg.id,
            fromSelf: false,
          });
        }
      });

      socketForWS.on("talk-to-client", (msg) => {
        console.log("talk-to-client ", msg);
      });

      if (ws.contacts && ws.setContacts) {

        socketForWS.on("online", (msg) => {
          // console.log("coming online or checking online status", msg);
          const newContacts = ws.contacts.map((contact) => {
            // console.log("contact.id ", contact.id, contact.username);
            if (contact.id === msg.id) {
              contact.onlineStatus = msg.onlineStatus;
              // console.log(
              //   `set ${contact.username}'s online status to: ${contact.onlineStatus}`
              // );
              return contact;
            } else return contact;
          });
          // console.log("newContacts ", newContacts);
          ws.setContacts(newContacts);
        });
        socketForWS.on("offline", (msg) => {
          // console.log("going offline ", msg);
          const newContacts = ws.contacts.map((contact) => {
            // console.log("contact.id ", contact.id, contact.username);
            if (contact.id === msg.id) {
              contact.onlineStatus = false;
              console.log(
                `set ${contact.username}'s online status to: ${contact.onlineStatus}`
              );
              return contact;
            } else return contact;
          });
          ws.setContacts(newContacts);
        });
      }
    }
    return socketForWS;
  }
  return null;
}

function isUserOnline(id) {
  // console.log("isUserOnline ", id);
  socketForWS && socketForWS.emit("online", id);
}

export function userGoesOffline(id) {
  console.log("going offline id ", id);
  socketForWS && socketForWS.emit("offline", id);
  socketForWS && socketForWS.disconnect();
}

function userGoesOnline(id) {
  // console.log("going online id ", id);
  socketForWS && socketForWS.emit("online", id);
}

function socketDisconnect() {
  socketForWS && socketForWS.disconnect();
}

// with acknoledgement
function talkToServer(msg) {
  if (socketForWS) socketForWS.emit("talk-to-server", msg,
    (response) => {
    console.log(response.status); // ok
  });
}

function setUpWebSockets(input) {
  return { ...input, socket: "socket" };
}

function updateWSContext() {}

export const WSContext = createContext({
  setUpWebSockets: setUpWebSockets,
  setUpSocketForWS: setUpSocketForWS,
  socketForWS: socketForWS,
  isUserOnline: isUserOnline,
  talkToServer: talkToServer,
  userGoesOffline: userGoesOffline,
  userGoesOnline: userGoesOnline,
  socketDisconnect: socketDisconnect,
});
