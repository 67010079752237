import { Buffer } from "buffer";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export function hasTokenExpired(token) {
  // console.log("token ", token);

  if (token) {
    // console.log("token ", token);
    const payloadBase64 = token.split(".")[1];
    const decodedJson = Buffer.from(payloadBase64, "base64").toString();
    const decoded = JSON.parse(decodedJson);
    const iat = decoded.iat;
    const exp = decoded.exp;
    const issuanceTimestamp = iat * 1000; // Date.now(); // This would be the timestamp you want to format
    const expiryTimestamp = exp * 1000; // Date.now(); // This would be the timestamp you want to format
    const expired = Date.now() >= exp * 1000;

    // console.log(
    //   new Intl.DateTimeFormat("en-US", {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //   }).format(issuanceTimestamp)
    // );
    // console.log(
    //   new Intl.DateTimeFormat("en-US", {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //   }).format(expiryTimestamp)
    // );
    // // 1669064938101
    // // 1668878962000
    // //         86400
    // // 1668965362
    // console.log(Date.now());
    // console.log(formatDate(Date.now()));
    // console.log(formatDate(issuanceTimestamp));
    // console.log(formatDate(expiryTimestamp));
    // console.log("token ", token);
    // console.log("payloadBase64 ", payloadBase64);
    // console.log("decodedJson ", decodedJson);
    // console.log("decoded ", decoded);
    // console.log("exp ", exp);
    // console.log("expired ", expired);

    return expired;
  }
  return true;
}

export function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

// https://jasonwatmore.com/post/2022/06/24/react-router-6-private-route-component-to-restrict-access-to-protected-pages
// https://reactrouter.com/en/main/fetch/redirect
export function AuthWrapper({ children }) {
  const { user: currentUser } = useSelector((state) => state.auth);
  // console.log("currentUser ", currentUser,  hasTokenExpired(currentUser.accessToken));
  return !currentUser || hasTokenExpired(currentUser.accessToken) ? (
    <Navigate to="/login" replace />
  ) : (
    <div> {children} </div>
  );
}
