import { SET_WINDOWSIZE, CLEAR_WINDOWSIZE } from "../actions/types";

const initialState = {};

function workWindow(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_WINDOWSIZE:
      // return { windowSize: payload };
      return payload;

    case CLEAR_WINDOWSIZE:
      // return { windowSize: {} };
      return {};

    default:
      return state;
  }
}
export default workWindow;