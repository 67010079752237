import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";

import "./Payments.css";


const SubscriptionCancel = () => {
  return (
    <div className="App">
      {" "}
      <div>
        <div>
          <h1>Payment Cancelled!</h1>
        </div>
        <Button style={{ className: "button" }}>
          <Link
            style={{ color: "white" }}
            to={"/subscriptions"}
            // state={{ id: 1, name: "sabaoon", shirt: "green" }}
            // state={[{ item: "Online Video Editor", qty: "3", itemCode: "99" }]}
            state={{}}
          >
            Go Back to the Subscription Page
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionCancel;
