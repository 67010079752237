import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { withTheme } from "styled-components";

import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/img/logo.svg";
import ThemeSwitcher from "../../components/ThemeSwitcher";
import chatService from "../../services/chat.service";

const FormContainer = styled.div`
  height: 100vh;
  overflow: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  /* padding: 1rem 1rem; */
  background-color: ${(props) => props.theme.backgroundColor};
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: left;
    img {
      height: 3rem;
    }
    h1 {
      color: white;
      text-transform: lowercase;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: ${(props) => props.theme.formBackgroundColor};
    border-radius: 2rem;
    /* padding: 3rem 5rem; */
    padding: 1rem 1rem;
    .themeswitcher {
      /* display: grid;
      grid-template-rows: 25% 75%; */
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      align-items: stretch;
      gap: 2rem;
      color: white;
    }
    input {
      background-color: transparent;
      padding: 1rem;
      border: 0.1rem solid #4e0eff;
      border-radius: 0.4rem;
      color: white;
      width: 100%;
      font-size: 1rem;
      &:focus {
        border: 0.1rem solid #997af0;
        outline: none;
      }
    }
    label {
      background-color: transparent;
      /* padding: 1rem; */
      /* border: 0.1rem solid #4e0eff;
      border-radius: 0.4rem; */
      color: white;
      width: 100%;
      font-size: 1rem;
    }
    button {
      background-color: #997af0;
      color: white;
      padding: 1rem 2rem;
      border: none;
      font-weight: bold;
      cursor: pointer;
      border-radius: 0.4rem;
      font-size: 1rem;
      text-transform: uppercase;
      transition: 0.5s ease-in-out;
      &:hover {
        background-color: #4e0eff;
      }
    }
    span {
      color: white;
      text-transform: uppercase;
      a {
        color: #4e0eff;
        text-decoration: none;
        font-weight: bold;
      }
    }
    .linklist {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 3rem;
      align-items: center;
      flex: 0 1;
      /* .link {
    float: left;
  } */
    }
  }
`;

function Preferences({ toggleTheme, theme }) {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [values, setValues] = useState({
    theme: "",
    username: "",
    email: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const navigate = useNavigate();

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: theme.name,
  };

  const handleChange = (event) => {
    event.preventDefault();
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      const { username, password, newPassword, email } = values;
      // console.log(values);
      // console.log("currentUser ", currentUser);
      const id = currentUser.id ? currentUser.id : currentUser._id;
      // const id = currentUser.id;
      const userData={
        // id: currentUser.id,
        username,
        password,
        newPassword,
        email,
        theme: theme.name,
      };
      const { data } = await chatService.savePreferences(id, userData);
      // console.log(" theme.name ", theme.name);
      // console.log("data ", data);
      // console.log("data.user ", { ...data.user, avatarImage: "" });
      // console.log("data.status ", data.status);
      // console.log("data.msg ", data.msg);
      // console.log("currentUser.accessToken ", currentUser.accessToken);

      if (data.status) {
        // const user = { ...data.user, accessToken: currentUser.accessToken };
        // const user = { accessToken: currentUser.accessToken, ...data.user };
        const user = { ...currentUser, ...data.user };
        // the existing token is overwriten in the local storage
        // console.log("currentUser ", currentUser.accessToken);
        // console.log("user ", user);

        localStorage.setItem(
          process.env.REACT_APP_LOCALHOST_KEY,
          JSON.stringify(user)
        );
        return navigate("/chat");
      } else {
        const errorMessage = data.msg
          ? data.msg
          : "Some error occurred. Try again.";
        toast.error(errorMessage, toastOptions);
      }
    }
    // alert("alert");
  };

  function handleValidation() {
    const { username, email, password, newPassword, confirmNewPassword } =
      values;
    // console.log(values);
    if (username === "") {
      toast.error("Username and Password are required.", toastOptions);
      return false;
    } else if (password === "") {
      toast.error("Username and Password are required.", toastOptions);
      return false;
    }
    if (confirmNewPassword !== newPassword) {
      toast.error(
        "password and confirm password should be the same.",
        toastOptions
      );
      return false;
    } else if (username.length < 3) {
      toast.error("Username should at least have 3 characters.", toastOptions);
      return false;
    } else if (email.indexOf("@") < 0) {
      toast.error("Please enter a valid email adress.", toastOptions);
      return false;
    } else if (email === "") {
      toast.error("Your email adress is required.", toastOptions);
      return false;
    } else if (password.length < 6) {
      toast.error("Password should at least have 6 characters.", toastOptions);
      return false;
    }
    return true;
  }

  useEffect(() => {
    async function getCurrentUser() {
      const user = await JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
      );
      // console.log("user ", { ...user, avatarImage: "" });
      if (user) {
        const completeUser = await getCurrentUserData(user);
        // console.log("theme ", theme);
        // console.log("completeUser.theme ", completeUser.theme);
        // console.log("completeUser ", { ...completeUser, avatarImage: "" });

        if (theme.name !== completeUser.theme) {
          toggleTheme();
        }
        setValues({
          theme: completeUser.theme,
          username: completeUser.username,
          email: completeUser.email,
          password: "",
        });
        // console.log("completeUser ", {
        //   ...user,
        //   ...completeUser,
        //   avatarImage: "",
        // });
        setCurrentUser({ ...user, ...completeUser });
        return completeUser;
      }
      setValues({
        username: user ? user.username : "",
        email: user ? user.email : "",
        password: "",
      });
      // console.log("user ", user);
      setCurrentUser(user);
      return user;
    }
    async function getCurrentUserData(user) {
      // console.log("user ", user);
      const { data } = await chatService.getPreferences(user.id);
      // console.log("data ", data);
      // const data = { user, status: 200, msg: "OK" };
      return data.user;
    }
    if (!localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
      navigate("/login");
    } else {
      getCurrentUser();
    }
  }, []);

  // currentUser && console.log("currentUser.email ", currentUser.email);

  return (
    <React.Fragment>
      <FormContainer>
        <form onSubmit={(event) => handleSubmit(event)}>
          <div className="brand">
            <img src={Logo} alt="" />
            <h1>pollucturum</h1>
          </div>
          <div className="themeswitcher">
            <div>Choose your theme (Actual: {theme.name})</div>
            <ThemeSwitcher
              choosenTheme={theme.name}
              toggleTheme={toggleTheme}
            />
          </div>
          <input
            hidden
            type="text"
            placeholder={theme.name}
            name="theme"
            value={theme.name}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="username">
            Username
            <input
              type="text"
              placeholder={currentUser ? currentUser.username : ""}
              name="username"
              min="3"
              value={values.username}
              onChange={(e) => handleChange(e)}
            />
          </label>
          <label htmlFor="email">
            email
            <input
              type="email"
              placeholder={currentUser ? currentUser.email : ""}
              name="email"
              // value=
              value={values.email}
              onChange={(e) => handleChange(e)}
            />
          </label>
          <label htmlFor="password">
            Password
            <input
              type="password"
              placeholder="Password"
              name="password"
              onChange={(e) => handleChange(e)}
            />
          </label>
          <label htmlFor="newPassword">
            New Password
            <input
              type="password"
              placeholder="New Password"
              name="newPassword"
              onChange={(e) => handleChange(e)}
            />
          </label>
          <label htmlFor="confirmNewPassword">
            Confirm New Password
            <input
              type="password"
              placeholder="Confirm New Password"
              name="confirmNewPassword"
              onChange={(e) => handleChange(e)}
            />
          </label>
          <button type="submit">Save</button>
          <div>
            <span>Please save your preferences!</span>
          </div>
          {/* <div>
            <span>
              <Link to="/">Home</Link>
            </span>
          </div>
          <div>
            <span>
              <Link to="/setavatar">Want to change your avatar?</Link>
            </span>
          </div>
          <div>
            <span>
              <Link to="/chat">Chat</Link>{" "}
            </span>
          </div> */}
          <div className="linklist">
            <div className="link">
              <span>
                <Link to="/">Home</Link>
              </span>
            </div>
            <div className="link">
              <span>
                <Link to="/setavatar">Want to change your avatar?</Link>
              </span>
            </div>
            <div className="link">
              <span>
                <Link to="/chat">Chat</Link>{" "}
              </span>
            </div>
          </div>
        </form>
      </FormContainer>
      <ToastContainer />
    </React.Fragment>
  );
}

export default withTheme(Preferences);
