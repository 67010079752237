import React from "react";

export default function Item(props) {
  const myStyle = {
    // float: "left",
    padding: props.padding ? props.padding : "0 2pt 0 2pt",
    // margin: props.margin ? props.margin : "0 5pt 0 5pt",
    margin: props.margin ? props.margin : "5pt 2pt 5pt 2pt",
    border: props.border ? props.border : "none", // "0.5pt solid black",
    // color: "black",
    backgroundColor: props.backgroundColor ? props.backgroundColor : "inherit",
    // backgroundColor: "white",
    // width: props.width ? props.width : "100%",
    maxWidth: props.maxWidth ? props.maxWidth : "40pt",
    float: props.float,
  };
  return (
    <div
      style={myStyle}
    >
      {props.children}
    </div>
  );
}
