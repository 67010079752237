import React, { useState, useEffect, useRef } from "react";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import ClearIcon from "@mui/icons-material/Clear";

// const DisplayMessage = React.forwardRef((props, ref) => {
function DisplayMessage( props ) {
  const [message, setMessage] = useState(props.children);

  // console.log("children ", props.children);
  // console.log("message ", props.message);

  // const myRef = useRef(null);

  // const { message } = props;

  function AlertDismissible(props) {
    const [show, setShow] = useState(props.children ? true : false);

    // console.log("show ", show);
    if (show) {
      return (
        <Alert variant="success" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>Success</Alert.Heading>
          <p>{props.children}</p>
        </Alert>
      );
    }
    // return <Button onClick={() => setShow(true)}>Show Alert</Button>;
    return null;
  }

  useEffect(() => {
    // setMessage(null);
    if (props.children) {
      setMessage(props.children);
      // props.setMessage(props.children);
    }
    // console.log("useEffect", props.children);
  }, [props.children]);

  return (
    <React.Fragment>
      {/* <AlertDismissible>{message}</AlertDismissible> */}
      {/* {message ? (
        <Alert ref={ref} id="error" key="danger" variant="danger">
          {message}
        </Alert>
      ) : null} */}
      {message ? (
        <div
          id="errorDiv"
          style={
            {
              // padding: "10pt",
              // margin: "5pt auto"
            }
          }
        >
          <div
            id="error"
            // ref={myRef}
            // ref={ref}
            style={{
              padding: "10pt 10pt 5pt 10pt",
              borderRadius: "5pt",
              margin: "5pt auto",
              maxWidth: props.maxWidth ? props.maxWidth : "70%",
              fontSize: "8pt",
              fontWeight: "200",
              color: "#f5ba13",
              backgroundColor: "#1976d2",
            }}
            // hidden
          >
            {message ? message : "Please enter something!"}
            <div style={{ float: "right", fontSize: "1rem" }}>
              <ClearIcon
                style={{ fontSize: "1rem", verticalAlign: "top" }}
                onClick={() => {
                  setMessage(null);
                  props.setMessage(null);
                }}
              />
            </div>
            <div style={{ clear: "both" }}> </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
// });
}

export default DisplayMessage;
