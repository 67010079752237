/**
 * This helped a lot
 * especially styling
 * https://www.bezkoder.com/react-node-express-mongodb-mern-stack/
 */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import KeyIcon from "@mui/icons-material/Key";

import CategoriesList from "../../components/Categories/CategoriesList";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const [ensemble, setEnsemble] = useState("Ingredient");
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isModerator, setIsModerator] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const isModerator = currentUser?.roles.includes("ROLE_MODERATOR");
    if (!isModerator) navigate("/home");
    setIsModerator(isModerator);
    // console.log(currentUser);
  }, []);
  return (
    // <div className="container" style={{ border: "solid black 1px" }}>
    <div className="container" style={{ marginTop: "10px" }}>
      <KeyIcon style={{ position: "absolute", left: "0" }} />
      {/* {ensemble} */}
      <CategoriesList ensemble={ensemble} setEnsemble={setEnsemble} />
    </div>
  );
};
export default Categories;

/**
 * A note on the Category Model:
 * The collection "categories" consists of - for the moment - 3 documents.
 * These 3 documents are the 3 categories in use in the application.
 * Each document is the document corresponding to one of the categories.
 * Within the document the different items of that category are saved in
 * an array "items".
 * 
 * It is feasible to save changes to one and only one item by changing that
 * item in the items array and then saving the whole document - replacing
 * the existing one.
 * 
 * Another possibility would be to alter only the element affected by that change.
 * 
 * Adding an element of category calls "updateCategory" which calls "update"
 * being this a wrapper for the put request on the id.
 * 
 * On the backend side that route calls update, which replaces the affected
 * category in the items array and saves the whole categoryGroup.
 * 
 * 
 * Logic of the solution's design:
 * 
 * Data structure:
 * 
 * The ensemble is given by the "ensemble" parameter and may be "Ingredient",
 * "Cooking", "Tags" and "Measure"
 * 
 * These ensembles are devided into disjoint categories which for one ensemble
 * are grouped into "categoryGroup"s: In each "categoryGroup" the pertaining
 * categories are in the "categories" array.
 * 
 */