/*********************************
 * Login Page
 *
 * This page has a Form with username & password.
 * – We’re gonna verify them as required field.
 * – If the verification is ok, we call AuthService.login() method,
 *   then direct user to Profile page: props.history.push("/profile");,
 *   or show message with response error.
 ***********************/
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link } from "react-router-dom";

// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";

// import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Public as PublicIcon } from "@mui/icons-material";

// redux
import { login, getUserFromAPI } from "../../actions/auth";
// import { SET_MESSAGE, CLEAR_MESSAGE } from "../../actions/types";

import { hasTokenExpired } from "./helper";
// styling
// assets
import "../../assets/css/Auth.css";
import __DEBUG__ from "../../config/debug.config";
import server from "../../config/server.config";

const BACKEND_URL = server.host + (server.port === "" ? "" : ":" + server.port);

// import AuthService from "../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const form = useRef();
  const checkBtnRef = useRef();
  // console.log(checkBtnRef);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  // useEffect(() => {
  //   dispatch({
  //     type: CLEAR_MESSAGE,
  //   });
  // }, []);

  // useEffect(() => {
  // console.log("isLoggedIn ", isLoggedIn);
  // if (isLoggedIn && currentUser && !hasTokenExpired(currentUser.accessToken)) {
  //   // return <Navigate to="/home" />;
  //   return navigate("/home");
  // }
  // }, []);

  const handleLogin = (e) => {
    e.preventDefault();

    // setMessage("");
    setLoading(true);

    // form.current.validateAll();

    // if (checkBtnRef.current.context._errors.length === 0) {
    // AuthService.login(username, password).then(
    //   () => {
    //     props.history.push("/profile");
    //     window.location.reload();
    //   },
    //   (error) => {
    //     const resMessage =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();

    //     setLoading(false);
    //     setMessage(resMessage);
    //   }
    // );

    dispatch(getUserFromAPI(username, password))
      .then((r) => {
        // console.log("getUserFromAPI() ", { ...r, avatarImage: "..." });
      })
      .catch((error) => {
        // console.log("getUserFromAPI(): catch", error);
      });

    dispatch(login(username, password))
      .then(() => {
        // console.log("successful login dispatch: isLoggedIn ", isLoggedIn);
        // for security avoid someone else going back in history to the
        // login page with the credentials filled in
        // this reset redux store
        // will use localStorage to persist
        // still patchwork, will introduce thunks later
        props.history.push("/home");
        // even simpler: Do NOT use the reload() since the history has been pushed already.
        // window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        if (__DEBUG__) {console.log("Login: catch", JSON.stringify(error, null, 2));}
        // return <Navigate to="/login" />;
        return navigate("/login");
      });
    // } else {
    //   setLoading(false);
    // }
  };

  // console.log("isLoggedIn ", isLoggedIn);
  // currentUser && console.log(
  //   "hasTokenExpired ",
  //   hasTokenExpired(currentUser.accessToken)
  // );
  // currentUser &&
  //   console.log("currentUser.accessToken ", currentUser.accessToken);

  if (isLoggedIn && currentUser && !hasTokenExpired(currentUser.accessToken)) {
    // console.log("Last logged in ", {...currentUser, avatarImage:"..."});
    return <Navigate to="/home" />;
    // navigate("/home");
  } else
    return (
      <div className="container">
        <PublicIcon style={{ position: "absolute", left: "0", top: "65px" }} />
        <div className="col-md-12">
          <div className="Auth card card-container">
            <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card"
            />

            <Form onSubmit={handleLogin} ref={form}>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required]}
                />
              </div>
              <Form.Group>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required]}
                />
              </Form.Group>
              <Form.Group>
                <button
                  className="btn btn-primary btn-block"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>
              </Form.Group>
              {__DEBUG__ && message &&
                console.log("Message ", JSON.stringify(message, null, 2))}
              {message && (
                <Form.Group>
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </Form.Group>
              )}
              {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  // style={{ display: "block" }}
                  ref={checkBtnRef}
                  type="checkbox"
                  label="Reference to child to check for errors"
                />
              </Form.Group> */}
              {/* <CheckButton style={{ display: "none" }}  ref={checkBtnRef} /> */}
              <Form.Group>
                <p className="lead mt-1">
                  No account yet? <Link to="/register">Register</Link>
                </p>
                <p className="lead mt-1">
                  Forgot your password?{" "}
                  <a href={BACKEND_URL+"/users/reset"}>
                    Reset your password
                  </a>
                </p>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    );
};

export default Login;
