/**
 * boilerplate
 */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import KeyIcon from "@mui/icons-material/Key";

import Dropdown from "../ShoppingList/Dropdown";
// import options from "../ShoppingList/qtyUnitOptions";
import sections from "./sections.definition";

import IngredientDataService from "../../services/ingredient.service";
import CategoryDataService from "../../services/category.service";

import "./Ingredients.css";
import providers from "./providers.definition";
// import server from "../../config/server.config";

// const BACKEND_URL = server.host + (server.port === "" ? "" : ":" + server.port);

// const providers = ["Día", "amazon", "Mercadona", "bofrost"];
const ensembles = ["Ingredient", "Packaging", "Measure"];

/**
 * component definition
 */

const AddIngredient = () => {
  const { message } = useSelector((state) => state.message);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [categoryGroups, setCategoryGroups] = useState({});
  const [options, setOptions] = useState([[], [], [], [], [], []]);

  const [lvl1, setLvl1] = useState("");
  const [lvl2, setLvl2] = useState("");
  const [lvl3, setLvl3] = useState("");
  const [level1, setLevel1] = useState([]);
  const [level2, setLevel2] = useState([]);
  const [level3, setLevel3] = useState([]);

  const navigate = useNavigate();
  // var categoryGroups = [];

  const handleBackClick = () => {
    // 👇️ replace set to true
    navigate("/ingredients", { replace: true });
  };

  useEffect(() => {
    ensembles.map((ensemble, indx) => retrieveCategoryGroup(ensemble, indx));
    // const categoryGroups = ensembles.map((ensemble) =>
    //   retrieveCategoryGroup(ensemble)
    // );
    // console.log("categoryGroups ", categoryGroups);
    setLevel1((x) => {
      const keys = Object.keys(sections);
      // console.log(keys);
      return keys;
    });
  }, []);

  const retrieveCategoryGroup = (ensemble, indx) => {
    CategoryDataService.getAll({ name: "", ensemble: ensemble })
      .then((response) => {
        // var categoryGroup;
        // categoryGroup[indx]=response.data.categoryGroup;
        // console.log("categoryGroup[indx] ",indx, categoryGroup[indx]);
        // console.log(
        //   "response.data.categoryGroup ",
        //   response.data.categoryGroup
        // );
        // console.log("ensemble ", ensemble);
        // console.log("categoryGroup ", categoryGroup);
        setCategoryGroups((prevCategoryGroups) => {
          const newCategoryGroups = {
            ...prevCategoryGroups,
            [ensembles[indx]]: response.data.categoryGroup,
          };
          // console.log("newCategoryGroups ", indx, newCategoryGroups);
          setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[indx] = response.data.categoryGroup
              ? response.data.categoryGroup.categories.map((category) => ({
                  label: category.name,
                  value: category.name,
                }))
              : null;
            // console.log("newOptions ", indx, newOptions);
            return newOptions;
          });
          return newCategoryGroups;
        });

        // also possible to write it as array construct
        //
        // setCategoryGroups((prevCategoryGroups) => {
        //   const a = [...prevCategoryGroups];
        //   a[indx] = response.data.categoryGroup;
        //   return a;
        // });

        // console.log(response);
        // return response.data.categoryGroup;
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  const initialIngredientState = {
    id: null,
    name: "",
    description: "",
    presentation: "",
    price: 0,
    category: "",
    quantity: 1,
    qtyUnit: "",
    published: false,
    media: {
      images: {
        head: "",
      },
    },
  };

  const [ingredient, setIngredient] = useState(initialIngredientState);
  const [submitted, setSubmitted] = useState(false);

  const retrieveSubSections = (key, subkey) => {
    // console.log("key, subkey ", key, subkey);

    const keys = Object.keys(sections[key]);
    // console.log("keys ", keys);

    const section = {
      s: Object.keys(sections[key]),
      ss:
        subkey === ""
          ? Object.keys(sections[key][keys[0]])
          : Object.keys(sections[key][subkey]),
    };
    // console.log(
    //   section,
    //   subkey,
    //   subkey === ""
    //     ? Object.keys(sections[key][keys[0]])
    //     : Object.keys(sections[key][subkey])
    // );
    return section;
  };

  function loadSubSections(event) {
    const { name, value } = event.target;
    // console.log(event.target);
    switch (name) {
      case "mainSection":
        setLvl1(value);
        const { s: s1, ss: ss1 } = retrieveSubSections(value, "");
        // console.log(s1);
        // console.log(ss1);
        setLevel2(s1);
        setLevel3(ss1);
        break;
      case "section":
        // console.log("name", name);
        // console.log("value ", value);

        setLvl2(value);
        const { s: s2, ss: ss2 } = retrieveSubSections(lvl1, value);
        // console.log(s2);
        // console.log(ss2);
        setLevel3(ss2);
        break;
      case "subsection":
        setLvl3(value);
        break;

      default:
        break;
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log("ingredient ", ingredient);
    // console.log("ingredient name, value ", name, value);
    let theIngredient = {};

    if (name === "name") {
      // const fileLocation = BACKEND_URL + "/assets/images/";
      // the Server is now parameterized
      const fileLocation = "assets/images/";
      const picture =
        fileLocation + value.replaceAll(" ", "-").toLowerCase() + ".jpg";
      const media = {};
      media.images = {};
      media.images.head = picture;
      theIngredient = { ...ingredient, media: media };
      theIngredient = { ...theIngredient, [name]: value };
    } else if (name === "media-images-head") {
      const media = {};
      media.images = {};
      media.images.head = value;
      theIngredient = { ...ingredient, media: media };
    } else {
      theIngredient = { ...ingredient, [name]: value };
    }
    // console.log("theIngredient ", theIngredient);
    setIngredient(theIngredient);
  };

  const saveIngredient = () => {
    var data = {
      name: ingredient.name,
      description: ingredient.description,
      presentation: ingredient.presentation,
      category: ingredient.category,
      mainSection: ingredient.mainSection,
      section: ingredient.section,
      subsection: ingredient.subsection,
      quantity: ingredient.quantity,
      qtyUnit: ingredient.qtyUnit,
      price: ingredient.price,
      packaging: ingredient.packaging,
      provider: ingredient.provider,
      published: false,
      media: {
        images: {
          head: ingredient.media.images.head,
        },
      },
    };
    IngredientDataService.create(data)
      .then((response) => {
        // console.log("saveIngredient() response data name", response.data.name);
        // console.log(
        //   "saveIngredient() response data description",
        //   response.data.description
        // );
        // console.log("saveIngredient() response data id", response.data.id);
        // console.log("saveIngredient() response data", response.data);
        setIngredient({
          id: response.data.id,
          name: response.data.ingredient.name,
          description: response.data.ingredient.description,
          presentation: response.data.ingredient.presentation,
          category: response.data.ingredient.category,
          mainSection: response.data.ingredient.mainSection,
          section: response.data.ingredient.section,
          subsection: response.data.ingredient.subsection,
          quantity: response.data.ingredient.quantity,
          qtyUnit: response.data.ingredient.qtyUnit,
          published: response.data.ingredient.published,
          media: {
            images: {
              head: response.data.ingredient.media.images.head,
            },
          },
        });
        setSubmitted(true);
        // console.log("saveIngredient() ", response.data);
      })
      .catch((e) => {
        console.log("saveIngredient() ", e);
      });
  };

  const newIngredient = () => {
    setIngredient(initialIngredientState);
    setSubmitted(false);
  };

  return (
    <div className="container">
      {/* {console.log(categoryGroups.Ingredient)} */}
      {/* {console.log(options[0])} */}
      <KeyIcon style={{ position: "absolute", left: "0" }} />
      <div className="submit-form">
        {submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <button className="btn btn-success" onClick={newIngredient}>
              Add
            </button>
            <button className="btn btn-success" onClick={handleBackClick}>
              Back
            </button>
          </div>
        ) : (
          <div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                name="name"
                type="text"
                className="form-control"
                id="name"
                required
                value={ingredient.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                name="description"
                type="text"
                className="form-control"
                id="description"
                required
                value={ingredient.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="category">Category</label>
              <input
                name="category"
                type="text"
                className="form-control"
                id="category"
                required
                value={ingredient.category}
                onChange={handleInputChange}
              /> */}
              <Dropdown
                myStyle={{
                  margin: "15pt 0pt 0pt 0pt",
                  // float: "left",
                  // maxWidth: "60pt",
                  width: "100%",
                }}
                label="Category"
                options={options[0]}
                // options={categoryGroups.Ingredient.categories}
                value={ingredient.category}
                className="form-control"
                id="category"
                required
                onChange={handleInputChange}
                name="category"
              />
            </div>
            <div className="form-group">
              <Dropdown
                myStyle={{
                  margin: "15pt 0pt 0pt 0pt",
                  // float: "left",
                  // maxWidth: "60pt",
                  width: "100%",
                }}
                label="Main Section"
                // options={options}
                options={level1}
                value={ingredient.mainSection}
                className="form-control"
                id="mainSection"
                required
                onChange={(e) => {
                  handleInputChange(e);
                  loadSubSections(e);
                }}
                name="mainSection"
              />
              <br />
            </div>
            <div className="form-group">
              <Dropdown
                myStyle={{
                  margin: "15pt 0pt 0pt 0pt",
                  // float: "left",
                  // maxWidth: "60pt",
                  width: "100%",
                }}
                label="Section"
                // options={options}
                options={level2}
                value={ingredient.section}
                className="form-control"
                id="section"
                required
                onChange={(e) => {
                  handleInputChange(e);
                  loadSubSections(e);
                }}
                name="section"
              />
              <br />
            </div>
            <div className="form-group">
              <Dropdown
                myStyle={{
                  margin: "15pt 0pt 0pt 0pt",
                  // float: "left",
                  // maxWidth: "60pt",
                  width: "100%",
                }}
                label="Subsection"
                // options={options}
                options={level3}
                value={ingredient.subsection}
                className="form-control"
                id="subsection"
                required
                onChange={(e) => {
                  handleInputChange(e);
                  loadSubSections(e);
                }}
                name="subsection"
              />
              <br />
            </div>
            <div className="form-group">
              {"Pollucturum"}
              {" > "}
              {ingredient.mainSection}
              {" > "}
              {ingredient.section}
              {" > "}
              {ingredient.subsection}
            </div>
            <div className="form-group mt-3">
              <label htmlFor="provider">Preferred Provider</label>
              <Dropdown
                myStyle={{
                  // margin: "15pt 0pt 0pt 0pt",
                  // float: "left",
                  // maxWidth: "60pt",
                  width: "100%",
                }}
                label="Provider"
                // options={options}
                options={providers}
                value={ingredient.provider}
                className="form-control"
                id="provider"
                required
                onChange={handleInputChange}
                name="provider"
              />
            </div>
            {console.log(ingredient.price)}
            <div className="form-group">
              <label htmlFor="price">Price</label>
              <input
                name="price"
                type="text"
                className="form-control"
                id="price"
                required
                value={ingredient.price}
                onChange={handleInputChange}
              />
              {/* <label htmlFor="qtyUnit">Quantity Unit</label>
            <input
              name="qtyUnit"
              type="text"
              className="form-control"
              id="qtyUnit"
              required
              value={ingredient.qtyUnit}
              onChange={handleInputChange}
            /> */}
            </div>
            <div className="form-group">
              <label htmlFor="presentation">Presentation</label>
              <input
                name="presentation"
                type="text"
                className="form-control"
                id="presentation"
                required
                value={ingredient.presentation}
                onChange={handleInputChange}
              />
              {/* <label htmlFor="qtyUnit">Quantity Unit</label>
            <input
              name="qtyUnit"
              type="text"
              className="form-control"
              id="qtyUnit"
              required
              value={ingredient.qtyUnit}
              onChange={handleInputChange}
            /> */}
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="number">Number</label>
                  <input
                    name="number"
                    type="text"
                    className="form-control"
                    id="number"
                    required
                    value={ingredient.number}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="packaging">Packaging</label>
                  <Dropdown
                    myStyle={{
                      // margin: "15pt 0pt 0pt 0pt",
                      // float: "left",
                      // maxWidth: "60pt",
                      width: "100%",
                    }}
                    label="Packaging"
                    // options={options}
                    options={options[1]}
                    value={ingredient.packaging}
                    className="form-control"
                    id="packaging"
                    required
                    onChange={handleInputChange}
                    name="packaging"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="quantity">Quantity</label>
                  <input
                    name="quantity"
                    type="text"
                    className="form-control"
                    id="quantity"
                    required
                    value={ingredient.quantity}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="qtyUnit">Unit</label>
                  <Dropdown
                    myStyle={{
                      // margin: "15pt 0pt 0pt 0pt",
                      // float: "left",
                      // maxWidth: "60pt",
                      width: "100%",
                    }}
                    label="Units"
                    // options={options}
                    options={options[2]}
                    value={ingredient.qtyUnit}
                    className="form-control"
                    id="qtyUnit"
                    required
                    onChange={handleInputChange}
                    name="qtyUnit"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="media-images-head">Picture</label>
              <input
                type="text"
                className="form-control"
                id="media-images-head"
                required
                value={
                  ingredient.media &&
                  ingredient.media.images &&
                  ingredient.media.images.head
                    ? ingredient.media.images.head
                    : ""
                }
                onChange={handleInputChange}
                name="media-images-head"
              />
            </div>
            <button onClick={saveIngredient} className="btn btn-success">
              Submit
            </button>
            <button onClick={handleBackClick} className="btn btn-success">
              Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default AddIngredient;
