import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { ThemeProvider } from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import {
  // Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  // Link,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// https://stackoverflow.com/questions/70752036/how-to-redirect-user-to-a-diferent-path-if-token-is-expired
import { AuthWrapper } from "../Auth/helper";

// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Layout from "./Layout";
import Navigation from "../Navigation/Navigation";

// main menu
import Public from "../../pages/Public/Public";
import Login from "../Auth/Login";
// import Register from "../Auth/Register";

import About from "../About/About";
import Private from "../../pages/Private/Private";
// import MyHome from "../MyHome/MyHome";
// import Invoices from "../Invoices/Invoices";
// import Users from "../Users/Users";
// import KeeperApp from "../Keeper/KeeperApp";
// import ToDoApp from "../ToDo/ToDoApp";
// import EmojipediaApp from "../Emojipedia/EmojipediaApp";
// import UserControlApp from "../UserControl/UserControlApp";
// import GetTime from "../../pages/Public/GetTime";
// import ResponsiveDrawer from "./ResponsiveDrawer";

// import Contacts, {
//   loader as contactsLoader,
//   action as contactsAction,
// } from "../Contacts/Contacts";
// import Contact, {
//   loader as contactLoader,
//   // action as contactAction,
// } from "../Contacts/Contact";
// import EditContact, {
//   loader as editLoader,
//   action as editAction,
// } from "../Contacts/EditContact";

// import Playground from "../Playground/Playground";

import Recipes from "../Recipes/Recipes";
import RecipesList from "../Recipes/RecipesList";
import RecipesPages from "../Recipes/RecipesPages";
import AddRecipe from "../Recipes/AddRecipe";
import Recipe from "../Recipes/Recipe";
import RecipesFromInventory from "../Recipes/RecipesFromInventory";

import ShoppingList from "../../pages/Private/ShoppingList";

import Inventory from "../../pages/Private/Inventory";

import Categories from "../../pages/Private/Categories";
// import AddCategory from "../Categories/AddCategory";

import Ingredients from "../../pages/Private/Ingredients";
import Ingredient from "../Ingredients/Ingredient";
import AddIngredient from "../Ingredients/AddIngredient";
import IngredientsPages from "../Ingredients/IngredientsPages";

import Cookware from "../../pages/Private/Cookware";
import Cookware1 from "../Cookware/Cookware";
import AddCookware from "../Cookware/AddCookware";
import CookwarePages from "../Cookware/CookwarePages";

import MenuPlanning from "../../pages/Private/MenuPlanning";

// the profile menu
import Profile from "../Auth/Profile";
import Messages from "../Messages/Messages";
import Notifications from "../../pages/Private/Notifications";
// utilities
// import FileUpload from "../Utils/FileUpload";
// import UploadFiles from "../Utils/UploadFiles";

import BoardUser from "../Board/BoardUser";
// import BoardModerator from "../Board/BoardModerator";
// import BoardAdmin from "../Board/BoardAdmin";

// import Rregister from "../../pages/Public/Register";
// import Llogin from "../../pages/Public/Login";
// import Chat from "../../pages/Private/Chat";
// import Home from "../../pages/Home";
import SetAvatar from "../Chat/SetAvatar";
import Preferences from "../../pages/Private/Preferences";
import { WSContext } from "../../context/WSContext";

import SubscriptionPage from "../Payments/SubscriptionPage";
import SubscriptionFailure from "../Payments/SubscriptionFailure";
import SubscriptionSuccess from "../Payments/SubscriptionSuccess";
import SubscriptionCancel from "../Payments/SubscriptionCancel";
import Subscriptions from "../Payments/Subscriptions";
import CheckOutPage from "../Payments/CheckOutPage";
import CheckOutFailure from "../Payments/CheckOutFailure";
import CheckOutSuccess from "../Payments/CheckOutSuccess";
import CheckOutCancel from "../Payments/CheckOutCancel";
import Shop from "../Payments/Shop";

// import ErrorPage from "../Contacts/error-page";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "./App.css";
import { darkTheme, ligthtTheme } from "../../utils/theming";

/*********
 * redux stuff
 *
 * instead of
 ***************************/
// import AuthService from "../services/auth.service";
// we use
// import { clearMessage } from "../../actions/message";

// helpers
import { history } from "../../helpers/history";

// styling
// assets
import "../../assets/css/App.css";
import { setWindowSize } from "../../actions/action-creators/windowSize";

// assets
// import logo from "../assets/img/logo.svg";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  const windowSize = { innerWidth, innerHeight };
  // console.log(windowSize.innerWidth);
  return windowSize;
}

const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  // console.log(process.env.REACT_APP_LOCALHOST_KEY, currentUser);
  const [theme, setTheme] = useState(
    currentUser && currentUser.theme === "dark" ? darkTheme : ligthtTheme
  );

  const dispatch = useDispatch();

  useEffect(() => {
    function handleWindowResize() {
      dispatch(setWindowSize(getWindowSize()));
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    dispatch(setWindowSize(getWindowSize()));
  }, []);

  function toggleTheme() {
    // console.log("toggle themes....");
    setTheme((previousTheme) => {
      // console.log("toggle themes....", previousTheme);
      if (previousTheme.name === darkTheme.name) {
        return ligthtTheme;
      } else {
        return darkTheme;
      }
    });
  }

  //   // function setUpWebSockets(input) {
  //   //   return { ...input, socket: "socket" };
  //   // }


  // useEffect(() => {
  //   history.listen((location) => {
  //     dispatch(clearMessage()); // clear message when changing location
  //   });
  // }, [dispatch]);

  //   // const theme = useTheme();
  //   // const matches = useMediaQuery(theme.breakpoints.up("sm"));
  //   // const matches1 = useMediaQuery("(min-width:600px)");

  //   // useEffect(() => {
  //   //   const user = AuthService.getCurrentUser();
  //   //   if (user) {
  //   //     setCurrentUser(user);
  //   //     setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
  //   //     setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
  //   //   }
  //   // }, []);

  //   /******
  //    *
  //    * value         |0px     600px    960px    1280px   1920px
  //    * key           |xs      sm       md       lg       xl
  //    * screen width  |--------|--------|--------|--------|-------->
  //    * range         |   xs   |   sm   |   md   |   lg   |   xl
  //    *
  //    *
  //    ******/

  //   // make sure the basename gets at least some value
  //   const basename = process.env.REACT_APP_BASE_NAME
  //     ? process.env.REACT_APP_BASE_NAME
  //     : "/";

  //   //   A <Routes> looks through its children <Route>s and
  //   // renders the first one that matches the current URL.
  //   //   <Routes basename="https://192.168.1.20">
  //   //   {console.log("REACT_APP_BASE_NAME ", basename)}

  //   //   <span>{`(min-width:600px) matches: ${matches1}`}</span>
  //   // <span>{`theme.breakpoints.up('sm') matches: ${matches}`}</span>

  const reload = () => window.location.reload();

  // const RoutesTree = () => (
  // );

  return (
    // {/* <Routes basename={basename}> */}
    // {/* <Routes basename={"/"}> */}
    // {/* <Routes history={history}> */}
    // {/* <RoutesTree /> */}
    <Routes>
      {/* 1️⃣ Wrap your routes in a pathless layout route */}
      <Route element={<Layout />}>
        <Route path="/" element={<Public />} />
        {/* <Route path="/hhome" element={<Home />} /> */}
        <Route path="/login" element={<Login history={history} />} />
        {/* <Route
                path="/register"
                element={<Register history={history} />}
              /> */}
        {/* <Route path="/signin" element={<UserControlApp />} />
              <Route
                path="/signup"
                element={<UserControlApp mode={"signup"} />}
              />
              <Route
                path="/rregister"
                element={<Rregister toggleTheme={toggleTheme} />}
              />
              <Route
                path="/llogin"
                element={<Llogin toggleTheme={toggleTheme} />}
              /> */}
        <Route path="/about" element={<About />} />
        {/* <Route path="/gettime" element={<GetTime />} />
              <Route path="/emojipedia" element={<EmojipediaApp />} /> */}
        <Route
          path="/home"
          element={
            <AuthWrapper>
              <Private />
            </AuthWrapper>
          }
        />
        {/* <Route
                path="/myhome"
                element={
                  <AuthWrapper>
                    <MyHome />
                  </AuthWrapper>
                }
              /> */}
        {/* <Route
                path="/invoices/*"
                element={
                  <AuthWrapper>
                    <Invoices />
                  </AuthWrapper>
                }
              /> */}
        {/* JSX syntax */}
        {/* {console.log("we are in app, route branching")} */}
        {/* 
              
                children={[
                  {
                    path: "contacts/:contactId",
                    element: <Contact />,
                  },
                ]}
              
                path: "contacts/:contactId/edit",
                element: <EditContact />,
                loader: contactLoader,
              
              
              */}

        {/* <Route
                path="/contacts"
                loader={contactsLoader}
                action={contactsAction}
                element={
                  <AuthWrapper>
                    <Contacts
                    action={contactsAction}
                    />
                  </AuthWrapper>
                }
                errorElement={<ErrorPage />}
              >
                <Route
                  path="/contacts/:contactId"
                  element={
                    <AuthWrapper>
                      <Contact />
                    </AuthWrapper>
                  }
                  loader={contactLoader}
                  errorElement={<ErrorPage />}
                />
                <Route
                  path="/contacts/:contactId/edit"
                  element={
                    <AuthWrapper>
                      <EditContact />
                    </AuthWrapper>
                  }
                  loader={editLoader}
                  action={editAction}
                  errorElement={<ErrorPage />}
                />
              </Route> */}

        {/* <Route
                path="/keeper"
                element={
                  <AuthWrapper>
                    <KeeperApp />
                  </AuthWrapper>
                }
              />
              <Route
                path="/todo"
                element={
                  <AuthWrapper>
                    <ToDoApp />
                  </AuthWrapper>
                }
              />
              <Route
                path="/users"
                element={
                  <AuthWrapper>
                    <Users />
                  </AuthWrapper>
                }
              />
              <Route
                path="/playground"
                element={
                  <AuthWrapper>
                    <Playground />
                  </AuthWrapper>
                }
              /> */}
        {/* <Route
          path="/recipes"
          element={
            <AuthWrapper>
              <Recipes />
            </AuthWrapper>
          }
          loader={async ({ params, request }) => {
            console.log("params", { params });
            console.log("request", { request });
            return await fetch(`https://ipecho.io/json`);
          }}
        /> */}
        <Route
          path="/recipeslist"
          element={
            <AuthWrapper>
              <RecipesList />
            </AuthWrapper>
          }
        />
        <Route
          path="/recipespages"
          element={
            <AuthWrapper>
              <RecipesPages />
            </AuthWrapper>
          }
        />
        <Route
          path="/recipes/:id"
          element={
            <AuthWrapper>
              <Recipe />
            </AuthWrapper>
          }
        />
        <Route
          path="/addrecipe"
          element={
            <AuthWrapper>
              <AddRecipe />
            </AuthWrapper>
          }
        />
        <Route
          path="/recipesfrominventory"
          element={
            <AuthWrapper>
              <RecipesFromInventory />
            </AuthWrapper>
          }
        />
        <Route
          path="/shoppinglist"
          element={
            <AuthWrapper>
              <ShoppingList />
            </AuthWrapper>
          }
        />
        <Route
          path="/inventory"
          element={
            <AuthWrapper>
              <Inventory />
            </AuthWrapper>
          }
        />
        <Route
          path="/categories"
          element={
            <AuthWrapper>
              <Categories />
            </AuthWrapper>
          }
        />
        {/* <Route path="/addcategory" element={<AuthWrapper><AddCategory /></AuthWrapper>} /> */}
        <Route
          path="/ingredients"
          element={
            <AuthWrapper>
              <Ingredients />
            </AuthWrapper>
          }
        />
        <Route
          path="/ingredientspages"
          element={
            <AuthWrapper>
              <IngredientsPages />
            </AuthWrapper>
          }
        />
        <Route
          path="/ingredients/:id"
          element={
            <AuthWrapper>
              <Ingredient />
            </AuthWrapper>
          }
        />
        <Route
          path="/addingredient"
          element={
            <AuthWrapper>
              <AddIngredient />
            </AuthWrapper>
          }
        />
        <Route
          path="/cookware"
          element={
            <AuthWrapper>
              <Cookware />
            </AuthWrapper>
          }
        />
        <Route
          path="/cookwarepages"
          element={
            <AuthWrapper>
              <CookwarePages />
            </AuthWrapper>
          }
        />
        <Route
          path="/cookware/:id"
          element={
            <AuthWrapper>
              <Cookware1 />
            </AuthWrapper>
          }
        />
        <Route
          path="/addcookware"
          element={
            <AuthWrapper>
              <AddCookware />
            </AuthWrapper>
          }
        />
        <Route
          path="/menuplanning"
          element={
            <AuthWrapper>
              <MenuPlanning />
            </AuthWrapper>
          }
        />
        {/* profile menu */}
        <Route
          path="/messages/*"
          element={
            <AuthWrapper>
              <Messages />
            </AuthWrapper>
          }
        />
        <Route
          path="/notifications/*"
          element={
            <AuthWrapper>
              <Notifications />
            </AuthWrapper>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthWrapper>
              <Profile toggleTheme={toggleTheme} />
            </AuthWrapper>
          }
        />
        <Route
          path="/user"
          element={
            <AuthWrapper>
              <BoardUser />
            </AuthWrapper>
          }
        />
        {/* <Route
                path="/mod"
                element={
                  <AuthWrapper>
                    <BoardModerator />
                  </AuthWrapper>
                }
              />
              <Route
                path="/admin"
                element={
                  <AuthWrapper>
                    <BoardAdmin />
                  </AuthWrapper>
                }
              />
              <Route
                path="/upload"
                element={
                  <AuthWrapper>
                    <FileUpload />
                  </AuthWrapper>
                }
              />
              <Route
                path="/uploads"
                element={
                  <AuthWrapper>
                    <UploadFiles />
                  </AuthWrapper>
                }
              /> */}
        <Route
          path="/setavatar"
          element={
            <AuthWrapper>
              <SetAvatar toggleTheme={toggleTheme} />
            </AuthWrapper>
          }
        />
        {/* <Route
                path="/chat"
                element={
                  <AuthWrapper>
                    <Chat toggleTheme={toggleTheme} />
                  </AuthWrapper>
                }
              /> */}
        <Route
          path="/preferences"
          element={
            <AuthWrapper>
              <Preferences toggleTheme={toggleTheme} />
            </AuthWrapper>
          }
        />
        {/* <Route path="/*" element={<Home toggleTheme={toggleTheme} />} /> */}
        {/* <Route
                exact
                path="/subscribe.html"
                render={() => {
                  window.location.href = "/subscribe.html";
                }}
              /> */}
        {/* <Route path="/subscribe.html" render={reload} /> */}
        {/* <Route path="/terms.html" render={reload} />
              <Route path="/privacy.html" render={reload} /> */}
        {/* <Route
                path="/subscribe"
                render={() => (
                  <Navigate
                    push
                    to={{
                      pathname: "/subscribe.html",
                    }}
                  />
                )}
              /> */}
        {/* <Route
                path="/terms"
                render={() => (
                  <Navigate
                    push
                    to={{
                      pathname: "/terms.html",
                    }}
                  />
                )}
              />
              <Route
                path="/privacy"
                render={() => (
                  <Navigate
                    push
                    to={{
                      pathname: "/privacy.html",
                    }}
                  />
                )}
              /> */}
        <Route
          path="/subscriptions/"
          element={
            <AuthWrapper>
              <Subscriptions />
            </AuthWrapper>
          }
        />
        <Route
          path="/subscriptions/subscribe"
          element={
            <AuthWrapper>
              <SubscriptionPage />
            </AuthWrapper>
          }
        >
          <Route
            index
            element={
              <AuthWrapper>
                <SubscriptionPage />
              </AuthWrapper>
            }
          />
          <Route
            path=":id?"
            element={
              <AuthWrapper>
                <SubscriptionPage />
              </AuthWrapper>
            }
          />
        </Route>
        <Route
          path="/subscriptions/success"
          element={
            <AuthWrapper>
              <SubscriptionSuccess />
            </AuthWrapper>
          }
        />
        <Route
          path="/subscriptions/failure"
          element={
            <AuthWrapper>
              <SubscriptionFailure />
            </AuthWrapper>
          }
        />
        <Route
          path="/subscriptions/cancel"
          element={
            <AuthWrapper>
              <SubscriptionCancel />
            </AuthWrapper>
          }
        />
        <Route
          path="/shop/"
          element={
            <AuthWrapper>
              <Shop />
            </AuthWrapper>
          }
        />
        <Route
          path="/shop/checkout"
          element={
            <AuthWrapper>
              <CheckOutPage />
            </AuthWrapper>
          }
        />
        <Route
          path="/shop/checkoutsuccess"
          element={
            <AuthWrapper>
              <CheckOutSuccess />
            </AuthWrapper>
          }
        />
        <Route
          path="/shop/checkoutfailure"
          element={
            <AuthWrapper>
              <CheckOutFailure />
            </AuthWrapper>
          }
        />
        <Route
          path="/shop/checkoutcancel"
          element={
            <AuthWrapper>
              <CheckOutCancel />
            </AuthWrapper>
          }
        />

        {/* Catch All */}
        <Route path="/*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};


export default App;

// function App() {
//   return (
//     <div className="container" style={{ width: "600px" }}>
//       <div className="my-3">
//         <h3>bezkoder.com</h3>
//         <h4>React hooks File Upload</h4>
//       </div>

//       <FileUpload />
//     </div>
//   );
// }
