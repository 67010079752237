import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  CLEAR_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";
import { setMessage } from "./action-creators/message";

export function getUserFromAPI(username, password) {
  return function (dispatch, getState) {
    return AuthService.getUserInfo(username, password).then(
      function (r) {
        // at this stage the user info is being fetched (i.e. "r"),
        // but not yet "dispatched", i.e. saved to the user object,
        // hence right now the user is NOT yet logged in!
        // console.log("getUserFromAPI() ", getState());
        // console.log("getUserFromAPI() ", r);
        dispatch(setMessage("user logged in"));
        return Promise.resolve(r);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setMessage(message));
        return Promise.reject();
      }
    );
  };
}

export const register =
  (forename, surname, username, email, password) => (dispatch) => {
    return AuthService.register(
      forename,
      surname,
      username,
      email,
      password
    ).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch(setMessage(response.data.message));

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch(setMessage(message));

        return Promise.reject();
      }
    );
  };

/**
 *
 * @param {*} username
 * @param {*} password
 * @returns Promise.reject or resolve
 *
 * Does NO input validation, just checking with the backend
 * if credentials are valid
 *
 * makes use of thunk---------------------------------------
 * https://daveceddia.com/what-is-a-thunk/                  |
                                                           \/   */
export const login = (username, password) => (dispatch, getState) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      // console.log(getState());
      // dispatch({
      //   type: CLEAR_MESSAGE,
      // });
      const message = `Successfully logged in: ${data.username}`;
      // console.log("from auth.login() message ", message);
      dispatch(setMessage(message));
      localStorage.setItem("message", JSON.stringify(message));

      // console.log("from AuthService.login() data ", data);

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // console.log("error message ", message);

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch(setMessage(message));
      // reject with reason in message
      return Promise.reject("error" + message);
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
