import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import KeyIcon from "@mui/icons-material/Key";

import { ToastContainer, toast } from "react-toastify";

import { withTheme } from "styled-components";

import UserService from "../../services/user.service";

import LoadingSpinner from "../../components/Utils/LoadingSpinner";
// import useWindowDimensions from "../../hooks/useWindowDimensions";
import { setMessage } from "../../actions/action-creators/message";

const Private = ({ toggleTheme, theme }) => {
  const [content, setContent] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  // const [loading, setLoading] = useState(true);
  let loading = !(Array.isArray(content) && content.length > 0);
  // const { height, width } = useWindowDimensions();
  // console.log("empty: ", content);
  // console.log("empty: ", content.length);
  // console.log("currentUser: ", currentUser);

  const toastOptions = {
    position: "top-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: theme.name,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log("Private");

  useEffect(() => {
    // setLoading(true);
    // console.log("message ", message);
    // if (message) {
    // }
    const savedMessage = JSON.parse(localStorage.getItem("message"));
    // console.log("savedMessage ", savedMessage);
    // get message from localStorage
    dispatch(setMessage(savedMessage));
    // console.log("message ", message);
    toast.error(message, toastOptions);
    // Do NOT use this one, it deletes the WHOLE localStorage
    // localStorage.clear();

    return localStorage.removeItem("message");
  }, []);

  useEffect(() => {
    // dispatch(setMessage(message));
    // console.log("useEffect");
    // setTimeout(() =>
    UserService.getPublicContent()
      .then(
        (response) => {
          // console.log("data: ", response.data);
          setContent(response.data);
          // setLoading(false);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          setContent(_content);
          // console.log("getPublicContent() error: ", _content);
        }
      )
      .catch((err) => {
        console.log(err);
      });
    // , 5000)
  }, []);

  const handleOnClick = (route) => {
    // 👇️ replace set to true
    navigate("/" + route, { replace: true });
  };

  // console.log("content: ", content);
  // console.log("render");
  // console.log("loading: ", loading);

  if (loading) {
    // console.log("loading: ", loading);
    // console.log("go spinning");
    return (
      <div className="loader">
        Loading ... <LoadingSpinner loading={loading} />
      </div>
    );
  }
  if (!loading && content) {
    return (
      // <div className="container" style={{border: "2px solid red"}}>
      <div className="container">
        <ToastContainer />
        <h1>Hello {currentUser.forename}</h1>
        {message && (
          <Form.Group>
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </Form.Group>
        )}
        <KeyIcon style={{ position: "absolute", left: "0" }} />
        <div className="list row">
          {/*
           * Left Pane
           */}
          <div
            className="col-md-6"
            style={{
              border: "solid black 1px",
              backgroundColor: "whitesmoke",
              padding: "20px",
              borderRadius: "20px",
            }}
          >
            Left Pane
            <div>
              <small>This is the page after successful login.</small>
            </div>
            <div>
              <p>
                This app allows you for organizing and planning your every day
                activities and tasks as well as covers the needs for your
                cooking and menu planning.
              </p>
              {/* <p>On one hand side the activities:</p>
              <ul>
                <li
                  style={{ textDecoration: "underline" }}
                  onClick={() => handleOnClick("todo")}
                >
                  To Do
                </li>
                <li
                  style={{ textDecoration: "underline" }}
                  onClick={() => handleOnClick("chat")}
                >
                  Chat
                </li>
                <li
                  style={{ textDecoration: "underline" }}
                  onClick={() => handleOnClick("keeper")}
                >
                  Notes
                </li>
                <li
                  style={{ textDecoration: "underline" }}
                  onClick={() => handleOnClick("contacts")}
                >
                  Contacts
                </li>
                <li
                  style={{ textDecoration: "underline" }}
                  onClick={() => handleOnClick("invoices")}
                >
                  Invoices
                </li>
              </ul> */}
              <p>On the other hand side the cooking:</p>
              <ul>
                <li
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "blue",
                  }}
                  onClick={() => handleOnClick("recipes")}
                >
                  Recipes
                </li>
                <li
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "blue",
                  }}
                  onClick={() => handleOnClick("menuplanning")}
                >
                  Menu Planning
                </li>
                <li
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "blue",
                  }}
                  onClick={() => handleOnClick("shoppinglist")}
                >
                  Shopping List
                </li>
                <li
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "blue",
                  }}
                  onClick={() => handleOnClick("inventory")}
                >
                  Inventory
                </li>
              </ul>
            </div>
            {/* <h3>{content}</h3> */}
            {/* {content} */}
            {/* {console.log(content)} */}
            {/* <header className="jumbotron">
              {content &&
                content.map((obj, index) => {
                  return (
                    <div key={index}>
                      <p>{obj.title}</p>
                      <p>{obj.subtitle}</p>
                    </div>
                  );
                })}
            </header>
            logged user's Private home page */}
            {/* <div>
          width: {width} ~ height: {height}
        </div> */}
          </div>
          {/*
           * Right Pane
           */}
          <div
            className="col-md-6"
            style={{
              border: "solid black 1px",
              backgroundColor: "whitesmoke",
              padding: "20px",
              borderRadius: "20px",
            }}
          >
            <h5>News Ticker</h5>
            <div className="list row">
              <div
                className="col-md-12"
                style={{
                  border: "solid black 1px",
                  backgroundColor: "whitesmoke",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                News Ticker for Site News
              </div>
              <div
                className="col-md-12"
                style={{
                  border: "solid black 1px",
                  backgroundColor: "whitesmoke",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                News Ticker for Cooking News
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default withTheme(Private);
