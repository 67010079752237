import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../actions/types";

// // get the user from localStorage
// var userFromStore = localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY);
// if (userFromStore === "undefined") userFromStore = null;
// console.log("userFromStore ", userFromStore);
// console.log("!userFromStore ", !userFromStore);
// // const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));
// // const user = !userFromStore ? null : JSON.parse(userFromStore);

// var user;
// if (!userFromStore) user = null;
// else user = JSON.parse(userFromStore);

// user = JSON.parse(userFromStore);

const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

function actOnState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
export default actOnState;
