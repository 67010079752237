import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Public as PublicIcon } from "@mui/icons-material";

import UserService from "../../services/user.service";

import LoadingSpinner from "../../components/Utils/LoadingSpinner";
// import useWindowDimensions from "../../hooks/useWindowDimensions";

import server from "../../config/server.config";
import __DEBUG__ from "../../config/debug.config";

const API_URL =
  server.host + (server.port === "" ? "" : ":" + server.port);

// var __DEBUG__; // = "DEBUG"
// if (process.env.NODE_ENV === "development") {
//   __DEBUG__ = "DEBUG";
// }
// console.log("__DEBUG__ ", __DEBUG__);

__DEBUG__ &&
  console.log(
    "-------------------------------->process.env.NODE_ENV ",
    process.env.NODE_ENV
  );

if (__DEBUG__) {
  console.log("process.env.PUBLIC_URL ", process.env.PUBLIC_URL);
  console.log("process.env.REACT_APP_IMAGE_SERVER_URL ", process.env.REACT_APP_IMAGE_SERVER_URL);
  console.log("process.env.REACT_APP_API_URL ", process.env.REACT_APP_API_URL);
  console.log("process.env.REACT_APP_ENV ", process.env.REACT_APP_ENV);
  console.log(
    "process.env.REACT_APP_PUBLIC_URL ",
    process.env.REACT_APP_PUBLIC_URL
  );
  console.log("process.env.REACT_APP_BASE_NAME ", process.env.REACT_APP_BASE_NAME);
}

const Public = () => {
  const [content, setContent] = useState([]);
  // const [loading, setLoading] = useState(true);
  let loading = !(Array.isArray(content) && content.length > 0);
  // const { height, width } = useWindowDimensions();
  // console.log("empty: ", content);
  // console.log("empty: ", content.length);
  const navigate = useNavigate();

  // console.log("Public");

  useEffect(() => {
    // setLoading(true);
    // console.log("useEffect");
    // setTimeout(() =>
    UserService.getPublicContent()
      .then(
        (response) => {
          // console.log("data: ", response.data);
          setContent(response.data);
          // setLoading(false);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          setContent(_content);
          console.log("error: ", _content);
        }
      )
      .catch((err) => {
        console.log(err);
      });
    // , 5000)
  }, []);

  const handleOnClick = (route) => {
    // 👇️ replace set to true
    navigate("/" + route, { replace: true });
  };

  // console.log("content: ", content);
  // console.log("render");
  // console.log("loading: ", loading);

  if (loading) {
    // console.log("loading: ", loading);
    // console.log("go spinning");
    return (
      <div className="loader">
        Loading ... <LoadingSpinner loading={loading} />{" "}
      </div>
    );
  }
  if (!loading && content) {
    return (
      // <div className="container" style={{border: "2px solid red"}}>
      <div className="container">
        <PublicIcon style={{ position: "absolute", left: "0" }} />
        {/* <h1>Public content</h1> */}
        <h1>Welcome to Pollucturum App.</h1>
        <h5>
          Welcome to your Pollucturum app, the app for all regarding cooking.
          Nearly.
          {/* Welcome to your swiss knife's app, the app for everything, nearly. */}
        </h5>
        <div>
          <p>
            This app is in beta phase and only accessible by invitation.
            {/* This app allows you for organizing and planning your every day
            activities and tasks as well as covers the needs for your cooking
            and menu planning. */}
          </p>
          <div className="mb-3">
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
              velit, sed quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
              veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
              vel eum iure reprehenderit qui in ea voluptate velit esse quam
              nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo
              voluptas nulla pariatur?
            </p>
            <div>
              If you want to know more, just follow the link{" "}
              <a className="badge bg-warning" href={`${API_URL}/index.html`}>
                Product Information (Not available yet. Please accept our apologies.)
              </a>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-10">Have already an account, just</div>
                <div className="col-2">
                  <Link to="/login">
                    <button className="badge bg-warning">Login</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-10">
                  Want to open an account, just (not available yet)
                </div>
                <div className="col-2">
                  {/* <Link to="/register"> */}
                  <button disabled className="badge bg-warning">
                    Register
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>

          {/* <p>On one hand side the activities:</p>
          <ul>
            <li
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOnClick("todo")}
            >
              To Do
            </li>
            <li
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOnClick("keeper")}
            >
              Notes
            </li>
            <li
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOnClick("contacts")}
            >
              Contacts
            </li>
            <li
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOnClick("invoices")}
            >
              Invoices
            </li>
          </ul> */}
          {/* <p>On the other hand side the cooking:</p> */}
          {/* <p>Some features of the Pollucturum App:</p>
          <ul>
            <li
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOnClick("recipes")}
            >
              Recipes
            </li>
            <li
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOnClick("menuplanning")}
            >
              Menu Planning
            </li>
            <li
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOnClick("shoppinglist")}
            >
              Shopping List
            </li>
            <li
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOnClick("inventory")}
            >
              Inventory
            </li>
          </ul> */}
        </div>
        {__DEBUG__?        <div>
          <small>
            You are running this application in <b>{process.env.NODE_ENV}</b>{" "}
            mode.
            <br />
            From the local development environment:{" "}
            {process.env.REACT_APP_DEV_LOCAL}.<br />
            From the development environment: {process.env.REACT_APP_DEV}
            <br />
            The environment variables for this build are taken from{" "}
            {process.env.REACT_APP_ENV}.
          </small>
        </div>
:null}
        {/* <form>
            <input
              type="hidden"
              defaultValue={process.env.REACT_APP_NOT_SECRET_CODE}
            />
          </form> */}
        {/* <h3>{content}</h3> */}
        {/* {content} */}
        {/* <header>
          <div className="jumbotron">
            {content.map((obj, index) => {
              return (
                <div key={index}>
                  <p>{obj.title}</p>
                  <p>{obj.subtitle}</p>
                </div>
              );
            })}
          </div>
        </header> */}
        {/* both, logged and not logged user's Public home page */}
        {/* <div>
          width: {width} ~ height: {height}
        </div> */}
      </div>
    );
  }
  return null;
};

export default Public;
