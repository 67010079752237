import { useState, useRef } from "react";

import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";

// import SellIcon from "@mui/icons-material/Sell";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";

import server from "../../config/server.config";

import "./Payments.css";
import imageNotAvailable from "../../assets/img/image-not-available.jpg";
const BACKEND_URL = server.host + (server.port === "" ? "" : ":" + server.port);

// const imageServerURL = server.cdn;
const imageServerURL = BACKEND_URL + "/assets/images/";

function addDefaultSrc(e) {
  e.target.src = imageNotAvailable;
}

const subscriptions = [
  {
    id: "0",
    name: "free",
    fee: 0,
    frequency: "month",
    recurrent: true,
    image: "chef-hat-free.jpg",
    description: [
      "Access to a Selection of Recipes",
      "Limited App Usage",
      "Ad supported",
      "In app purchases",
    ],
    qty: 0,
  },
  {
    id: "1",
    name: "starter",
    fee: 100,
    frequency: "month",
    recurrent: true,
    image: "chef-hat-starter.jpg",
    description: [
      "Access to All Recipes",
      "Unlimited App Usage",
      "Ad supported",
      "In app purchases",
    ],
    qty: 0,
  },
  {
    id: "2",
    name: "basic",
    fee: 200,
    frequency: "month",
    recurrent: true,
    image: "chef-hat-basic.jpg",
    description: [
      "All the features of the starter plan",
      "Shopping List",
      "No adds",
      "Social Interaction: Chat",
    ],
    qty: 0,
  },
  {
    id: "3",
    name: "family",
    fee: 600,
    frequency: "month",
    recurrent: true,
    image: "chef-hat-pro.jpg",
    description: [
      "All the features of the Basic plan",
      "Share & collaborate",
      "Access for up to 5 family members",
      "Email Support",
    ],
    qty: 0,
  },
  {
    id: "4",
    name: "pro",
    fee: 500,
    frequency: "month",
    recurrent: true,
    image: "chef-hat-pro.jpg",
    description: [
      "All the features of the Basic plan",
      "Larder Management",
      "Private cooking books",
      "Email Support",
    ],
    qty: 0,
  },
  {
    id: "5",
    name: "chef",
    fee: 1000,
    frequency: "month",
    image: "chef-hat-chef.jpg",
    recurrent: true,
    description: [
      "All the features of the Pro plan",
      "Social Cooking: Life Stream",
      "Live Support*",
      "3rd Party Integration",
    ],
    qty: 0,
  },
];
let cart = [];

const Subscriptions = () => {
  const [showButton, setShowButton] = useState(false);
  const [show, setShow] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  const target = useRef(null);

  // const { user: currentUser } = useSelector((state) => state.auth);


  function handleSubscription(id) {
    // check if product already in cart,
    // if then increment the quantity,
    // not adding the product again
    setIsDisabled(true);
    // console.log(id);
    let productAlreadyInCart = false;
    let indx = 0;
    // for (var subscription in subscriptions) {
    //   console.log("for in: ", subscriptions[subscription]);
    // }
    for (var item of cart) {
      // console.log("for of: ", indx, item);
      if (item.id === id) {
        productAlreadyInCart = true;
        break;
      }
      indx++;
    }
    // console.log(productAlreadyInCart);
    if (productAlreadyInCart) {
      cart[indx].qty++;
      setTotalAmount((totalAmount)=>{return(totalAmount+cart[indx].fee)});
    } else {
      cart.push(subscriptions[id]);
      cart[cart.length - 1].qty++;
      setTotalAmount((totalAmount)=>{return(totalAmount + cart[cart.length - 1].fee)});
    }
    // console.log("totalAmount ", totalAmount);
    // subscriptions.forEach((subscription) => {
    //   console.log("forEach: ", subscription);
    // });
    // console.log(cart);
    // console.log(cart.length);
    setShowButton(cart.length > 0);
  }

  return (
    <div className="App">
      <h1>Subscriptions</h1>
      {showButton ? (
        <Button variant="danger" ref={target} onClick={() => setShow(!show)}>
          {show ? "Hide your subscription" : "Show your subscription"}
        </Button>
      ) : null}
      {/* You can choose between:
      <Link to={"/payments/subscription"} className="nav-link">
        Subscription
      </Link>
      and
      <Link to={"/payments/checkout"} className="nav-link">
        Checkout
      </Link> */}
      <div>
        <div className="list row">
          {subscriptions.map((subscription, indx) => {
            const { id } = subscription;
            // let nextLine;
            // if (indx % 3 === 0) {
            //   nextLine = true;
            // }
            // console.log("Recipes ", indx, id, createdAt, recipe);
            // console.log("Recipes ", indx, id);

            return (
              // <RecipeCard
              //   key={id}
              //   id={id}
              //   createdAt={createdAt}
              //   indx={indx}
              //   recipe={recipe}
              // />
              <div
                id={"subscription-" + indx}
                key={indx}
                className="col-xl-4 col-lg-6 col-md-12"
              >
                {/* <p>{indx}</p> */}
                {/* <Link
                  to={"/payments/subscription/" + subscription.id}
                  // className="badge bg-warning"
                > */}
                {/* Edit */}
                <Card
                  // className="card bg-dark text-white"
                  className="card"
                  // bg={"secondary"}
                  style={{
                    width: "20rem",
                    margin: "10px auto",
                    borderWidth: "4px",
                  }}
                >
                  {indx === 3 || indx === 4 || indx === 5 ? (
                    <Card.Title
                      style={{ textAlign: "center", fontSize: "3rem" }}
                    >
                      <p style={{ color: "lightgrey", fontSize: "2rem" }}>
                        not available yet
                      </p>
                    </Card.Title>
                  ) : null}

                  {/* <Card.Title style={{ textAlign: "center", fontSize: "3rem" }}>
                    {subscription.name}
                  </Card.Title> */}
                  {/* {imageServerURL} */}
                  {true ? (
                    <div className="shrinkToFitImage">
                      <Card.Img
                        // variant="top"
                        src={imageServerURL + subscription.image}
                        onError={addDefaultSrc}
                      />
                      {/* <label>
                                <strong>Picture (server side):</strong>
                                </label>
                                {recipe.media.images.head} */}
                      {/* <img
                                width={"100%"}
                                src={recipe.media.images.head}
                                alt="Image Head Recipe {recipe.name}"
                              /> */}
                    </div>
                  ) : (
                    <div>
                      <Card.Img
                        variant="top"
                        style={{ maxHeight: "250px" }}
                        src={imageNotAvailable}
                        alt="Image Head Recipe not  available yet..."
                      />
                      {/* <img src={imageNotAvailable} alt="Image Head Recipe" /> */}
                      {/* <br />
                            <p>No picture available yet...</p> */}
                    </div>
                  )}
                  {/* <Card.ImgOverlay></Card.ImgOverlay> */}
                  <Card.Title style={{ textAlign: "center", fontSize: "3rem" }}>
                    {subscription.name}
                    {/* {indx === 4 ? (
                      <p style={{ color: "lightgrey", fontSize: "2rem" }}>
                        not available yet
                      </p>
                    ) : null} */}
                  </Card.Title>
                  <Card.Body
                    style={
                      indx === 3 || indx === 4 || indx === 5
                        ? { color: "lightgrey" }
                        : { color: "black" }
                    }
                  >
                    {/* <div>
                            <label>
                              <strong>Name:</strong>
                            </label>
                            {recipe.name}
                          </div> */}
                    <div>
                      <label>
                        <strong>Description:</strong>
                      </label>
                      <br />
                      <div>
                        <ul>
                          {subscription.description.map((item, indx) => {
                            return <li key={indx}>{item}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                    {/* <Card.Text>
                        {subscription.description.map((subscription, indx) => {
                          return <li>{subscription}</li>;
                        })}
                      </Card.Text> */}
                    {/* <Card.Text>{subscription.description}</Card.Text> */}
                    <div>
                      <label>
                        <strong>Billing Frequency:&nbsp;</strong>
                      </label>
                      {subscription.frequency}
                    </div>
                    {/* <div>
                      <label>
                        <SellIcon />
                        <strong>Tag:&nbsp;</strong>
                      </label>
                      None
                    </div> */}
                    <div className="mb-1 mt-1">
                      <label>
                        <strong>Monthly fee: </strong>
                      </label>
                      <button
                        key={indx}
                        className="badge bg-warning mr-2"
                        style={{ color: "#0d6efd", marginLeft: "10px" }}
                      >
                        {subscription.fee / 100} € (Eur)
                      </button>
                    </div>
                    {/* {console.log("indx: ", indx)} */}
                    <Button
                      variant="primary"
                      onClick={() => handleSubscription(subscription.id)}
                      disabled={
                        isDisabled || indx === 3 || indx === 4 || indx === 5
                      }
                    >
                      Yes, choose this subscription
                    </Button>
                    {/* <Link
                              to={"/recipes/" + id}
                              className="badge bg-warning"
                            >
                              Edit
                            </Link> */}
                  </Card.Body>
                  {/* </Card.ImgOverlay> */}
                </Card>
                {/* </Link> */}
                {/* <div> {createdAt} </div>
                          <div> {recipe.name} </div>
                          <div> {recipe.description} </div>
                          <div> {recipe.category} </div>
                          <div> {recipe.subcategory} </div> */}
              </div>
            );
          })}
        </div>
      </div>
      <Overlay target={target.current} show={show} placement="right">
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              position: "fixed", // "sticky",   //"relative", // "absolute",
              // right: "0pt",
              // top: "0pt",
              backgroundColor: "rgba(100, 203, 255, 0.859)",
              // backgroundColor: "rgba(255, 100, 100, 0.85)",
              padding: "20px 10px",
              color: "white",
              borderRadius: 7,
              ...props.style,
            }}
          >
            <div className="list row">
              {cart.map((item, idx) => {
                return (
                  <div key={idx} className="col-xl-12 col-lg-12 col-md-12">
                    {item.qty} {item.name} @ {item.fee/100} € / month ={" "}
                    {item.qty * item.fee/100} € / month
                  </div>
                );
              })}
            </div>
            <div style={{ color: "black" }}>
              The total amout to check out: {totalAmount/100} € / month
            </div>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <Button style={{ className: "button" }}>
                <Link
                  style={{ color: "white" }}
                  to={"/subscriptions/subscribe"}
                  // state={{ id: 1, name: "sabaoon", shirt: "green" }}
                  // state={[{ item: "Online Video Editor", qty: "3", itemCode: "99" }]}
                  state={cart}
                >
                  Subscribe
                </Link>{" "}
              </Button>
            </div>
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default Subscriptions;
