import React from "react";

import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";

import RestaurantIcon from "@mui/icons-material/Restaurant";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StarRateIcon from "@mui/icons-material/StarRate";
// import StarOutlineIcon from "@mui/icons-material/StarOutline";

import Repeater from "../Recipes/Repeater";

// import imageNotAvailable from "../../assets/img/image-not-available.jpg";
import server from "../../config/server.config";
const imageServerURL = server.cdn;

function MiniCard(props) {
  // console.log("props.recipe.image ", props.recipe.image);
  return (
    <div className="row">
      <div
        className="col-4"
        // style={{ margin: "auto"}}
      >
        <div
        // style={{ margin: "5px", textAlign:"center" }}
        >
          <Link to={`/recipes/${props.recipe.id}`} className="nav-link">
            <Card.Img
              style={{ margin: "auto", borderRadius: "10px", width: "100%" }}
              variant="top"
              src={imageServerURL + props.recipe.image}
            />
          </Link>
          {/* <label>
            <strong>Picture (server side):</strong>
          </label>
          {props.recipe.image}
          <img
            style={{ borderRadius: "40px", width: "80px" }}
            width={"100%"}
            src={props.recipe.image}
            alt="Image Head Recipe {props.recipe.name}"
          /> */}
        </div>
      </div>
      <div className="col-8">
        <div className="row">
          <div
            className="col"
            style={{ overflow: "clip", textOverflow: "ellipsis" }}
          >
            {props.recipe.name}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div
              style={{
                margin: "0 2px",
                // borderTop: "solid #aeaeae 0.5px",
                padding: "2px",
                backgroundColor: "#e4e4e4",
                borderRadius: "10px",
                textAlign: "center",
                fontSize: "0.75rem",
              }}
            >
              <div id="time" style={{ whiteSpace: "nowrap" }}>
                <ScheduleIcon
                  style={{
                    fontSize: "1rem",
                  }}
                />{" "}
                {props.recipe.time ? props.recipe.time : "1:00h"}
              </div>
            </div>
          </div>
          {/* {props.recipe.time} */}
          <div className="col">
            <div
              style={{
                margin: "0 2px",
                // borderTop: "solid #aeaeae 0.5px",
                padding: "2px",
                backgroundColor: "#e4e4e4",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              <div id="difficulty" style={{ whiteSpace: "nowrap" }}>
                <Repeater
                  fontSize={"1rem"}
                  icon={RestaurantIcon}
                  count={
                    props.recipe.difficulty ? props.recipe.difficulty : "3"
                  }
                />
              </div>
            </div>
          </div>
          {/* {props.recipe.difficulty} */}
        </div>
        <div className="row">
          <div className="col">
            <div
              style={{
                margin: "0 auto",
                // borderTop: "solid #aeaeae 0.5px",
                // padding: "2px",
                // backgroundColor: "#e4e4e4",
                // borderRadius: "10px",
                // textAlign: "center",
                // width: "130px",
                // fontSize:"large"
              }}
            >
              <div
                style={{
                  // margin: "0 auto",
                  // borderTop: "solid #aeaeae 0.5px",
                  padding: "2px",
                  backgroundColor: "#e4e4e4",
                  borderRadius: "10px",
                  textAlign: "center",
                  // width: "130px",
                  // fontSize:"large"
                }}
              >
                <div id="rating" style={{ whiteSpace: "nowrap" }}>
                  <Repeater
                    fontSize={"1rem"}
                    icon={StarRateIcon}
                    count={props.recipe.rating ? props.recipe.rating : "3"}
                  />{" "}
                  {" ("}
                  {props.recipe.ratings}
                  {")"}
                </div>
              </div>
            </div>
          </div>
          {/* {props.recipe.rating} */}
        </div>
      </div>
    </div>
  );
}

export default MiniCard;
