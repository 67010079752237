/**
 * This helped a lot
 * especially styling
 * https://www.bezkoder.com/react-node-express-mongodb-mern-stack/
 */


import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { Pagination } from "@mui/material";


// import UserService from "../../services/user.service";
import CookwareDataService from "../../services/cookware.service";

import "./Cookware.css";

// Tell webpack this JS file uses this image
import imageNotAvailable from "../../assets/img/image-not-available.jpg";


/**
 * component definition
 */

const CookwareList = () => {
  const [cookware, setCookware] = useState([]);
  const [currentCookware, setCurrentCookware] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchName, setSearchName] = useState("");

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const pageSizes = [5, 10, 20];

  const getRequestParams = (searchName, page, pageSize) => {
    let params = {};

    if (searchName) {
      params["name"] = searchName;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };


  const retrieveCookware = () => {
    // const pageSize = 10;
    // const page = 1;
    const params = getRequestParams(searchName, page, pageSize);

    CookwareDataService.getAllPaginated(params)
      .then((response) => {
        const { cookware, totalPages } = response.data;

        setCookware(cookware);
        setCount(totalPages);

        // console.log(response.data.cookware);
      })
      .catch((e) => {
        console.log("retrieveCookware: ", e);
      });
  };

  useEffect(() => {
    retrieveCookware();
  }, [page, pageSize]);



  const refreshList = () => {
    retrieveCookware();
    setCurrentCookware(null);
    setCurrentIndex(-1);
  };

  const setActiveCookware = (cookware, index) => {
    // console.log("cookware ", cookware);
    // console.log("index ", index);
    setCurrentCookware(cookware);
    setCurrentIndex(index);
  };

  const removeAllCookware = () => {
    CookwareDataService.removeAll()
      .then((response) => {
        console.log(response.data.cookware);
        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const findByName = () => {
    CookwareDataService.findByName(searchName)
      .then((response) => {
        setCookware(response.data.cookware);
        // console.log(response.data.cookware);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  return (
    <div className="list row">
      <div className="col-md-8">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search by name"
            value={searchName}
            onChange={onChangeSearchName}
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={findByName}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <h4>Cookware List</h4>

        <div className="mt-3">
          {"Items per Page: "}
          <select onChange={handlePageSizeChange} value={pageSize}>
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>

          <Pagination
            className="my-3"
            count={count}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>

        <ul className="list-group">
          {cookware &&
            cookware.map((cookware, index) => (
              <li
                className={
                  "list-group-item " + (index === currentIndex ? "active" : "")
                }
                onClick={() => setActiveCookware(cookware, index)}
                key={index}
              >
                {cookware.cookware.name}
              </li>
            ))}
        </ul>

        <button
          className="m-3 btn btn-sm btn-danger"
          onClick={removeAllCookware}
        >
          Remove All
        </button>

        <button className="m-3 btn btn-sm btn-danger">
          <Link to={"/addcookware"} className="nav-link">
            Add
          </Link>
        </button>
      </div>
      <div className="col-md-6">
        {currentCookware ? (
          <div>
            <h4>Cookware</h4>
            <div>
              <label>
                <strong>Name:</strong>&nbsp;
              </label>
              {currentCookware.cookware.name}
            </div>
            <div>
              <label>
                <strong>Description:</strong>&nbsp;
              </label>
              {currentCookware.cookware.description}
            </div>
            <div>
              <label>
                <strong>Presentation:</strong>&nbsp;
              </label>
              {currentCookware.cookware.presentation}
            </div>
            <div>
              <label>
                <strong>Category:</strong>&nbsp;
              </label>
              {currentCookware.cookware.category}
            </div>
            <div>
              <label>
                <strong>Picture:</strong>&nbsp;
              </label>
              {currentCookware.cookware.media &&
              currentCookware.cookware.media.images &&
              currentCookware.cookware.media.images.head ? (
                <div>
                  <img
                    width={"25%"}
                    src={currentCookware.cookware.media.images.head}
                    alt={`Image Head category ${currentCookware.cookware.name}`}
                  />
                </div>
              ) : (
                <div>
                  <img src={imageNotAvailable} alt="Image Head cookware" />
                </div>
              )}
            </div>
            <div>
              <label>
                <strong>Units:</strong>&nbsp;
              </label>
              {currentCookware.cookware.qtyUnit}
            </div>
            <div>
              <label>
                <strong>Quantity:</strong>&nbsp;
              </label>
              {currentCookware.cookware.quantity}
            </div>
            <div>
              <label>
                <strong>Status:</strong>&nbsp;
              </label>
              {currentCookware.cookware.published ? "Published" : "Pending"}
            </div>
            {/* {console.log("currentCookware ", currentCookware)} */}
            <Link
              to={"/cookware/" + currentCookware.id}
              className="badge bg-info"
            >
              Show
            </Link>
            <Link
              to={"/cookware/" + currentCookware.id}
              className="badge bg-warning"
            >
              Edit
            </Link>
          </div>
        ) : (
          <div>
            <br />
            <p>Please click on a Cookware...</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default CookwareList;