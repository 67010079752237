import http from "../config/http-common";

import authHeader from "./auth-header";


class CategoryDataService {

  getAll(params) {
    const response = http.get("/api/categories/", {
      headers: authHeader(),
      params: params,
    });
    // console.log("response ", response);
    return response;
  }

  get(id) {
    return http.get(`/api/categories/${id}`, { headers: authHeader() });
  }

  create(data) {
    // console.log("data ", data);
    return http.post("/api/categories/add", data, { headers: authHeader() });
  }

  update(ensemble, id, data) {
    const params = {
      ensemble: ensemble,
      id: id,
      data: data,
    };
    // console.log("data ", data)
    return http.put(`/api/categories/${id}`, params, { headers: authHeader() });
  }

  delete(id) {
    return http.delete(`/api/categories/${id}`, { headers: authHeader() });
  }

  deleteAll() {
    return http.delete(`/api/categories`, { headers: authHeader() });
  }

  findByName(name) {
    // console.log("findByName API call: name", name)
    return http.get(`/api/categories?name=${name}`, {
      headers: authHeader(),
    });
  }
}
export default new CategoryDataService();
