import { combineReducers } from "redux";

import windowSize from "./windowSize";
import message from "./message";
import auth from "./auth";
// is a DEFAULT import
// import actOnState from "./auth";
// const auth = actOnState;

// rootReducer
export default combineReducers({
  windowSize,
  auth,
  message,
});
