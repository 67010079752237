import React, { useState, useEffect, createRef, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";

// import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import KitchenIcon from "@mui/icons-material/Kitchen";
import { Fab } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";

import ListItems from "../../components/ShoppingList/ListItems";
import EditItemRow from "../../components/ShoppingList/EditItemRow";
import CategorisedIngredients from "../../components/Ingredients/CategorisedIngredients";

import CategoryDataService from "../../services/category.service";
import InventoryService from "../../services/inventory.service";
import handleSave from "../../components/Inventory/Inventory.Aux";
import { compactList as compactInventory } from "../../helpers/helper";

/**
 * auth_db.shoppinglists is a collection of shoppingLists
 * each object is asigned to one and only one user, identified by the userid
 * each shoppingList is an object with
 * - id (of the object in the collection)
 * - userid
 * - items[] an array that contains the shopping list items which themselves
 *   are objects and contain the data (quantity, index, qtyUnit, ingredient,
 *   for the time being)
 *
 * Next step could be to introduce an array of shopping list for the same user
 * or alternatively several objects of the base collection with the same userid.
 */

import { green } from "@mui/material/colors";

// assets
import "../../components/ShoppingList/ShoppingList.css";
import { Inventory as InventoryIcon } from "@mui/icons-material";

// const useStyles = makeStyles((theme) => ({
//   grow: {
//     flexGrow: 1,
//   },
//   sectionMobile: {
//     display: "flex",
//     [theme.breakpoints.up("md")]: {
//       display: "none",
//     },
//   },
//   listItemsBox: {
//     color: "green", fontSize: "20pt", border: "5pt yellow solid"
//   }
// }));

let items = [
  { index: 1, ingredient: "pimientos", quantity: 5, qtyUnit: "uds" },
  { index: 2, ingredient: "tomates", quantity: 2, qtyUnit: "uds" },
  { index: 3, ingredient: "tomate triturado", quantity: 2, qtyUnit: "kg" },
  { index: 4, ingredient: "tomate frito", quantity: 4, qtyUnit: "l" },
  { index: 5, ingredient: "tomate en pasta", quantity: 3, qtyUnit: "latas" },
  { index: 6, ingredient: "huevos", quantity: 3, qtyUnit: "uds" },
  { index: 7, ingredient: "guisantes", quantity: 3, qtyUnit: "kg" },
  { index: 8, ingredient: "zanahorias", quantity: 3, qtyUnit: "uds" },
  { index: 9, ingredient: "acelgas", quantity: 1, qtyUnit: "fajo" },
  { index: 10, ingredient: "esparragos", quantity: 3, qtyUnit: "frascos" },
];

const emptyItem = {
  index: 1,
  ingredient: "",
  name: "",
  quantity: 5,
  qtyUnit: "uds",
  description: "",
};
const ensemble = "Ingredient";
const ensembles = ["Measure"];

function Inventory() {
  const [item, setItem] = useState(emptyItem);
  const [addingMode, setAddingMode] = useState(false);
  const [items, setItems] = useState([]);
  // const { message } = useSelector((state) => state.message);
  const [message, setMessage] = useState("");
  const { user: currentUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [isInError, setIsInError] = useState(false);
  const [categoryGroup, setCategoryGroup] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [ingredients, setIngredients] = useState([]);

  const myRef = useRef("there is no element");

  const [categoryGroups, setCategoryGroups] = useState({});
  const [options, setOptions] = useState([
    //     [{ label: "", value: "", action: "" }],
    [],
  ]);

  let errorElement = null;
  let navigate = useNavigate();

  // const classes = useStyles();

  /**
   * database functions
   */

  const retrieveCategoryGroup = (ensemble) => {
    CategoryDataService.getAll({ name: "", ensemble: ensemble })
      .then((response) => {
        setCategoryGroup(response.data.categoryGroup);
        // console.log("response.data.categoryGroup ", response.data.categoryGroup);
        // console.log(response);
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  useEffect(() => {
    retrieveCategoryGroup(ensemble);
  }, []);

  useEffect(() => {
    setIngredients(
      items.map((item, indx) => {
        return { ingredient: item };
      })
    );
    // console.log("ingredients ", ingredients);
  }, [items]);

  async function getInventory() {
    return InventoryService.getInventory(currentUser)
      .then(
        (response) => {
          // console.log("response.data.totalItems ", response.data.totalItems);
          // console.log("response.data.items ", response.data.items);
          // console.log(
          //   "response.data.items[0].index ",
          //   // response.data.items[0].items[0].index
          //   response.data.items[0].index
          // );
          // console.log(response.status);
          // console.log(response.data);
          // console.log(response.status);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // if (errorElement) {
          // errorElement.hidden = true;
          // errorElement.style.display = "none"; //"block";

          // errorElement.hidden = true;
          // errorElement.innerText = response.statusText;
          // errorElement.innerText = "";
          // errorElement.style.display = "block";
          // errorElement.style.display = "none";
          // }

          if (response.status === 200) {
            if (response.data.items.length === 0) {
              setMessage(
                "You have no items in your inventory. Please go shopping and update your inventory."
              );
            } else {
              setMessage("");
            }
          } else {
            setMessage(response.statusText);
          }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(false);
          setLoading(false);

          const inventoryItems = response.data.items.map((v, i) => {
            v.index = i;
            return v;
          });
          // console.log("inventoryItems ", inventoryItems);

          setItems((prevValue) => {
            // console.log(prevValue);
            // console.log(response.data);
            // return [...prevValue, ...response.data];
            // return [...response.data.items[0].items];
            // return [...response.data.items];
            return inventoryItems;
          });
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...")
          // const errorElement = myRef.current;
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // console.log("error: ", error ? error : "no error");
          // console.log("_content: ", _content ? _content : "no _content");

          // if (errorElement) {
          //   errorElement.hidden = false;
          //   errorElement.innerText = "No items found. Error: " + _content;
          //   errorElement.style.display = "block";
          // }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(true);
          setMessage(_content);

          // Set it to an empty array, since the user exists,
          // but it has NO items
          setItems([]);
          setLoading(false);
        }
        // ).catch((err) => (console.log(err)));
      )
      .catch(() => {
        setLoading(false);
      });
  }

  function onSuggestRecipes() {
    navigate(`/recipesfrominventory`);
  }

  function onDelete(id) {
    console.log("before ", items);
    console.log("id ", id);
    let theItems = [...items];

    theItems.splice(id, 1);
    theItems.filter((item, index) => {
      // here all the index elements have to be updated
      if (index >= id) {
        console.log("index, id, item.index", index, id, item.index);
        item.index--;
        console.log("index, id, item.index", index, id, item.index);
        // item.index = item.index - 1;
      }
    });
    console.log("after ", theItems);

    const compactedInventory = compactInventory(theItems);

    setItems(compactedInventory);
    if (compactedInventory.length === 0) {
      setMessage(
        "You have no items in your inventory. Please go shopping and update your inventory."
      );
    }

    // const errorElement = document.getElementById("error");
    handleSave(currentUser, compactedInventory);
  }

  function onEdit(id) {
    // console.log(id);
  }

  function onSave(id, value) {
    let theItems = [...items];
    if (id === -1) {
      theItems.push(value);
      theItems[theItems.length - 1].index = theItems.length;
    } else {
      theItems[id] = value;
    }

    const compactedInventory = compactInventory(theItems);

    // console.log("Inventory/onSave ",id, value);
    setItems(compactedInventory);
    // const errorElement = document.getElementById("error");
    handleSave(currentUser, compactedInventory);
  }

  function handleClick() {
    setAddingMode(!addingMode);
  }

  useEffect(() => {
    const retrieveCategoryGroup = (ensemble, indx) => {
      CategoryDataService.getAll({ name: "", ensemble: ensemble })
        .then((response) => {
          // var categoryGroup;
          // categoryGroup[indx]=response.data.categoryGroup;
          // console.log("categoryGroup[indx] ",indx, categoryGroup[indx]);
          // console.log(
          //   "response.data.categoryGroup ",
          //   response.data.categoryGroup
          // );
          // console.log("ensemble ", ensemble);
          // console.log("categoryGroup ", categoryGroup);
          const categoryGroup = response.data.categoryGroup;
          setCategoryGroups((prevCategoryGroups) => {
            const newCategoryGroups = {
              ...prevCategoryGroups,
              [ensembles[indx]]: categoryGroup,
            };
            // console.log("newCategoryGroups ", indx, newCategoryGroups);

            // move this into the getRecipe function since there currentRecipe is available
            // and it decouples setOptions from categoryGroups

            setOptions((prevOptions) => {
              const newOptions = [...prevOptions];
              // console.log("currentRecipe ", currentRecipe);
              newOptions[indx] = categoryGroup
                ? categoryGroup.categories.map((category, indx) => {
                    // console.log(
                    //   currentRecipe.recipe.cookWays.includes(category.name)
                    // );
                    return {
                      // label: currentRecipe.recipe.cookWays.includes(category.name)
                      //   ? category.name
                      //   : null,
                      label: category.name,
                      value: category.name,
                      action: "",
                    };
                  })
                : [{ label: "pcs", value: "pcs" }];
              // console.log("newOptions ", indx, newOptions);
              return newOptions;
            });

            return newCategoryGroups;
          });

          // also possible to write it as array construct
          //
          // setCategoryGroups((prevCategoryGroups) => {
          //   const a = [...prevCategoryGroups];
          //   a[indx] = response.data.categoryGroup;
          //   return a;
          // });

          // console.log(response);
          // return response.data.categoryGroup;
        })
        .catch((e) => {
          console.log("retrieveCategoryGroup: ", e);
        });
    };
    ensembles.map((ensemble, indx) => retrieveCategoryGroup(ensemble, indx));
    // const errorElement = document.getElementById("error");
    // errorElement.hidden = true;
    // errorElement.style.display = "none"; //"block";
    errorElement = myRef.current;
    // console.log(
    //   "errorElement: ",
    //   errorElement ? errorElement : "no useEffect ..."
    // );

    // handleSave(errorElement, currentUser, items);
    setLoading(true);
    async function fetchInventory() {
      // You can await here
      const response = await getInventory();
      // ...
      return response;
    }
    fetchInventory();
    // console.log("updating");
  }, []);

  // console.log("listItemsBox ", listItemsBox);

  const fabStyle = {
    position: "absolute",
    bottom: "16px",
    right: "16px",
  };
  const fab = {
    color: "primary",
    sx: fabStyle,
    icon: AddIcon,
    label: "Add",
  };
  // if (items.length === 0) {
  //   setMessage(
  //     "You have no items in your inventory. Please go shopping and update your inventory."
  //   );
  // }

  const setActiveIngredient = (ingredient, index) => {
    // console.log("ingredient ", ingredient);
    // console.log("index ", index);
    // setCurrentIngredient(ingredient);
    setCurrentIndex(index);
  };

  if (!loading) {
    return (
      <div className="ShoppingList">
        <KeyIcon style={{ position: "absolute", left: "0" }} />
        <header className="ShoppingList-header">
          <h1>
            <KitchenIcon /> Inventory <InventoryIcon />
          </h1>
          <h2>What's in your pantry, storeroom, larder</h2>
        </header>
        {isInError ? (
          <Alert
            ref={myRef}
            id="error"
            key="danger"
            variant="danger"
            // style={{ display: "none" }}
          >
            {/* <p>{message}</p> */}
            {message}
          </Alert>
        ) : // null
        message !== "" ? (
          <Alert
            ref={myRef}
            id="error"
            key="info"
            variant="info"
            // style={{ display: "none" }}
          >
            {/* <p>{message}</p> */}
            {message}
          </Alert>
        ) : null}

        {items.length > 0 ? (
          <Button
            variant="info"
            onClick={onSuggestRecipes}
            style={{ margin: "10pt" }}
          >
            Suggest recipes depending on what's in the pantry
          </Button>
        ) : (
          // "nothing here"
          ""
        )}

        <div style={{ width: "92%" }}>
          {/* {console.log("ingredients ", ingredients)} */}
          {/* {console.log("categoryGroup ", categoryGroup)} */}
          {ingredients.length ? (
            <CategorisedIngredients
              categoryGroup={categoryGroup}
              // ingredients={[{ ingredient: list?.ingredients }]}
              ingredients={ingredients}
              setActiveIngredient={setActiveIngredient}
              currentIndex={currentIndex}
            />
          ) : null}
        </div>

        <div style={{ marginTop: "40px", width: "92%" }}>
          <Accordion
            className="Accordion"
            // defaultActiveKey=""
            // flush
            // defaultActiveKey={["0"]}
            // alwaysOpen
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header
              // className={"list-group-item "}
              // style={{
              //   fontWeight: "bold",
              //   paddingLeft: "5px",
              //   fontSize: "large",
              // }}
              >
                {/* <span
                style={{
                  fontWeight: "bold",
                  paddingLeft: "5px",
                  fontSize: "large",
                }}
              > */}
                <h2>All your inventory</h2>
                {/* </span> */}
              </Accordion.Header>
              <Accordion.Body>
                {items.length > 0 ? (
                  <ListItems
                    title={"Ingredients"}
                    hasChkBox={false}
                    key={Date.now()}
                    cssClass={"listItemsBox"}
                    items={items}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onSave={onSave}
                    options={options[0]}
                  />
                ) : // "nothing here"
                null}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        {addingMode ? (
          <div
            style={{
              // width: "600pt",
              width: "100%",
              float: "left",
              borderRadius: "5pt",
              backgroundColor: "white",
            }}
          >
            <EditItemRow
              collection={"Ingredients"}
              value={item}
              addingMode={addingMode}
              setAddingMode={setAddingMode}
              // setEditMode={setEditMode}
              editMode={true}
              onSave={onSave}
              onEdit={onEdit}
            />
          </div>
        ) : (
          <Fab
            sx={fab.sx}
            aria-label={fab.label}
            onClick={(event) => {
              event.preventDefault();
              setAddingMode(!addingMode);
            }}
            color={fab.color}
          >
            {/* <AddIcon /> */}
            <AddIcon
              onClick={(event) => {
                event.preventDefault();
                setAddingMode(!addingMode);
              }}
            />
          </Fab>
        )}
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
}

export default Inventory;
