import React, { useEffect, useState } from "react";
import Calendar from "./Calendar";
import MenuForSelectedDay from "./MenuForSelectedDay";

function CalendarOverview({
  user,
  menus,
  findByName,
  onAdd,
  onDelete,
  onReplace,
  convertDate,
  theDate,
  setTheDate,
}) {
  // console.log(theDate);
  // console.log(menus);

  // useEffect(() => {
  //   setTheDate(theDate);
  // }, [theDate]);

  return (
    <div style={{ margin: "0 auto" }}>
      <div className="row" 
      style={{ padding: "0 20px" }}
      >
        <div className="col-md-6" style={{ marginBottom: "20px" }}>
          <Calendar setTheDate={setTheDate} theDate={theDate} />
        </div>
        <div className="col-md-6" style={{ marginBottom: "20px" }}>
          <MenuForSelectedDay
            user={user}
            onDelete={onDelete}
            onReplace={onReplace}
            convertDate={convertDate}
            onAdd={onAdd}
            findByName={findByName}
            theDate={theDate}
            setTheDate={setTheDate}
            menus={menus}
          />
        </div>
      </div>
    </div>
  );
}

export default CalendarOverview;
