import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import authHeader from "../../services/auth-header";

import "./Payments.css";

import imageNotAvailable from "../../assets/img/image-not-available.jpg";
import server from "../../config/server.config";

const BACKEND_URL = server.host + (server.port === "" ? "" : ":" + server.port);

const public_stripe_key = process.env.REACT_APP_PUBLIC_STRIPE_KEY;

// const imageServerURL = server.cdn;
// ATTENTION: This is not the assets folder for the cdn images, BUT the public static images
const imageServerURL = BACKEND_URL+"/assets/images/";

function addDefaultSrc(e) {
  e.target.src = imageNotAvailable;
}

const CheckOutPage = () => {
  
  const handleCheckOut = async () => {
    const stripePromise = await loadStripe(public_stripe_key);
    const header1 = { "Content-Type": "Application/JSON" };
    const header2 = authHeader();
    const headers = { ...header1, ...header2 };
    if (order) {
    try {
      const response = await fetch(
        BACKEND_URL+"/api/payments/create-stripe-session",
        {
          method: "POST",
          // headers: { "Content-Type": "Application/JSON" },
          headers: headers,
          // body: JSON.stringify([
          //   { item: "Online Video Editor", qty: "3", itemCode: "99" },
          // ]),
          body: JSON.stringify(order),
        }
      );
      const session = await response.json();
      stripePromise.redirectToCheckout({
        sessionId: session.id,
      });
    } catch (error) {
      console.log("error ", error);
    }
  }
  };

  const location = useLocation();
  // console.log(location);

  const order = location.state;

  let totalAmout = 0;
  if (order && order.length>0) {
    console.log(order);

    for (var item of order) {
      totalAmout += item.price * item.qty;
    }
  }

  return (
    <div className="App">
      <div
      // style={{
      //   margin: "30px",
      //   borderWidth: "3px 9px 9px 9px",
      //   borderStyle: "solid",
      //   borderColor: "#FF6633",
      //   height: "100px",
      //   borderRadius: "10px",
      // }}
      >
        {order && order.length > 0 ? (
          <div>
            <div className="list row">
              {order?.map((item, indx) => {
                return (
                  <div
                    id={"order-" + indx}
                    key={indx}
                    className="col-xl-3 col-lg-4 col-md-6"
                  >
                    {/* <div
                      style={{
                        margin: "30px",
                        padding: "10px",
                        borderWidth: "3px 9px 9px 9px",
                        borderStyle: "solid",
                        borderColor: "#FF6633",
                        // height: "100px",
                        borderRadius: "10px",
                      }}
                    > */}

                    <Card
                      className="card"
                      style={{ width: "20rem", margin: "10px auto" }}
                    >
                      {true ? (
                        <div className="shrinkToFitImage">
                          <Card.Img
                            variant="top"
                            // src={
                            //   "http://localhost:4000/assets/images/camiseta-blanca.jpg"
                            // }
                            src={imageServerURL + item.image}
                            onError={addDefaultSrc}
                          />
                          {/* <label>
                                <strong>Picture (server side):</strong>
                                </label>
                                {recipe.media.images.head} */}
                          {/* <img
                                width={"100%"}
                                src={recipe.media.images.head}
                                alt="Image Head Recipe {recipe.name}"
                              /> */}
                        </div>
                      ) : (
                        <div>
                          <Card.Img
                            variant="top"
                            style={{ maxHeight: "250px" }}
                            src={imageNotAvailable}
                            alt="Image Head Recipe not  available yet..."
                          />
                          {/* <img src={imageNotAvailable} alt="Image Head Recipe" /> */}
                          {/* <br />
                            <p>No picture available yet...</p> */}
                        </div>
                      )}
                      <Card.Title
                        style={{ textAlign: "center", fontSize: "3rem" }}
                      >
                        {item.name}
                      </Card.Title>
                      <Card.Body>
                        {/* <p>Index: {indx}</p>
                        <p>Id: {item.id}</p>
                        <p>Name: {item.name}</p>
                        <p>Image: {item.image}</p> */}
                        {/* {item.description}</p> */}

                        {/* <p>Description: </p>
                        <ul>
                          {item.description.map((item, indx) => {
                            return (
                              <li key={indx}>
                                <div>{item}</div>
                              </li>
                            );
                          })}
                        </ul> */}
                        <div className="list row">
                          <div className="col-xl-8 col-lg-8 col-md-8">
                            Quantity: {item.qty}
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4">
                            Size: {item.size}
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8">
                            Price: {item.price/100} € (EUR)
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                    {/* </div> */}
                  </div>
                );
              })}
            </div>
            <h1>The total amout of your order is: {totalAmout/100} EUR</h1>
            <Button onClick={() => handleCheckOut()}>Check out!</Button>
          </div>
        ) : (
          <div>
            <div>
              <h1>Your order is empty!</h1>
            </div>
            <Button style={{ className: "button" }}>
              <Link
                style={{ color: "white" }}
                to={"/shop"}
                // state={{ id: 1, name: "sabaoon", shirt: "green" }}
                // state={[{ item: "Online Video Editor", qty: "3", itemCode: "99" }]}
                state={{}}
              >
                Go Back to the Shop
              </Link>
            </Button>
          </div>
        )}
        {/* {order[0].id}
        {order[0].name}
        {order[0].price}
        {order[0].frequency}
        {order[0].image}
        {order[0].recurrent}
        {order[0].description}
        {order[0].qty} */}
        {/* {order[0].itemCode} */}
        {/* Online Video Editor <br />
        Charges - 20INR For Lifetime <br />
        Quantity - 3 Copies <br /> */}
        {/* <button onClick={() => handleCheckOut()}>Check out!</button> */}
      </div>
    </div>
  );
};

export default CheckOutPage;
