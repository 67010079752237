import { useSelector } from "react-redux";

import "./Payments.css";

const CheckOutSuccess = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  
  return (
    <div className="App">
      <h1>CheckOut Success</h1>
      <div className="avatar">
        <img
          width={"50pt"}
          height={"50pt"}
          src={`data:image/svg+xml;base64,${currentUser.avatarImage}`}
          alt="avatar"
        />
        <div className="onlineStatus"></div>
        <div className="">
          Hello {currentUser.fullname}. Thank you for your purchase.
        </div>
      </div>
      Your purchase order will be processed shortly.
      <br />
      We will inform you promptly about the delivery status of your order.
      <br />
       The invoice will be sent within the next 24h to your email: {currentUser.email}.
    </div>
  );
};

export default CheckOutSuccess;
