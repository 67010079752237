import { SET_WINDOWSIZE, CLEAR_WINDOWSIZE } from "../types";

// export const setWindowSize = (windowSize) => (dispatch) => {
export const setWindowSize = (windowSize) => {
  // console.log("setWindowSize", windowSize);
  return {
    type: SET_WINDOWSIZE,
    // payload: { windowSize: windowSize },
    payload: windowSize,
  };
};

export const clearWindowSize = () => ({
  type: CLEAR_WINDOWSIZE,
});
