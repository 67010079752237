import * as React from "react";

import { Link } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";

function CategorisedIngredients({
  categoryGroup,
  ingredients,
  setActiveIngredient,
  currentIndex,
  isExtended
}) {
  return (
    <Accordion
      className="Accordion"
      // defaultActiveKey=""
      // flush
      // defaultActiveKey={["0"]}
      // alwaysOpen
    >
      {categoryGroup &&
        categoryGroup.categories &&
        categoryGroup.categories.map((category, cindex) => {
          // console.log(category);
          let isIngredientInCategory = false;
          const heading = (
            <Accordion.Header
              className={"list-group-item "}
              key={cindex}
              style={{
                fontWeight: "bold",
                paddingLeft: "5px",
                fontSize: "large",
              }}
              onClick={() => setActiveIngredient(null, -1)}
            >
              <span
                style={{
                  fontWeight: "bold",
                  paddingLeft: "5px",
                  fontSize: "large",
                }}
              >
                {category.name}
              </span>
            </Accordion.Header>
          );
          const ingredientsInCategory =
            ingredients &&
            ingredients.map((ingredient, index) => {
              // console.log(category, ingredient.ingredient);
              // console.log(
              //   category.name,
              //   ingredient.ingredient.category
              // );
              // ingredient.ingredient.matched = false;
              if (category.name === ingredient.ingredient.category) {
                // console.log(category.name, ingredient.ingredient.category);
                isIngredientInCategory = true;
                ingredient.ingredient.matched = true;
                return (
                  <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }
                    onClick={() => setActiveIngredient(ingredient, index)}
                    key={cindex + " " + index}
                  >
                    {ingredient.ingredient.name}{" "}
                    <button
                      className="badge bg-warning mr-2"
                      style={{ float: "right" }}
                      // onClick={() =>
                      //   handleAddIngredientToShoppingList(false)
                      // }
                    >
                      <Link
                        to={`/ingredients/${ingredient.ingredient.id}`}
                        className="nav-link"
                      >
                        See product
                        {/* {ingredient.ingredient.name} */}
                        {/* {ingredient.ingredient.id} */}
                      </Link>
                    </button>
                  </li>
                );
              } else {
                return null;
              }
            });
          // console.log(
          //   "ingredientsInCategory",
          //   category,
          //   ingredientsInCategory.length,
          //   isIngredientInCategory
          // );
          if (isIngredientInCategory)
            return (
              <Accordion.Item key={cindex} eventKey={cindex}>
                {heading}
                <Accordion.Body>
                  <ul className="list-group">{ingredientsInCategory}</ul>
                </Accordion.Body>
              </Accordion.Item>
            );
          else return null;
        })}
      {/* {console.log("CategorisedIngredients() ingredients ",
      ingredients ? ingredients:"---->undefined")}
      {console.log("CategorisedIngredients() ingredients.filter((ingredient) => !ingredient.ingredient.matched) ",
       ingredients?.filter((ingredient) => !ingredient.ingredient.matched))} */}

      {ingredients &&
      ingredients.filter((ingredient) => !ingredient.ingredient.matched)?.length >0 ? (
        <Accordion.Item eventKey={100}>
          <Accordion.Header
            className={"list-group-item "}
            key={"xxxxxx"}
            style={{
              fontWeight: "bold",
              paddingLeft: "5px",
              fontSize: "large",
            }}
            onClick={() => setActiveIngredient(null, -1)}
          >
            <span
              style={{
                fontWeight: "bold",
                paddingLeft: "5px",
                fontSize: "large",
              }}
            >
              {"Unknown"}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="list-group">
              {ingredients &&
                ingredients.map((ingredient, index) => {
                  // console.log(category, ingredient.ingredient);
                  // console.log(
                  //   category.name,
                  //   ingredient.ingredient.category
                  // );
                  if (!ingredient.ingredient.matched) {
                    // if(isExtended){
                    // return (
                    //   <li
                    //     className={
                    //       "list-group-item " +
                    //       (index === currentIndex ? "active" : "")
                    //     }
                    //     onClick={() => setActiveIngredient(ingredient, index)}
                    //     key={100 + index}
                    //   >
                    //     {ingredient.ingredient.name}
                    //   </li>
                    // );
                    // } else {
                    return (
                      <li
                        className={
                          "list-group-item " +
                          (index === currentIndex ? "active" : "")
                        }
                        onClick={() => setActiveIngredient(ingredient, index)}
                        key={100 + index}
                      >
                        {ingredient.ingredient.name}{" "}
                        <button
                          style={{ float: "right" }}
                          className="badge bg-warning mr-2"
                          // onClick={() =>
                          //   handleAddIngredientToShoppingList(false)
                          // }
                        >
                          <Link
                            to={`/ingredients/${ingredient.ingredient.id}`}
                            className="nav-link"
                          >
                            See product
                            {/* {ingredient.ingredient.name} */}
                            {/* {ingredient.ingredient.id} */}
                          </Link>
                        </button>
                      </li>
                    );
                    // }
                  } else {
                    return null;
                  }
                })}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ) : null}
    </Accordion>
  );
};
export default CategorisedIngredients;
