import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createTheme } from "@mui/material/styles";

// import { makeStyles } from "@mui/material/styles";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import Divider from "@mui/material/Divider";

import MenuIcon from "@mui/icons-material/Menu";
// import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
// import LogOut from "@mui/icons-material/ExitToApp";
// import LogOut from "@mui/icons-material/ExitToApp";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";


/*********
 * redux stuff
 * 
 * instead of
 ***************************/
// import AuthService from "../services/auth.service";
// we use
import { logout } from "../../actions/auth";

// styling
import "./Navigation.css";

const mui = true;

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const breakpoints = {
  up: (x) => {
    let y;
    switch (x) {
      case "sm":
        y = 500;
        break;
      case "md":
        y = 500;
        break;
    
      default:
        break;
      }
    return "";
  },
};

const classes = {
  // root: {
  //   flexGrow: 1,
  // },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 2,
  },
  title: {
    display: "none",
    [breakpoints.up("sm")]: {
      display: "block",
      // flexGrow: 1,
    },
  },
  search: {
    position: "relative",
    borderRadius: "10px",
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
    marginRight: 2,
    marginLeft: 0,
    width: "100%",
    [breakpoints.up("sm")]: {
      marginLeft: 3,
      width: "auto",
    },
  },
  searchIcon: {
    padding: 2,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: "1, 1, 1, 0",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 4px)`,
    transition: "width",
    width: "100%",
    [breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [breakpoints.up("md")]: {
      display: "none",
    },
  },
};

// function Navegation({ currentUser, logOut }) {
function Navegation({ currentUser }) {

  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const [activeMenu, setActiveMenu] = React.useState("");
  const [menuEl, setMenuEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [selectedMenuIndex, setSelectedMenuIndex] = React.useState(0);
  const [
    selectedProfileMenuIndex,
    setSelectedProfileMenuIndex,
  ] = React.useState(0);

  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    // AuthService.logout();
  };

  useEffect(() => {
    if (currentUser && currentUser.roles) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }
  }, [currentUser]);

  const menuItems = [
    "About",
    // "emojipedia",
    // "Get Time",
    // "Home",
    // "Chat",
    // "Keeper",
    // "To Do",
    // "Contacts",
    // "Users",
    // "My Home",
    "Recipes",
    "Inventory",
    "Ingredients",
    "Categories",
    "Cookware",
    "Shopping List",
    "Menu Planning",
    // "Playground",
    // "Invoices",
  ];

  const profileMenuItems = [
    "Login",
    // "Sign In",
    // "Register",
    // "Profile",
    "Messages",
    "Notifications",
    // "Upload",
    // "Uploads",
    // "UserBoard",
    // "ModBoard",
    // "AdminBoard",
    "Subscribe",
    "Shop",
  ];

  const handleMenuOpen = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    // console.log(event.target);
    // console.log(menuEl);
    setMenuEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    // console.log(menuItems[index]);
    setSelectedMenuIndex(index);
    setMenuEl(null);
    // console.log(menuItems[index]);
    setActiveMenu("menu");
  };

  const handleProfileMenuItemClick = (event, index) => {
    // console.log(menuItems[index]);
    setSelectedProfileMenuIndex(index);
    handleProfileMenuClose();
    // console.log(profileMenuItems[index]);
    setActiveMenu("profile");
  };


  const isProfileMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileProfileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
    handleMobileProfileMenuClose();
  };

  const handleMobileProfileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLogOut = () => {
    handleProfileMenuClose();
    logOut();
  };


  const profileMenuId = "primary-search-account-menu";
  const renderProfileMenu = currentUser ? (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem disabled onClick={handleProfileMenuClose}>
        {currentUser.username}
      </MenuItem>
      <Divider variant="inset" component="li" />
      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 3)}
        component={Link}
        to={"/profile"}
      >
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        Profile
      </MenuItem>
      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 8)}
        component={Link}
        to={"/user"}
      >
        User
      </MenuItem>
      {/* <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 6)}
        component={Link}
        to={"/upload"}
      >
        Upload
      </MenuItem>
      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 7)}
        component={Link}
        to={"/uploads"}
      >
        Uploads
      </MenuItem>

      {showModeratorBoard && (
        <MenuItem
          onClick={(event) => handleProfileMenuItemClick(event, 9)}
          component={Link}
          to={"/mod"}
        >
          Moderator Board
        </MenuItem>
      )}

      {showAdminBoard && (
        <MenuItem
          onClick={(event) => handleProfileMenuItemClick(event, 10)}
          component={Link}
          to={"/admin"}
        >
          Admin Board
        </MenuItem>
      )}
 */}
      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 11)}
        component={Link}
        to={"/subscriptions"}
      >
        Subscribe
      </MenuItem>
      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 12)}
        component={Link}
        to={"/shop"}
      >
        Shop
      </MenuItem>

      <MenuItem onClick={handleLogOut} component={Link} to={"/home"}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <i className="material-icons">logout</i>
          {/* <LogOut /> */}
        </IconButton>
        Log Out
      </MenuItem>
    </Menu>
  ) : null;

  const mobileProfileMenuId = "primary-search-account-menu-mobile";
  const renderMobileProfileMenu = currentUser ? (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileProfileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileProfileMenuClose}
    >
      <MenuItem
        component={Link}
        to={"/messages"}
        onClick={(event) => handleProfileMenuItemClick(event, 4)}
      >
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        Messages
      </MenuItem>

      <MenuItem
        component={Link}
        to={"/notifications"}
        onClick={(event) => handleProfileMenuItemClick(event, 5)}
      >
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        Notifications
      </MenuItem>
      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 3)}
        component={Link}
        to={"/profile"}
      >
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        Profile
      </MenuItem>
      {/* <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 8)}
        component={Link}
        to={"/user"}
      >
        User
      </MenuItem>
      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 6)}
        component={Link}
        to={"/upload"}
      >
        Upload
      </MenuItem>
      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 7)}
        component={Link}
        to={"/uploads"}
      >
        Uploads
      </MenuItem>

      {showModeratorBoard && (
        <MenuItem
          onClick={(event) => handleProfileMenuItemClick(event, 9)}
          component={Link}
          to={"/mod"}
        >
          Moderator Board
        </MenuItem>
      )}

      {showAdminBoard && (
        <MenuItem
          onClick={(event) => handleProfileMenuItemClick(event, 10)}
          component={Link}
          to={"/admin"}
        >
          Admin Board
        </MenuItem>
      )} */}

      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 11)}
        component={Link}
        to={"/subscriptions"}
      >
        Subscribe
      </MenuItem>
      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 12)}
        component={Link}
        to={"/shop"}
      >
        Shop
      </MenuItem>

      <MenuItem onClick={handleLogOut} component={Link} to={"/home"}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <i className="material-icons">logout</i>
          {/* <LogOut /> */}
        </IconButton>
        Log Out
      </MenuItem>
    </Menu>
  ) : (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileProfileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileProfileMenuClose}
    >
      <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 0)}
        component={Link}
        to={"/login"}
      >
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <i className="material-icons">login</i>
        </IconButton>
        Login
      </MenuItem>
      {/* <MenuItem
        onClick={(event) => handleProfileMenuItemClick(event, 2)}
        component={Link}
        to={"/register"}
      >
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <EditIcon />
        </IconButton>
        Register
      </MenuItem> */}
    </Menu>
  );


  return mui ? (
    <nav>
      <div style={classes.grow}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              style={classes.menuButton}
              color="inherit"
              aria-label="menu"
              aria-controls="menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            {/* <Button>Open Menu</Button> */}
            <Menu
              id="menu"
              anchorEl={menuEl}
              keepMounted
              open={Boolean(menuEl)}
              onClose={handleMenuClose}
            >
              {menuItems.map(
                (menuItem, index) =>
                  (index < 1 || currentUser) && (
                    <MenuItem
                      selected={index === selectedMenuIndex}
                      key={menuItem}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      component={Link}
                      to={`/${menuItem.replace(/ /g, "").toLowerCase()}`}
                    >
                      {menuItem}
                    </MenuItem>
                  )
              )}
            </Menu>
            <Typography variant="h6" style={classes.title}>
              {activeMenu === "profile"
                ? profileMenuItems[selectedProfileMenuIndex]
                : menuItems[selectedMenuIndex]}
            </Typography>
            <div style={classes.grow}></div>
            <div style={classes.sectionDesktop}>
              {currentUser ? (
                <>
                  <IconButton
                    aria-label="show 4 new mails"
                    color="inherit"
                    component={Link}
                    to={"/messages"}
                    onClick={(event) => handleProfileMenuItemClick(event, 4)}
                  >
                    <Badge badgeContent={4} color="secondary">
                      <MailIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    component={Link}
                    to={"/notifications"}
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={(event) => handleProfileMenuItemClick(event, 5)}
                  >
                    <Badge badgeContent={17} color="secondary">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={profileMenuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <AccountCircle />
                    {currentUser.username}
                  </IconButton>
                  {/* <Button
                    onClick={(event) => handleProfileMenuItemClick(event, 3)}
                    color="inherit"
                    component={Link}
                    to={"/profile"}
                  >
                    {currentUser.username}
                  </Button>
                  <Button
                    onClick={handleLogOut}
                    color="inherit"
                    component={Link}
                    to={"/"}
                  >
                    LogOut
                  </Button> */}
                </>
              ) : (
                <>
                  <Button
                    onClick={(event) => handleProfileMenuItemClick(event, 0)}
                    color="inherit"
                    component={Link}
                    to={"/login"}
                    // style={{ textTransform: "none" }}
                  >
                    Login <i className="material-icons">login</i>
                  </Button>
                  {/* <Button
                    onClick={(event) => handleProfileMenuItemClick(event, 2)}
                    color="inherit"
                    component={Link}
                    to={"/register"}
                    // style={{ textTransform: "none" }}
                  >
                    {"Register"} <EditIcon />
                  </Button> */}
                </>
              )}
            </div>
            <div style={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileProfileMenuId}
                aria-haspopup="true"
                onClick={handleMobileProfileMenuOpen}
                color="inherit"
              >
                <Typography variant="h6" style={classes.title}>
                  {currentUser ? currentUser.username : null}
                </Typography>
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileProfileMenu}
        {renderProfileMenu}
        {/* <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/keeper">Keeper</Link>
        </li>
        <li>
          <Link to="/todo">To Do</Link>
        </li>
        <li>
          <Link to="/gettime">Get Time</Link>
        </li>
        <li>
          <Link to="/login">Login</Link>
        </li>
        <li>
          <Link to="/emojipedia">emojipedia</Link>
        </li>
        <li>
          <Link to="/contacts">Contacts</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/users">Users</Link>
        </li>
      </ul> */}
      </div>
    </nav>
  ) : (
    <nav className="navbar navbar-expand navbar-dark bg-dark">
      <Link to={"/"} className="navbar-brand">
        beraver
      </Link>
      <div className="navbar-nav mr-auto">
        <li className="nav-item">
          <Link to={"/home"} className="nav-link">
            Home
          </Link>
        </li>

        {showModeratorBoard && (
          <li className="nav-item">
            <Link to={"/mod"} className="nav-link">
              Moderator Board
            </Link>
          </li>
        )}

        {showAdminBoard && (
          <li className="nav-item">
            <Link to={"/admin"} className="nav-link">
              Admin Board
            </Link>
          </li>
        )}

        {currentUser && (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                User
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/upload"} className="nav-link">
                Upload
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/uploads"} className="nav-link">
                Uploads
              </Link>
            </li>
          </div>
        )}
      </div>

      <div className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link to={"/invoices"} className="nav-link">
            Invoices
          </Link>
        </li>
      </div>

      {currentUser ? (
        <div className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link to={"/profile"} className="nav-link">
              {currentUser.username}
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/subscriptions"} className="nav-link">
              Subscribe {currentUser.username}
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/shop"} className="nav-link">
              Shop {currentUser.username}
            </Link>
          </li>

          <li className="nav-item">
            <a href="/home" className="nav-link" onClick={handleLogOut}>
              LogOut
            </a>
          </li>
        </div>
      ) : (
        <div className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link to={"/login"} className="nav-link">
              Login
            </Link>
          </li>

          {/* <li className="nav-item">
            <Link to={"/register"} className="nav-link">
              Sign Up
            </Link>
          </li> */}
        </div>
      )}
    </nav>
  );
}

export default Navegation;
