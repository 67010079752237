import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
// import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";

import { Fab } from "@mui/material";
import { Zoom } from "@mui/material";

import MenuDatePicker from "./MenuDatePicker";
import { courses } from "./menu.config";
import SearchBox from "../Playground/SearchBox";
import MenuBuilder from "./MenuBuilder";

const weekday = [
  // "Sunday",
  // "Monday",
  // "Tuesday",
  // "Wednesday",
  // "Thursday",
  // "Friday",
  // "Saturday",
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];

// const lunch = [];
// lunch[0] = { name: "Lunch", id: "", cookWay: "" };
// const dinner = [];
// dinner[0] = { name: "Dinner", id: "", cookWay: "" };
const lunch = {
  mealType: "",
  courses: [{ name: "Lunch", id: "", cookWay: "", procured: false, serves: 4 }],
};
const dinner = {
  mealType: "",
  courses: [
    { name: "Dinner", id: "", cookWay: "", procured: false, serves: 4 },
  ],
};

/**
 *
 * @param {*} props
 *  menus - the menus (in that week, if any)
 *  findByName - serach function
 *  // availableRecipes
 *  children -
 *  id -
 *  onAdd - function to add the menu if on that date no menu exists
 *  onReplace- function to replace any existing menu for that date
 *  theDate - the date selected
 * @returns
 */
function CreateMenuEntry(props) {
  const {
    menus,
    findByName,
    // availableRecipes,
    children,
    id,
    onAdd,
    onReplace,
    theDate,
  } = props;
  // console.log("availableRecipes ", availableRecipes);

  // console.log(theDate);
  const dateForMenu = theDate ? new Date(theDate) : new Date(Date.now());
  // console.log(dateForMenu);
  const emptyMenu = {
    date: dateForMenu,
    weekday: weekday[dateForMenu.getDay()],
    lunch: lunch,
    dinner: dinner,
  };

  const [courseForDinner, setCourseForDinner] = useState(0);
  const [menu, setMenu] = useState(emptyMenu);
  const [formShown, setFormShown] = useState(false);
  // const [clearStateFunc, setClearStateFunc] = useState(null);
  const [dayHasMenu, setDayHasMenu] = useState(false);
  const [reset, setReset] = useState(false);
  const ref = React.createRef();
  const zoomRef = React.createRef();

  /**
   * https://www.freecodecamp.org/news/javascript-date-comparison-how-to-compare-dates-in-js/
   * @param {*} a - one date
   * @param {*} b - another date
   * @returns 0 if equal
   */
  function compare(a, b) {
    // console.log("a", a, "b", b);
    const x = new Date(a);
    const y = new Date(b);
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    const dateForMenu = theDate ? new Date(theDate) : new Date(Date.now());
    const theWeekday = new Date(dateForMenu).getDay();
    let dayHasMenu = false;
    // console.log("theDate ", theDate);
    // console.log("theDate ", theDate);
    // console.log(dateForMenu);
    // console.log("menus ", menus);

    // const y = new Date(theDate);
    for (let i = 0; i < menus?.length; i++) {
      // const x = new Date(menus[i].date);
      // console.log("menu i ", i, menus[i]);
      // console.log("x === y x y ", (x === y), x, y);
      // console.log(
      //   "compare(menus[i].date, theDate) menus[i].date, theDate",
      //   compare(menus[i].date, theDate),
      //   menus[i].date,
      //   theDate
      // );
      // if (compare(x , y)) {
      if (compare(menus[i].date, theDate) === 0) {
        setMenu(menus[i]);
        setDayHasMenu(true);
        // console.log("------> menu i theDate ", i, menus[i], theDate);
        dayHasMenu = true;
        break;
      }
    }
    if (!dayHasMenu) {
      const emptyMenu = {
        weekday: weekday[theWeekday],
        lunch: lunch,
        dinner: dinner,
        date: dateForMenu,
      };
      setMenu(emptyMenu);
      setDayHasMenu(false);
    }
  }, [theDate, menus]);

  // function resetMenu() {
  //   setMenu(emptyMenu);
  // }

  function extractWeekday(date) {
    // const now = new Date(Date.now());
    // const nowAgain = Date.now();
    // console.log("now, nowAgain ", now, nowAgain);
    return weekday[date.getDay()];
  }

  function changeMenuDate(date) {
    // event.preventDefault();
    // console.log("changeMenuDate");
    // console.log(event.target);
    // console.log("date ", date);
    // const { name, value } = event.target;
    const weekday = extractWeekday(date);
    // console.log("weekday ", weekday);
    setMenu((prevValue) => {
      // console.log(date);
      return { ...prevValue, date: date, weekday: weekday };
    });
    // console.log(menu);
  }

  function changeMenu(event) {
    event.preventDefault();
    // console.log(event.target);
    const { name, value } = event.target;
    // if ( value.length < 3 ){
    setMenu((prevValue) => ({ ...prevValue, [name]: value }));
    // } else {
    //
    // }
    console.log(menu);
  }

  function toggleShowForm() {
    setFormShown(!formShown);
  }

  const FancyButton = React.forwardRef((props, ref) => (
    // zoomRef = React.createRef();
    <button
      ref={ref}
      onClick={toggleShowForm}
      className={props.class ? props.class + " FancyButton" : "FancyButton"}
    >
      {props.children}
    </button>
  ));

  const ZoomFab = React.forwardRef((props, ref) => (
    <Zoom in={formShown} children={Fab}>
      <Fab
        // ref={zoomRef}
        ref={ref}
        color="primary"
        aria-label="add"
        // onClick={toggleShowForm}
        onClick={(event) => {
          event.preventDefault();
          // props.onAdd(menu);
          // resetMenu();
          setMenu(emptyMenu);
          toggleShowForm();
        }}
      >
        {children}
        {/* <AddIcon /> */}
        {/* <NoteAddIcon /> */}
      </Fab>
    </Zoom>
  ));

  // function handleClearState() {
  //   if (clearStateFunc) clearStateFunc();
  // }

  function deleteMenuItem(meal, indx) {
    if (meal === "lunch") menu.lunch[indx] = {};
    else menu.dinner[indx] = {};
    console.log("deleteMenuItem ", indx);
  }

  // function passClearStateFunc(clearState){
  //   setClearStateFunc(clearState);
  // }

  return (
    <div className="MenuCards">
      <form className="createMenu">
        <React.Fragment>
          <h4>Una nueva entrada</h4>
        </React.Fragment>
        {formShown ? (
          <React.Fragment>
            <div className="row">
              <div className="col-md-4">
                {/* {menu.date.toLocaleDateString("de-DE")} */}
                {/* {menu.weekday === "......"
                  ? weekday[new Date(Date.now()).getDay()]
                  : menu.weekday} */}
                {menu.weekday}
              </div>
              {/* <div className="col-md-8">
                <div className="circle-button">
                  <MenuDatePicker menu={menu} onChange={changeMenuDate} />
                </div>
              </div> */}
            </div>

            <MenuBuilder
              // passClearStateFunc={setClearStateFunc}
              meal={"lunch"}
              reset={reset}
              findByName={findByName}
              menu={menu}
              setMenu={setMenu}
            />
            <MenuBuilder
              // passClearStateFunc={setClearStateFunc}
              meal={"dinner"}
              reset={reset}
              findByName={findByName}
              menu={menu}
              setMenu={setMenu}
            />

            {/* <input
              name="dinner"
              placeholder="Dinner"
              value={menu.dinner}
              onChange={changeMenu}
              style={{
                display: formShown ? "inline" : "none",
                // padding: "5pt",
                margin: "2pt",
              }}
            /> */}
            {/* <div style={{ backgroundColor: "yellow" }}>
              <div
                style={{ display: "inline" }}
                onClick={handleLoopCourseForDinner}
              >
                <span style={{ verticalAlign: "top" }}>Add</span>
                <AddIcon />
              </div>
              {courses[courseForDinner]}
              <div
                style={{
                  backgroundColor: "#ffffff",
                  border: "solid black 1px",
                  maxWidth: "400px",
                  // height: "20px",
                  overflow: "hidden",
                  padding: "1px",
                }}
              >
                {courses.map((course, indx) => {
                  // console.log("menu, indx ", menu, indx);
                  // console.log(
                  //   "menu, indx ",
                  //   menu &&
                  //     menu.dinner &&
                  //     menu.dinner[indx] &&
                  //     menu.dinner[indx].name,
                  //   indx
                  // );
                  if (menu.dinner[indx])
                    return (
                      <div
                        key={indx}
                        style={{
                          float: "left",
                          padding: "5px 10px",
                          margin: "5px",
                          backgroundColor: "#f5f5f5",
                          borderRadius: "20px",
                        }}
                      >
                        {courses[indx]}
                        {menu &&
                          menu.dinner &&
                          menu.dinner[indx] &&
                          menu.dinner[indx].name}
                        <div
                          onClick={() => deleteMenuItem("dinner", indx)}
                          style={{
                            position: "relative",
                            display: "inline-block",
                            backgroundColor: "#c0c0c0",
                            padding: "2px",
                            borderRadius: "15px",
                            color: "#1976d2",
                            marginLeft: "5px",
                          }}
                        >
                          <ClearIcon style={{ marginTop: "-2px" }} />
                        </div>
                      </div>
                    );
                  else return null;
                })}
              </div>
              <div style={{ clear: "both" }}></div>
              <SearchBox
                passClearState={passClearState}
                destructureVector="matchingRecipes"
                name="dinner"
                onSelect={onSelect}
                placeholder={"Dinner " + courses[courseForLunch]}
                // the recipes are now objects {name, id}
                // value={{ name: " ", id: "0" }}
                find={findByName}
              />
            </div> */}
            {/* <textarea
              name="weekday"
              placeholder="Date..."
              rows={formShown ? 3 : 1}
              value={menu.weekday}
              onChange={changeMenu}
            /> */}
            {/* <ZoomFab ref={zoomRef}>
              <AddIcon />
            </ZoomFab> */}

            <button
              className="saveNewButton"
              onClick={(event) => {
                event.preventDefault();
                if (dayHasMenu) onReplace(id, menu);
                else onAdd(id, menu);
                toggleShowForm();
              }}
            >
              <div style={{ display: "inline" }}>
                <span style={{ verticalAlign: "top" }}>Save</span>
                <CheckCircleOutlineIcon />
              </div>
            </button>

            <FancyButton ref={ref} class="cancelNewButton">
              <ClearIcon />
              {/* Click me! */}
            </FancyButton>

            <button
              className=""
              onClick={(event) => {
                event.preventDefault();
                // handleClearState();
                setReset(true);
              }}
            >
              <div style={{ display: "inline" }}>
                <span style={{ verticalAlign: "top" }}>Reset</span>
                <CheckCircleOutlineIcon />
              </div>
            </button>
          </React.Fragment>
        ) : (
          <FancyButton ref={ref}>
            <div style={{ display: "inline" }}>
              <span style={{ verticalAlign: "top" }}>Add</span>
              <AddIcon />
            </div>

            {/* <NoteAddIcon /> */}
            {/* Click me! */}
          </FancyButton>
          // null
        )}
      </form>
    </div>
  );
}

export default CreateMenuEntry;
