import React, { useState, useEffect, useRef } from "react";
import { redirect } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import KeyIcon from "@mui/icons-material/Key";

import Alert from "react-bootstrap/Alert";

import { history } from "../../helpers/history";
import { hasTokenExpired } from "../Auth/helper";

import UserService from "../../services/user.service";
import ListService from "../../services/list.service";
import ShoppingListService from "../../services/shoppingList.service";
import LoadingSpinner from "../Utils/LoadingSpinner";
import CategorisedIngredients from "../../components/Ingredients/CategorisedIngredients";
import CategoryDataService from "../../services/category.service";
import { saveShoppingList } from "../../components/ShoppingList/ShoppingList.Aux";
import QRCodeReader from "../QRCode/QRCodeReader";

const ensemble = "Ingredient";

const BoardUser = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [content, setContent] = useState("");
  const [item, setItem] = useState(null);
  const [items, setItems] = useState(null);
  const [list, setList] = useState(null);
  const [lists, setLists] = useState(null);
  // const { message } = useSelector((state) => state.message);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(!content);
  const [isInError, setIsInError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [listName, setListName] = useState("");
  const [categoryGroup, setCategoryGroup] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const myRef = useRef("there is no element");
  // const ingredients = useRef([]);
  // console.log("currentUser: ", {...currentUser, avatarImage:""});
  // console.log("empty: ", content);
  // console.log("loading: ", loading);

  useEffect(() => {
    retrieveCategoryGroup(ensemble);
  }, []);

  useEffect(() => {
    setIngredients(
      list?.items.map((item, indx) => {
        return { ingredient: item };
      })
    );
    // console.log("ingredients ", ingredients);
  }, [list]);

  const retrieveCategoryGroup = (ensemble) => {
    CategoryDataService.getAll({ name: "", ensemble: ensemble })
      .then((response) => {
        setCategoryGroup(response.data.categoryGroup);
        // console.log("response.data.categoryGroup ", response.data.categoryGroup);
        // console.log(response);
      })
      .catch((e) => {
        console.log("retrieveCategoryGroup: ", e);
      });
  };

  const setActiveIngredient = (item, index) => {
    // console.log("item ", item);
    // console.log("index ", index);
    setItem(item);
    setCurrentIndex(index);
  };

  useEffect(() => {
    UserService.getUserBoard(currentUser.id).then(
      (response) => {
        setLoading(false);
        // console.log("response.status: ", response.status);
        // console.log("response.data: ", response.data);
        // if (response.status === 401) {
        //   return redirect("/home");
        //   ;
        // }
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);

        console.log("error.response: ", error.response);
        console.log("error.message: ", error.message);
        console.log("error.response.status : ", error.response.status);
        if (error.response.status === 401) {
          return redirect("/home");
          // setContent("dusodufosuofdsuofidsu");
        } else {
          setContent(_content);
        }
      }
    );
  }, []);

  useEffect(() => {
    // handleSave(errorElement, currentUser, items);
    setLoading(true);
    async function fetchList() {
      // You can await here
      const response = await getLists();
      // ...
      return response;
    }
    fetchList();
    // console.log("updating");
  }, []);

  async function getShoppingListNow() {
    setLoading(true);
    async function fetchShoppingList() {
      // You can await here
      const response = await getShoppingList();
      // ...
      return response;
    }
    const response = await fetchShoppingList();
    return response;
  }

  if (
    !currentUser ||
    hasTokenExpired(currentUser.accessToken || localStorage.getItem("token"))
  ) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  /**
   * database functions
   */

  async function getShoppingList() {
    return ShoppingListService.getShoppingList(currentUser)
      .then(
        (response) => {
          // console.log("response.data.totalItems ", response.data.totalItems);
          // console.log("response.data.items ", response.data.items);
          // console.log(
          //   "response.data.items[0].index ",
          //   // response.data.items[0].items[0].index
          //   response.data.items[0].index
          // );
          // console.log(response.status);
          console.log("getShoppingList() ", response.data);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // if (errorElement) {
          // errorElement.hidden = true;
          // errorElement.style.display = "none"; //"block";

          // errorElement.hidden = true;
          // errorElement.innerText = response.statusText;
          // errorElement.innerText = "";
          // errorElement.style.display = "block";
          // errorElement.style.display = "none";
          // }
          // setMessage(response.statusText);
          if (response.status === 200) {
            if (response.data.items.length === 0) {
              setMessage(
                "You have no items in your shopping cart. Please add items you need to your shopping list and then... enjoy shopping."
              );
            } else {
              // setMessage(response.statusText);
              setMessage("");
            }
          } else {
            // setMessage(response.statusText);
            setMessage(response.statusText);
          }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(false);
          setLoading(false);
          setItems((prevValue) => {
            // console.log(prevValue);
            // console.log(response.data);
            // return [...prevValue, ...response.data];
            // return [...response.data.items[0].items];
            // console.log(response.data.items);
            // console.log(listOfItemsToBuy);
            // return [...response.data.items];
            return response.data.items;
          });
          return response.data.items;
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...")
          // const errorElement = myRef.current;
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // console.log("error: ", error ? error : "no error");
          // console.log("_content: ", _content ? _content : "no _content");

          // if (errorElement) {
          //   errorElement.hidden = false;
          //   errorElement.innerText = "No items found. Error: " + _content;
          //   errorElement.style.display = "block";
          // }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(true);
          setMessage(_content);

          // Set it to an empty array, since the user exists,
          // but it has NO items
          setItems([]);
          setLoading(false);
        }
        // ).catch((err) => (console.log(err)));
      )
      .catch(() => {
        setLoading(false);
      });
  }

  async function getLists() {
    return ListService.getLists(currentUser)
      .then(
        (response) => {
          // console.log("response.data.totalItems ", response.data.totalItems);
          // console.log("response.data.lists ", response.data.lists);
          // console.log(
          //   "response.data.lists[0].index ",
          //   // response.data.lists[0].lists[0].index
          //   response.data.lists[0].index
          // );
          // console.log(response.status);
          // console.log("getLists() ", response.data.lists);
          // console.log(response.status);
          // console.log(response.statusText);
          // console.log(response.headers);
          // console.log(response.config);
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // if (errorElement) {
          // errorElement.hidden = true;
          // errorElement.style.display = "none"; //"block";

          // errorElement.hidden = true;
          // errorElement.innerText = response.statusText;
          // errorElement.innerText = "";
          // errorElement.style.display = "block";
          // errorElement.style.display = "none";
          // }

          if (response.status === 200) {
            if (response.data.lists.length === 0) {
              setMessage(
                "You have no lists in your inventory. Please go shopping and update your inventory."
              );
            } else {
              setMessage("");
            }
          } else {
            setMessage(response.statusText);
          }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(false);
          setLoading(false);

          // console.log("setLists() lists ", response.data.lists);

          setLists((prevValue) => {
            // console.log(prevValue);
            // console.log("setLists() lists ", response.data.lists);
            // return [...prevValue, ...response.data];
            // return [...response.data.lists[0].lists];
            // return [...response.data.lists];
            return response.data.lists;
          });
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // const errorElement = document.getElementById("error");
          // console.log("errorElement: ", errorElement ? errorElement : "no ...")
          // const errorElement = myRef.current;
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          // console.log("error: ", error ? error : "no error");
          // console.log("_content: ", _content ? _content : "no _content");

          // if (errorElement) {
          //   errorElement.hidden = false;
          //   errorElement.innerText = "No lists found. Error: " + _content;
          //   errorElement.style.display = "block";
          // }
          // console.log("errorElement: ", errorElement ? errorElement : "no ...");
          setIsInError(true);
          setMessage(_content);

          // Set it to an empty array, since the user exists,
          // but it has NO lists
          setLists([]);
          setLoading(false);
        }
        // ).catch((err) => (console.log(err)));
      )
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function saveLists(currentUser, lists) {
    const errorElement = document.getElementById("error");
    // console.log("saveList: lists ", lists);
    // console.log("notes: ", notes);
    // console.log("postList()/saveList() ", currentUser);

    // just replace the list of todos, i.e. the tasks
    // console.log("todo.current: ", todo.current);

    ListService.postLists(currentUser, lists).then(
      (response) => {
        if (errorElement) {
          errorElement.hidden = false;
          errorElement.style.color = "black";
          errorElement.style.display = "block"; // "none";
          errorElement.innerText = "Server's answer: " + response.data.message;
        }
        return response.data.message;
      },
      (error) => {
        const _content =
          error.message ||
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.toString();
        if (errorElement) {
          errorElement.hidden = false;
          errorElement.innerText = _content;
        }
        // console.log("error ", error);
        // console.log("error.message ", error.message);
        // console.log("error.toString() ", error.toString());
        // console.log("error.response ", error.response);
        // console.log("error.response.data ", error.response.data);
        // console.log("error.response.data.message ", error.response.data.message);
        // console.log("_content ", _content);
        // console.log("errorElement ", errorElement);
        return _content;
      }
    );
  }

  async function saveShoppingListAsList() {
    const theItems = await getShoppingListNow();
    const list = {
      name: listName,
      items: theItems,
    };

    let newLists;
    if (!lists) {
      // newLists = { userid: currentUser.id, lists: [list] };
      newLists = [list];
    } else {
      // newLists = { userid: currentUser.id, lists: [...lists, list] };
      newLists = [...lists, list];
    }

    console.log("theItems ", theItems);
    console.log("list ", list);
    console.log("items ", items);
    console.log("lists ", lists);
    console.log("newLists ", newLists);
    saveLists(currentUser, newLists);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setListName(value);
  };

  function handleLoad(indx) {
    const theItems = lists[indx].items;
    console.log("theItems: ", theItems);
    console.log("indx: ", indx);
    saveShoppingList(currentUser, theItems);
    // alert(indx);
  }

  function handleShow(indx) {
    // console.log("indx, lists[indx], lists ", indx, lists[indx], lists);
    setList(lists[indx]);
  }

  function handleEdit(indx) {
    setEditMode(!editMode);
  }

  function handleDelete(indx) {
    const newLists = lists.filter((list, i) => i !== indx);
    setLists(newLists);
    saveLists(currentUser, newLists);
  }

  function handleEditItem(indx) {
    setEditMode(true);
  }

  function handleDeleteItem(indx) {
    const newItems = list.items.filter((item, i) => i !== indx);
    setItems(newItems);
    saveListItems(currentUser, newItems);
  }

  function saveListItems(currentUser, lists) {}

  if (loading) {
    // console.log("loading: ", loading);
    return (
      <div className="loader">
        Loading ... <LoadingSpinner loading={loading} />
      </div>
    );
  }
  if (!loading && content) {
    // console.log("loading: ", loading);
    // console.log("favoriteRecipes ", currentUser.favoriteRecipes);
    // console.log("currentUser ", currentUser);
    return (
      <div className="container">
        <KeyIcon style={{ position: "absolute", left: "0" }} />
        {message !== "" ? (
          <Alert
            ref={myRef}
            id="error"
            key="info"
            variant="info"
            // style={{ display: "none" }}
          >
            {/* <p>{message}</p> */}
            {message}
          </Alert>
        ) : null}
        <header className="jumbotron">
          <h3>{content}</h3>
        </header>
        <div>Last login: {currentUser.lastLoggedIn}</div>
        <main>
          <div className="mt-4">
            <h3>Here are your favorite recipes</h3>
            <div>
              <ul>
                {currentUser.favoriteRecipes?.map((recipe, indx) => {
                  return <li key={indx}>{recipe.id}</li>;
                })}
              </ul>
            </div>
          </div>

          <div className="mt-4">
            {lists ? (
              <h3>Here are your lists</h3>
            ) : (
              <h3>You have no lists yet</h3>
            )}
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="name">Name of your list</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={listName}
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="col-6"
                  style={{
                    display: "inline",
                    verticalAlign: "bottom",
                    marginTop: "30px",
                  }}
                >
                  <button
                    className="badge bg-warning mr-2"
                    style={{ color: "#0d6efd", marginLeft: "10px" }}
                    onClick={saveShoppingListAsList}
                  >
                    Guardar su ShoppingList
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* {console.log("lists ", lists)} */}
          <div>
            <div className="mt-4">
              <div className="row" style={{ textAlign: "center" }}>
                <div className="col-4">
                  {/* list.id */}
                  list.name
                </div>
                <div className="col-2">Cargar</div>
                <div className="col-2">Ver</div>
                <div className="col-2">Editar</div>
                <div className="col-2">Borrar</div>
                {lists?.map((list, indx) => {
                  return (
                    <div
                      className="row"
                      key={indx}
                      style={{ textAlign: "center" }}
                    >
                      <div className="col-4">
                        {/* {list.id}  */}
                        {list.name}
                      </div>
                      <div className="col-2">
                        <button
                          className="badge bg-warning mr-2"
                          style={{ color: "#0d6efd", marginLeft: "10px" }}
                          onClick={() => handleLoad(indx)}
                        >
                          Cargar
                        </button>
                      </div>
                      <div className="col-2">
                        <button
                          className="badge bg-warning mr-2"
                          style={{ color: "#0d6efd", marginLeft: "10px" }}
                          onClick={() => handleShow(indx)}
                        >
                          Ver
                        </button>
                      </div>
                      <div className="col-2">
                        <button
                          className="badge bg-warning mr-2"
                          style={{ color: "#0d6efd", marginLeft: "10px" }}
                          onClick={() => handleEdit(indx)}
                        >
                          Editar
                        </button>
                      </div>
                      <div className="col-2">
                        <button
                          className="badge bg-warning mr-2"
                          style={{ color: "#0d6efd", marginLeft: "10px" }}
                          onClick={() => handleDelete(indx)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-4">
              {list ? (
                <ul style={{ listStyle: "none", paddingLeft: "0rem" }}>
                  <h4>The items in the selected list</h4>
                  {list?.items?.map((item, indx) => {
                    return (
                      <li key={indx}>
                        <div className="row">
                          <div className="col-4">
                            {/* {item.id} */}
                            {item.name}
                          </div>
                          <div className="col-1">{item.quantity}</div>
                          <div className="col-2">{item.qtyUnit}</div>
                          <div className="col-1">
                            {item.price ? item.price : 1.0}€
                          </div>
                          {editMode ? (
                            <div className="col-2">
                              <button
                                className="badge bg-warning mr-2"
                                style={{
                                  color: "#0d6efd",
                                  marginLeft: "10px",
                                }}
                                onClick={() => handleEditItem(indx)}
                              >
                                Editar
                              </button>
                            </div>
                          ) : null}
                          {editMode ? (
                            <div className="col-2">
                              <button
                                className="badge bg-warning mr-2"
                                style={{
                                  color: "#0d6efd",
                                  marginLeft: "10px",
                                }}
                                onClick={() => handleDeleteItem(indx)}
                              >
                                Borrar
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
            <div>
              {/* {console.log(
                "list, list.items, categoryGroup ",
                list,
                list?.items,
                categoryGroup
              )} */}
              {/* {console.log("ingredients ", ingredients )} */}
              {ingredients ? (
                <CategorisedIngredients
                  categoryGroup={categoryGroup}
                  // ingredients={[{ ingredient: list?.items }]}
                  ingredients={ingredients}
                  setActiveIngredient={setActiveIngredient}
                  currentIndex={currentIndex}
                />
              ) : null}
            </div>
          </div>
          <div><QRCodeReader /></div>
        </main>
      </div>
    );
  }
  return null;
};

export default BoardUser;
