import * as React from "react";

import dayjs, { Dayjs } from "dayjs";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, deDE, TimePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";

export default function BasicTimePicker({ label, value, onChange }) {
  const [theValue, setTheValue] = React.useState({});

  useEffect(() => {
    if (value) {
      let H = parseInt(value.split(":")[0]);
      let m = parseInt(value.split(":")[1].replace("h", ""));
      // console.log(H, m);
      // setTheValue(
      //   value
      //     ? {
      //         $D: NaN,
      //         $H: H,
      //         $L: "en",
      //         $M: NaN,
      //         $W: NaN,
      //         $d: null,
      //         $m: m,
      //         $ms: NaN,
      //         $s: NaN,
      //         $u: undefined,
      //         $x: {},
      //         $y: NaN,
      //       }
      //     : null
      // );
      // console.log(
      //   value
      //     ? {
      //         $D: NaN,
      //         $H: H,
      //         $L: "en",
      //         $M: NaN,
      //         $W: NaN,
      //         $d: null,
      //         $m: m,
      //         $ms: NaN,
      //         $s: NaN,
      //         $u: undefined,
      //         $x: {},
      //         $y: NaN,
      //       }
      //     : null
      // );
      // console.log(dayjs("2022-04-07T01:30"));
      // setTheValue(dayjs("2022-04-07T01:30"));
      const timeString = `2022-04-07T ${H}:${m}`;
      // console.log(timeString);
      setTheValue(dayjs(timeString));
      // [$H, $m] =value.split(":");
    }
  }, [value]);

  function handleOnChange(newValue) {
    const timeString = newValue.$H + ":" + newValue.$m + "h";
    // console.log(timeString);
    const event = {
      target: {
        name: "time",
        value: timeString,
      },
    };
    // console.log(event);
    onChange(event);
  }
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={
        deDE.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <TimePicker
        name="time"
        label={label}
        value={theValue}
        onChange={(newValue) => {
          setTheValue(newValue);
          // console.log(newValue);
          // console.log(event);
          handleOnChange(newValue);
        }}
        // renderInput={(params) => <TextField {...params} />}
        componentsProps={{ textField: { variant: "outlined" } }}
      />
    </LocalizationProvider>
  );
}
