/**
 * boilerplate
 */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import GroupsIcon from "@mui/icons-material/Groups";

import SpinButton from "../ShoppingList/SpinButton";

import RecipeDataService from "../../services/recipe.service";

import "./Recipes.css";

/**
 * component definition
 */

const AddRecipe = () => {
  const { message } = useSelector((state) => state.message);
  const { user: currentUser } = useSelector((state) => state.auth);
  
  const navigate = useNavigate();

  const handleBackClick = () => {
    // 👇️ replace set to true
    navigate("/recipeslist", { replace: true });
  };

  const initialRecipeState = {
    id: null,
    name: "",
    description: "",
    published: false,
    media:{
      images:{
        head: ""
      }
    }
  };

  const [recipe, setRecipe] = useState(initialRecipeState);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = event => {
    const { name, value } = event.target;
    // console.log("recipe ", recipe);
    let theRecipe = {};
    if (name === "name") {
      const fileLocation = "assets/images/";
      const picture = fileLocation + value.replaceAll(" ", "-").toLowerCase();
      const media = {};
      media.images = {};
      media.images.head = picture;
      theRecipe = { ...recipe, media: media };
      theRecipe = { ...theRecipe, [name]: value };
    }
    else if (name === "media-images-head") {
      const media = {};
      media.images = {};
      media.images.head = value;
      theRecipe = { ...recipe, media: media };
    } else {
      theRecipe = { ...recipe, [name]: value };
    }
    // console.log("theRecipe ", theRecipe);
    setRecipe(theRecipe);
  };

  const saveRecipe = () => {
    var data = {
      name: recipe.name,
      description: recipe.description,
      // ingredients: recipe.ingredients,
      // instructions: recipe.instructions,
      published: false,
      media: {
        images: {
          head: recipe.media.images.head,
        },
      },
    };
    RecipeDataService.create(data)
      .then(response => {
        // console.log("saveRecipe() response data name", response.data.name);
        // console.log(
        //   "saveRecipe() response data description",
        //   response.data.description
        // );
        // console.log("saveRecipe() response data id", response.data.id);
        // console.log("saveRecipe() response data", response.data);
        setRecipe({
          id: response.data.id,
          name: response.data.recipe.name,
          description: response.data.recipe.description,
          published: response.data.recipe.published,
          media: {
            images: {
              head: response.data.recipe.media.images.head
            }
          },
        });
        setSubmitted(true);
        // console.log("saveRecipe() ", response.data);
      })
      .catch(e => {
        console.log("saveRecipe() ", e);
      });
  };

  const newRecipe = () => {
    setRecipe(initialRecipeState);
    setSubmitted(false);
  };

  return (
    <div className="submit-form">
      {submitted ? (
        <div>
          <h4>You submitted successfully!</h4>
          <button className="btn btn-success" onClick={newRecipe}>
            Add
          </button>
          <button className="btn btn-success" onClick={handleBackClick}>
            Back
          </button>
        </div>
      ) : (
        <div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              required
              value={recipe.name}
              onChange={handleInputChange}
              name="name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              className="form-control"
              id="description"
              required
              value={recipe.description}
              onChange={handleInputChange}
              name="description"
            />
          </div>
          <div className="form-group">
            {/* <label>
                      <strong>Serves:</strong>
                    </label> */}
            <SpinButton
              myStyle={{ margin: "10", padding: "10", width: "100px" }}
              id="serves"
              className="form-control"
              value={recipe.servings}
              label="Serves"
              onChange={handleInputChange}
              name="serves"
            />
            {/* {recipe.servings} */}
            <GroupsIcon /> {recipe.servings ? recipe.servings : "4"}{" "}
          </div>

          <div className="form-group">
            <label htmlFor="media-images-head">Picture</label>
            <input
              type="text"
              className="form-control"
              id="media-images-head"
              required
              value={
                recipe.media && recipe.media.images && recipe.media.images.head
                  ? recipe.media.images.head
                  : "assets/images/"
              }
              onChange={handleInputChange}
              name="media-images-head"
            />
          </div>
          <button onClick={saveRecipe} className="btn btn-success">
            Submit
          </button>
          <button onClick={handleBackClick} className="btn btn-success">
            Back
          </button>
        </div>
      )}
    </div>
  );
};
export default AddRecipe;

